<template>
    <section class="bgcc">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">สรุปยอดเงินการสะสางดอกเบี้ย</h1>
            </div>
            <div class="flex ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <!-- <div class="flex flex-col md:flex-row">
                        <div class="sm:p-2 md:p-2 sm:w-full md:w-4/5">
                            <u><b>สะสางดอกเบี้ยในหมวดหมู่ :- </b></u>
                        </div>
                    </div> -->
                    <div class="flex flex-col md:flex-row">
                        <div class="mt-2 sm:p-2 md:p-2 sm:w-full md:w-2/4">
                           <!-- {{cleanupTypeText(cleanupType)}} -->
                           จำนวนเงิน
                        </div>
                        <div class="mt-2 md:text-right sm:p-2 md:p-2 sm:w-full md:w-2/4">
                            {{showMoney(money)}} บาท
                        </div>
                    </div>
                </div>
            </div>
            <form @submit.prevent="qrpay()">
                <!-- <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-2 md:mr-2 lg:mr-2 sm:w-full md:w-2/4">
                                <input type="text" placeholder="ชื่อ"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.name}"
                                    v-model="name">
                                <div v-if="serverError.name" class="mt-2 text-sm text-red-500">
                                    {{serverError.name[0]}}
                                </div>   
                            </div>
                            <div class="mt-2 md:ml-2 lg:ml-2 sm:w-full md:w-2/4">
                                <input type="text" placeholder="นามสกุล"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.lastname}"
                                    v-model="lastname">
                                <div v-if="serverError.lastname" class="mt-2 text-sm text-red-500">
                                    {{serverError.lastname[0]}}
                                </div>  
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex flex-col md:flex-row">
                            <div class="mt-2 md:mr-2 lg:mr-2 sm:w-full md:w-2/4">
                                 <input type="text" placeholder="อีเมล"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.email}"
                                    v-model="email">
                                <div v-if="serverError.email" class="mt-2 text-sm text-red-500">
                                    {{serverError.email[0]}}
                                </div>
                            </div>
                            <div class="mt-2 md:ml-2 lg:ml-2 sm:w-full md:w-2/4">
                                <input type="text" placeholder="เบอร์โทรศัพท์"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.telephone}"
                                    v-model="telephone">   
                                <div v-if="serverError.telephone" class="mt-2 text-sm text-red-500">
                                    {{serverError.telephone[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                    <div  class="flex-1 max-w-4xl m-5">
                        <div class="flex flex-col md:flex-row">
                            <div class="sm:p-2 md:p-2 sm:w-full md:w-4/5">
                                <u><b>ช่องทางการชำระเงิน :- </b></u>
                            </div>
                        </div>
                        <div class="flex ml-10 mr-10 rounded-lg">
                            <div  class="justify-center flex-1 clemax-w-4xl">
                                <div class="flex flex-col md:flex-row">
                                    <div class="mt-2 md:mr-2 lg:w-1/2 sm:w-full md:w-1/2">
                                        <div>
                                            <input @click="selectPaymentMethod(2)" class="hidden" id="radio_1" type="radio" name="radio">
                                            <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_1">
                                                <span class="text-xs font-semibold uppercase">
                                                    <img src="@/assets/img/qr_scb.png" alt="">
                                                </span>
                                                <span class="mt-3 text-xl font-bold text-center">
                                                    โอนเงินด้วย qr code
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="mt-2 md:ml-2 lg:w-1/2 sm:w-full md:w-1/2">
                                        <div>
                                            <input @click="selectPaymentMethod(1)" class="hidden" id="radio_2" type="radio" name="radio">
                                            <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_2"
                                                :class="{'border-red-500': serverError.payment_method}">
                                                <span class="text-xs font-semibold uppercase">
                                                    <img src="@/assets/img/kasikorn-bank.png" alt="">
                                                </span>
                                                <span class="mt-3 text-xl font-bold text-center">
                                                    บัญชีธนาคาร
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4 row">
                                    <p v-if="paymentMethod == 1" class="text-base">
                                        ธนาคารกสิกรไทย สาขายะลา ชื่อบัญชี:มูลนิธิจูจีสร้างสรรค์สังคม (กองทุนสะสางดอกเบี้ย)
                                        <span class="text-sm font-bold text-red-500" style="margin-top: -15px">
                                            &nbsp;&nbsp;
                                            099-8-33940-5
                                            <button @click.stop.prevent="copyToClipboard()"  class="px-1 py-1 mb-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700">
                                                <div class="flex">
                                                    <span>คัดลอก</span> 
                                                    <svg class="w-5 h-5" :class="{'hidden': is_copied}" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                            </button>
                                            <input type="hidden" id="accountNumber" value="0998339405">
                                        </span>
                                    </p>
                                    <p v-if="paymentMethod == 1" class="font-baseextrabold text-">
                                        อย่าลืมกรอกฟอร์มยืนยันโอนเงินที่เว็บไซต์ หลังจากโอนเงินผ่านธนาคารแล้ว
                                    </p>
                                    <!-- <p v-if="paymentMethod == 2" class="text-base">ชำระผ่านการ สแกน/อัพโหลด QR code ด้วย mobile banking application ของ ธนาคารไทยพานิชย์ ธนาคารทหารไทย ธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรี ธนาคารธนชาต ธนาคารกสิกร ธนาคารออมสิน</p>
                                    <p v-if="paymentMethod == 2" class="font-baseextrabold text-">คุณจะได้ QR code หลังจากดกปุ่มยืนยัน</p> -->
                                    <p v-if="paymentMethod == 2" class="font-baseextrabold text-">
                                        พร้อมให้บริการเร็วๆนี้
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="flex mt-2 ml-10 mr-10 rounded-lg">
                    <button type="submit"
                    class="w-full mt-1 btncc-riba"
                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                    :disabled="disabled"
                    v-if="paymentMethod == 2"
                    >
                    ถัดไป
                    </button>
                </div> -->
                <!-- <div class="flex mt-2 ml-10 mr-10 rounded-lg" v-if="paymentMethod == 2">
                    <button
                        class="w-full mt-1 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                    ยืนยันการสะสางดอกเบี้ย
                    </button>
                </div> -->
                <!-- <button class="w-full mt-1 btncc-riba" 
                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                    :disabled="disabled"
                    v-if="paymentMethod == 1"
                > 
                    ถัดไป
                </button> -->
            </form>

            <form @submit.prevent="checkout()" v-if="paymentMethod == 1">
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-full">
                                วันที่โอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-4/12 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.year}"
                                    v-model="year">
                                    <option style="display: none">--ปี--</option>
                                    <option v-for="(year, index) in yearList" :value="year" :key="index">{{year}}</option>
                                </select>
                                <div v-if="serverError.year" class="mt-2 text-sm text-red-500">
                                    {{serverError.year[0]}}
                                </div>
                            </div>
                            <div class="w-4/12 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.month}"
                                    v-model="month"
                                    @change="changeMonth($event.target.value)">
                                    <option value="" style="display: none">--เดือน--</option>
                                    <option v-for="(month, index) in 12" :value="formatMonth(month)" :key="index">{{formatMonth(month)}}</option>
                                </select>
                                <div v-if="serverError.month" class="mt-2 text-sm text-red-500">
                                    {{serverError.month[0]}}
                                </div>
                            </div>
                            <div class="w-4/12 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.day}"
                                    v-model="day">
                                    <option value="" style="display: none">--วันที่--</option>
                                    <option v-for="(day, index) in dayList" :value="day" :key="index">
                                        {{formatDay(day)}}
                                    </option>
                                </select>
                                <div v-if="serverError.day" class="mt-2 text-sm text-red-500">
                                    {{serverError.day[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                เวลาโอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.hour}"
                                    v-model="hour">
                                    <option value="" style="display: none">--ชั่วโมง--</option>
                                    <option v-for="(h, index) in 24" :value="hourFormat(index)" :key="index">{{hourFormat(index)}}</option>
                                </select>
                                <div v-if="serverError.hour" class="mt-2 text-sm text-red-500">
                                    {{serverError.hour[0]}}
                                </div>
                            </div>
                            <div class="w-2/4 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.minute}"
                                    v-model="minute">
                                    <option value="" style="display: none">--นาที--</option>
                                    <option v-for="(m, index) in 60" :value="minuteFormat(index)" :key="index">{{minuteFormat(index)}}</option>
                                </select>
                                <div v-if="serverError.minute" class="mt-2 text-sm text-red-500">
                                    {{serverError.minute[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                หลักฐานการโอนเงิน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-1 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                <input type="file" accept="image/png, image/gif, image/jpeg"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 bg-white bg-auto border rounded"
                                    :class="{'border-red-500': serverError.slip}"
                                    @change="onFileChange">
                                <div v-if="serverError.slip" class="mt-2 text-sm text-red-500">
                                    {{serverError.slip[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                หมายเหตุ
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 mr-1 md:mr-1 lg:mr-1">
                                <textarea rows="5"
                                class="w-full px-3 py-2 leading-tight text-gray-700 bg-white bg-auto border rounded"
                                :class="{'border-res-500': serverError.description}"
                                v-model="description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-2 ml-10 mr-10 rounded-lg ">
                    <button
                        class="w-full mt-1 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                    ยืนยันการสะสางดอกเบี้ย
                    </button>
                </div>
            </form>
            
        </div> 
    </section>
</template>

<script>
    import filter from '@/helpers/filter.js'
    import http from '@/services/FrontendService.js'
    import moment from 'moment'
// import { faL } from '@fortawesome/free-solid-svg-icons'
    // import unLoginhttp from '@/services/AuthService.js'
    export default {
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
            // this.retreivePendingHistory()
        },
        data(){
            return{
                yearList: [],
                serverError: '',
                //form data
                name: '',
                lastname: '',
                email: '',
                telephone: '',
                receipt: 0,
                paymentMethod: '',
                loginStatus: 0,
                // cleanup_type: localStorage.getItem('cuciCleanupTypeSelected'),
                disabled: false,
                money: localStorage.getItem('riba_amount'),
                dayList: [],
                year: '',
                month: '',
                day: '',
                hour: '',
                minute: '',
                slip: '',
                imgUrl: '',
                description: '',
                is_copied: true,
            }
        },
        created(){
            // this.timer = setInterval(this.checkPayment, 5000)
            // window.location.hash = 'top'
            // this.cleanupType = localStorage.getItem('cuciCleanupTypeSelected')
            // if(!localStorage.getItem('cuciCleanupTypeSelected')){
                //     this.$router.push({name: 'CleanupFor'})
                // }
                // if(!localStorage.getItem('cuciCheckoutMoney')){
                    //     this.$router.push({name: 'CleanupFor'})
                    // }
                    // if(!localStorage.getItem('cuciCleanupTypeSelected')){
                        //     this.$router.push({name: 'CleanupFor'})
                        // }
            this.retreiveMemberInfo()
            if(!localStorage.getItem('riba_amount')){
                this.$router.push({name: 'Riba'})
            }

            let beforThisyear = moment().subtract(1, 'year').format("YYYY")
            let yearList = [parseInt(beforThisyear)]
            this.yearList.push(parseInt(beforThisyear))
            let i = 1
            while(i < 2){
                yearList.push(parseInt(yearList[i-1])+1)
                this.yearList.push(parseInt(yearList[i-1])+1)
                i++
            }
            this.year = moment().format("YYYY")
            this.month = moment().format("MM")
            this.dayList = moment().daysInMonth()
            this.day = moment().format("D")
        },
        computed:{
            isLogedIn(){
                return this.$store.getters['authMember/logedIn']
            }
        },
        methods:{
            checkout(){
                this.disabled = true
                this.$Progress.start()
                let data = new FormData()
                data.append('pay_type', this.paymentMethod)
                data.append("money", this.money)
                data.append("year", this.year)
                data.append("month", this.month)
                data.append("day", this.day)
                data.append("hour", this.hour)
                data.append('minute', this.minute)
                data.append('slip', this.slip)
                http.post('transaction/createRibaTransaction', data)
                    .then(response => {
                        if(response.data.isExist == true){
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'ขออภัย',
                                text: 'กรุณาตรวจสอบสลิบโอนเงิน และกรอกวัน เวลาโอนให้ถูกต้อง',
                                confirmButtonText: 'OK',
                            })
                        }else{
                            this.$swal.fire({
                                icon: 'success',
                                title: 'สำเร็จ',
                                text: 'ขอบคุณที่สะสางดอกเบี้ยกับ จูจี ขอความบารอกัตบังเกิดแก่ท่าน',
                                confirmButtonText: 'OK'
                            }).then(() => {
                                this.$router.push({name: 'Riba'})
                                localStorage.removeItem('riba_amount')
                            });   
                        }
                        this.serverError = {}
                        this.disabled = false
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
            },
            onFileChange(e){
                const slip = e.target.files[0]
                this.slip = e.target.files[0]
                this.imgUrl = URL.createObjectURL(slip)
                
            },
            selectPaymentMethod(id){
                this.paymentMethod = id
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0.00')
            },
            copyToClipboard(){
                let accountNumber = document.querySelector('#accountNumber')
                accountNumber.setAttribute('type', 'text')
                accountNumber.select()
                try {
                    document.execCommand('copy');
                    this.is_copied = false
                } catch (err) {
                    alert('Oops, unable to copy');
                }
                /* unselect the range */
                accountNumber.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges() 
            },
            retreiveMemberInfo(){
                if(this.isLogedIn){//ถ้าผู้ใช้เข้าสู่ระบบแล้ว
                    http.get('member/retreiveInfo').then(response => {
                        this.name = response.data.user.name
                        this.lastname = response.data.user.lastname
                        this.email = response.data.user.email
                        this.telephone = response.data.user.telephone
                        this.loginStatus = 1
                    }).catch(error => {
                        if(error.response.status == 401){ 
                            this.$router.push({name: 'Login'})
                        }
                    })
                }
            },
            minuteFormat(m){
                return moment(m, "mm").format("mm")
            },
            hourFormat(h){
                return moment(h, "HH").format("HH")
            },
            changeMonth(month){
               this.dayList = moment(moment().month(month-1).year(this.year).format('YYYY-MM')).daysInMonth()
            },
            formatMonth(month){
                return moment(month, "m").format("mm")
            },
            formatDay(d){
                return filter.dayFormat(d)
            },
            qrpay(){
                let data = new FormData()
                let url = ''
                data.append("name", this.name)
                data.append("lastname", this.lastname)
                data.append("email", this.email)
                data.append("telephone", this.telephone)
                data.append("receipt", this.receipt)
                data.append("paymentMethod", this.paymentMethod)
                data.append("money", this.money)
                data.append("cleanup_type", this.cleanup_type)
                // if(this.isLogedIn){
                url = http.post('cleanup/signinConfirming', data)
                // }else{
                //     url = unLoginhttp.post('unsigninCleanup/unsigninConfirming', data)
                // }
                this.disabled = true
                this.$Progress.start()
                url.then(response => {
                    localStorage.setItem('cref', response.data.cref)
                    // if(this.paymentMethod == 1){
                    //     this.$router.push({name: 'CleanupConfirming'})
                    // }else{
                    this.$router.push({name: 'QrCleanup'})
                    // }
                    console.log(this.paymentMethod)
                    this.serverError = ''
                    this.disabled = false
                    this.$Progress.finish()
                }).catch(error => {
                    if(error.response.status == 401){ //ถ้า token หมดอายุ
                        this.$router.push({name: 'Login'})
                    }
                    this.serverError = error.response.data.errors
                    this.disabled = false
                    this.$Progress.fail()
                })
            },
            // cleanupTypeText(id){
            //    return  filter.cleanupType(id)
            // },
        },
    }
</script>

<style lang="css">
    input:checked + label {
        border-color: rgb(27, 175, 16) !important;
        box-shadow: 0 10px 15px -3px rgba(140, 147, 140, 0.1), 0 4px 6px -2px rgba(126, 131, 127, 0.05) !important;
    }
</style>