<template>
    <section>
        <div class="container mx-auto pt-35 lg:pt-24" >
            <div class="w-full bgcontent-center ">
                <iframe class="w-full" width="" height="1375" src="https://datastudio.google.com/embed/reporting/6e9175b6-d1eb-4581-b8a7-668ebd5f5a58/page/DZ0sB" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>  
        </div>
    </section>
</template>

<script>
    export default{
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }
</script>
