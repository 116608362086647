<template>
    <Navbar/>
        <main>
            <router-view></router-view>
        </main>
    <Footer/>
    <div :class="{'modal-backdrop fade show': backdrop}"></div>
</template>

<script>
import Navbar from '@/components/frontend/Nav.vue'
import Footer from '@/components/frontend/Foot.vue'
export default {
    name: 'frontendLayout',
    components:{
        Navbar,
        Footer
    },
    computed: {
      backdrop(){
        return this.$store.getters['authMember/backdrop']
      },
  },
}
</script>