<template>
    <div v-if="showDeleteModal" id="showFormModal" class="fixed top-0 left-0 flex items-center justify-center w-full h-full modal">
        <div class="absolute w-full h-full bg-gray-900 opacity-70 modal-overlay"></div>
        <div class="z-50 w-11/12 p-5 mx-auto overflow-y-auto bg-white rounded shadow-lg h-2/7 modal-container md:max-w-md">
            <!-- Header -->
            <div class="flex items-center justify-center w-full h-auto">
                <div class="flex items-start justify-start w-full h-auto py-2 text-xl font-bold">
                    <span>ยืนยันการลบ</span>
                </div>
                <div @click="closeDeleteForm()" class="flex justify-center w-1/12 h-auto cursor-pointer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </div>
            <!--Header End-->
            </div>
            <!-- Modal Content-->
            <div class="w-full h-auto mb-4">

                <form @submit.prevent="submitForm()">
                    
                    <label class="block my-3 text-gray-700 text-md" for="name">รหัสผ่าน</label>
                    <input v-model="password" :class="{'border-red-500': serverError.password}" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow" type="password" placeholder="password">
                    <div v-if="serverError.password" class="mt-2 text-sm text-red-500">
                        {{serverError.password[0]}}
                    </div>

                    <div class="grid grid-cols-3 gap-4">
                        
                        <div class="col-span-12">
                            <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="w-full px-4 py-2 mt-4 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg text-md active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                                <span>ยืนยัน</span> 
                            </button>
                        </div>
                        
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex'
    import http from '@/services/BackendService'
    
    export default {
        name: 'category-delete-form',
        data(){
            return{
                
                password: '',
                disabled: false,
                serverError: [],

            }
        },
        computed:{
            ...mapState('projectCategory', [
                'showDeleteModal', 
                'deleteId',
                'currentPage'
            ])
        },

        methods:{
            deleteProjectCatecory(){
                http.delete(`project/category/${this.deleteId}`)
                    .then(() => {
                        this.$store.dispatch("projectCategory/getProjectCategory", {pageNumber: this.currentPage})
                        this.closeDeleteForm()
                    })
            },
            submitForm(){
                this.disabled = true
                this.$Progress.start()

                let data = new FormData()
                data.append('password', this.password)

                http.post(`userIdentify`, data)
                    .then(() => {
                        this.$Progress.finish() 
                        this.disabled = false  
                        this.deleteProjectCatecory()
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.serverError = error.response.data.errors
                        this.disabled = false
                    })
                
            },
            closeDeleteForm(){
                this.$store.dispatch("projectCategory/closeDeleteForm")
                this.password = ''
                this.serverError = ''
            }
        }
    }
    
</script>

<style lang="scss" scoped>

</style>