<template>
    <div v-if="showFormModal" id="showFormModal" class="fixed top-0 left-0 flex items-center justify-center w-full h-full modal">
        <div class="absolute w-full h-full bg-gray-900 opacity-70 modal-overlay"></div>
        <div class="z-50 w-11/12 p-5 mx-auto overflow-y-auto bg-white rounded shadow-lg h-4/5 modal-container md:max-w-md">
            <!-- Header -->
            <div class="flex items-center justify-center w-full h-auto">
                <div class="flex items-start justify-start w-full h-auto py-2 text-xl font-bold">
                    <span v-if="!editMode">เพิ่ม</span>
                    <span v-else>แก้ไข</span>
                </div>
                <div @click="closeFormModal" class="flex justify-center w-1/12 h-auto cursor-pointer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </div>
            <!--Header End-->
            </div>
            <!-- Modal Content-->
            <div class="w-full h-auto mb-4">

                <form ref="categoryForm" @submit.prevent="!editMode ? createCategory() : updateCategory()" enctype="multipart/form-data">
                    
                    <label class="block my-3 text-gray-700 text-md" for="name">ชื่อสินค้า</label>
                    <input v-model="name" :class="{'border-red-500': serverError.name}" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow" type="text" placeholder="Product name">
                    <div v-if="serverError.name" class="mt-2 text-sm text-red-500">
                        {{serverError.name[0]}}
                    </div>

                    <!-- {{name}} -->

                    <label class="block my-3 text-gray-700 text-md" for="description">รายละเอียด</label>
                    <textarea v-model="description"
                        class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow"
                        rows="5"
                        placeholder="รายละเอียดหมวดหมู่"
                    ></textarea>
                    <div v-if="serverError.description" class="mt-2 text-sm text-red-500">
                        {{serverError.description[0]}}
                    </div>

                    <div class="mt-4">
                        <img v-if="imgUrl" :src="imgUrl" class="w-ful" />
                    </div>

                    <label class="block my-3 text-gray-700 text-md" for="image">ไอคอน</label>
                    <input ref="fileUpload" @change="onFileChange" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow" type="file">

                    <!-- <p></p> -->

                    <div class="grid grid-cols-3 gap-4">
                        
                        <div class="col-span-2" :class="{'col-span-12': editMode}">
                            <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="w-full px-4 py-2 mt-4 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg text-md active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                                <span v-if="!editMode">บันทึก</span> 
                                <span v-else>แก้ไข</span>
                            </button>
                        </div>

                        <div>
                            <button v-if="!editMode" type="button" @click="onResetForm" class="w-full px-4 py-2 mt-4 font-medium leading-5 text-white transition-colors duration-150 bg-gray-500 border border-transparent rounded-lg text-md active:bg-purple-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-purple">
                                ล้าง
                            </button>
                        </div>
                        
                    </div>

                </form>
                
            </div>
        </div>
    </div>
</template>

<script>
    import http from '@/services/BackendService'
    import { mapState } from 'vuex'
    export default {
        name: 'category-form',
        data(){
            return{
                
                id: "",
                name: "",
                description: "",
                imgUrl: "",
                file: null,

                serverError: [],

                disabled: false,
                
            }
        },

        computed:{
            ...mapState("projectCategory", [
                "showFormModal",
                "category",
                "editMode",
                "currentPage",
                "keyword",
            ])
        },

        watch:{
            category: function(){
                this.id = this.category.id
                this.name = this.category.name
                this.description = this.category.description
                this.imgUrl = this.category.icon
            },
            editMode: function(){
                if(!this.editMode){
                    this.onResetForm()
                }
                this.serverError = ''
            }
        },

        methods: {
            updateCategory(){
                this.disabled = true
                this.$Progress.start()
                /* let data = new FormData()*/
                let data = new FormData()
                data.append('name', this.name)
                data.append('description', this.description)
                data.append('file', this.file)
                data.append('_method', 'PATCH')
                
                http.post(`project/category/${this.id}`, data)
                    .then(() => {

                        this.serverError = ''

                        if(this.keyword == ""){
                            this.$store.dispatch('projectCategory/getProjectCategory', {pageNumber: this.currentPage})
                        }else{
                            this.$store.dispatch('projectCategory/getProjectCategorySearch', {pageNumber: this.currentPage})
                        }
                        
                        //sweet alert popup
                        const Toast = this.$swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขข้อมูลเรียบร้อย'
                        })

                        
                        this.disabled = false
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()  
                    })
                // console.log("update")

            },

            createCategory(){
                
                this.disabled = true
                this.$Progress.start()
                
                let formData = new FormData()
                formData.append('name', this.name)
                formData.append('description', this.description)
                formData.append('file', this.file)

                http.post('project/category', formData)
                    .then(() => {
                        
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                        })
                        
                        Toast.fire({
                            icon: 'success',
                            title: 'เพิ่มข้อมูลใหม่เรียบร้อย'
                        })
                        
                        this.disabled = false
                        this.$Progress.finish()
                        this.serverError = ''
                        this.$store.dispatch('projectCategory/getProjectCategory', {pageNumber: 1})
                    })
                    .catch(error => {
                        this.disabled = false
                        this.$Progress.fail()
                        this.serverError = error.response.data.errors
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/isStillLogedIn')
                        }
                    })
            },

            onFileChange(e){
                const file = e.target.files[0]
                this.file = e.target.files[0]
                this.imgUrl = URL.createObjectURL(file)
                // this.eimgUrl = URL.createObjectURL(file)
            },

            onResetForm(){
                // this.$refs.categoryForm.reset()
                this.name = ''
                this.description = ''
                this.file = ''
                this.imgUrl = ''
                this.$refs.fileUpload.value = null
                // console.log('reset form')
            },
            
            closeFormModal(){
                this.$store.dispatch("projectCategory/closeFormModal")
                // console.log(this.showFormModal)
                this.onResetForm()
                this.serverError = ''
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>