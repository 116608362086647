<template>
    <section class="bg-indigo-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">
                    ขอลิงค์รีเซ็ตรหัสผ่าน
                </h1>
            </div>
            <form @submit.prevent="forgotPassword()">
                <div class="flex justify-center mb-2">
                    <div class="w-1/2 mt-1">
                        <input type="text" placeholder="อีเมล"
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                            v-model="email">
                        <div v-if="serverError.email" class="mt-2 text-sm text-red-500">
                            {{serverError.email[0]}}
                        </div>   
                    </div>
                </div>
                <div class="flex justify-center">
                    <button type="submit"
                        class="block w-1/2 px-4 py-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                        รับลิงค์เพื่อรีเซ็ตรหัสผ่าน
                    </button>
                </div>
            </form>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div> 
    </section>
</template>

<script>
    // import axios from 'axios'
    import http from '@/services/AuthService'
    export default {
        data(){
            return{
                disabled: false,
                email: '',
                serverError: '',
            }
        },
        methods:{
            forgotPassword(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append("email", this.email)
                http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
                    .then(() => {
                        http.post('forgot-password', data)
                            .then(response => {
                                console.log(response.data)
                                this.$Progress.finish()
                                this.disabled = false
                                this.serverError = ''
                                localStorage.setItem("email_reseting", this.email)
                                
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'กรุณาตรวจสอบอีเมล',
                                    text: 'เราได้ส่งลิงค์สำหรับรีเซ็ตรหัสผ่านบัญชีไปยังอีเมลของท่านเเล้ว'
                                    
                                }).then(() => {
                                    this.email = ''
                                })
                            })
                            .catch(error => {
                                console.log(error)
                                this.serverError = error.response.data.errors
                                this.$Progress.fail()
                                this.disabled = false
                            })
                    })
            }
        }
    }
</script>