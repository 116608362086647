<template>
    <header class="fixed z-10 w-full py-4 bg-white shadow-md">
      
      <!-- desktop -->
      <nav class="container flex flex-wrap mx-auto">
        <router-link to="/">
          <img src="@/assets/img/logo-main.png" class="w-32 ml-4" alt="logo">
        </router-link>
        <div class="flex justify-end ml-auto">
            <router-link class="my-4 ml-auto lg:hidden" v-show="!logedIn" to="/login" >
              <b class="text-xs text-indigo-900">เข้าสู่ระบบ <font-awesome-icon :icon="['fas', 'right-to-bracket']" /></b>
            </router-link>
            <router-link class="my-4 ml-auto lg:hidden" v-show="logedIn" to="/cart" >
              <b class="text-indigo-900 text-small">
                <span v-if="myCart > 0" class="bg-red-100 text-white text-sm font-medium mr-2 px-1 py-0.2 rounded bg-red-500">{{myCart}}</span>  
                <i class="fas fa-shopping-basket"></i> 
              </b>
            </router-link>
            <button @click="openToggleMenu" class="px-3 py-2 ml-auto text-indigo-900 border-indigo-600 rounded-md hover:bg-indigo-600 hover:text-white lg:hidden">
              <span class="block w-6 my-1 border-b-2 border-current"></span>
              <span class="block w-6 my-1 border-b-2 border-current"></span>
              <span class="block w-6 my-1 border-b-2 border-current"></span>
            </button>
            <div class="hidden w-full ml-auto xl:block lg:hidden md:hidden lg:w-auto">
              <router-link to="/" class="cctextlink">หน้าหลัก</router-link>
              <!-- <router-link to="/about" class="cctextlink">เกี่ยวกับเรา</router-link> -->
              <router-link to="/projects" class="cctextlink">โครงการ</router-link>
              <!-- <router-link to="/cleanup" class="cctextlink">สะสางดอกเบี้ย</router-link> -->
              <router-link to="/riba" class="cctextlink">สะสางดอกเบี้ย</router-link>
              <router-link to="/ezakat" class="cctextlink">ซะกาต</router-link>
              <router-link to="/donate" class="cctextlink">ระดมทุน</router-link>
              <router-link to="/opendata" class="cctextlink">Open Data</router-link>
              <!-- <router-link to="/literacy" class="cctextlink">ความรู้</router-link> -->
              <!--<router-link to="/contactMe" class="cctextlink">ติดต่อเรา</router-link> -->
              <router-link v-if="!logedIn" to="/register" class="cctextlink">สมัครสมาชิก</router-link>
              <router-link v-if="logedIn" to="/account" class="cctextlink">บัญชี</router-link>
              <router-link to="/cart" class="cctextlink">
                <span class="bg-red-100 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-red-500">{{myCart}}</span>
                <i class="fas fa-shopping-basket"></i>
                ตะกร้า
              </router-link>
              <router-link v-if="logedIn && $roleCan('$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm')" target="_blank" to="/dashboard" class="cctextlink">Dashboard</router-link>
              <div class="inline-block px-4 py-2">
                  <router-link v-show="logedIn" to="/logout" class="btncc-01">ออกจากระบบ</router-link>
                  <router-link v-show="!logedIn" to="/login" class="btncc-01">เข้าสู่ระบบ</router-link>
              </div>
            </div>
            <div class="hidden w-full ml-auto lg:block xl:hidden lg:w-auto">
              <router-link to="/" class="cctextlink">หน้าหลัก</router-link>
              <!-- <router-link to="/about" class="cctextlink">เกี่ยวกับเรา</router-link> -->
              <router-link to="/projects" class="cctextlink">โครงการ</router-link>
              <!-- <router-link to="/cleanup" class="cctextlink">สะสางดอกเบี้ย</router-link> -->
              <router-link to="/riba" class="cctextlink">สะสางดอกเบี้ย</router-link>
              <router-link to="/ezakat" class="cctextlink">ซะกาต</router-link>
              <router-link to="/donate" class="cctextlink">ระดมทุน</router-link>
              <!-- <router-link to="/opendata" class="cctextlink">Open Data</router-link> -->
              <!-- <router-link to="/literacy" class="cctextlink">ความรู้</router-link> -->
              <!--<router-link to="/contactMe" class="cctextlink">ติดต่อเรา</router-link> -->
              <router-link v-if="!logedIn" to="/register" class="cctextlink">สมัครสมาชิก</router-link>
              <router-link v-if="logedIn" to="/account" class="cctextlink">บัญชี</router-link>
              <router-link to="/cart" class="cctextlink">
                <span class="bg-red-100 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-red-500">{{myCart}}</span>
                <i class="fas fa-shopping-basket"></i>
                ตะกร้า
              </router-link>
              <router-link v-if="logedIn && $roleCan('$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm')" target="_blank" to="/dashboard" class="cctextlink">Dashboard</router-link>
              <div class="inline-block px-4 py-2">
                  <router-link v-show="logedIn" to="/logout" class="btncc-01">ออกจากระบบ</router-link>
                  <router-link v-show="!logedIn" to="/login" class="btncc-01">เข้าสู่ระบบ</router-link>
              </div>
            </div>
        </div>
      </nav>
      <!-- \.desktop -->

      <!-- Mobile Menu -->
      <div class="absolute inset-x-0 top-0 p-2 transition origin-top transform" :class="{'hidden': toggleMenu}">
          <div class="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
            <div class="flex items-center justify-between px-5 pt-4">
              <div>
                <!-- <img class="w-auto h-8" src="@/assets/img/logo-main.png" alt="Achieve your life dream"> -->
                <router-link to="/" class="cctextlink">                
                    <img src="@/assets/img/logo-main.png" alt="logo" class="w-32">
                </router-link>
              </div>
              <div class="-mr-2">
                <button @click="closeToggleMenu" type="button" class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="pt-5 pb-6" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
              <div class="px-2 pt-2 pb-3 space-y-1">
                <!-- <router-link @click="closeToggleMenu()" to="/" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">หน้าหลัก</router-link> -->
                <!-- <router-link @click="closeToggleMenu()" to="/about" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">เกี่ยวกับเรา</router-link> -->
                <router-link @click="closeToggleMenu()" to="/projects" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">โครงการ</router-link>
                <!-- <router-link @click="closeToggleMenu()" to="/cleanup" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">สะสางดอกเบี้ย</router-link> -->
                <router-link @click="closeToggleMenu()" to="/riba" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">สะสางดอกเบี้ย</router-link>
                <router-link @click="closeToggleMenu()" to="/ezakat" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">ซะกาต</router-link>
                <router-link @click="closeToggleMenu()" to="/donate" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">ระดมทุน</router-link>
                <router-link @click="closeToggleMenu()" to="/opendata" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">Open Data</router-link>
                <!-- <router-link @click="closeToggleMenu()" to="/literacy" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">ความรู้</router-link> -->
                <!-- <router-link @click="closeToggleMenu()" to="/contactMe" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">ติดต่อเรา</router-link> -->
                <router-link v-if="!logedIn" @click="closeToggleMenu()"  to="/register" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">สมัครสมาชิก</router-link>
                <router-link @click="closeToggleMenu()" v-if="logedIn" to="/account" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">บัญชี</router-link>
                <router-link @click="closeToggleMenu()" v-if="logedIn && $roleCan('$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm')" to="/dashboard" class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">Dashboard</router-link>
                <router-link @click="closeToggleMenu()" to="/cart" class="cctextlink">
                  <span class="bg-red-100 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded bg-red-500">{{myCart}}</span>
                  <i class="fas fa-shopping-basket"></i>
                  ตะกร้า
                </router-link>
                <div class="inline-block px-4 py-2">
                    <router-link v-show="logedIn" to="/logout" @click="closeToggleMenu()" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">ออกจากระบบ</router-link>
                    <router-link v-show="!logedIn" to="/login" @click="closeToggleMenu()" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">เข้าสู่ระบบ</router-link>
                </div>
              </div>

            </div>
          </div>
      </div>
      <!-- \.Mobile Menu -->

    </header>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
      logedIn(){
        return this.$store.getters['authMember/logedIn']
      },
      myCart(){
        return this.$store.getters['authMember/cart']
      }
  },
  data(){
    return{
      toggleMenu: true
    }
  },
  methods:{
    closeToggleMenu(){
      this.toggleMenu = !this.toggleMenu
    },
    openToggleMenu(){
      this.toggleMenu = !this.toggleMenu
    }
  }
}
</script>

<style lang="css">
    .router-link-active, .router-link-exact-active{
        color: purple;
    }
</style>