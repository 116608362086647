<template>

  <a class="mt-5 b_title">
    <div class="flex items-center">
      <span>จัดการผู้ใช้งาน</span>
    </div>
  </a>
  
  <!-- Breadcrumbs  -->
  <!-- <nav class="w-full mt-5 rounded-md">
    <ol class="flex list-reset">
      <li><a href="#" class="text-emerald-600 hover:text-emerald-700"></a>จัดการผู้ใช้งาน</li>
      <li><span class="mx-2 text-gray-500">/</span></li>
      <li><a href="#" class="text-emerald-600 hover:text-emerald-700">สมาชิก</a></li>
    </ol>
  </nav> -->
  <!-- \.Breadcrumbs  -->

  <!-- ฟอร์มสำหรับค้าหา -->
  <div class="flex flex-wrap items-center my-1 ">
    <div class="flex flex-1 justify-left ">
      <div class="relative w-full max-w-xl mr-6 focus-within:text-gray-500">
        <div class="absolute inset-y-0 flex items-center pl-2">
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <form @submit.prevent="submitSearchForm">
          <input
            v-model="search_keyword"
            class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-200 border-0 rounded-md"
            type="text"
            placeholder="ป้อนชื่อผู้ใช้งาน"
            aria-label="Search"/>
          
        </form>
      </div>
      </div>
      <div class="flex items-center justify-center">
        
        <button @click="submitSearchForm" class="b_btn_search">
            <font-awesome-icon icon="fa-magnifying-glass"/> &nbsp; ค้นหา
        </button>
        
        <!-- <button class="b_btn_f5">
            <font-awesome-icon icon="fa-rotate"/> &nbsp; ล้าง
        </button> -->
    
    </div>
    <!-- \.ฟอร์มสำหรับค้าหา -->

    <button @click="openForm()" class="b_btn_add">
      <font-awesome-icon icon="fa-plus" />
    </button>
  </div>

  <!-- list of users -->
  <section>
    <div class="container pb-20 mx-auto mt-3">
      <div v-for="(item, index) in users" :key="index" class="relative grid mb-2 bg-white rounded-lg shadow-lg lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700">
        <a href="#" class="flex">
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-16 h-16 mx-2"
              :src="item.avatar"
            />
            <div class="text">
              <p class="text-lg">
                {{item.mem_name}} {{item.mem_lastname}} |
                <span class="text-sm" v-for="(role, index) in item.roles" :key="index">
                  <span v-if="index > 0"> , </span>
                  {{role.name}}
                </span>
              </p>
              <p class="text-sm">
                สถานะ : <span v-if="item.status == 1">active</span> <span v-else>unactive</span> 
                | Email : {{item.mem_email}} 
                | Tel. : {{item.mem_tel}}
              </p>
              <p class="text-xs">
                วันที่สร้าง {{formatDay(item.created_at)}} เวลา {{formatTime(item.created_at)}} น. 
                | แก้ไขล่าสุด {{formatDay(item.updated_at)}}
                เวลา {{formatTime(item.updated_at)}} น.
              </p>
            </div>
          </div>
        </a>
        <!-- ตั้งค่า -->
        <div class="absolute right-0">
          <!-- <button class="inline-block b_btn_setting ">ตั้งค่า</button> -->
          <div class="">
            <div>
              <div class="relative flex justify-center dropdown">
                <button class="dropdown-toggle
                    b_btn_setting
                    px-6
                    py-2.5
                    text-white
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-emerald-700 hover:shadow-lg
                    focus:bg-emerald-700
                    focus:shadow-lg
                    focus:outline-none
                    focus:ring-0
                    active:bg-emerald-800 active:shadow-lg active:text-white
                    transition
                    duration-150
                    ease-in-out
                    flex
                    items-center
                    whitespace-nowrap
                  "
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <font-awesome-icon icon="fa-cog"/> &nbsp; ตั้งค่า
                </button>
                <ul class="absolute z-50 hidden float-left py-2 m-0 mt-1 text-base text-left list-none bg-white border-none rounded-lg shadow-lg dropdown-menu min-w-max bg-clip-padding" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="cc_li_submenu" href="#">
                      <i class="fas fa-user-edit"> แก้ไขโปรไฟล์ </i></a
                    >
                  </li>
                  <li>
                    <a class="cc_li_submenu" href="#">
                      <i class="fas fa-map-marker-alt"> แก้ไขที่อยู่ </i>
                    </a>
                  </li>
                  <li>
                    <a class="cc_li_submenu" href="#">
                      <i class="fas fa-key"> แก้ไขรหัสผ่าน </i>
                    </a>
                  </li>
                  <li>
                    <a class="cc_li_submenu" href="#">
                      <router-link :to="{name: 'MemberRole', params:{id: item.id}}">
                        <i class="fa fa-user-cog"> ตั้งค่าบทบาท</i>
                      </router-link>
                    </a>
                  </li>
                  <li>
                    <a class="cc_li_submenu" href="#">
                      <i class="fas fa-user-shield"> ตั้งค่าสิทธิ์</i></a>
                  </li>
                  <li>
                    <a class="cc_li_submenu" href="#">
                      <i class="fas fa-user-check"> ตั้งค่าสถานะ</i></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- pagination -->
      <VueTailwindPagination class="mb-3" v-if="users.length"
          :current="pagination.current_page"
          :total="pagination.total"
          :per-page="pagination.per_page"
          @page-changed="onPageClick($event)"
      />
      <!-- \.pagination -->
    </div>
  </section>
    
</template>

<script>
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    import http from '@/services/BackendService.js'
    import moment from 'moment'
    export default {
        components:{
            VueTailwindPagination 
        },
        mounted(){
            this.retreiveUser() 
        },
        data(){
           return{
                users: [],
                pagination: {
                    current_page: 1,
                },
                search_keyword: '',
           }
        },
        watch:{
          search_keyword: function(){
                if(this.search_keyword != ""){
                    this.submitSearchForm()
                }else{
                    this.retreiveUser()
                }
            }
        },
        methods:{
          submitSearchForm(){
            http.get(`member/search?search_keyword=${this.search_keyword}&page=${this.pagination.current_page}`)
              .then(response => {
                this.users = response.data.members.data
                this.pagination = response.data.members  
              })
              .catch(error => {
                if(error.response.status == 401){
                    this.$store.dispatch('authMember/unauthenticateResetting')
                } 
              })
          },
          onPageClick(event){
            if(this.search_keyword == ''){
              this.changePage(event)
            }else{
              this.changePageSearch(event)
            }
          },
          changePageSearch(event){
            this.$Progress.start()
            http.get(`member/search?search_keyword=${this.search_keyword}&page=${event}`)
              .then(response => {
                this.users = response.data.members.data
                this.pagination = response.data.members  
                this.$Progress.finish()
              })
              .catch(error => {
                if(error.response.status == 401){
                    this.$store.dispatch('authMember/unauthenticateResetting')
                }
                this.$Progress.fail()   
              })  
          },
          changePage(event){
            this.$Progress.start()
              http.get(`member/member?page=${event}`)
                  .then(response => {
                      this.users = response.data.members.data
                      this.pagination = response.data.members
                      this.$Progress.finish()
                  })
                  .catch(error => {
                      if(error.response.status == 401){
                          this.$store.dispatch('authMember/unauthenticateResetting')
                      }
                      this.$Progress.fail()    
                  })
          },
          retreiveUser(){
              this.$Progress.start();
              http.get(`member/member?page=${this.pagination.current_page}`)
                  .then(response => { //success
                      this.users = response.data.members.data
                      this.pagination = response.data.members
                      this.$Progress.finish();  
                  })
                  .catch(error => {
                      if(error.response.status == 401){ //ถ้า token หมดอายุ
                          this.$store.dispatch('authMember/unauthenticateResetting')
                      }
                      this.$Progress.fail()   
                  })
          },
          //สร้างฟังก์ชันสำหรับจัดรูปแบบวันที่
          formatDay(value){
              if(value){
                  return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
              }
          },
          formatTime(value){
              if(value){
                  return moment(String(value)).format('HH:mm:ss')
              }
          },
        }
    }
</script>

<style lang="scss" scoped>

</style>