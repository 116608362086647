<template>
  <router-view/>
  <!-- set progressbar -->
  <vue-progress-bar></vue-progress-bar>
</template>

<script>

  // let brand = " | Inventory VUE SPA"

  let brand = " | " + process.env.VUE_APP_TITLE


  export default {
    name: 'App',
    computed: {
        logedIn(){
          return this.$store.getters['authMember/logedIn']
        }
    },
    watch: {
      $route(to){
        document.title = to.meta.title + brand,
        document.description = to.meta.description
      }
    }
  }
  
</script>

