<template>

   <!-- <div class="w-full mt-0 bgcontent-center">
        <img src="@/assets/img/bg.png" class=" pt-14 2xl:w-full lg:w-full lg:h-900 sm:pt-10" alt="" width="" height="" />
    </div> -->
<section class="bgcc">
  <div class="w-full px-4 pt-40 mx-auto my-auto text-center bgcc lg:mx-auto ">

    <a href="/accountPersonal"> บัญชี Personal </a> <a href="/accoutOrganization"> บัญชี Organization </a>
    <h1 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize"> องค์กร : Organization </h1>
</div>
<section>
    <!-- องค์กร : Organization -->
    <div class="container mx-auto my-auto">
      <div class="md:col-span-2">
        <form action="#" method="POST">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <!-- เริ่ม -->
            <div class="px-2 py-2 bg-white sm:p-6">
              <h3 class="flexcc-top">องค์กร : Organization</h3>
              
              <ul class="stepper" data-mdb-stepper="stepper" data-mdb-stepper-type="  vertical">
                <li class="stepper-step ">
                    <div class="stepper-head">
                        <span class="stepper-head-icon"> 1 </span>
                        <span class="stepper-head-text"> ข้อมูลทั่วไป </span>
                    </div>
                    <div class="stepper-content"> 011111
                    </div>
                </li>
                <li class="stepper-step stepper-active">
                  <div class="stepper-head">
                    <span class="stepper-head-icon"> 2 </span>
                    <span class="stepper-head-text"> ข้อมูลที่อยู่ </span>
                  </div>
                  <div class="stepper-content">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </li>
                <li class="stepper-step">
                  <div class="stepper-head">
                    <span class="stepper-head-icon"> 3 </span>
                    <span class="stepper-head-text"> สรุปผลการสมัคร </span>
                  </div>
                  <div class="stepper-content">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  </div>
                </li>
                </ul>

              <div >

                <div class="col-span-6 sm:col-span-3">
                
                  <div class="container grid content-center grid-flow-col grid-cols-3 ">
  <div class="content-center row-span-6 ">
    
    <div class="col-span-4 sm:col-span-6 ">
                  <label class="block text-sm font-medium text-gray-700">
                    รูป LOGO</label
                  >
                  <div class="flex items-center mt-1">
                    <span
                      class="inline-block w-12 h-12 overflow-hidden bg-gray-100 rounded-full "
                    >
                      <svg
                        class="w-full h-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                    </span>
                    <button
                      type="button"
                      class="px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      เลือกภาพ
                    </button>
                  </div>
                </div>
    </div>
  <div class="col-span-8"><input type="text" class="mb-2 cc_input_text" id="" placeholder="ชื่อผู้ประกอบการ/หน่วยงาน/ร้านค้า" /></div>
  <div class="col-span-8"><input type="textarea" class="mb-2 form-textarea cc_input_text" id="" placeholder="การบริการโดยสรุป" /></div>
  <div class="col-span-8">              
    <div class="relative mb-2">
              <select
                class="block w-full h-full px-4 py-2 pr-8 cc_input_text ">
                <option>ประเภทการให้บริการ</option>
                <option>ร้านอาหาร</option>
                <option>วัตถุดิบสด-แห้ง</option>
                <option>โรงผลิตน้ำดื่ม</option>
                <option>ห้างทอง</option>
                <option>บริการฮัจย์และอุมเราะห์</option>
                <option>อื่นๆ ระบุ...</option>
              </select>

              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
              >

                <i class="w-4 h-4 fill-current far fa-arrow-alt-square-down"></i>
              </div>
            </div>
          </div>
  
  </div>
              


              <input type="text" class="mb-2 cc_input_text" id="" placeholder="Facebook ID" />
              <input type="text" class="mb-2 cc_input_text" id="" placeholder="Line ID" />
              <input type="text" class="mb-2 cc_input_text" id="" placeholder="Email address" />
              <input type="text" class="mb-2 cc_input_text" id="" placeholder="Website" />
              <input type="text" class="mb-2 cc_input_text" id="" placeholder="หมายเลขโทรศัพท์" />
              <input type="text" class="mb-2 cc_input_text" id="" placeholder="หมายเลขโทรศัพท์มือถือ" />
            

                </div>
              </div>
            </div>
            
          </div>
          <!-- Save -->
            <div class="py-1 pb-2 text-right bg-white ">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                สร้างข้อมูลองค์กร
              </button>
            </div>
            <!-- Save -->
        </form>

      </div>
    </div>
    <!-- ข้อมูลที่อยู่องค์กร : Organization Address -->
    <div class="container content-center mx-auto my-auto ">
    <div class="pt-2 pb-5 md:grid md:grid-cols-2 md:gap-6 ">
      <div class="px-4 py-2 mt-2 bg-white shadow md:mt-0 md:col-span-3 ">
        <P class="flexcc-top">ข้อมูลที่อยู่องค์กร : Organization Address </P>
        <ul class="stepper" data-mdb-stepper="stepper" data-mdb-stepper-type="  vertical">
                <li class="stepper-step ">
                    <div class="stepper-head">
                        <span class="stepper-head-icon"> 1 </span>
                        <span class="stepper-head-text"> ข้อมูลทั่วไป </span>
                    </div>
                    <div class="stepper-content"> 011111
                    </div>
                </li>
                <li class="stepper-step stepper-active">
                  <div class="stepper-head">
                    <span class="stepper-head-icon"> 2 </span>
                    <span class="stepper-head-text"> ข้อมูลที่อยู่ </span>
                  </div>
                  <div class="stepper-content">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </li>
                <li class="stepper-step">
                  <div class="stepper-head">
                    <span class="stepper-head-icon"> 3 </span>
                    <span class="stepper-head-text"> สรุปผลการสมัคร </span>
                  </div>
                  <div class="stepper-content">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  </div>
                </li>
                </ul>

        <form action="#" method="POST">
          <div class="overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 sm:p-6">
              <P class="text-lg font-medium leading-6 text-gray-900">
                ชื่อที่อยู่ <input type="text" placeholder="ชื่อที่อยู่ขององค์กรท่าน" class="cc_input_text">
              </P>
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label
                    for="street-address"
                    class="block text-sm font-medium text-gray-700"
                    >เลขที่</label
                  >
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autocomplete="street-address"
                    class="cc_input_text"
                  />
                </div>

                <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label
                    for="city"
                    class="block text-sm font-medium text-gray-700"
                    >หมู่ที่</label
                  >
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autocomplete="address-level2"
                    class="cc_input_text"
                  />
                </div>

                <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label
                    for="region"
                    class="block text-sm font-medium text-gray-700"
                    >หมู่บ้าน</label
                  >
                  <input
                    type="text"
                    name="region"
                    id="region"
                    autocomplete="address-level1"
                    class="cc_input_text"
                  />
                </div>

                <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                  <label
                    for="postal-code"
                    class="block text-sm font-medium text-gray-700"
                    >ซอย</label
                  >
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autocomplete="postal-code"
                    class="cc_input_text"
                  />
                </div>
<!-- ถนน -->
                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="street-address"
                    class="block text-sm font-medium text-gray-700"
                    >ถนน</label
                  >
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autocomplete="street-address"
                    class="cc_input_text"
                  />
                </div>
                                <!-- รหัสไปรษณีย์ -->
                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="postal-code"
                    class="block text-sm font-medium text-gray-700"
                    >รหัสไปรษณีย์
                  </label>
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autocomplete="postal-code"
                    class="cc_input_text"
                  />
                </div>
                                <!-- จังหวัด -->
                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="postal-code"
                    class="block text-sm font-medium text-gray-700"
                    >จังหวัด</label
                  >
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autocomplete="postal-code"
                    class="cc_input_text"
                  />
                </div>
                <!-- อำเภอ/เขต -->
                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="region"
                    class="block text-sm font-medium text-gray-700"
                    >อำเภอ/เขต</label
                  >
                  <input
                    type="text"
                    name="region"
                    id="region"
                    autocomplete="address-level1"
                    class="cc_input_text"
                  />
                </div>

<!-- >ตำบล/แขวง -->
                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    for="city"
                    class="block text-sm font-medium text-gray-700"
                    >ตำบล/แขวง</label
                  >
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autocomplete="address-level2"
                    class="cc_input_text"
                  />
                </div>


              </div>
              <div class="px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                บันทึก
              </button>
           
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                บันทึกและเพิ่มใหม่
              </button>
            </div>
            </div>

          </div>
        </form>
      </div>
    </div>
    </div>
    <!-- สรุปผลการสมัครองค์กร : Organization -->
    <div class="container content-center mx-auto my-auto ">
    <div class="pt-2 pb-5 md:grid md:grid-cols-2 md:gap-6 ">
      <div class="px-4 py-2 mt-2 bg-white shadow md:mt-0 md:col-span-3 ">
        <P class="flexcc-top">สรุปผลการสมัครองค์กร : Organization</P>
        <ul class="stepper" data-mdb-stepper="stepper" data-mdb-stepper-type="  vertical">
                <li class="stepper-step ">
                    <div class="stepper-head">
                        <span class="stepper-head-icon"> 1 </span>
                        <span class="stepper-head-text"> ข้อมูลทั่วไป </span>
                    </div>
                    <div class="stepper-content"> 011111
                    </div>
                </li>
                <li class="stepper-step ">
                  <div class="stepper-head">
                    <span class="stepper-head-icon"> 2 </span>
                    <span class="stepper-head-text"> ข้อมูลที่อยู่ </span>
                  </div>
                  <div class="stepper-content">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                </li>
                <li class="stepper-step stepper-active">
                  <div class="stepper-head">
                    <span class="stepper-head-icon"> 3 </span>
                    <span class="stepper-head-text"> สรุปผลการสมัคร </span>
                  </div>
                  <div class="stepper-content">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  </div>
                </li>
                </ul>
                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Organization Information</h3>
    <p class="max-w-2xl mt-1 text-sm text-gray-500">ข้อมูลองค์กร</p>
  </div>
  <div class="border-t border-gray-200">
    <dl>
      <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">ชื่อองค์กร/หน่วยงาน/ผู้ประกอบการ</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Mira Dinking Water</dd>
      </div>
      <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">ประเภทการให้บริการ</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">โรงผลิตน้ำดื่ม</dd>
      </div>
      <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">Email address</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">miradrinkingwater@gmail.com</dd>
      </div>
      <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">หมายเลขโทรศัพท์</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">087-8378335</dd>
      </div>
      <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">การบริการโดยรวม</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">โรงผลิตน้ำดื่ม ได้มาตรฐาน อย. และเครื่องหมายฮาลาล </dd>
      </div>
      <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">ที่อยู่</dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          ชื่อที่อยู่ ที่อยู่โรงผลิตน้ำดื่ม
          เลขที่ 106/8  หมู่ที่ 2 หมู่บ้าน - ซอย - ถนน -  ตำบลปิตูมุดี อำเภอยะรัง จังหวัดปัตตานี รหัสไปรษณีย์ 94160
        </dd>
      </div>
    </dl>
  </div>
</div>
<div class="px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                แก้ไขข้อมูลทั่วไป
              </button>
           
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                แก้ไขที่อยุ่
              </button>
            </div>

      </div>
    </div>
    </div>
</section>
      <!-- สมัครเป็น Partner -->
    <div class="container content-center mx-auto my-auto ">
    <div class="pt-2 pb-5 md:grid md:grid-cols-2 md:gap-6 ">
      <div class="px-4 py-2 mt-2 bg-white rounded-lg shadow-lg md:mt-0 md:col-span-3 ">
        <P class="flexcc-top">สมัครเป็นพันธมิตร : Apply tobe Partner</P>

                <div class="overflow-hidden bg-white shadow sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg font-medium leading-6 text-gray-900">ผู้ประกอบการดีใจ</h3>
    <p class="max-w-2xl mt-1 text-sm text-gray-500"> Entrepreneur Rejoices</p>
  </div>
  <div class="border-t border-gray-200">
    <dl>
      <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-gray-500">
          <div class="px-4 py-3 text-center sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-2 text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              ><i class="fa-solid fa-handshake"> </i>     Apply Partner
              </button>
           
            </div>
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          การสมัครเป็นพันธมิตร ท่านสามารถมอบบัตรกำนัลให้กับผู้ใช้งานระบบในการบริจาค 
          <br> CuCi Derma CuCi Zakat CuCi Riba 
          <br> โดยท่านผู้ประกอบการสามารถเลือกโครงการที่ต้องมอบบัตรกำนัล ตามเงื่อนไขต่างๆ ที่ท่านสามารถตั้งค่าได้ เช่น
          <br> - จำนวนบัตรกำนัล
          <br> - โครงการที่ต้องการสนับสนุน
          <br> - ยอดเงินที่บริจาค
          <br> - ระยะเวลาการใช้งานของบัตรกำนัล
</dd>
      </div>
     
    </dl>

  </div>
</div>


<div class="h-48" ></div>      
</div>

    </div>
    </div>
  <!-- สร้าง Gift Voucher -->

  <!-- <div class="container content-center mx-8 mt-10 bg-white sm:mt-0 ">
    <div class="md:grid md:grid-cols-1 md:gap-6">
      <div class="mt-5 md:mt-0 md:col-span-3">
        <P class="flexcc-top">บัตรกำนัล : Gift Voucher </P>
        <form action="#" method="POST">
          <div class="overflow-hidden shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <P class="text-lg font-medium leading-6 text-gray-900">
                บัตรกำนัล Gift Voucher <input type="text" placeholder=" ชื่อบัตรกำนัล" class="mb-2 cc_input_text">
              </P>
              <div class="grid grid-cols-4 gap-4">
                <div class="col-span-3 sm:col-span-3 lg:col-span-2">

                  <input type="text" class="mb-2 cc_input_text" id="" placeholder="เลือกโครงการ" />
                </div>

                <div class="col-span-3 sm:col-span-3 lg:col-span-2">

                  <input type="text" class="mb-2 cc_input_text" id="" placeholder="มูลค่าบัตรกำนัล" />
                </div>

                <div class="col-span-3 sm:col-span-3 lg:col-span-2">

                  <input type="text" class="mb-2 cc_input_text" id="" placeholder="เงื่อนไขการใช้งาน ตามยอดเงิน" />
                </div>

                <div class="col-span-3 sm:col-span-3 lg:col-span-2">

                  <input type="text" class="mb-2 cc_input_text" id="" placeholder="วันหมดอายุ" />
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-2">

                  <input type="text" class="mb-2 cc_input_text" id="" placeholder="จำนวน GV " />
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                  <input type="text" class="mb-2 cc_input_text" id="" placeholder="รูปบัตรกำนัล" />
                  
                </div>


              </div>
              <div class="px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                สร้างบัตรกำนัล
              </button> 
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              สร้างบัตรกำนัลและเพิ่มใหม่
              </button>
            </div>
            
            </div>

          </div>
        </form>
      </div>
    </div>
  </div> -->
  <!-- สร้างบัตรกำนัล : Gift Voucher -->
  <div class="container mx-auto my-auto ">
    <div class="pt-5 pb-5 bg-white shadow md:col-span-2 sm:col-span-1">
      <form action="#" method="POST">
        <div class=" sm:rounded-md sm:overflow-hidden">
          <!-- เริ่ม -->
          <div class="px-4 py-2">
            <h3 class="text-left flexcc-top"> สร้างบัตรกำนัล : Gift Voucher</h3>

            <div class="mx-4 my-4">
              <div class="col-span-6 sm:col-span-3">
                <div
                  class="container grid content-center grid-flow-col grid-cols-3 "
                >
                  <div class="content-center row-span-6">
                    <div class="col-span-4 sm:col-span-6">
                    
                      <!-- <div class="flex items-center mx-2 mt-1">
                        <span
                          class="inline-block w-full h-40 overflow-hidden bg-gray-100 rounded "
                        >
                        <img
                  class="w-full h-full"
                  src="@/assets/img/GVC003.png"
                  alt=""
                />
                         
                        </span>
                         <button
                          type="button"
                          class="px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          
                        </button> 
                      </div>      -->
                     <!-- <div class="flex items-center bg-grey-lighter">
                    <label class="flex flex-col items-center w-full px-4 py-6 mx-4 tracking-wide uppercase bg-white border rounded-lg shadow cursor-pointer text-blue border-blue hover:bg-emerald-500 hover:text-white">
                      <i class="w-8 h-8 fa-solid fa-cloud-arrow-up" fill="currentColor" viewBox="0 0 10 10"></i>
                      <span class="mt-2 text-base leading-normal">เลือกรูปภาพบัตรกำนัล</span>
                      <input type='file' class="hidden" />
                    </label>
                   </div> -->

                   <div class="flex items-center justify-center w-full pr-4 ">
    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">กดเพื่อเลือกภาพ</span> หรือ ลากไฟล์มาวางตรงนี้</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        </div>
        <input id="dropzone-file" type="file" class="hidden">
    </label>
</div> 
                    </div>
                    <!-- <div class="flex justify-center">
  <div class="mb-3 w-96">
    <label for="formFile" class="inline-block mb-2 text-gray-700 form-label">เลือกภาพ</label>
    <input class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-emerald-600 focus:outline-none" type="file" id="formFile">
  </div>
                    </div> -->
                   </div>
                  <div class="col-span-8">
                    <input
                      type="text"
                      class="mb-2 cc_input_text"
                      id=""
                      placeholder="ชื่อบัตรกำนัล"
                    />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  placeholder="มูลค่าบัตรกำนัล"
                />
                  </div>
                  <div class="col-span-8">
                    เงื่อนไขการได้รับบัตรกำนัล
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  placeholder=" ตามยอดเงิน"
                />  <label>
            <input
              type="checkbox"
              class="w-5 h-5 text-emerald-600 focus:ring-emerald-500"
              name="radio"
            /><span class="ml-2 text-gray-700">1 สิทธิ์ต่อ 1 โครงการ</span>
          </label>
                
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  placeholder="เลือกโครงการที่กำลังระดมทุน"
                />
                
                  </div>

                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  placeholder="จำนวนบัตรกำนัล"
                />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  placeholder="วันหมดอายุ"
                />
                  </div>                  
                </div>


              </div>
            </div>
          </div>
        </div>
        <!-- สร้างบัตรกำนัล -->
        <div class="text-right bg-white sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
           
            <i class="fa-solid fa-plus"> สร้างบัตรกำนัล</i> 
          </button>
          <button
            type="submit"
            class="inline-flex justify-center px-2 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
           
            <i class="fa-solid fa-plus"> สร้างและเพิ่มบัตรกำนัลใหม่</i> 
          </button>
        </div>
        <!-- Save -->
      </form>
    </div>
  </div>

    <!-- วิวแต่ละชิ้นบัตรกำนัล : Gift Voucher  ตั้งค่า-->
    <div class="container mx-auto my-auto">
    <div class="pt-5 pb-5 ">
      <form action="#" method="POST">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <!-- เริ่ม -->
          <div class="px-2 py-4 bg-white sm:p-6">
            <h3 class="flexcc-top">บัตรกำนัล : Gift Voucher [Partner] </h3>
           <!-- button แก้ไข เพิ่ม -->
            <div class="px-4 py-3 text-right bg-white sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center px-4 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
          <i class="fa-solid fa-pen-to-square"> แก้ไข </i> 
          </button>
          <button
            type="submit"
            class="inline-flex justify-center px-4 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
          <i class="fa-solid fa-plus"> เพิ่ม</i> 
          </button>
             </div>
            
              <div class="container content-center col-span-6 sm:col-span-3">
                <div     class="" >
                  <div class="content-center row-span-7">
                    <div class="col-span-4 sm:col-span-6">
                      <!-- <label class="block text-sm font-medium text-gray-700">
                        รูปภาพของรางวัล</label
                      > -->
                      <div class="flex items-center mx-2 ">
                        <span
                          class="overflow-hidden bg-gray-100 "
                        >
                        <img
                  class="w-full h-full"
                  src="@/assets/img/GVC003.png"
                  alt=""
                />
                        </span>
                        <!-- <br>
                        <button
                          type="button"
                          class="px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          เปลี่ยนภาพ
                        </button> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-span-8">
                    <input
                      type="text"
                      class="mb-2 cc_input_text"
                      id="" disabled
                      placeholder="ชื่อบัตรกำนัล  : Promotion 9.9 "
                    />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id="" disabled
                  placeholder="รหัสโครงการ P023"
                />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id="" disabled
                  placeholder="มูลค่า 100 บาท"
                />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id="" disabled
                  placeholder="เงื่อนไขการใช้งาน ยอดชำระ 1,000 บาท"
                />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  disabled
                  placeholder="วันหมดอายุ : 30 ธันาคม 2565"
                />
                  </div>
                  <div class="col-span-8">
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  disabled
                  placeholder="จำนวนบัตรกำนัล 10 ใบ "
                />
                  </div>
                </div>


              </div>
            
          </div>
        </div>
        <!-- เพิ่ม CuCi Gift -->

        <!-- Save -->
      </form>
    </div>
  </div>

    <!-- แก้ไขแต่ละชิ้นบัตรกำนัล : Gift Voucher  -->
    <div class="container mx-auto my-auto">
    <div class="px-4 py-4 bg-white rounded-md shadow md:mt-0 md:col-span-2">
      <form action="#" method="POST">
        <div class=" sm:rounded-md sm:overflow-hidden">
          <!-- เริ่ม -->
          <div class="space-y-6 bg-white sm:p-6">
            <h3 class="flexcc-top"> แก้ไขบัตรกำนัล : Gift Voucher  :</h3>

            <div>
              <div class="col-span-6 sm:col-span-3">
                <div
                  class="container grid content-center grid-flow-col grid-cols-3"
                >
                  <div class="content-center row-span-6 mx-4">
                    <div class="col-span-4 sm:col-span-6">

                      <div class="flex items-center mt-1">
                        <label class="block text-sm font-medium text-gray-700">
                        รูปภาพบัตรกำนัล</label
                      >
                      </div>
                      <div class="flex items-center mt-1">
                        <span
                          class="overflow-hidden bg-gray-100 "
                        >
                        <img
                  class="w-full h-full"
                  src="@/assets/img/GVC003.png"
                  alt=""
                />
                        </span>

                      </div>
                      <div class="flex items-center mx-2 mt-1">
                        เปลี่ยนภาพ
                        <input
                          type="file"
                          class="px-3 py-2 ml-5 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-span-8">
                    ชื่อ บัตรกำนัล :
                    <input
                      type="text"
                      class="mb-2 cc_input_text"
                      id="" 
                      placeholder=" เสื้อ CuCi"
                    />
                  </div>
                  <div class="col-span-8">
                    จำนวนแต้มที่ต้องใช้ในการแลก :
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id="" 
                  placeholder=" 300"
                />
                  </div>
                  <div class="col-span-8">
                    จำนวนบัตรกำนัล :
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id="" 
                  placeholder=" 100 ชิ้น"
                />
                  </div>
                  <div class="col-span-8">
                    วันหมดอายุ : 
                    <input
                  type="text"
                  class="mb-2 cc_input_text"
                  id=""
                  
                  placeholder="30 ธันาคม 2565"
                />
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <!-- บันทึกการแก้ไข แก้ไขบัตรกำนัล : Gift Voucher : -->
        <div class="px-4 py-3 text-right bg-white sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
           
           <i class="fa-solid fa-floppy-disk">  บันทึก</i>
          </button>
        </div>
        <!-- Save -->
      </form>
    </div>
  </div>

  <!-- Card Gift Voucher -->
  <div class="container mx-auto my-auto">

<div class="px-4 py-4 mt-4 bgcc01 ">
  <P class="flexcc-top">บัตรกำนัล : Gift Voucher </P>
  <div class="text-right bg-white sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
           
            <i class="fa-solid fa-plus"> สร้างบัตรกำนัลใหม่ </i> 
          </button>

        </div>
<div class="flex flex-wrap content-center mx-auto mt-4 mb-4">


  <div class="w-full max-w-sm p-4 mx-2 mt-6 overflow-hidden bg-white rounded shadow-lg lg:w-4/12 sm:w-6/12">
    <img          class="w-full"          src="@/assets/img/GVC009.png"        />
    <div class="px-2 py-4">
      <div class="mb-2 text-xl font-bold text-gray-900">บัตรกำนัล 9.9</div>
      <p class="text-lg">
            Gift Voucher ID<span class="text-sm">| GVSY17087 | มูลค่า 200 บาท </span>
          </p>
          <p class="text-xs">
            รหัสโครงการ P023  โดย Syifa ห้างทองชีฟาอฺ
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลทั้งหมด  : 10
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลลูกค้าเก็บ  : 5
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลลูกค้าใช้งาน  : 0
          </p>
          <p class="text-xs">
            คงเหลือบัตรกำนัลจำนวน  : 5
          </p>

    </div>

    <div class="px-2 pt-1 pb-1">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              แก้ไข
              </button> 
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              รายละเอียด
              </button>
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              ยุติการใช้งาน
              </button>
    </div>
  </div>
  <div class="w-full max-w-sm p-4 mx-2 mt-6 overflow-hidden bg-white rounded shadow-lg lg:w-4/12 sm:w-6/12">
    <img          class="w-full"          src="@/assets/img/GVC001.png"        />
    <div class="px-2 py-4">
      <div class="mb-2 text-xl font-bold text-gray-900">บัตรกำนัล 9.9</div>
      <p class="text-lg">
            Gift Voucher ID<span class="text-sm">| GVSY17077 | มูลค่า 50 บาท </span>
          </p>
          <p class="text-xs">
            รหัสโครงการ P023  โดย Syifa ห้างทองชีฟาอฺ
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลทั้งหมด  : 10
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลลูกค้าเก็บ  : 5
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลลูกค้าใช้งาน  : 2
          </p>
          <p class="text-xs">
            คงเหลือบัตรกำนัลจำนวน  : 3
          </p>

    </div>
    <div class="px-2 pt-1 pb-1">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              แก้ไข
              </button> 
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              รายละเอียด
              </button>
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              ยุติการใช้งาน
              </button>
    </div>
  </div>
  <div class="w-full max-w-sm p-4 mx-2 mt-6 overflow-hidden bg-white rounded shadow-lg lg:w-4/12 sm:w-6/12">
    <img          class="w-full"          src="@/assets/img/GVC005.png"        />
    <div class="px-2 py-4">
      <div class="mb-2 text-xl font-bold text-gray-900">บัตรกำนัล 9.9</div>
      <p class="text-lg">
            Gift Voucher ID<span class="text-sm">| GVSY10088 | มูลค่า 100 บาท </span>
          </p>
          <p class="text-xs">
            รหัสโครงการ P023  โดย Syifa ห้างทองชีฟาอฺ
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลทั้งหมด  : 10
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลลูกค้าเก็บ  : 2
          </p>
          <p class="text-xs">
            จำนวนบัตรกำนัลลูกค้าใช้งาน  : 1
          </p>
          <p class="text-xs">
            คงเหลือบัตรกำนัลจำนวน  : 7
          </p>

    </div>
    <div class="px-2 pt-1 pb-1">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              แก้ไข
              </button> 
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              รายละเอียด
              </button>
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
              ยุติการใช้งาน
              </button>
    </div>
  </div>
</div>


</div>
</div>

</section>


<!-- รายละเอียด Gift Voucher แต่ละคน -->
<div class="container mx-auto my-auto bg-red-400 ">
    <div class="px-4 py-4 bgcc01 "> 
    <a class="flexcc-top">
      <div class="container flex items-center">
        <span> ข้อมูลบัตรกำนัล Gift Voucher</span>
      </div>
    </a>
    
    
    <ul class="flex flex-col flex-wrap pl-0 mb-4 list-none border-b-0 nav nav-tabs md:flex-row" id="tabs-tab"
  role="tablist">
  <li class="nav-item" role="presentation">
    <a href="#tabs-GV01" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL"
      aria-selected="true">
      
      <div class="">
        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
    <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>2</span></span>
    <span class="ml-1.5"><i class="fas fa-gifts"></i>  Gift Voucher ที่ยังไม่เก็บ </span>
  </strong>
</div>
      
     </a>
  </li>
  <li class=" nav-item" role="presentation">
    <a href="#tabs-GV02" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL"
      aria-selected="true">
      
      <div class="">
        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
    <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>5</span></span>
    <span class="ml-1.5"><i class="fas fa-gifts"></i>  Gift Voucher ที่เก็บทั้งหมด </span>
  </strong>
</div>
      
     </a>
  </li>

  <li class=" nav-item" role="presentation">
    <a href="#tabs-GV03" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-messages-tab" data-bs-toggle="pill" data-bs-target="#tabs-messages" role="tab"
      aria-controls="tabs-messages" aria-selected="false">
      <div class="">
        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
            <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>2</span></span>
    <span class="ml-1.5"><i class="fas fa-gifts"></i>  ใช้ไปแล้ว</span>
  </strong>
</div>
    </a>
  </li>
  <li class=" nav-item" role="presentation">
    <a href="#tabs-GV04" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-contact-tab" data-bs-toggle="pill" data-bs-target="#tabs-contact" role="tab"
      aria-controls="tabs-contact" aria-selected="false">
      <div class="">
        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
            <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>3</span></span>
    <span class="ml-1.5"><i class="fas fa-gifts"></i> ยังไม่ได้ใช้</span>
  </strong>
</div></a>
  </li>
  <li class="nav-item" role="presentation">
    <a href="#tabs-GV05" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-contact-tab" data-bs-toggle="pill" data-bs-target="#tabs-contact" role="tab"
      aria-controls="tabs-contact" aria-selected="false">
      <div class="">
        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
            <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>1</span></span>
    <span class="ml-1.5"><i class="fas fa-gifts"></i> หมดอายุ</span>
  </strong>
</div></a>
  </li>
</ul>
<div class="tabs-GV01" id="tabs-tabContent">
  <h1 class="inline mx-2 my-20 font-black text-orange-500 ">Gift Voucher  ที่หมดอายุ</h1> 
</div>

      <div
        class="relative flex flex-wrap mx-4 my-4 bg-red-100 border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700"
      >
        <a href="#tabs-GV01" class="">
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/GVC002.png"
            />

            <div class="text">
              <p class="text-lg">
                Gift Voucher ID<span class="text-sm">| GVMR1241 | มูลค่า 50 บาท </span>
              </p>
              <p class="text-xs">
                รหัสโครงการ P022  โดย MiRA Drinking Water
              </p>
              <p class="text-xs">
                วันที่เก็บ  25 ก.ค. 65 เวลา 16:23:41 น.
              </p>
              <p class="text-xl ">
                วันที่หมดอายุแล้ว  2 ก.ย. 65 
              </p>
            </div>
          </div>
        </a>
    

      </div>
     <!--  <div
        class="relative grid mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700"
      >
        <a href="#" class="flex">
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/GVC007.png"
            />

            <div class="text">
              <p class="text-lg">
                Gift Voucher ID<span class="text-sm">| GVCB12008 | มูลค่า 75 บาท </span>
              </p>
              <p class="text-xs">
                รหัสโครงการ P022  โดย CHABA Buger
              </p>
              <p class="text-xs">
                วันที่ยื่นยันชำระ  25 ก.ค. 65 เวลา 14:23:41 น.
              </p>
              <p class="text-xs">
                <div class="text-left sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          >
           
            <i class="fa-solid fa-plus"> เก็บบัตรกำนัล </i> 
          </button>

        </div>
              </p>
            </div>
          </div>
        </a>

      </div> 
      <div
        class="relative grid mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700"
      >
        <a href="#" class="flex">
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/GVC005.png"
            />

            <div class="text">
              <p class="text-lg">
                Gift Voucher ID<span class="text-sm">| GVAB10014 | มูลค่า 100 บาท </span>
              </p>
              <p class="text-xs">
                รหัสโครงการ P022  โดย Ayam Bang อะยัมบัง
              </p>
              <p class="text-xs">
                วันที่เก็บ  20 ก.ค. 65 เวลา 14:23:41 น.
              </p>
              <p class="text-xs">
                การใช้งาน : ยังไม่ได้ใช้
              </p>
            </div>
          </div>
        </a>
        ตั้งค่า 

      </div>
      <div
        class="relative grid mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700"
      >
        <a href="#" class="flex">
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/GVC007.png"
            />

            <div class="text">
              <p class="text-lg">
                Gift Voucher ID<span class="text-sm">| GVCB14079 | มูลค่า 150 บาท </span>
              </p>
              <p class="text-xs">
                รหัสโครงการ P022  โดย CHABA Beef เนื้แท้ 100 %
              </p>
              <p class="text-xs">
                วันที่เก็บ  18 ก.ค. 65 เวลา 14:23:41 น.
              </p>
              <p class="text-xs">
                การใช้งาน : ยังไม่ได้ใช้
              </p>
            </div>
          </div>
        </a>

      </div>
      <div
        class="relative grid mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700"
      >
        <a href="#" class="flex">
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/GVC010.png"
            />

            <div class="text">
              <p class="text-lg">
                Gift Voucher ID<span class="text-sm">| GVSY17087 | มูลค่า 200 บาท </span>
              </p>
              <p class="text-xs">
                รหัสโครงการ P023  โดย Syifa ห้างทองชีฟาอฺ
              </p>
              <p class="text-xs">
                วันที่เก็บ  18 ก.ค. 65 เวลา 14:23:41 น.
              </p>
              <p class="text-xs">
                การใช้งาน : ยังไม่ได้ใช้
              </p>
            </div>
          </div>
        </a>

      </div> -->
    <div class="h-96"></div>
    </div>
</div>


<div class="container mx-auto my-auto ">
  <div class=" bgcc01">
    <div class="container flex items-center flexcc-top">
      <span> รายละเอียด CuCi Point ของท่าน</span>
    </div>
  <div class="container flex flex-row px-10 py-6 border-2 rounded-md shadow-md bg-yellow-50 border-slate-200">
    <div>
      <img
              class="object-contain w-64 h-64 mx-2"
              src="@/assets/img/cuciPoint.png"
            />
    </div>
    <div class="inline px-10 py-4 text-justify">
     <div class="text-base ">  CuCi Point ปัจจุบัน </div>
     <!-- <div class="flex items-center justify-center w-24 h-24 text-5xl text-center text-white bg-yellow-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"> 101 </div> -->
     <div class="text-yellow-500 text-7xl "> 101 </div>
     <div class="text-left texe-xl">   แต้ม</div>
   <div> -----</div>

      <div class="mb-2">
              <a href="#orderid" class="">
            <p class="flex items-center text-base text-emerald-600 hover:text-emerald-300">
              แต้มหมดอายุ  วันที่ 31 ธันวาคม 2565
             
            </p> </a>
            <div class="mb-2 text-xl font-bold text-gray-900">
              <i class="fa-solid fa-coin"> 0 </i>
            </div>
                
              <p class="text-gray-600">แต้ม</p>
         
          </div>
    </div>       
    
      <!-- <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          > -->
          <div class="px-4 py-3 text-center sm:px-6">
              <button
                type="submit"
                class="inline-flex justify-center px-4 py-2 mx-2 text-2xl font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              ><i class="fa-solid fa-gifts">     </i> แลก CuCi Gift
              </button>
           
            </div>
    

    
           
  </div>
  
  <ul class="flex flex-col flex-wrap pl-0 mb-4 list-none border-b-0 nav nav-tabs md:flex-row" id="tabs-tab"
  role="tablist">
  <li class="mx-1 nav-item" role="presentation">
    <a href="#tabs-GVALL" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL"
      aria-selected="true">
      
      <div class="">
        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
    <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>301</span></span>
    <span class="ml-1.5 "><i class="fa-solid fa-gifts"></i>ประวัติการได้รับ CuCi Point </span>
  </strong>
</div>
      
     </a>
  </li>
  <li class="mx-1 nav-item" role="presentation">
    <a href="#tabs-GVGive" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-GVGive-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVGive" role="tab"
      aria-controls="tabs-GVGive" aria-selected="false">
    
      <div class="">
  <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
    <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>200</span></span>
    <span class="ml-1.5"><i class="fas fa-gifts"></i> ประวัติการแลกรางวัล CuCi Point</span>
  </strong>
</div>
      
    </a>
  </li>

</ul>
<div class="tab-content" id="tabs-tabContent">
  <div class="mx-4 font-black text-orange-500 tab-pane fade show active" id="tabs-GVALL" role="tabpanel" aria-labelledby="tabs-GVALL-tab">
    ประวัติการได้รับ CuCi Point 
    <div
        class="relative flex flex-wrap mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2"
      >
        
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/cuciPoint.png"
            />

            <div class="mb-2">
              <a href="#orderid" class="">
            <p class="flex items-center text-base text-emerald-600 hover:text-emerald-300">
        
              Order ID : 01012022.001
            </p> </a>
            <div class="mb-2 text-xl font-bold text-gray-900">
              ได้ัรบ 10 CuCi Point
            </div>
            <p class="text-base text-gray-700">
              ยอดเงินชำระ 1,000 บาท
            </p>
          
            <i class=" fa-solid fa-circle-check"> ยื่นยันการชำระ </i>
            
              <p class="text-gray-600"> 01012022 20:00:10</p>
         
          </div>
          </div>
       
    

    </div>
    <div
        class="relative flex flex-wrap mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2"
      >
        
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/cuciPoint.png"
            />

            <div class="mb-2">
              <a href="#orderid" class="">
            <p class="flex items-center text-base text-emerald-600 hover:text-emerald-300">
        
              Order ID : 02012022.002
            </p> </a>
            <div class="mb-2 text-xl font-bold text-gray-900">
              ได้ัรบ 5 CuCi Point
            </div>
            <p class="text-base text-gray-700">
              ยอดเงินชำระ 500 บาท
            </p>
          
            <i class=" fa-solid fa-circle-check"> ยื่นยันการชำระ </i>
            
              <p class="text-gray-600"> 03012022 05:04:14</p>
         
          </div>
          </div>
       
    

    </div>
    <div
        class="relative flex flex-wrap mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2"
      >
        
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/cuciPoint.png"
            />

            <div class="mb-2">
              <a href="#orderid" class="">
            <p class="flex items-center text-base text-emerald-600 hover:text-emerald-300">
        
              Order ID : 14012022.078
            </p> </a>
            <div class="mb-2 text-xl font-bold text-gray-900">
              ได้ัรบ 3 CuCi Point
            </div>
            <p class="text-base text-gray-700">
              ยอดเงินชำระ 300 บาท
            </p>
          
            <i class=" fa-solid fa-circle-check"> ยื่นยันการชำระ </i>
            
              <p class="text-gray-600"> 14012022 08:07:17</p>
         
          </div>
          </div>
       
    

    </div>
    <div
        class="relative flex flex-wrap mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2"
      >
        
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/cuciPoint.png"
            />

            <div class="mb-2">
              <a href="#orderid" class="">
            <p class="flex items-center text-base text-emerald-600 hover:text-emerald-300">
        
              Order ID : 17052022.047
            </p> </a>
            <div class="mb-2 text-xl font-bold text-gray-900">
              ได้ัรบ 4 CuCi Point
            </div>
            <p class="text-base text-gray-700">
              ยอดเงินชำระ 400 บาท
            </p>
          
            <i class=" fa-solid fa-circle-check"> ยื่นยันการชำระ </i>
            
              <p class="text-gray-600"> 18052022 20:01:10</p>
         
          </div>
          </div>
       
    

    </div>

  </div>
  <div class="mx-4 font-black text-orange-500 tab-pane fade" id="tabs-GVGive" role="tabpanel" aria-labelledby="tabs-GVGive-tab">
    ประวัติการแลกรางวัล CuCi Point
    <div
        class="relative flex flex-wrap mx-4 my-4 bg-white border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2"
      >
        
          <div class="flex items-center mx-2 my-2">
            <img
              class="object-contain w-64 h-32 mx-2"
              src="@/assets/img/CuCiTopi.png"
            />

            <div class="mb-2">
              <a href="#orderid" class="">
            <p class="flex items-center text-base text-emerald-600 hover:text-emerald-300">
        
              Exchange ID : 20220712 001
            </p> </a>
            <div class="mb-2 text-xl font-bold text-gray-900">
              ได้แลกของรางวัล หมวก CuCi 
            </div>
            <p class="text-base text-gray-700">
              ใช้ CuCi Point 200 แต้ม 
            </p>
          
            <i class=" fa-solid fa-circle-check"> สถานะการจัดส่ง</i>
            
              <p class="text-gray-600"> จัดส่งเรียบร้อย เมื่อ วันที่ 15072022</p>
         
          </div>
          </div>
       
    

    </div>
    <div class="h-32"></div>
  </div>

</div>
</div>
</div>  

  <!-- //  CuCi Point  CuCi Gift-->
  <section class="w-full px-10 pt-4 pb-4 mx-auto text-center bgcc lg:mx-auto">
    <div class="container pb-2 mx-auto text-center">
      <div>
        <P class="excc-top">แลกรางวัล: CuCi Gifts</P>
        <h2
          class="mb-2 text-xl font-semibold leading-tight text-gray-800 capitalize "
        >
        
        </h2>
      </div>
      <a class="text-xl flexcc-top">
      <div class="container flex items-center">
        <span> แลกรางวัล: CuCi Gifts</span>
      </div>
    </a>
      <div class="flex flex-wrap items-center mb-4">
        <!-- เสื้อ -->
        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
            <img src="@/assets/img/CuCiBaju.png" class="object-cover w-full h-full" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                เสื้อ CuCi 
              </h5> <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p>

            <p class="text-xs text-left">300 Point</p>
            <i class="fa-solid fa-gift"></i>
            </div>
          </router-link>

          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        </div>
        <!-- แลกรางวัล: CuCi Gifts -->
        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
            <img src="@/assets/img/CuCipayung.png" class="object-cover w-full h-full" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                ร่ม CuCi
              </h5>
              <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p>

            <p class="text-xs text-left">250 Point</p>
            <i class="fa-solid fa-gift"></i>
            </div>
          </router-link>

          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        </div>
        <!-- p003 -->
        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
            <img src="@/assets/img/CuCiTopi.png" class="object-cover w-full h-full" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                หมวก CuCi
              </h5>
              <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p>
            <p class="text-xs text-left">200 Point</p>
              <i class="fas fa-book-reader"></i>
            </div>
          </router-link>
        

          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        
        </div>
        <!-- แก้ว  CuCi  -->
        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
          <img src="@/assets/img/CuCiGlass01.png" class="object-cover w-full h-full" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                แก้ว  CuCi 
              </h5>
              <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p>
            <p class="text-xs text-left">150 Point</p>
            <i class="fa-solid fa-gift"></i>
            </div>
          </router-link>

          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        </div>
        <!-- แก้ว  CuCi Riba -->
        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
          <img src="@/assets/img/CuCiGlass03.png" class="object-cover w-full h-full" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                แก้ว  CuCi Riba
              </h5>
              <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p>
            <p class="text-xs text-left">150 Point</p>
            <i class="fa-solid fa-gift"></i>
            </div>
          </router-link>

          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        </div>
        <!--  แก้ว  CuCi Derma -->
        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
            <img src="@/assets/img/CuCiGlass04.png" class="object-cover w-full h-full" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                แก้ว  CuCi Derma
              </h5>
              <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p>
            <p class="text-xs text-left">150 Point</p>
            <i class="fa-solid fa-gift"></i>
            </div>
          </router-link>

          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        </div>
      
        </div>
      </div>

  </section>  
    <!-- // แลก CuCi Point  CuCi Gift-->
    <section class="w-full px-10 pt-4 pb-4 mx-auto text-center bgcc lg:mx-auto">
    <div class="container pb-2 mx-auto text-center">
      <div>
        <P class="flexcc-top">แลกรางวัล: CuCi Gifts</P>
        <h2
          class="mb-2 text-xl font-semibold leading-tight text-gray-800 capitalize "
        >
        
        </h2>
      </div>
      <div class="flex flex-wrap items-center mb-4">
        <!-- เสื้อ -->
        <div class="w-full p-4 lg:w-6/12 sm:w-6/12">
          <router-link
            to="/#"
            class="block text-left hover:opacity-75"
          >
            <img src="@/assets/img/CuCiBaju.png" class="object-cover w-full h-full" />
            
          </router-link>

          
        </div>
        <!-- CuCipayung -->
        <div class="block w-full p-4 text-left lg:w-6/12 sm:w-6/12 hover:opacity-75">
                 
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                <i class="fa-solid fa-gift"></i>  เสื้อ CuCi
                <p class="text-sm text-left">
              แลกโดยใช้ CuCi Point 
            </p> <p class="text-lg text-left">300 Point</p>
            
              </h5>

            </div>
           
          
 <div class="inline-flex w-6/12">
  <div class="w-6/12 ">
    <input type="text" class="my-2 cc_input_text" id=""   placeholder="ใส่จำนวน - ชิ้น" /> 
  </div>
  <div class="w-6/12 "> 
   
  </div>
                     
                  </div>
          <router-link to="/#" class="flexbtncc-og"
            >แลก CuCi Gift</router-link
          >
        </div>
      
        </div>
      </div>

  </section>  


    <!-- //ยื่นยันที่อยู่ แลก CuCi Point  CuCi Gift-->
    <section class="bgcc">
    <div class="container pb-2 mx-auto my-auto text-center bg-white border-2 rounded-lg shadow-lg border-slate-100">
      <div>
        <P class="px-4 py-4 mx-4 flexcc-top">ยื่นยันการแลกรางวัล: CuCi Gifts</P>
      <div class="flex flex-col items-center w-full mx-4 my-4">
            <img src="@/assets/img/CuCiBaju.png" class="object-cover w-32 h-32" />
            <h5 class="pt-3 text-base font-semibold text-gray-900">
                <i class="fa-solid fa-gift"></i> รายละเอียดยื่นยันการแลกรางวัล
                <p class="text-sm ">แลก เสื้อ CuCi โดยใช้ CuCi Point </p> 
                <p class="text-lg ">300 Point</p>
              </h5>
                  <div class="relative mb-2">
                    <div  class="absolute inset-y-0 left-0 flex items-center px-2 ml-1 text-gray-700 pointer-events-none">
                    <i class="w-4 h-4 fill-current fa-solid fa-address-book"> </i>
                    </div>
              <select
                class="block w-full h-full px-4 py-2 pr-8 ml-2 cc_input_text">
                <option>เลือกที่อยู่เพื่อจัดส่งของรางวัล</option>
                <option>ที่อยู่ที่บ้าน</option>
                <option>ที่อยุ่ ที่ทำงาน</option>
                <option>ที่อยู่ โรงผลิตน้ำดื่ม</option>
                
              </select>

              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
              >

                <i class="w-4 h-4 fill-current far fa-arrow-alt-square-down"></i>
              </div>
            </div>
            <h5 class="text-base font-semibold text-gray-900">
                
                <p class="text-sm ">
                  101 ม.1 ตำบลปิตูมุดี อำเภอยะรัง จังหวัดปัตตานี 94160 
                  <br> โทรศัพท์ 08.7837.8335
                </p>
               
              </h5>
          <router-link to="/#" class="flexbtncc-og">ยื่นยันแลก CuCi Gift</router-link>
      </div>
    </div>
  </div>
 <div class="h-96">

 </div>

</section>
  
<!-- ขอใช้บัตรกำนัล Gift Voucher -->
<section class="bgcc">
    <div class="container pb-2 mx-auto my-auto text-center bg-white border-2 rounded-lg shadow-lg border-slate-100">
      <div>
        <P class="px-4 py-4 mx-4 flexcc-top">ขอใช้บัตรกำนัล Gift Voucher </P>
      <div class="flex flex-col items-center w-full mx-4 my-4">
            <img src="@/assets/img/GVC001.png" class="object-cover w-64 h-32" />
            <h5 class="pt-3 text-base font-semibold text-gray-900">
                <i class="fa-solid fa-gift"></i> รายละเอียดข้อมูลบัตรกำนัล Gift Voucher
                <p class="text-sm ">Gift Voucher ID| GVMR1241</p> 
                <p class="text-lg ">มูลค่า 50 บาท </p>
                

              </h5>
                 
            <h5 class="text-base font-semibold text-gray-900">
                
                <p class="text-sm ">
                  รหัสโครงการ P022 โดย MiRA Drinking Water
                  <br> วันที่เก็บ 25 ก.ค. 65 เ เวลา 16:23:41 น.
                  <br> วันที่หมดอายุแล้ว 2 ก.ย. 65
                </p>
               
              </h5>
          <router-link to="/#" class="flexbtncc-og">ใช้ Gift Voucher</router-link>
      </div>
    </div>
  </div>
 <div class="h-96">

 </div>

</section>

<!-- ยื่นยันใช้บัตรกำนัล Gift Voucher -->
<section class="bgcc">
    <div class="container pb-2 mx-auto my-auto text-center bg-white border-2 rounded-lg shadow-lg border-slate-100">
      <div>
        <P class="px-4 py-4 mx-4 flexcc-top">ยื่นยันการขอใช้บัตรกำนัล Gift Voucher </P>
      <div class="flex flex-col items-center w-full mx-4 my-4">
            <img src="@/assets/img/GVC001.png" class="object-cover w-64 h-32" />
            <h5 class="pt-3 text-base font-semibold text-gray-900">
                <i class="fa-solid fa-gift"></i> รายละเอียดข้อมูลบัตรกำนัล Gift Voucher
                <p class="text-sm ">Gift Voucher ID| GVMR1241</p> 
                <p class="text-lg ">มูลค่า 50 บาท </p>
                

              </h5>
                 
            <h5 class="text-base font-semibold text-gray-900">
                
                <p class="text-sm ">
                  รหัสโครงการ P022 โดย MiRA Drinking Water
                  <br> วันที่เก็บ 25 ก.ค. 65 เ เวลา 16:23:41 น.
                  <br> วันที่หมดอายุแล้ว 2 ก.ย. 65
                </p>
                <p class="text-xl font-bold text-emerald-500"> คุณอิบรอฮิม กีละ </p>
               
              </h5>
          <router-link to="/#" class="flexbtncc-og">ยื่นยันการขอใช้ Gift Voucher</router-link>
      </div>
    </div>
  </div>
 <div class="h-96">

 </div>

</section>
  
</template>

<script>

</script>
