// import axios from 'axios'

// const backendService = axios.create({
//     baseURL: process.env.VUE_APP_URL_API,
//     withCredentials: true,
//     headers: {
//         "Content-type": "multipath/form-data",
//         "Accept": "application/json",
//     }
// })

// //การใช้ Interceptor เพื่อแทรกตัว token เข้าไปใน header
// backendService.interceptors.request.use(config => {
//     let token = ''
//     try{
//         let userStorage = localStorage.getItem('cuciMember')
//         let userStorageJSON = JSON.parse(userStorage)
//         token = userStorageJSON['token']
//     }catch(error){
//         console.log(error)
//     }

//     if(token){
//         config.headers.Authorization = "Bearer "+token
//     }

//     return config

// })

// export default backendService

import axios from 'axios';

const authService = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
    withCredentials: true,
    headers:{
        "Content-type": 'Application/json',
        "Accept": "application/json"
    }
})

export default authService