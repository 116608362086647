<template>
    <section class="bg-indigo-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">ชำระเงินด้วย QR code</h1>
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">{{showMoney(amount)}} บาท</h1>
                <!-- <img :src="`data:image/png;base64, ${qrcode}`" alt="" srcset=""> -->
                <img :src="qrcode" alt="" srcset="">
                
                <p v-if="showText == false" class="flex justify-center">loading...</p>
            </div>
            <!-- <div class="flex justify-center w-full px-4 mt-4 mb-4 lg:mx-auto lg:w-2/2" v-if="showText == true">
                <button @click="downloadImage" class="inline-flex items-center px-4 py-2 font-semibold text-white bg-blue-500 rounded hover:bg-blue-600">
                    <i class="fa-regular fa-circle-down"></i>&nbsp;&nbsp;
                     Download
                </button>
            </div> -->
            <div class="flex justify-center w-full px-4 mt-4 mb-4 lg:mx-auto lg:w-2/2" v-if="showText == true">
                <p class="font-bold">ขั้นตอนการชำระเงินโดยใช้ Thai QR code ผ่านแอปธนาคาร</p>
            </div>
            <div class="w-full px-4 mt-4 mb-4 lg:mx-auto lg:w-2/2" v-if="showText == true">
                <p>1. หากท่านใช้โทรศัพท์มือถือ และต้องการชำระผ่านแอพธนาคาร ให้ท่านจับภาพหน้าจอโดยให้เห็น QR Code ทั้งหมดไว้ก่อน</p>
                <p>2. เข้าสู่ระบบแอพธนาคาร</p>
                <p>3. เลือกเมนู โอนเงิน หรือ Scan QR Code หรือ Upload QR</p>
                <p>4. ใช้โทรศัพท์มือถือเปิด QR Code ที่จับภาพหน้าจอไว้ก่อนหน้า หรือ scan QR ที่ปรากฏบนหน้าจอนี้</p>
                <p>5. ตรวจสอบชื่อบัญชีว่าตรงกับชื่อบัญชีข้างต้นหรือไม่</p>
                <p>6. เมื่อทำรายการเรียบร้อย หน้าจอจะแสดงผลสำเร็จ</p>
            </div>
        </div> 
    </section>
</template>

<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter'
    export default {
        mounted(){
            this.retreiveQrcode()
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        beforeCreated(){
            this.retreiveQrcode()
        },
        data(){
            return{
                qrcode: '',
                amount: '', 
                timer: '',
                checkingCount: 0,
                showText: false
            }
        },
        methods:{
            downloadImage() {
                // Get the image URL
                const imageUrl = this.qrcode;
                
                // Create a link element and set its properties
                const link = document.createElement("a");
                link.href = imageUrl;
                link.download = "image.png";
                
                // Append the link to the DOM and click it to trigger the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            retreiveQrcode(){
                // http.get(`riba/retreiveQrcode/${this.$route.params.order_id}`)
                http.get(`omise/retreiveQrcode/${this.$route.params.order_id}`)
                    .then(response => {
                        if(response.data.data == null){
                            this.$router.push({name: 'Riba'})
                        }else{
                            this.qrcode = response.data.data.qrcode
                            this.amount = response.data.data.amount
                            this.showText = true
                            this.timer = setInterval(() => {
                                this.retreiveQrcodeStatus()
                            }, 5000)
                        }
                    })
            },
            retreiveQrcodeStatus(){
                if(this.checkingCount <= 30){
                    // http.get(`riba/retreiveQrcodeStatus/${this.$route.params.order_id}`)
                    http.get(`omise/retreiveQrcodeStatus/${this.$route.params.order_id}`)
                        .then(response => {
                            if(response.data.status == 'successful'){
                                clearInterval(this.timer)
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'สำเร็จ',
                                    text: 'ขอบคุณที่สะสางดอกเบี้ยกับ จูจี ขอความบารอกัตบังเกิดแก่ท่าน',
                                    confirmButtonText: 'ตกลง'
                                }).then(() => {
                                    this.$router.push({name: 'Riba'})
                                })
                            }else{
                                this.checkingCount++ 
                            }
                        })
                }else{
                    clearInterval(this.timer)
                    this.$swal.fire({
                        title: 'ท่านยังไม่ได้ชำระเงิน',
                        text: "ยังต้องการดำเนินการต่อหรือไม่!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'ไม่',
                        confirmButtonText: 'ใช่',
                        }).then((result) => {
                            if(result.isConfirmed){
                                this.checkingCount = 0
                                this.timer = setInterval(() => {
                                    this.retreiveQrcodeStatus()
                                }, 5000)
                            }else{
                                this.$router.push({name: 'PaymentHistories'})
                            }
                        })
                }
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0.00')
            },
        },
        beforeUnmount () {
            clearInterval(this.timer)
        }
    }
</script>

<style lang="scss" scoped>

</style>