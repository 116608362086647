<template>
    <div class="container pt-40 pb-20 pl-4 mx-auto">
        <div class="max-w-md overflow-hidden bg-white shadow-md lg:mx-10 rounded-xl md:max-w-2xl">
            <div class="md:flex">
            </div>
        </div>
 
       <!-- Cards -->
       <h2 class="my-6 text-2xl font-semibold text-center text-gray-700 lg:mx-80 dark:text-gray-200">
           ตะกร้า
       </h2>
 
       <!-- New Table -->
       <div class="overflow-hidden rounded-lg shadow-xs lg:mx-80" id="target-cart-list">
           <div class="w-full overflow-x-auto">
                <table class="w-full whitespace-no-wrap">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <!-- <th  class="px-4 py-3 font-semibold">#</th> -->
                            <th  class="px-4 py-3 font-semibold">โครงการ</th>
                            <th  class="px-4 py-3 font-semibold">จำนวนเงิน</th>
                            <th  class="px-4 py-3 font-semibold">ลบ</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in cartItem" :key="index">
                            <!-- <td class="px-4 py-3 text-sm">
                                {{ index+1 }}
                            </td> -->
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                <div>
                                    <p class="text-base font-bold">{{item.name}} ({{ item.budget_type_text }})</p>
                                    <p class="text-sm text-gray-600 dark:text-gray-400">
                                        <i>ยอด{{ item.budget_type_text }} {{ showMoney(netColculate(item.amount, item.fee)) }} บาท ค่าธรรมเนียม({{ item.fee }}%) {{ showMoney(feeColculate(item.amount, item.fee)) }} บาท </i>
                                    </p>
                                </div>
                            </div>
                            </td>
                            <td  class="px-4 py-3 text-sm">
                            <input type="number" v-model="item.amount" @change="logedIn ? updateCart() : updateCartWitoutLogin(index)" step="1" min="20" required class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInputEmail1" aria-describedby="emailHelp">
                            <div v-if="serverError.amount && item.id == serverError.id" class="mt-1 text-sm text-red-500">
                                {{serverError.amount[0]}}
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                <i class="cursor-pointer fas fa-trash-alt" style="color: red" @click=" logedIn ? deleteItem(item.id) : deleteItemWithoutLogin(index)"></i>   
                            </td> 
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400" v-if="cartItem.length == 0">
                            <td class="px-4 py-3 text-sm text-center" colspan="4">
                                ตะกร้าว่าง
                            </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3 text-sm text-center" colspan="4">
                                <p v-if="serverError.totalMoney" class="text-sm text-red-500">
                                    {{ serverError.totalMoney[0] }}
                                </p>
                                <p class="font-semibold" v-if="serverError == ''">
                                    จำนวนเงินที่ต้องชำระทั้งหมด {{showMoney(totalMoney)}} บาท
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
           </div>
           <div class="text-right">
                <button v-if="cartItem.length != 0" @click="gotoDonate" class="items-center ml-2 buttoncuci">ชำระเงิน</button>
                <router-link  to="/donate" class="ml-2 mr-2 buttoncuci">ดูโครงการเพิ่ม</router-link>
            </div>
        </div>

        <div class="container pt-5 pb-5 mx-auto sm:w-full md:w-3/4 lg:w-2/4" id="target-donate" v-if="cartItem != 0">
            <br><br><br>
            <div class="flex justify-center mt-2 rounded-lg">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex flex-col md:flex-row">
                        <div class="sm:p-2 md:p-2 sm:w-full md:w-4/5">
                            <u><b>ช่องทางการชำระเงิน :- </b></u>
                        </div>
                    </div>
                    <div class="flex rounded-lg">
                        <div  class="justify-center flex-1 clemax-w-4xl">
                            <div class="flex flex-col md:flex-row">
                                <div class="mt-2 md:mr-2 lg:w-1/2 sm:w-full md:w-1/2">
                                    <input class="hidden" @click="selectPaymentMethod(2)" id="radio_1" type="radio" name="radio">
                                    <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_1">
                                        <span class="text-xs font-semibold uppercase">
                                            <img src="@/assets/img/qr_scb.png" alt="">
                                        </span>
                                        <span class="mt-3 text-xl font-bold text-center">
                                            ชำระเงินด้วย qr code
                                        </span>
                                    </label>
                                </div>
                                <!-- <div class="mt-2 md:ml-2 lg:w-1/2 sm:w-full md:w-1/2">
                                    <div>
                                        <input class="hidden" @click="selectPaymentMethod(1)" id="radio_2" type="radio" name="radio">
                                        <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_2">
                                            <span class="text-xs font-semibold uppercase">
                                                <img src="@/assets/img/scb.jpg" alt="">
                                            </span>
                                            <span class="mt-3 text-xl font-bold text-center">
                                                บัญชีธนาคาร
                                            </span>
                                        </label>
                                    </div>
                                </div> -->
                            </div>
                            <div class="mt-4 row">
                                <p v-if="paymentMethod == 1" class="text-base">
                                    ธนาคารไทยพานิชย์ ชื่อบัญชี:มูลนิธิจูจีสร้างสรรค์สังคม 
                                    <span class="text-sm font-bold text-red-500" style="margin-top: -15px">
                                        &nbsp;&nbsp;
                                        508-303048-0
                                        <button @click.stop.prevent="copyToClipboard()"  class="px-1 py-1 mb-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700">
                                            <div class="flex">
                                                <span>คัดลอก</span> 
                                                <svg class="w-5 h-5" :class="{'hidden': is_copied}" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </button>
                                        <input type="hidden" id="accountNumber" value="5083030480">
                                    </span>
                                </p>
                                <p v-if="paymentMethod == 1" class="font-baseextrabold text-">
                                    อย่าลืมกรอกฟอร์มยืนยันโอนเงินที่เว็บไซต์ หลังจากโอนเงินผ่านธนาคารแล้ว
                                </p>
                                <!-- <p v-if="paymentMethod == 2" class="font-baseextrabold text-">พร้อมให้บริการเร็วๆนี้</p> -->
                                <p v-if="paymentMethod == 2 && totalMoney >= 20" class="text-base">ชำระผ่านการ สแกน/อัพโหลด QR code ด้วย mobile banking application ของ ธนาคารไทยพานิชย์ ธนาคารทหารไทย ธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรี ธนาคารธนชาต ธนาคารกสิกร ธนาคารออมสิน</p>
                                <p v-if="paymentMethod == 2 && totalMoney >= 20" class="font-baseextrabold text-">คุณจะได้ QR code หลังจากดกปุ่มยืนยัน</p>
                                <p v-if="!logedIn" class="mt-3 text-sm text-gray-700 font-baseextrabold">สมัครสมาชิกและเข้าสู่ระบบก่อนกดยืนยันชำระ เพื่อสามารถดูประวัติการชำระและขอใบเสร็จของท่านได้ในภายหลัง 
                                    <router-link to="/login" class="text-sm text-emerald-500">
                                        [เข้าสู่ระบบ] 
                                    </router-link>
                                    หากไม่ต้องการสามารถกดยืนยันชำระได้เลย
                                </p>
                                <div class="flex rounded-lg" v-if="paymentMethod == 2 && totalMoney >= 20">
                                    <div  class="flex-1">
                                        <!-- if not logedIn -->
                                        <div class="flex md:flex-row" v-if="!logedIn">
                                            <div class="w-1/2 mt-2">
                                                ชื่อ *
                                            </div>
                                            <div class="w-1/2 mt-2 ml-2">
                                                นามสกุล *
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!logedIn">
                                            <div class="w-1/2 mt-2">
                                                <input type="text" v-model="name" class="cc_input_text" :class="{'border-red-500': serverError.name}">
                                                <div v-if="serverError.name" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.name[0] }}
                                                </div>
                                            </div>
                                            <div class="w-1/2 mt-2 ml-2">
                                                <input type="text" v-model="lastname" class="cc_input_text" :class="{'border-red-500': serverError.lastname}">
                                                <div v-if="serverError.lastname" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.lastname[0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!logedIn">
                                            <!-- <div class="w-1/2 mt-2">
                                                อีเมล
                                            </div> -->
                                            <div class="w-1/2 mt-2">
                                                เบอร์โทรศัพท์ *
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!logedIn">
                                            <!-- <div class="w-1/2 mt-2">
                                                <input type="text" v-model="email" class="cc_input_text" :class="{'border-red-500': serverError.email}">
                                                <div v-if="serverError.email" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.email[0] }}
                                                </div>
                                            </div> -->
                                            <div class="w-1/2 mt-2">
                                                <input type="text" v-model="telephone" class="cc_input_text" :class="{'border-red-500': serverError.telephone}">
                                                <div v-if="serverError.telephone" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.telephone[0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!logedIn">
                                            <div class="w-full mt-2">
                                                <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม 
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!anonymous && !logedIn">
                                            <div class="w-full mt-2">
                                                <i> ชื่อและนามสกุลนี้จะแสดงในหน้ารายชื่อผู้ร่วมสนับสนุน</i>
                                            </div>
                                        </div>
                                        <!-- \. if not logedIn -->

                                        <!-- if logedIn -->
                                        <div class="flex md:flex-row" v-if="logedIn">
                                            <div class="w-full mt-2">
                                                <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม 
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!anonymous && logedIn">
                                            <div class="w-full mt-2">
                                                <i> ชื่อและนามสกุลนี้จะแสดงในหน้ารายชื่อผู้ร่วมสนับสนุน</i>
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!anonymous && logedIn">
                                            <div class="w-1/2 mt-2">
                                                ชื่อ
                                            </div>
                                            <div class="w-1/2 mt-2 ml-2">
                                                นามสกุล
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row" v-if="!anonymous && logedIn">
                                            <div class="w-1/2 mt-2">
                                                <input type="text" v-model="name" class="cc_input_text" :class="{'border-red-500': serverError.name}">
                                                <div v-if="serverError.name" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.name[0] }}
                                                </div>
                                            </div>
                                            <div class="w-1/2 mt-2 ml-2">
                                                <input type="text" v-model="lastname" class="cc_input_text" :class="{'border-red-500': serverError.lastname}">
                                                <div v-if="serverError.lastname" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.lastname[0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- \.if logedIn -->
                                    </div>
                                </div>
                                <p v-if="paymentMethod == 2 && totalMoney < 20">จำนวนเงินน้อยกว่า 20 บาท ไม่สามารถชำระด้วย qrcode ได้</p>
                                <div v-if="paymentMethod == 2 && totalMoney >= 20" class="w-full rounded-lg">
                                    <button 
                                        class="w-full mt-1 btncc-riba"
                                        @click="logedIn ? createPromptpayTransaction() : createPromptpayTransactionWithoutLogin()"
                                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                                        :disabled="disabled">
                                        ยืนยันการชำระเงิน
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <form @submit.prevent="createTransaction()" v-if="paymentMethod == 1">
               
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5">
                                ชื่อ นามสกุล {{name}} {{lastname}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5 ">
                                <span class="text-gray-500">(Email: {{email}})</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5 mr-5">
                                <input type="text" v-model="telephone" class="cc_input_text" :class="{'border-red-500': serverError.telephone}">
                                <div v-if="serverError.telephone" class="text-sm text-red-500">
                                    {{serverError.telephone[0]}}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-2 ml-5">
                                <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-2 rounded-lg ">
                    <button
                        class="w-full mt-1 ml-5 mr-5 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                    ยืนยันการบริจาค
                    </button>
                </div>
            </form> -->

        </div>
   
    </div>  
</template>
   
<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter'
    import Cookies from 'js-cookie'
    export default {
        mounted(){
            let logedIn = this.$store.getters['authMember/logedIn']
            if(logedIn){
                this.retreiveCart()
                this.retreiveProfile()
            }else{
                this.retreiveCartWithoutLogin()
            }
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        computed:{
            logedIn(){
                return this.$store.getters['authMember/logedIn']
            },
        },
        data(){
            return{
                cartItem: [],
                totalMoney: 0,

                paymentMethod: 2,
                
                serverError: '',
                disabled: false,
                is_copied: true,
                
                order_id: '',
             
                //ชื่อสกุลผู้บริจาค กรณีตประสงค์ออกนามผู้บริจาค
                name: '',
                lastname: '',
                telephone: '',
                email: '',
                anonymous: true,

                order_id_code: '',
            }
        },
        methods:{
            createPromptpayTransactionWithoutLogin(){
                if(this.name == ''){
                    this.serverError = {name: ['กรุณากรอกชื่อ']}
                }else if(this.lastname == ''){
                    this.serverError = {lastname: ['กรุณากรอกนามสกุล']}
                // }else if(this.email == ''){
                //     this.serverError = {email: ['กรุณากรอกอีเมล']}
                }else if(this.telephone == ''){
                    this.serverError = {telephone: ['กรุณากรอกเบอร์โทรศัพท์']}
                }else{
                    this.$Progress.start()
                    this.disabled = true
                    let data = new FormData()
                    data.append('cart_items', JSON.stringify(this.cartItem))
                    data.append('anonymous', Number(this.anonymous))
                    data.append('name', this.name)
                    data.append('lastname', this.lastname)
                    data.append('telephone', this.telephone)
                    data.append('email', this.email)
                    http.post(`omiseWithoutLogin/createPromptpayTransactionWithoutLogin`, data)
                        .then(response => {
                            this.$Progress.finish()
                            this.disabled = false
                            // console.log(response.data)
                            //clear cookies
                            Cookies.remove('cart_items')
                            localStorage.removeItem('myCart')
                            this.$router.push({name: 'CartQrPromptpayWithoutLogin', params:{'order_id_code': response.data.order_id_code, 'unmember_id_code': response.data.unmember_id_code}})
                            this.serverError = ''
                        })
                        .catch(error => {
                            if(error.response.data.errors['totalMoney']){
                                var getMeTo = document.getElementById("target-cart-list");
                                getMeTo.scrollIntoView({behavior: 'smooth'}, true);       
                            }
                            this.$Progress.fail()
                            this.disabled = false
                            this.serverError = error.response.data.errors
                        })
                }
            },
            deleteItemWithoutLogin(id){
                this.cartItem.splice(id, 1)
                let cart_items = JSON.parse(Cookies.get('cart_items'))
                cart_items.splice(id, 1)
                Cookies.set('cart_items', JSON.stringify(cart_items))
                let totalMoney = 0
                this.cartItem.forEach(element => {
                    totalMoney = parseFloat(totalMoney + element.amount)
                });
                this.totalMoney = totalMoney
                this.$store.dispatch("authMember/retreiveCartWithoutLogin", JSON.parse(Cookies.get('cart_items')))
            },
            updateCartWitoutLogin(id){
                let cart_items = JSON.parse(Cookies.get('cart_items'))
                let money = this.cartItem[id].amount
                if(money >= 20){
                    let totalMoney = 0
                    this.cartItem.forEach(element => {
                        totalMoney = parseFloat(totalMoney + element.amount)
                    });
                    if(totalMoney > 10000){
                        this.serverError = {
                            totalMoney: ['จำนวนเงินต้องไม่เกิน 100,000 บาท']
                        }
                        this.retreiveCartWithoutLogin()
                    }else{
                        cart_items[id].money = this.cartItem[id].amount
                        Cookies.set('cart_items', JSON.stringify(cart_items))
                        this.totalMoney = totalMoney
                        this.serverError = ''
                    }
                }else{
                    this.retreiveCartWithoutLogin()
                }
            },
            retreiveCartWithoutLogin(){
                this.$Progress.start()
                let cart_items = Cookies.get('cart_items')
                let data = new FormData();
                data.append('cart_items', cart_items)
                http.post(`cartWithoutLogin/retreiveCart`, data)
                    .then(response => {
                        this.cartItem = response.data.data
                        let totalMoney = 0
                        response.data.data.forEach(element => {
                            totalMoney = parseFloat(totalMoney + element.amount)
                        });
                        this.totalMoney = totalMoney
                        // if(response.data.data.length){
                        //     this.order_id = response.data.data[0].order_id
                        //     this.order_id_code = response.data.order_id
                        // }
                        // this.$store.dispatch("authMember/retreiveCart")
                        this.$Progress.finish()
                    })
                    .catch((error) => {
                        this.$Progress.fail()
                        console.log(error)
                    })
            },
            feeColculate(amount, fee){
                //2.5 percent of the total amount
                return (fee/100) * amount
            },
            netColculate(amount, fee){
                let feeRate = (fee/100) * amount
                return amount - feeRate
            },
            createPromptpayTransaction(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData();
                data.append('order_id', this.order_id_code)
                data.append('anonymous', Number(this.anonymous))
                data.append('name', this.name)
                data.append('lastname', this.lastname)
                http.post(`omise/createPromptpayTransaction`, data)
                    .then(() => {
                        this.$store.dispatch("authMember/retreiveCart")
                        this.$Progress.finish()
                        this.disabled = false
                        this.$router.push({name: 'CartQrPromptpay', params:{'order_id_code': this.order_id_code}})
                    })
                    .catch(error => {
                        // if(error.response.status == 401 || error.response.status == 419){
                        //     this.$store.dispatch('authMember/unauthenticateResetting')
                        // }
                        // if(error.response.data.errors.money){
                        //     var getMeTo = document.getElementById(targetId);
                        //     getMeTo.scrollIntoView({behavior: 'smooth'}, true);
                        // }
                        if(error.response.status == 422 && error.response.data.errors['totalMoney']){
                            var getMeTo = document.getElementById("target-cart-list");
                            getMeTo.scrollIntoView({behavior: 'smooth'}, true);       
                        }else if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
            },
            deleteItem(order_detail_id){
                http.delete(`cart/delete/${order_detail_id}`)
                    .then(() => {
                        this.retreiveCart()
                        this.$store.dispatch("authMember/retreiveCart")
                        this.serverError = ''
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            createTransaction(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('order_id', this.order_id_code)
                data.append('name', this.name)
                data.append('lastname', this.lastname)
                data.append('telephone', this.telephone)
                data.append('amount', this.totalMoney)
                data.append('pay_type', this.paymentMethod)
                data.append('anonymous', Number(this.anonymous))
                http.post(`cart/createTransaction`, data)
                    .then(() => {
                        this.$router.push({ name: 'CheckoutAgain', params: {order_id: this.order_id_code }})
                        this.$Progress.finish()
                        this.disabled = false   
                        this.serverError = ''
                    })
                    .catch(error => {
                        if(error.response.status == 422 && error.response.data.errors['totalMoney']){
                            var getMeTo = document.getElementById("target-cart-list");
                            getMeTo.scrollIntoView({behavior: 'smooth'}, true);       
                        }else if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                        this.disabled = false
                        this.serverError = error.response.data.errors
                    })
            },
            gotoDonate(){
                // window.location.hash='target-donate'
                // document.getElementById('target-donate').scrollIntoView();
                var getMeTo = document.getElementById("target-donate");
                getMeTo.scrollIntoView({behavior: 'smooth'}, true);
            },
            selectPaymentMethod(id){
                this.paymentMethod = id
            },
            updateCart(){
                let cart = this.cartItem.map(item => {
                    return {
                        id: item.id,
                        amount: item.amount,
                        project_id: item.project_id
                    }
                }) 
                this.$Progress.start()
                this.disabled = true
                http.post(`cart/updateCart`, {'cart': cart})
                    .then(() => {
                        this.$Progress.finish()
                        this.disabled = false
                        this.retreiveCart()
                        this.serverError = ''
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }  
                        this.retreiveCart()
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                    })
            },
            retreiveCart(){
                http.get(`cart/myCartList`)
                    .then(response => {
                        this.cartItem = response.data.data
                        let totalMoney = 0
                        response.data.data.forEach(element => {
                            totalMoney = parseFloat(totalMoney + element.amount)
                        });
                        this.totalMoney = totalMoney
                        if(response.data.data.length){
                            this.order_id = response.data.data[0].order_id
                            this.order_id_code = response.data.order_id
                        }
                        this.$store.dispatch("authMember/retreiveCart")
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }  
                    })
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0.00')
            },
            retreiveProfile(){
                http.get(`member/profile`)
                    .then(response => {
                        this.name = response.data.data.mem_name
                        this.lastname = response.data.data.mem_lastname
                        this.telephone = response.data.data.mem_tel
                        this.email  = response.data.data.mem_email
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            copyToClipboard(){
                let accountNumber = document.querySelector('#accountNumber')
                accountNumber.setAttribute('type', 'text')
                accountNumber.select()
                try {
                    document.execCommand('copy');
                    this.is_copied = false
                } catch (err) {
                    alert('Oops, unable to copy');
                }
                /* unselect the range */
                accountNumber.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges() 
            },
        }
    };
</script>
   
<style lang="scss" scoped>

</style>