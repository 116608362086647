<template>
    <section class="bg-orange-200">
        <div class="container pt-20 pb-20 mx-auto lg:pt-40 sm:w-full md:w-3/4 lg:w-2/4">
            <div class="container ">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-2 mr-2 shadow sm:rounded-md sm:overflow-hidden">
                       
                        <!-- profile -->
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">
                                โปรไฟล์
                                <router-link to="/account">
                                    <button type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        <i class="fa-solid fa-circle-left"></i> &nbsp; กลับ
                                    </button>
                                </router-link>
                            </h3>
                            <div>
                                <form @submit.prevent="updateProfile()" enctype="multipart/form-data">
                                    <!-- <div class="flex justify-center col-span-12 sm:col-span-12">
                                        <label class="block text-sm font-medium text-center text-gray-700">
                                            รูปภาพโปรไฟล์ 
                                        </label>
                                        <img :src="`${image_path}images/member/thumbnail/${avatar}`" class="flex justify-center" width="150" height="200">
                                        <div class="flex items-center mt-1">
                                           <div>
                                            
                                           </div>
                                            <span class="inline-block w-12 h-12 overflow-hidden bg-gray-100 rounded-full">
                                                <img :src="mem_photo_url">
                                            </span>
                                            <input @change="selectProfilePhoto" type="file"
                                                class="ml-1 cc_input_text"
                                                max-file-size="1024"
                                                :class="{'border-red-500': serverError.mem_photo}">
                                        </div>
                                        <div v-if="serverError.mem_photo" class="mt-1 text-sm text-red-500">
                                            {{serverError.mem_photo[0]}}
                                        </div>
                                    </div> -->
                                    <div class="flex justify-center col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-center text-gray-700">
                                            รูปภาพโปรไฟล์ 
                                        </label>
                                    </div>   
                                    <div class="flex justify-center col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <img :src="`${avatar}`" class="flex justify-center" width="150" height="200">
                                    </div>   
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">เลือกรูป</label>
                                        <input @change="selectProfilePhoto" type="file"
                                                class="mt-2 ml-1 cc_input_text"
                                                max-file-size="1024"
                                                :class="{'border-red-500': serverError.mem_photo}">
                                    </div>   
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">ชื่อ</label>
                                        <input type="text" 
                                            v-model="mem_name"
                                            :class="{'border-red-500': serverError.mem_name}"
                                            class="mt-1 mb-1 cc_input_text" id="" placeholder="ชื่อ" />
                                            <div v-if="serverError.mem_name" class="mt-1 text-sm text-red-500">
                                                {{serverError.mem_name[0]}}
                                            </div>
                                    </div>   
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">นามสกุล</label>
                                        <input type="text" 
                                            v-model="mem_lastname"
                                            :class="{'border-red-500': serverError.mem_lastname}"
                                            class="mt-1 mb-1 cc_input_text" id="" placeholder="สกุล" />
                                            <div v-if="serverError.mem_lastname" class="mt-1 text-sm text-red-500">
                                                {{serverError.mem_lastname[0]}}
                                            </div>
                                    </div>
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">เลือก วันเดือนปี เกิด</label>
                                        <div class="relative mb-3 form-floating xl:w-full sm:w-full">
                                            <input type="date"
                                                v-model="mem_day_of_birth"
                                                :class="{'border-red-500': serverError.mem_day_of_birth}"
                                                class="form-control mb-1 mt-1 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-emerald-600 focus:outline-none" placeholder="Select a date" />
                                            <label for="floatingInput" class="text-gray-700">-เลือก วันเดือนปี เกิด</label>
                                            <div v-if="serverError.mem_day_of_birth" class="mt-1 text-sm text-red-500">
                                                {{serverError.mem_day_of_birth[0]}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700"> เพศ  </label>
                                        <div class="flex ">
                                            <div>
                                                <div class="form-check">
                                                    <input class="cc_input_radio" type="radio" name="flexRadioDefault"
                                                        v-model="mem_gender" :value="2"
                                                        :class="{'border-red-500': serverError.mem_gender}">
                                                    <label class="inline-block text-gray-800 form-check-label" for="flexRadioDefault1">
                                                        ผู้หญิง
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="cc_input_radio" type="radio" name="flexRadioDefault" 
                                                        v-model="mem_gender" :value="1"
                                                        :class="{'border-red-500': serverError.mem_gender}">
                                                    <label class="inline-block text-gray-800 form-check-label" for="flexRadioDefault2">
                                                        ผู้ชาย
                                                    </label>
                                                </div>
                                                <div v-if="serverError.mem_gender" class="mt-1 text-sm text-red-500">
                                                    {{serverError.mem_gender[0]}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">เฟสบุค [facebook ID]</label>
                                        <input type="text" v-model="mem_fb" class="mt-1 mb-2 cc_input_text" placeholder="Facebook ID" />
                                    </div>
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">ไลน์ไอดี [line ID]</label>
                                        <input type="text" v-model="mem_line_id" class="mb-2 cc_input_text" placeholder="Line ID" />
                                    </div>
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">อีเมล</label>
                                        <input type="text" class="mb-2 cc_input_text" placeholder="อีเมล" 
                                            v-model="mem_email"
                                            :class="{'border-red-500': serverError.mem_email}"/>
                                        <div v-if="serverError.mem_email" class="mt-1 text-sm text-red-500">
                                            {{serverError.mem_email[0]}}
                                        </div>
                                    </div>
                                    <div class="col-span-12 mt-4 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">เบอร์โทรศัพท์</label>
                                        <input type="text" class="cc_input_text" placeholder="เบอร์โทรศัพท์" 
                                            v-model="mem_tel"
                                            :class="{'border-red-500': serverError.mem_tel}"/>
                                        <div v-if="serverError.mem_tel" class="mt-1 text-sm text-red-500">
                                            {{serverError.mem_tel[0]}}
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <button type="submit"
                                            :disabled="disabled"
                                            :class="{'opacity-50 cursor-not-allowed': disabled}"
                                            class="inline-flex justify-center px-3 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                            บันทึก
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- \.profile -->

                        <!-- ที่อยู่ -->
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6" v-if="isAddressForm" ref="address-form">
                            
                            <h3 class="flexcc-top" v-if="!isEdit">เพิ่มที่อยู่</h3>
                            <h3 class="flexcc-top" v-if="isEdit">แก้ไขที่อยู่</h3>
                            
                            <form @submit.prevent="isEdit ? updateAddress() : addNewAddress()">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">ชื่อที่อยู่</label>
                                        <input type="text" class="mt-1 cc_input_text"
                                            v-model="address_name"
                                            :class="{'border-red-500': serverError.address_name}"/>
                                        <div v-if="serverError.address_name" class="mt-1 text-sm text-red-500">
                                            {{serverError.address_name[0]}}
                                        </div>
                                    </div>
                                    
                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">เลขที่</label>
                                        <input type="text" class="mt-1 cc_input_text"
                                            v-model="home_no"
                                            :class="{'border-red-500': serverError.home_no}"/>
                                        <div v-if="serverError.home_no" class="mt-1 text-sm text-red-500">
                                            {{serverError.home_no[0]}}
                                        </div>
                                    </div>
    
                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">หมู่ที่</label>
                                        <input type="text" class="mt-1 cc_input_text"
                                            v-model="moo"
                                            :class="{'border-red-500': serverError.moo}"/>
                                        <div v-if="serverError.moo" class="mt-1 text-sm text-red-500">
                                            {{serverError.moo[0]}}
                                        </div>
                                    </div>
        
                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">หมู่บ้าน</label>
                                        <input type="text" class="mt-1 cc_input_text"
                                            v-model="village"
                                            :class="{'border-red-500': serverError.village}"/>
                                        <div v-if="serverError.village" class="mt-1 text-sm text-red-500">
                                            {{serverError.village[0]}}
                                        </div>   
                                    </div>
        
                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">ซอย</label>
                                        <input type="text" class="mt-1 cc_input_text"
                                            v-model="soi"
                                            :class="{'border-red-500': serverError.soi}"/>
                                        <div v-if="serverError.soi" class="mt-1 text-sm text-red-500">
                                            {{serverError.soi[0]}}
                                        </div>       
                                    </div>
                                    
                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">ถนน</label>
                                        <input type="text" class="mt-1 cc_input_text"
                                            v-model="road"
                                            :class="{'border-red-500': serverError.road}"/>
                                        <div v-if="serverError.road" class="mt-1 text-sm text-red-500">
                                            {{serverError.road[0]}}
                                        </div>   
                                    </div>
                                         
                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                        <label for="postal-code" class="block text-sm font-medium text-gray-700">จังหวัด</label>
                                        <select class="cc_input_text"
                                            @change="findDistrict($event.target.value)" 
                                            v-model="province_id" 
                                            :class="{'border-red-500': serverError.province_id}">
                                            <option value="">select</option>
                                            <option v-for="(item, index) in provinces" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                        </select>
                                        <div v-if="serverError.province_id" class="mt-1 text-sm text-red-500">
                                            {{serverError.province_id[0]}}
                                        </div>
                                    </div>
                                    
                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label for="region" class="block text-sm font-medium text-gray-700">อำเภอ/เขต</label>
                                        <select class="cc_input_text"
                                            @change="findSubdistrict($event.target.value)"
                                            v-model="district_id"
                                            :class="{'border-red-500': serverError.district_id}">
                                            <option value="">select</option>
                                            <option v-for="(item, index) in districts" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                        </select>
                                        <div v-if="serverError.district_id" class="mt-1 text-sm text-red-500">
                                            {{serverError.district_id[0]}}
                                        </div>
                                    </div>
            
                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                        <label for="city" class="block text-sm font-medium text-gray-700">ตำบล/แขวง</label>
                                        <select class="cc_input_text"
                                            v-model="subdistrict_id"
                                            :class="{'border-red-500': serverError.subdistrict_id}">
                                            <option value="">select</option>
                                            <option v-for="(item, index) in subdistricts" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                        </select>
                                        <div v-if="serverError.subdistrict_id" class="mt-1 text-sm text-red-500">
                                            {{serverError.subdistrict_id[0]}}
                                        </div>
                                    </div>
    
                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                        <label class="block text-sm font-medium text-gray-700">รหัสไปรษณีย์</label>
                                        <input type="text" class="cc_input_text"
                                            v-model="postcode"
                                            :class="{'border-red-500': serverError.postcode}"/>
                                        <div v-if="serverError.postcode" class="mt-1 text-sm text-red-500">
                                            {{serverError.postcode[0]}}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button type="submit" v-if="!isEdit"
                                        :disabled="disabled"
                                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                                        class="inline-flex justify-center px-3 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        บันทึก
                                    </button>
                                    <button type="button" v-if="isEdit" @click="updateCancel()" class="inline-flex justify-center px-3 py-2 mt-3 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        ยกเลิก
                                    </button>
                                    <button type="submit" v-if="isEdit"
                                        :disabled="disabled"
                                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                                        class="inline-flex justify-center px-3 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        แก้ไข
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- \.ที่อยู่ -->

                        <!-- รายการที่อยู่ -->
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <button @click="addAddredd()" type="submit" class="inline-flex justify-center px-3 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                <i class="fa-solid fa-circle-plus"></i> &nbsp; เพิ่มที่อยู่
                            </button>
                            <div class="flex flex-row" v-for="(item, index) in address" :key="index">
                                <div class="w-5/6 mt-1 text-left">
                                    {{item.address_name}}
                                    เลขที่ {{item.home_no}}
                                    หมู่ที่ {{item.moo}}
                                    ตำบล {{item.subdistrict}}
                                    อำเภอ {{item.district}}
                                    จังหวัด {{item.province}}
                                    {{item.postcode}}
                                </div>
                                <div class="w-1/6 mt-1 text-right">
                                    <button @click="editAddress(item)" type="submit" class="inline-flex justify-center px-3 py-2 text-sm font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        <i class="fa-solid fa-pen-to-square"></i> &nbsp; แก้ไข
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- \.รายการที่อยู่ -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/AuthService'
    export default{
        mounted(){
            this.retreiveProfile()
        },  
        data(){
            return{
                mem_photo: '',
                mem_photo_url: '',
                avatar: '',
                mem_name: '',
                mem_lastname: '',
                mem_day_of_birth: '',
                mem_gender: '',
                mem_fb: '',
                mem_line_id: '',
                mem_email: '',
                mem_tel: '',
                image_path: '',

                //serverError
                serverError: {},
                disabled: false,

                //address
                provinces: [],
                districts: [],
                subdistricts: [],

                member_address_id: '',
                address_name: '',
                home_no: '',
                moo: '',
                village: '',
                soi: '',
                road: '',
                province_id: '',
                district_id: '',
                subdistrict_id: '',
                postcode: '',

                address: [],
                isEdit: false,
                isAddressForm: false,
                
            }
        },
        methods:{
            addAddredd(){
                this.isAddressForm = true
                this.isEdit = false
                this.$refs["address-form"].scrollIntoView({ behavior: "smooth" })
                this.address_name = ''
                this.home_no = ''
                this.moo = ''
                this.village = ''
                this.soi = ''
                this.road = ''
                this.province_id = ''
                this.district_id = ''
                this.subdistrict_id = ''
                this.postcode = ''
            },
            updateCancel(){
                this.isEdit = false
                this.isAddressForm = false
                this.address_name = ''
                this.home_no = ''
                this.moo = ''
                this.village = ''
                this.soi = ''
                this.road = ''
                this.province_id = ''
                this.district_id = ''
                this.subdistrict_id = ''
                this.postcode = ''
            },
            updateAddress(){
                let data = new FormData()
                data.append('id', this.member_address_id)
                data.append('address_name', this.address_name)
                data.append('home_no', this.home_no)
                data.append('moo', this.moo)
                data.append('village', this.village)
                data.append('soi', this.soi)
                data.append('road', this.road)
                data.append('province_id', this.province_id)
                data.append('district_id', this.district_id)
                data.append('subdistrict_id', this.subdistrict_id)
                data.append('postcode', this.postcode)
                data.append('_method', 'put')
                this.$Progress.start()
                this.disabled = true
                http.post(`member/updateAddress/${this.member_address_id}`, data)
                    .then(() => {
                        // console.log(response.data.data)
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                        })
                        
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขที่อยู่เรียนร้อย'
                        })

                        this.retreiveMemberAddress()
                        this.updateCancel()
                        this.$Progress.finish()
                        this.disabled = false;
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }    
                    })
            },
            editAddress(item){
                this.isEdit = true
                this.isAddressForm = true
                this.serverError = {}
                this.findDistrict(item.province_id)
                this.findSubdistrict(item.district_id)
                this.member_address_id = (item.id)
                this.address_name = item.address_name
                this.home_no = item.home_no
                this.moo = item.moo
                this.village = item.village
                this.soi = item.soi
                this.road = item.road
                this.province_id = item.province_id
                this.district_id = item.district_id
                this.subdistrict_id = item.subdistrict_id
                this.$refs["address-form"].scrollIntoView({ behavior: "smooth" })
            },
            retreiveMemberAddress(){
                http.get(`member/memberAddress`)
                    .then(response => {
                        this.address = response.data.data
                    })
            },
            addNewAddress(){
                let data = new FormData()
                data.append('address_name', this.address_name)
                data.append('home_no', this.home_no)
                data.append('moo', this.moo)
                data.append('village', this.village)
                data.append('soi', this.soi)
                data.append('road', this.road)
                data.append('province_id', this.province_id)
                data.append('district_id', this.district_id)
                data.append('subdistrict_id', this.subdistrict_id)
                data.append('postcode', this.postcode)
                this.$Progress.start()
                this.disabled = true
                http.post(`member/addNewAddress`, data)
                    .then(() => {

                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                        })
                        
                        Toast.fire({
                            icon: 'success',
                            title: 'เพิ่มที่อยู่เรียบร้อย'
                        })

                        this.serverError = {}
                        this.disabled = false
                        this.$Progress.finish()  
                        this.retreiveMemberAddress() 
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            updateProfile(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('mem_photo', this.mem_photo)
                data.append('mem_name', this.mem_name)
                data.append('mem_lastname', this.mem_lastname == null ? '' : this.mem_lastname)
                data.append('mem_gender', this.mem_gender)
                data.append('mem_day_of_birth', this.mem_day_of_birth)
                data.append('mem_fb', this.mem_fb == null ? '' : this.mem_fb)
                data.append('mem_line_id', this.mem_line_id == null ? '' : this.mem_line_id)
                data.append('mem_email', this.mem_email)
                data.append('mem_tel', this.mem_tel)
                http.post(`member/updateProfile`, data)
                    .then(response => {

                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                        })
                        
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขข้อมูลเรียบร้อย'
                        })

                        this.avatar = response.data.avatar
                        this.mem_photo_url = ''
                        this.mem_photo = ''

                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            selectProfilePhoto(e){
                const image = e.target.files[0]
                this.mem_photo = e.target.files[0]
                this.mem_photo_url = URL.createObjectURL(image)
            },
            retreiveProfile(){
                this.$Progress.start()
                http.get(`member/profile`)
                    .then(response => {
                        let data = response.data.data
                        this.avatar = response.data.avatar
                        this.mem_name = data.mem_name
                        this.mem_lastname = data.mem_lastname
                        this.mem_day_of_birth = data.mem_day_of_birth
                        this.mem_gender = data.mem_gender
                        this.mem_fb = data.mem_fb
                        this.mem_line_id = data.mem_line_id
                        this.mem_email = data.mem_email
                        this.mem_tel = data.mem_tel
                        this.image_path = process.env.VUE_APP_URL_COOKIE
                        this.$Progress.finish()
                        this.retreiveProvince()
                        this.retreiveMemberAddress()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                        this.$Progress.fail()
                    })
            },
            findSubdistrict(district_id){
                http.get(`address/subdistrict/${district_id}`)
                    .then(response => {
                        this.subdistricts = response.data.data
                        // this.subdistrict_id = response.data.data[0].id
                        this.postcode = response.data.data[0].zip_code
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            findDistrict(province_id){
                http.get(`address/district/${province_id}`)
                    .then(response => {
                        this.districts = response.data.data
                        // this.district_id = response.data.data[0].id
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            retreiveProvince(){
                http.get(`address/province`)
                    .then(response => {
                        this.provinces = response.data.data
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>