// import Vue from 'vue'
import Vuex from 'vuex'

//frontend
import authMember from './modules/authentication/member';

//backend
import projectCategory from './modules/projects/categiry'
import auth from './modules/projects/categiry';
import sidebar from './modules/userInterface/sidebar'
import projectDonation from './modules/projects/projectDonation'

export default new Vuex.Store({
  modules:{
    authMember,
    projectCategory,
    auth,
    sidebar,
    projectDonation
  }
})
