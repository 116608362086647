<template>
  <section class="bgcc">
      <div class="container pt-40 pb-20 mx-auto">
          <div class="flex flex-wrap">
              <div class="w-full p-4 lg:w-6/12">
                  <img src="@/assets/img/logoCuCi.png" class="object-center w-full "/>
              </div>
              <div class="w-full p-4 lg:w-6/12 lg:ml-auto">
                  <h6 class="cctext-sm">รู้จักเรา</h6>
                  <h1 class="cctext-4xl">มูลนิธี จูจีสร้างสรรค์สังคม</h1>
                  <p class="ccp">
                    <br><br>ในอิสลามดอกเบี้ยไม่สามารถนำไปใช้ประโยชน์ส่วนตัวได้แต่สามารถนำไปใช้ประโยชน์ส่วนรวมหรือสาธารณประโยชน์ได้ดังนักวิชาการมุสลิมได้ให้คำชี้ขาดไว้  ดังนั้น ทีมงานมีไอเดียในการแก้ปัญหาของกลุ่มคนหลายล้านคน โดยจัดตั้งองค์กรเพื่อมาบริหารจัดการการสะสางดอกเบี้ย ในรูปแบบแพลตฟอร์ม
                    <br><br>แพลตฟอรม์ CuCi  เป็นแพลตฟอร์มที่พัฒนาขึ้นมา เพื่อการบริหารจัดการดอกเบี้ยเพื่อสาธารณประโยชน์ เป็นพื้นที่กลางระหว่างผู้สะสางดอกเบี้ยและผู้ขอทุนสนับสนุน โดยฝ่ายที่ 1 คือผู้ที่มีดอกเบี้ยสามารถเข้ามาสะสางในแพลตฟอร์ม และฝ่ายที่ 2 คือกลุ่มคน องค์กร หน่วยงาน และชุมชน เข้ามายื่นขอรับทุนสนับสนุนในการพัฒนาสาธารณประโยชน์ในพื้นที่	
                    <br><br>แพลตฟอร์ม CuCi ที่นำเครื่องมือดิจิทัลมาใช้ในการบริหารจัดการสะสางดอกเบี้ยเพื่อสาธารณประโยชน์ เป็นทางเลือกสำหรับมุสลิมที่มีดอกเบี้ยในการสะสางดอกเบี้ย เป็นพื้นที่รับมอบและรวบรวมการสะสางดอกเบี้ย เปิดรับโครงการสาธารณประโยชน์ (รับสมัคร คัดเลือก อนุมัติ ติดตาม ส่งมอบ ประเมิน สรุปผล) ให้แก่ผู้ขอรับทุนสนับสนุนโครงการ ที่เป็นระบบระเบียบ มีความโปร่งใส โดยใช้หลักการ Open Data  และผู้ใช้งานแพลตฟอร์มสามารถเลือกโครงการที่ต้องการสนับสนุน Democracy Vote</p>
                  <a href="#" class="btncc-01">เรียนรู้เพิ่มเติม</a>
              </div>
          </div>
      </div>
  </section>

  <!-- <section>
      <div class="flex bg-teal-200 ...">
  <div class="flex-none w-64 h-14  bg-orange-700 ...">
    01
  </div>
  <div class="flex grow h-14  bg-amber-400 ...">
    02
  </div>
  <div class="flex-none w-14 h-64 bg-stone-800 ...">
    03
  </div>
</div>
  </section> -->
</template>

<script>
  export default{
    mounted(){
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
</script>
