import http from '@/services/BackendService'
const state = {
    current_page: 0,
    per_page: 0,
    total: 0,
    data: [],
    keyword: '',
}

const getters = {

}

const mutations = {
    getProjectDonation(state, data){
        // console.log(data)
        state.data = data.data
        state.current_page = data.current_page
        state.per_page = data.per_page
        state.total = data.total
    },
    changeKeyword(state, keyword){
        state.keyword = keyword
    },
}

const actions = {
    submitSearchForm(context, payload){
        return new Promise((resolve, reject) => {
            http.get(`project/donation/search?keyword=${payload.keyword}&page=${payload.page_number}`)
            .then((response) => {
                resolve(response.data)
                context.commit('getProjectDonation', response.data.data)
            })
            .catch((error) => {
                reject(error)
            })    
        })   
    },
    changeKeyword(context, keyword){
        context.commit("changeKeyword", keyword)
    },
    getProjectDonation(context, payload){
        return new Promise((resolve, reject) => {
            http.get(`project/donation?page=${payload.page_number}`)
            .then((response) => {
                resolve(response.data)
                context.commit('getProjectDonation', response.data.data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}