<template>
    <div class="flex items-center min-h-screen p-6 py-40 bg-gray-50 dark:bg-gray-900">
      <div
        class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
      >
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:hidden"
              src="@/assets/img/login-office.jpeg"
              alt="Office"
            />
            <img
              aria-hidden="true"
              class="hidden object-cover w-full h-full dark:block"
              src="@/assets/img/login-office-dark.jpeg"
              alt="Office"
            />
          </div>
          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div class="w-full">
              <h1
                class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
              >
                Login / Admin
              </h1>
              <form @submit.prevent="submitForm()">

                <label class="block mb-2 text-sm text-gray-700" for="fullname">อีเมลย์/ชื่อผู้ใช้</label>
                <input v-model="username" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" type="text">
                <div v-if="v$.username.$error" class="mt-2 text-sm text-red-500">
                    {{v$.username.$errors[0].$message}}
                </div>

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="username">รหัสผ่าน</label>
                <input v-model="password"  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" type="text">
                <div v-if="v$.password.$error" class="mt-2 text-sm text-red-500">
                    {{v$.password.$errors[0].$message}}
                </div>
                
                <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700" >LOGIN</button>
              </form>

              <p class="mt-4">
                <router-link to="/forgotPassword"
                  class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                >
                  ลืมรหัสผ่าน
                </router-link>
              </p>
              <p class="mt-1">
                <router-link to="/register"
                  class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                >
                  สมัครสมาชิก
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import useVuelidate from '@vuelidate/core'
    // import { required, helpers, minLength } from '@vuelidate/validators'
    import { required, helpers } from '@vuelidate/validators'

    import http from '@/services/AuthService'

    export default {
        // setup () {
        //     return { v$: useVuelidate() }
        // },    
        data () {
            return {
                v$: useVuelidate(),
                username: '',
                password: '',
                serverError: '',
                disabled: false
            }
        },
        methods:{
            submitForm(){
               this.v$.$validate();
               if(!this.v$.$error){
                  //  alert('Form validate success')
                  //เรียกใช้งาน API Login จาก Laravel
                  this.$Progress.start()
                  this.disabled = true
                  http.post('login', 
                  {
                    "username": this.username,
                    "password": this.password,
                  }).then(response => {
                    // console.log(response.data)

                    //เก็บข้อมูล user ลง localstorage
                    localStorage.setItem('cuciUser', JSON.stringify(response.data))

                    localStorage.setItem('userPermissions', ['admin-dashboard'])

                    //เมื่อล็อคอิลผ่านส่งไปหน้า dashboard
                    // this.$router.push('backend')

                    const Toast = this.$swal.mixin({
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                      }
                    })

                    Toast.fire({
                      icon: 'success',
                      title: 'กำลังเข้าสู่ระบบ...'
                    }).then(() => {
                      this.$router.push({name: 'Dashboard'})
                      this.$Progress.finish()
                      this.disabled = false
                    })

                  }).catch(error => {
                    if(error.response){
                      if(error.response.status == 422){

                        const Toast = this.$swal.mixin({
                          toast: true,
                          position: 'top-end',
                          showConfirmButton: false,
                          timer: 3000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                          }
                        })

                        let usernameError = error.response.data.errors['username']
                        let passwordError = error.response.data.errors['password']
                        let errorText = '';
                        if(usernameError){
                          errorText = error.response.data.errors['username'][0]
                        }else if(passwordError){
                          errorText = error.response.data.errors['password'][0]
                        }else{
                          errorText = ''
                        }

                        Toast.fire({
                          icon: 'error',
                          title: errorText
                        })

                        // console.log(usernameError)
                        // console.log(errorText)

                      }
                    }

                  this.$Progress.fail()
                  this.disabled = false
                    
                  })
               }else{
                  //  alert('Form validate fail')
               }
            }
        },
        validations(){
            return {
                username: {
                    required: helpers.withMessage('ป้อนอีเมลหรือชื่อผู้ใช้งานก่อน' , required), 
                },
                password: {
                    required: helpers.withMessage('ป้อนรหัสผ่าน', required),
                    // minLength: helpers.withMessage(({$params}) => `รหัสผ่านต้องไม่น้อยกว่า ${$params.min} ตัวอักษร `, minLength(6))
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>