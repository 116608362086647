import numerify from "numerify";

export default{
    cleanupType(id){
        let cleanupType = ''
        switch (id) {
            case "1":
                cleanupType = "กองกลาง"
                break;
            case "2":
                cleanupType = "สาธารณประโยชน์"
                break;
            case "3":
                cleanupType = "การศึกษา"
                break;
            case "4":
                cleanupType = "ฟากิรและมิสกิน"
                break;
        }
        return cleanupType
    },
    numberFormat(number){
        // return numerify(number, '0,0.00')
        return numerify(number, '0,0.00')
    },
    dayFormat(day){
        return numerify(day, '00')
    },
    numberAmount(amount){
        return numerify(amount, '0,0')
    }
}
