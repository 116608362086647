<template>
    <div class="container pt-40 pb-20 pl-4 mx-auto">
        <h2 class="content-center my-6 text-2xl font-semibold text-gray-700 lg:mx-80 dark:text-gray-200">
           รายละเอียด
        </h2>
        <div class="overflow-hidden rounded-lg shadow-xs lg:mx-80">
            <div class="w-full overflow-x-auto">
                <table class="w-full whitespace-no-wrap">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th colspan="4" class="px-4 py-3 font-semibold">ID : {{ transaction_id }}</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">สถานะ</p>
                                        <p class="text-xs text-gray-600 dark:text-gray-400"></p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                <span class="text-orange-300" v-if="transaction_status == 1 && confirm_status == ''">
                                    รอชำระ
                                    <router-link v-if="transactionData.pay_type == 1 && is_expired_qrcode == false && pay_for == 'riba'" :to="{'name': 'RibaRecheckout', params:{'order_id': order_id}}">
                                        <span class="text-gray-500"> :: ไปหน้าชำระเงิน</span>
                                    </router-link>
                                    <router-link v-if="transactionData.pay_type == 1 && is_expired_qrcode == false && pay_for == 'zakat'" :to="{'name': 'ZakatRecheckout', params:{'order_id': order_id}}">
                                        <span class="text-gray-500"> :: ไปหน้าชำระเงิน</span>
                                    </router-link>
                                    <router-link v-if="transactionData.pay_type == 2 && is_expired_qrcode == false" :to="{'name': 'CartQrPromptpay', params:{'order_id_code': order_id}}">
                                        <span class="text-gray-500"> :: ไปหน้าชำระเงิน</span>
                                    </router-link>
                                    <span v-if="is_expired_qrcode == true" class="text-red-500 "> :: qrcode หมดอายุ</span>
                                </span>
                                <span class="text-orange-300" v-if="transaction_status == 2 && confirm_status == 1">
                                    กำลังตรวจสอบ
                                </span>
                                <span class="text-green-600" v-else-if="transaction_status == 2 && confirm_status == 2">
                                    ชำระแล้ว
                                </span>
                                <span class="text-red-600" v-else-if="transaction_status == 3">
                                    ยกเลิก
                                </span>
                                <span class="text-yellow-600" v-else-if="transaction_status == 4">
                                    ชำระไม่ตรงยอด
                                </span>
                            </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">เวลาที่สร้าง</p>
                                        <p class="text-xs text-gray-600 dark:text-gray-400"></p>
                                    </div>
                                </div>
                            </td>
                        <td colspan="3" class="px-4 py-3 text-sm">
                                {{ formatDate(transactionData.created_at) }} น.   
                        </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                <div>
                                    <p class="font-semibold">เวลาที่อัพเดต</p>
                                    <p class="text-xs text-gray-600 dark:text-gray-400">
                                    </p>
                                </div>
                        </div>
                        </td>
                        <td class="px-4 py-3 text-sm">
                                {{ formatDate(transactionData.updated_at) }} น.
                        </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">จำนวนเงิน</p>
                                        <p class="text-xs text-gray-600 dark:text-gray-400"></p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                {{ transactionData.amount }} บ.
                            </td>
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">วิธีการชำระเงิน</p>
                                        <p class="text-xs text-gray-600 dark:text-gray-400"></p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                <span v-if="transactionData.pay_type == 1">โอนผ่านบัญชีธนาคาร</span>    
                                <span v-else-if="transactionData.pay_type == 2">Thai QrPromptpay</span>
                            </td>
                        </tr>
                        <tr v-if="transaction_status == 1" class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">ยกเลิก</p>
                                        <p class="text-xs text-gray-600 dark:text-gray-400"></p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                <button @click="cancelTransaction()" class="inline-block px-6 py-2 text-white bg-red-500 border-orange-600 rounded-md hover:text-emerald-600 hover:bg-emerald-100">ยกเลิก</button>    
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>

                <table class="w-full whitespace-no-wrap">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th colspan="4" class="px-4 py-3 font-semibold">โครงการที่สนับสนุน</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in order_details" :key="index">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm"><div>
                                    <div class="flex justify-between">
                                        <div>
                                            {{ item.project_name }} 
                                            <span v-if="item.budget_type == 1">(บริจาค)</span>
                                            <span v-else-if="item.budget_type == 2">(สะสางดอกเบี้ย)</span>
                                            <span v-else>(ซะกาต)</span>
                                        </div>
                                        <div>
                                            {{ item.amount }} บ.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                            
                            </td> 
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex justify-between">
                <router-link to="/paymentHistories" class="ml-2 buttoncuci">กลับ</router-link>
                <a class="mr-2 buttonderma" href="https://line.me/R/ti/p/@911dmjcl" target="_blank" v-if="confirm_status == 2 && transaction_status == 2">ขอใบเสร็จ</a>
            </div>
            <img class="mx-auto mt-2 w-82" v-if="transactionData.pay_type == 1 && transaction_status == 2" :src="`${imagePath}images/slip/thumbnail/${transfer_file}`" alt="screenshot" >
       </div>
    </div>
</template>

<script>
    import http from '@/services/FrontendService'
    import moment from 'moment'
    export default{
        mounted(){
            this.retreiveTransactionInfo()
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        data(){
            return {
                id: '',
                transaction_id: '',
                transactionData: {},
                transaction_status: '',
                order_details: [],
                confirm_status: '',
                slip: '',
                imagePath: process.env.VUE_APP_URL_COOKIE,
                order_id: '',
                is_expired_qrcode: '',
                pay_for: '',
            }
        },
        methods: {
            cancelTransaction(){
                //sweet alert confirm
                this.$swal({
                    title: 'ยืนยันการยกเลิก',
                    text: "คุณต้องการยกเลิกการสนับสนุนนี้ใช่หรือไม่",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก'
                }).then((result) => {
                    if (result.isConfirmed) {                       
                        this.$Progress.start()
                        http.delete(`account/deleteTransaction/${this.id}`)
                        .then(() => {
                            this.transaction_status = 3
                            this.$Progress.finish()
                            this.$swal({
                                title: 'ยกเลิกสำเร็จ',
                                text: "ยกเลิกรายการชำระเรียบร้อย",
                                icon: 'success',
                                confirmButtonText: 'ตกลง'
                            })
                            // .then((result) => {
                            //     if (result.isConfirmed) {
                            //         this.retreiveHistory('firstload')
                            //     }
                            // })
                            //change status to cancel
                            // this.histories.forEach((item, index) => {
                            //     if(item.id == transaction_id){
                            //         this.histories[index].status = 'ยกเลิก'
                            //     }
                            // })
                        })
                        .catch(error => {
                            if(error.response.status == 401 || error.response.status == 419){
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                            this.$Progress.fail()
                        })
                    }
                })

                //20125336
            },
            retreiveTransactionInfo(){
                this.$Progress.start()
                http.get(`account/transactionInfo/${this.$route.params.transaction_id}`)
                    .then(response => {
                        this.id = response.data.id
                        this.transaction_id = response.data.transaction_id
                        this.transactionData = response.data.transaction
                        this.transaction_status = response.data.transaction_status
                        this.confirm_status = response.data.confirm_status
                        this.order_details = response.data.order_details
                        this.transfer_file = response.data.transfer_file
                        this.order_id = response.data.order_id
                        this.is_expired_qrcode = response.data.is_expired_qrcode
                        this.pay_for = response.data.pay_for
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.statu == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            },
            formatDate(value){
                if(value){
                    return moment(String(value)).format('DD/MM/YYYY HH:m')
                }
            },
        }
    }
</script>