export default{
    methods: {
        $roleCan(role){
            let accept = false
            let roles = localStorage.getItem('ccur');
            if(roles){
                accept = roles.includes(role)
            }else{
                accept = false
            }
            // console.log(accept)
            return accept
        }
    },
}