<template>
    <section class="bg-indigo-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2" v-if="showing">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">
                    ยืนยันอีเมล
                </h1>
            </div>
            <div v-if="is_verified && showing" class="flex ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex justify-center">
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <b> ยืนยันบัญชีผู้ใช้สำเร็จ </b>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!is_verified && showing" class="flex ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex justify-center">
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <b> เพื่อยืนยันบัญชีผู้ใช้ของท่าน กรุณาคลิกปุ่มด้านล่าง </b>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!is_verified && showing" class="flex-1 m-5 ml-10 mr-10" >
                <div class="flex justify-center">
                    <button type="submit" @click="verify()"
                        class="block w-full px-4 py-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled"
                        >
                        คลิก
                    </button>
                </div>
            </div>
            <!-- email_verify_url : {{email_verify_url}} -->
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div> 
    </section>
</template>

<script>
    // import axios from 'axios';
    import http from '@/services/FrontendService';
    export default {
        mounted(){
            if(this.$store.getters['authMember/logedIn'] == true){
                this.queryString()    
                // let userStorage = localStorage.getItem('cuciMember')
                // let userStorageJSON = JSON.parse(userStorage)
                // this.token = userStorageJSON['token']
                this.isVerifiedEmail()    
            }else{
                this.$router.push({name: 'Login'})
            }
        },
        data(){
            return{
                showing: false,
                email_verify_url: Boolean,
                token: '',
                is_verified: '',
                disabled: false,
            }
        },
        methods: {
            verifyEmail(){
                this.$Progress.start()
                this.progressText = 'กำลังดำเนินการ...'
                this.disabled = true
                this.is_send_email = false
                http.post('email/verification-notification')
                    .then(response => {
                        console.log(response.data)
                        this.progressText = 'ยืนยันอีเมล'
                        // this.$router.push({name: 'EmailVerification'})
                        this.$Progress.finish()
                        this.disabled = false
                        this.is_send_email = true
                        this.$swal.fire({
                                icon: 'success',
                                title: 'เราได้ส่งลิงค์ยืนยันบัญชีผู้ใช้ไปยังอีเมลของท่านแล้ว',
                                text: 'กรุณาคลิก OK และตรวจสอบอีเมลของท่านอีกครั้ง',
                            }).then(() => {
                                this.$router.push({name: 'Home'})
                            })
                    })  
                    .catch(error => {
                        console.log(error.response.data.errors) 
                        this.progressText = 'ยืนยันอีเมล'  
                        this.$Progress.fail()
                        this.disabled = false
                    })  
            },
            verify(){
                if(this.email_verify_url === null){
                    // this.$router.push({name: 'Account'})
                    this.$router.push({name: 'Account'})
                }else{
                    this.disabled = true
                    this.$Progress.start()
                    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token
                    http.get(this.email_verify_url)
                        .then(() => {
                            // console.log(response.data)
                            this.disabled = false
                            this.is_verified = true
                            this.$Progress.finish()
                        })  
                        .catch(() => {
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'ลิงค์ยืนยันไม่ถูกต้อง',
                                text: 'กรุณาคลิก OK เพื่อรับลิงค์ยืนยันอีกครั้ง',
                            }).then(() => {
                                this.verifyEmail()    
                            })
                            this.disabled = false
                            this.$Progress.fail()
                        })       
                }
            },
            isVerifiedEmail(){
                http.get('member/isVerifiedEmail')
                    .then(response => {
                        if(response.data == 1){
                            this.is_verified = true
                        }else{
                            this.is_verified = false
                        }
                        this.showing = true
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    })
            },
            queryString(){
              const urlSearchParams = new URLSearchParams(window.location.search);
              const params = Object.fromEntries(urlSearchParams.entries())
              if(Object.keys(params).length === 0 && params.constructor === Object){
                  this.email_verify_url = null
                  this.$router.push({name: 'Account'})
              }else{
                  this.email_verify_url = params.email_verify_url
              }
          },
        }
    }
</script>