<template class="bgcc-riba">
    
    <section class="bgcc lg:mx-24">
        <div class="container lg:mx-4 pt-28">
            <h2 class="mx-4 my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                {{project_name}}
            </h2>
        </div>
        <div class="container mx-auto">
            <div class="flex flex-wrap">
          <div class="order-first w-full p-4 lg:w-7/12 lg:order-first">
            <div id="carouselExampleCaptions" class="relative carousel slide" data-bs-ride="carousel">
                <div class="absolute bottom-0 left-0 right-0 flex justify-center p-0 mb-4 carousel-indicators">
                            <button v-for="(item, index) in images" :key="index"
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                :data-bs-slide-to="`${index}`"
                                :class="{'active': index == 0}"
                                :aria-current="{'true': index == 0}"
                                aria-label="Slide 1"
                            ></button>
                            <!-- <button
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to="1"
                                aria-label="Slide 2"
                            ></button>
                            <button
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to="2"
                                aria-label="Slide 3"
                            ></button> -->
                        </div>
                        <div class="w-full overflow-hidden carousel-inner">
                            <div class="float-left w-full carousel-item" v-for="(item, index) in images" :key="index" :class="{'active': index == 0}">
                                <img
                                :src="`${image_path}/${item.picture_name}`"
                                class="block w-full"
                                alt="..."
                                />
                                <div class="absolute hidden text-center carousel-caption md:block">
                            
                                </div>
                            </div>
                            <!-- <div class="float-left w-full carousel-item">
                                <img
                                :src="`${image_path}/na.jpg`"
                                class="block w-full"
                                alt="..."
                                />
                                <div class="absolute hidden text-center carousel-caption md:block">
                                
                                </div>
                            </div>
                            <div class="float-left w-full carousel-item">
                                <img
                                :src="`${image_path}/toilet.jpg`"
                                class="block w-full"
                                alt="..."
                                />
                                <div class="absolute hidden text-center carousel-caption md:block">
                                
                                </div>
                            </div> -->
                        </div>
                        <button
                            class="absolute top-0 bottom-0 left-0 flex items-center justify-center p-0 text-center border-0 carousel-control-prev hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="prev">
                            <span class="inline-block bg-no-repeat carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                            class="absolute top-0 bottom-0 right-0 flex items-center justify-center p-0 text-center border-0 carousel-control-next hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide="next"
                            >
                            <span class="inline-block bg-no-repeat carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div class="order-last w-full lg:w-5/12 lg:order-last">
                    <div class="flex justify-center">
                        <div class="block w-10/12 p-6 bg-white rounded-lg shadow-lg">
                            
                            <p class="mb-4 text-xl text-gray-700">
                                งบประมาณทั้งหมด 
                            </p>
                            <p class="mb-4 text-3xl text-gray-700">
                                {{showMoney(budget)}} บาท
                            </p>
                        
                            <h5 class="mb-2 font-medium leading-tight text-gray-900 text-l">สถานะโครงการ : 
                                <!--  v-if project_process is 1 or 2 or 3  -->
                                <span v-if="project_process <= 4" class="inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-400 text-white rounded">
                                    กำลังระดมทุน
                                </span>
                                <span v-else class="inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-500 text-white rounded">
                                    ปิดการระดมทุน
                                </span>
                            </h5>
                            <br>
                            <p class="mb-4 text-ส text-gray-700">
                            ผู้ขอทุน:
                            </p>
                            <p class="mb-4 text-ส text-gray-700">
                                {{organization}}
                            </p>
                            <br>
                            <div class="flex justify-start">
                                <div class="text-sm">ระดมทุนได้แล้ว</div>
                            </div>
                            <div class="flex justify-between mt-2">
                                <div>{{showMoney(totalSuccess)}} บาท</div>
                                <div>{{showMoney(persent)}} %</div>
                            </div>
                            <div class="w-full bg-gray-200 rounded-full">
                                <div class="bg-yellow-400 text-xs h-2 font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full" :style="`width: ${persent}%`"></div>
                            </div>
                            <div class="flex justify-between mt-2">
                                    <div class="text-sm">เหลือ {{dayRemaining}} วัน</div>
                                    <div class="text-sm">ผู้ร่วมสนับสนุน {{totalDonator}}</div>
                                </div>  
                            <br> 
                            <div class="grid grid-cols-3 gap-4">
                                
                                <div class="col-span-3 ..." v-if="logedIn && derma_budget == 11">
                                    <button type="button"  @click="selectProject(1)" class="w-full inline-block px-6 py-2.5 bg-yellow-500 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <!-- <i class="fas fa-shopping-basket"></i>  -->
                                        หยิบใส่ตะกร้าเพื่อบริจาค
                                    </button>
                                </div>

                                <div class="col-span-3 ..." v-if="logedIn && riba_budget == 11">
                                    <button type="button"  @click="selectProject(2)" class="w-full inline-block px-6 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <!-- <i class="fas fa-shopping-basket"></i>  -->
                                        หยิบใส่ตะกร้าเพื่อสะสาง
                                    </button>
                                </div>
                                
                                <div class="col-span-3 ..." v-if="logedIn && zakat_budget == 11">
                                    <button type="button"  @click="selectProject(3)" class="w-full inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <!-- <i class="fas fa-shopping-basket"></i>  -->
                                        หยิบใส่ตะกร้าเพื่อซะกาต
                                    </button>
                                </div>

                                <!-- not login -->
                                <!-- <div class="col-span-3 ..." v-if="!logedIn && derma_budget == 11">
                                    <button type="button"  data-bs-toggle="modal" data-bs-target="#loginAlertModal" class="w-full inline-block px-6 py-2.5 bg-yellow-500 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <i class="fas fa-shopping-basket"></i> 
                                        หยิบใส่ตะกร้าเพื่อบริจาค
                                    </button>
                                </div> -->
                                <div class="col-span-3 ..." v-if="!logedIn && derma_budget == 11">
                                    <button type="button"  @click="selectProject(1)" :disabled="project_process >= 5" :class="{'opacity-50 cursor-not-allowed': project_process >= 5}" class="w-full inline-block px-6 py-2.5 bg-yellow-500 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <i class="fas fa-shopping-basket"></i> 
                                        หยิบใส่ตะกร้าเพื่อบริจาค
                                    </button>
                                </div>

                                <div class="col-span-3 ..." v-if="!logedIn && riba_budget == 11">
                                    <button type="button"  @click="selectProject(2)" :disabled="project_process >= 5" :class="{'opacity-50 cursor-not-allowed': project_process >= 5}" class="w-full inline-block px-6 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <i class="fas fa-shopping-basket"></i> 
                                        หยิบใส่ตะกร้าเพื่อสะสาง
                                    </button>
                                </div>
                                
                                <div class="col-span-3 ..." v-if="!logedIn && zakat_budget == 11">
                                    <button type="button"  @click="selectProject(3)" :disabled="project_process >= 5" :class="{'opacity-50 cursor-not-allowed': project_process >= 5}" class="w-full inline-block px-6 py-2.5 bg-green-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                        <i class="fas fa-shopping-basket"></i> 
                                        หยิบใส่ตะกร้าเพื่อซะกาต
                                    </button>
                                </div>

                                <div class="col-span-3 ..." v-if="myCart != 0 && logedIn == true">
                                    <router-link to="/cart">
                                        <button :disabled="project_process == 5" :class="{'opacity-50 cursor-not-allowed': project_process == 5}" class="w-full inline-block px-6 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                                            ไปยังตะกร้าเพื่อชำระเงิน
                                        </button>
                                    </router-link>
                                </div>
                                <div class="col-span-3 ..." v-else>
                                   
                                </div>
                            </div>
                            <br>
                            <!-- <button type="button" @click="gotoDetail('target-detail')" class=" inline-block w-full px-6 py-2.5 bg-orange-400 text-white font-medium leading-tight uppercase rounded shadow-md hover:bg-orang-700 hover:shadow-lg focus:bg-orange-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-800 active:shadow-lg transition duration-150 ease-in-out">
                                ข้อมูลโครงการ
                            </button> -->
                            <div class="flex flex-wrap pt-5 sharing-buttons">
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
    <section class=" bgcc lg:mx-24" id="target-detail">
        <br><br><br>
        <ul class="flex flex-col flex-wrap pl-0 mb-4 list-none border-b-0 nav nav-tabs nav-justified md:flex-row" id="tabs-tabJustify" role="tablist">
            <li class="flex-grow text-center nav-item" role="presentation">
                <a  @click="changeShowTap(1)" class="block w-full px-6 py-3 my-2 font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent cursor-pointer text-l nav-link border-x-0 hover:border-transparent hover:bg-gray-100 focus:border-transparent active" id="tabs-home-tabJustify" data-bs-toggle="pill" role="tab" aria-controls="tabs-homeJustify" aria-selected="true">
                    ข้อมูลโครงการ
                </a>
            </li>
            <li class="flex-grow text-center nav-item" role="presentation">
                <a @click="changeShowTap(2)" class="block w-full px-6 py-3 my-2 font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent cursor-pointer text-l nav-link border-x-0 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-profile-tabJustify" data-bs-toggle="pill" role="tab" aria-controls="tabs-profileJustify" aria-selected="false">
                    ความคืบหน้า
                </a>
            </li>
            <li class="flex-grow text-center nav-item" role="presentation">
                <a @click="changeShowTap(3)" class="block w-full px-6 py-3 my-2 font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent cursor-pointer text-l nav-link border-x-0 hover:border-transparent hover:bg-gray-100 focus:border-transparent" id="tabs-messages-tabJustify" data-bs-toggle="pill" role="tab" aria-controls="tabs-messagesJustify" aria-selected="false">
                    แผนงบประมาณ
                </a>
            </li>
        </ul>
        <div class="tab-content" id="tabs-tabContentJustify">
            <div class="p-4 tab-pane fade" :class="{'show active': isShowTapOne}" id="tabs-homeJustify" role="tabpanel" aria-labelledby="tabs-home-tabJustify">
                <br>ที่ตั้งของโครงการ 
                
                <p> หมู่ที่ {{ address.data.p_moo }} ตำบล  {{ address.subdistrict }}  อำเภอ {{ address.subdistrict }}	  จังหวัด {{ address.province }}  รหัสไปรษณีย์   {{ address.data.p_postcode }} </p>
                <br><br>

                <p>ที่มาของโครงการ</p>
                <p>{{ detail.project_background }}</p>
                <br><br>
                วัตถุประสงค์โครงการ
                <p v-for="(item, index) in objectives" :key="index">{{index+1}}. {{ item.pro_obj_detail }}</p>
                <br><br>
                ประโยชน์ที่คาดว่าจะได้รับจากโครงการ
                <p v-for="(item, index) in benefits" :key="index">{{ index+1 }}. {{ item.pro_benefit_detail }}</p>
                <br><br>
                
            </div>
            <div class="p-4 tab-pane fade" :class="{'show active': isShowTapTwo}"  id="tabs-profileJustify" role="tabpanel">
                <!-- start table -->
                <br>
                <h5 class="mb-2 font-medium leading-tight text-gray-900 text-l">สถานะโครงการ : 
                    <span class="inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-400 text-white rounded">กำลังระดมทุน</span>

                </h5>
                <div class="flex flex-col">
                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div class="overflow-hidden">
                                <table class="min-w-full">
                                    <thead class="border-b">
                                        <tr>
                                            <th scope="col" class="w-1/5 px-6 py-4 text-sm font-medium text-left text-gray-900">
                                                วันที่ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </th>
                                            <th scope="col" class="w-2/5 px-6 py-4 text-sm font-medium text-left text-gray-900">
                                                ผู้สนับสนุน
                                            </th>
                                            <th scope="col" class="w-2/5 px-6 py-4 text-sm font-medium text-left text-gray-900">
                                                ประเภทเงินสนับสนุน
                                            </th>
                                            <th scope="col" class="w-2/5 px-6 py-4 text-sm font-medium text-left text-right text-gray-900">
                                                จำนวนเงิน (บาท)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="border-b" v-for="(item, index) in transactions" :key="index">
                                            <td class="px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap">
                                                {{item.confirm_date}}
                                            </td>
                                            <td class="px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap">
                                                <span v-if="item.anonymous == 1">ไม่ประสงค์ออกนาม</span>
                                                <span v-else>
                                                    {{item.mem_name}} {{item.mem_lastname}}
                                                </span>
                                            </td>
                                            <td class="px-6 py-4 text-sm font-light text-gray-900 whitespace-nowrap">
                                                <span v-if="item.budget_type == 1">บริจาค</span>
                                                <span v-if="item.budget_type == 2">ดอกเบี้ย</span>
                                                <span v-if="item.budget_type == 3">ซะกาต</span>
                                                <span v-else></span>
                                            </td>
                                            <td class="px-6 py-4 text-sm font-light text-right text-gray-900 whitespace-nowrap">
                                                {{showMoney(item.amount)}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>     
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end table -->
                
            </div>
            <div class="p-4 tab-pane fade" :class="{'show active': isShowTapThree}" id="tabs-messagesJustify" role="tabpanel">
                <div class="flex flex-col">
                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div class="overflow-hidden">
                                <div class="w-full overflow-x-auto">
                                    <table class="w-full whitespace-no-wrap">
                                        <thead>
                                            <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                                <th  class="px-4 py-3 font-semibold">#</th>
                                                <th  class="px-4 py-3 font-semibold">รายการ</th>
                                                <th  class="px-4 py-3 font-semibold">จำนวน</th>
                                                <th  class="px-4 py-3 font-semibold">ราคารวม (บาท)</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                                            <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in budgets" :key="index">
                                                <td class="px-4 py-3 text-sm">
                                                    {{ index+1 }}
                                                </td>
                                                <td class="px-4 py-3">
                                                    {{ item.boq_name }}
                                                </td>
                                                <td  class="px-4 py-3 text-sm">
                                                    {{ item.boq_amount }}
                                                </td>
                                                <td  class="px-4 py-3 text-sm">
                                                    {{ showMoney(item.sumbuget) }}
                                                </td> 
                                            </tr> 
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- <section class=" bgcc lg:mx-24" id="target-payment" v-if="myCart == 0">
        <br><br><br>
        <div class="container pt-5 pb-5 mx-auto sm:w-full md:w-3/4 lg:w-2/4" id="target-money-choise">
            <br><br><br>
            <div class="w-full p-3 sm:p-6">
                <div class="flex flex-wrap justify-around w-full">
                    <button @click="selectMoney(100)" class="w-40 h-24 px-4 py-1 mt-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                      100 บาท
                    </button>
                    <button @click="selectMoney(500)" class="w-40 h-24 px-4 py-1 mt-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                      500 บาท
                    </button>
                      <button @click="selectMoney(1000)" class="w-40 h-24 px-4 py-1 mt-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                        1,000 บาท
                      </button>
                    <button class="w-40 h-24 px-4 py-1 mt-1 font-semibold border border-purple-200 rounded-sm text-l text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ระบุจำนวน (บาท)
                    <input type="number" v-model="input_money" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder=""/>
                    </button>
                </div>
                <div class="flex flex-wrap w-full mt-2 ml-5">
                  <div v-if="serverError.money" align="center" class="text-sm text-red-500">
                    {{serverError.money[0]}}
                  </div>
                  <div v-if="!serverError.money" align="center" class="text-xl text-center text-bold">
                    <span v-if="money">{{showMoney(money)}} บาท</span>
                  </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex flex-col md:flex-row">
                        <div class="sm:p-2 md:p-2 sm:w-full md:w-4/5">
                            <u><b>ช่องทางการชำระเงิน :- </b></u>
                        </div>
                    </div>
                    <div class="flex rounded-lg">
                        <div  class="justify-center flex-1 clemax-w-4xl">
                            <div class="flex flex-col md:flex-row"> -->
                                <!-- <div class="mt-2 md:mr-2 lg:w-1/2 sm:w-full md:w-1/2">
                                    <div>
                                        <input class="hidden" @click="selectPaymentMethod(2)" id="radio_1" type="radio" name="radio">
                                        <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_1">
                                            <span class="text-xs font-semibold uppercase">
                                                <img src="@/assets/img/qr_scb.png" alt="">
                                            </span>
                                            <span class="mt-3 text-xl font-bold text-center">
                                                ชำระเงินด้วย qr code
                                            </span>
                                        </label>
                                    </div>
                                </div> -->
                                <!-- <div class="mt-2 md:ml-2 lg:w-1/2 sm:w-full md:w-1/2">
                                    <div>
                                        <input class="hidden" @click="selectPaymentMethod(1)" id="radio_2" type="radio" name="radio">
                                        <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_2">
                                            <span class="text-xs font-semibold uppercase">
                                                <img src="@/assets/img/scb.jpg" alt="" height="10">
                                            </span>
                                            <span class="mt-3 text-xl font-bold text-center">
                                                บัญชีธนาคาร
                                            </span>
                                        </label>
                                    </div>
                                </div> -->
                            <!-- </div> -->
                            <!-- <div class="mt-4 row">
                                <p v-if="paymentMethod == 1" class="text-base">
                                    ธนาคารไทยพานิชย์ ชื่อบัญชี:มูลนิธิจูจีสร้างสรรค์สังคม 
                                    <span class="text-sm font-bold text-red-500" style="margin-top: -15px">
                                        &nbsp;&nbsp;
                                        508-303048-0
                                        <button @click.stop.prevent="copyToClipboard()"  class="px-1 py-1 mb-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700">
                                            <div class="flex">
                                                <span>คัดลอก</span> 
                                                <svg class="w-5 h-5" :class="{'hidden': is_copied}" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </button>
                                        <input type="hidden" id="accountNumber" value="5083030480">
                                    </span>
                                </p>
                                <p v-if="paymentMethod == 1" class="font-baseextrabold text-">
                                    อย่าลืมกรอกฟอร์มยืนยันโอนเงินที่เว็บไซต์ หลังจากโอนเงินผ่านธนาคารแล้ว
                                </p>
                                <p v-if="paymentMethod == 2" >
                                    <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม
                                </p>
                                <p v-if="paymentMethod == 2" class="text-base">ชำระผ่านการ สแกน/อัพโหลด QR code ด้วย mobile banking application ของ ธนาคารไทยพานิชย์ ธนาคารทหารไทย ธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรี ธนาคารธนชาต ธนาคารกสิกร ธนาคารออมสิน</p>
                                <p v-if="paymentMethod == 2" class="font-baseextrabold text-">คุณจะได้ QR code หลังจากดกปุ่มยืนยัน</p>
                                <button 
                                    @click="insertTocart()" 
                                    v-if="paymentMethod == 2" 
                                    class="w-full mt-1 btncc-riba"
                                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                                    :disabled="disabled">
                                    ยืนยันการบริจาค
                                </button>
                            </div> -->
                        <!-- </div>
                    </div>
                </div>
            </div> -->
            <!-- <form @submit.prevent="insertTocart()" v-if="paymentMethod == 1">
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5">
                                ชื่อ นามสกุล {{name}} {{lastname}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5 ">
                                <span class="text-gray-500">(Email: {{email}})</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5 mr-5">
                                <input type="text" v-model="telephone" class="cc_input_text" :class="{'border-red-500': serverError.telephone}" required placeholder="เบอร์โทรศัพท์">
                                <div v-if="serverError.telephone" class="text-sm text-red-500">
                                    {{serverError.telephone[0]}}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-2 ml-5">
                                <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-2 ml-10 mr-10 rounded-lg ">
                    <button v-if="logedIn"
                        class="w-full mt-1 ml-5 mr-5 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                        ยืนยันการบริจาค
                    </button>
                    <button v-else
                        data-bs-toggle="modal" data-bs-target="#loginAlertModal"
                        class="w-full mt-1 ml-5 mr-5 btncc-riba">
                        ยืนยันการบริจาค
                    </button>
                </div>
            </form> -->
        <!-- </div>   
    </section> -->

    <!-- ฟอร์มระบุจำนวนเงิน -->
    <div class="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none modal fade" :class="[showModal ? ['show','show-modal'] : 'hide-modal']"  id="selectMoneyModal" tabindex="-1" aria-labelledby="exampleModalCenteredScrollable" aria-modal="true" role="dialog">
        <div class="relative w-auto pointer-events-none modal-dialog-scrollable modal-lg modal-dialog">
            <div class="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                <div class="flex items-center justify-between flex-shrink-0 p-4 border-b border-gray-200 modal-header rounded-t-md">
                    <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalCenteredScrollableLabel">
                        <span v-if="budget_type == 1">เลือกหรือระบุจำนวนเงินเพื่อบริจาค</span>
                        <span v-else-if="budget_type == 2">เลือกหรือระบุจำนวนเงินเพื่อสะสางดกเบี้ย</span>
                        <span v-else-if="budget_type == 3">เลือกหรือระบุจำนวนเงินเพื่อซะกาต</span>
                    </h5>
                    <button type="button"
                        class="box-content w-1 h-1 p-1 text-black border-none rounded-none opacity-50 btn-close focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                        @click="closeModal" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
            <div class="modal-body">
                <div class="flex flex-wrap items-center justify-around w-full" v-if="set_amount.length">
                    <div class="w-1/2 max-w-sm p-3 mt-1 rounded-lg lg:w-3/12 sm:w-6/12" v-for="(item, index) in set_amount" :key="index"> 
                        <button @click="selectMoney(item.amount)" class="w-full h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                            {{item.name}} <br> 
                            <span class="text-sm">
                            ( {{ showAmount(item.amount) }} บาท )
                            </span>
                        </button>
                    </div>
                    <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                        <button class="w-40 h-24 px-4 py-1 font-semibold border border-purple-200 rounded-sm text-l text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ระบุจำนวน
                        <br>
                        <input type="number" v-model="input_money" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder=""/>
                        </button>     
                    </div>
                </div>
                <div class="flex flex-wrap items-center justify-around w-full" v-else>
                    <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                        <button @click="selectMoney(100)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                        100
                        </button>
                    </div>
                    <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12">
                        <button @click="selectMoney(500)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                        500
                        </button>
                    </div>
                    <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12">
                        <button @click="selectMoney(1000)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                            1,000
                        </button>
                    </div>
                    <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                        <button class="w-40 h-24 px-4 py-1 font-semibold border border-purple-200 rounded-sm text-l text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ระบุจำนวน
                        <br>
                        <input type="number" v-model="input_money" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder=""/>
                        </button>     
                    </div>
                </div>
                <div v-if="serverError.money"  class="flex flex-wrap items-center justify-around w-full text-sm text-red-500">
                    {{serverError.money[0]}}
                </div>
                <!-- <div v-if="money != ''" class="flex flex-wrap items-center justify-around w-full">
                    <span v-if="budget_type == 1">ยอดบริจาค {{showMoney(netColculate(money))}} บาท  ค่าธรรมเนียม({{fee}}%) {{ showMoney(feeColculate(money)) }} บาท </span>
                    <span v-else-if="budget_type == 2">ยอดสะสางดกเบี้ย {{showMoney(netColculate(money))}} บาท  ค่าธรรมเนียม({{fee}}%) {{showMoney(feeColculate(money))}} บาท </span>
                    <span v-else-if="budget_type == 3">ยอดซะกาต {{showMoney(netColculate(money))}} บาท  ค่าธรรมเนียม({{fee}}%) {{showMoney(feeColculate(money))}} บาท </span>
                </div> -->
            </div>
                <div class="flex flex-wrap items-center justify-end flex-shrink-0 p-4 border-t border-gray-200 modal-footer rounded-b-md">
                    <div class="w-full">
                        <button @click="logedIn ? insertItem() : insertItemWithoutLogin()" 
                            :disabled="disabled"
                            :class="{'opacity-50 cursor-not-allowed': disabled}"
                            class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-orange-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-orange-500">
                            <i class="text-l text-white-700 fas fa-shopping-cart"></i> ใส่ตะกร้า
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- \.ฟอร์มระบุจำนวนเงิน -->

    <!-- ฟอร์มแจ้งเตือนให้เข้าสู่ระบบ -->
    <div class="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none modal fade" id="loginAlertModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="relative w-auto pointer-events-none modal-dialog modal-dialog-scrollable modal-lg">
        <div class="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
          <div
            class="flex items-center justify-between flex-shrink-0 p-4 border-b border-gray-200 modal-header rounded-t-md">
            <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">เข้าสู่ระบบ
            </h5>
            <button type="button"
              class="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="relative p-4 modal-body">
            เข้าสู่ระบบ หรือ ลงทะเบียนสมาชิกก
            <!-- เพื่อสะสมคะแนน CUCI-POINT และรับ Gift voucher หากท่านไม่ต้องการใช้สิทธิ์นี้  กดข้าม -->
          </div>
          <div class="flex flex-wrap items-center justify-end flex-shrink-0 p-4 border-t border-gray-200 modal-footer rounded-b-md">
            <!-- <button type="button" class="px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out" data-bs-dismiss="modal">ข้าม</button> -->
            <router-link to="/login">
              <button type="button" class="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1" data-bs-dismiss="modal">เข้าสู่ระบบ / ลงทะเบียน</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- \.ฟอร์มแจ้งเตือนให้เข้าสู่ระบบ -->

  </template>

  
<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter'
    import Cookies from 'js-cookie'
    export default{
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
            localStorage.setItem('lastVisit', 'projectDetail')
            localStorage.setItem('project_id', this.$route.params.id)
            this.retreiveProjectDetail()
            this.retreiveRealProjectId()
            // this.retreiveProfile()
        },
        data(){
            return{
                id: '',//real project id
                project_id: '',//encode project id
                money: '',
                input_money: '',
                serverError: {},
                disabled: false,
                showModal: false,
                
                project_name: '',
                banner: '',
                budget: '',
                fee: '',
                organization: '',
                totalSuccess: '',
                totalDonator: '',
                persent: '',
                dayRemaining: '',
                images: [],
                objectives: [],
                benefits: [],
                budgets: [],
                address: {
                    data: '',
                    subdistrict: '',
                    district: '',
                    province: '',
                },
                detail: '',
                publish_status: '',
                project_process: '',
                transactions: [],
                
                image_path: process.env.VUE_APP_URL_COOKIE+'images/project/slide',

                paymentMethod: '',
                is_copied: true,

                order_id: '',
                name: '',
                lastname: '',
                telephone: '',
                email: '',
                totalMoney: '',
                anonymous: '',

                budget_type: '',//เลือกเพื่อบริจาคหรือซะกาตหรือสะสางดอกเบี้ย

                riba_budget: '',
                derma_budget: '',
                zakat_budget: '',

                set_amount: [],

                isShowTapOne: true,
                isShowTapTwo: false,
                isShowTapThree: false,

            }
        },
        computed: {
          logedIn(){
            return this.$store.getters['authMember/logedIn']
          },
          myCart(){
            return this.$store.getters['authMember/cart']
          }
        },
        watch:{
          input_money: function(){
            this.money = this.input_money
          },
          money: function(){
            this.serverError = {}
          }
        },
        methods:{
            changeShowTap(tapNumber){
                if(tapNumber == 1){
                    this.isShowTapOne = true
                    this.isShowTapTwo = false
                    this.isShowTapThree = false
                }else if(tapNumber == 2){
                    this.isShowTapOne = false
                    this.isShowTapTwo = true
                    this.isShowTapThree = false
                }else if(tapNumber == 3){
                    this.isShowTapOne = false
                    this.isShowTapTwo = false
                    this.isShowTapThree = true
                }
            },
            insertItemWithoutLogin(){
                if(this.money == ''){
                    this.serverError = {
                        money: ['กรุณากรอกจำนวนเงิน']
                    }
                }else if(this.money > 10000){
                    this.serverError = {
                        money: ['จำนวนเงินต้องไม่เกิน 10,000 บาท']
                    }
                }else{
                    let currentCart = Cookies.get('cart_items')
                    if(currentCart == undefined){
                        let cart = []
                        let item = {
                            id: this.id,
                            money: this.money,
                            budget_type: this.budget_type
                        }
                        cart.push(item)
                        Cookies.set('cart_items', JSON.stringify(cart), { expires: 1 })
                    }else{
                        let cart = JSON.parse(currentCart)
                        let item = cart.find(item => item.id == this.id && item.budget_type == this.budget_type)
                        if(item == undefined){
                            cart.push({
                                id: this.id,
                                money: this.money,
                                budget_type: this.budget_type
                            })
                        }else{
                            item.money = this.money
                        }
                        Cookies.set('cart_items', JSON.stringify(cart), { expires: 1 })
                    }
                    // console.log(JSON.parse(Cookies.get('cart_items')))
                    this.$store.dispatch("authMember/retreiveCartWithoutLogin", JSON.parse(Cookies.get('cart_items')))
                    this.showModal = false
                    this.$store.dispatch("authMember/changeBackdrop")
                    this.$router.push({name: 'Cart'})
                }
                
            },
            retreiveRealProjectId(){
                http.get(`frontendProject/retreiveRealProjectId/${this.$route.params.id}`)
                    .then(response => {
                        this.id = response.data.id
                    })
            },
            feeColculate(amount){
                //2.5 percent of the total amount
                return (this.fee/100) * amount
            },
            netColculate(amount){
                let feeRate = (this.fee/100) * amount
                return amount - feeRate
            },
            insertItem(){
                this.disabled = true
                let data = new FormData()
                data.append('project_id', this.project_id)
                data.append('money', this.money)
                data.append('budget_type', this.budget_type)
                http.post(`donate/insertToCart`, data)
                .then(() => {
                    this.money = ''
                    this.$store.dispatch("authMember/retreiveCart")
                    this.showModal = false
                    this.$store.dispatch("authMember/changeBackdrop")
                    const Toast = this.$swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", this.$swal.stopTimer);
                        toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                    },
                    });

                    Toast.fire({
                    icon: "success",
                    title: "เพิ่มเข้าตะกร้าเรียบร้อย",
                    })
                    
                    this.$router.push({name: 'Cart'})
                    this.disabled = false
                    this.serverError = {}
                })
                .catch(error => {
                    if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                        this.$store.dispatch('authMember/unauthenticateResetting')
                    }
                    this.disabled = false
                    this.serverError = error.response.data.errors
                })
            },
            insertTocart(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('project_id', this.$route.params.id)
                data.append('money', this.money)
                http.post(`donate/insertToCartOneItem`, data)
                .then(response => {
                    this.order_id = response.data.order_id
                    this.$store.dispatch("authMember/retreiveCart")
                    if(this.paymentMethod == 1){
                        this.createTransaction()
                    }else{
                        this.createPromptpayTransaction()
                    }
                    this.disabled = false
                    this.serverError = {}
                })
                .catch(error => {
                    if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                        this.$store.dispatch('authMember/unauthenticateResetting')
                    }
                    this.disabled = false
                    this.serverError = error.response.data.errors
                    if(error.response.data.errors.money){
                        this.gotoDetail('target-money-choise')
                    }
                    this.$Progress.fail()
                })
            },
            createPromptpayTransaction(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('order_id', this.order_id)
                data.append('anonymous', Number(this.anonymous))
                http.post(`riba/createPromptpayTransaction`, data)
                    .then(() => {
                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                        this.$router.push({name: 'DonateQrPromptpay', params: {order_id: this.order_id}})
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                    })
            },
            retreiveCart(){
                http.get(`cart/myCartList`)
                    .then(response => {
                        let totalMoney = 0
                        response.data.data.forEach(element => {
                            totalMoney = parseFloat(totalMoney + element.amount)
                        });
                        this.totalMoney = totalMoney
                        this.createTransaction()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            createTransaction(){
                let data = new FormData()
                data.append('order_id', this.order_id)
                data.append('name', this.name)
                data.append('lastname', this.lastname)
                data.append('telephone', this.telephone)
                data.append('amount', this.money)
                data.append('pay_type', this.paymentMethod)
                data.append('anonymous', Number(this.anonymous))
                http.post(`cart/createTransaction`, data)
                    .then(() => {
                        this.$Progress.finish()
                        this.disabled = false   
                        this.serverError = {}
                        this.$router.push({ name: 'CheckoutAgain', params: {order_id: this.order_id }})
                    })
                    // .catch(error => {
                    //     if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                    //         this.$store.dispatch('authMember/unauthenticateResetting')
                    //     }
                    //     this.$Progress.fail()
                    //     this.disabled = false
                    //     this.serverError = error.response.data.errors
                    // })
            },
            selectPaymentMethod(id){
                this.paymentMethod = id
            },
            closeModal(){
                this.showModal = false
                this.money = ''
                this.$store.dispatch("authMember/changeBackdrop")  
            },
            selectProject(budget_type){
                if(this.project_process != 5){
                    this.budget_type = budget_type
                    this.showModal = true
                    this.money = ''
                    this.$store.dispatch("authMember/changeBackdrop")
                }
            },
            selectMoney(money){
                this.money = money
                this.input_money = ''
            },
            retreiveProjectDetail(){
                this.$Progress.start()
                http.get(`frontendProject/projectInfo/${this.$route.params.id}`)
                    .then(response => {
                        this.project_id = this.$route.params.id
                        this.project_name = response.data.name
                        this.banner = response.data.banner
                        this.budget = response.data.budget
                        this.totalSuccess = response.data.totalSuccess
                        this.organization = response.data.organization
                        this.persent = response.data.persent
                        this.totalDonator = response.data.totalDonator
                        this.dayRemaining = response.data.dayRemaining
                        this.transactions = response.data.transactions
                        this.images = response.data.images
                        this.objectives = response.data.odjectives
                        this.benefits = response.data.benefits
                        this.budgets  = response.data.budgets
                        this.address.data = response.data.address.data
                        this.address.subdistrict = response.data.address.subdistrict
                        this.address.district = response.data.address.district
                        this.address.province = response.data.address.province
                        this.detail = response.data.detail
                        this.derma_budget = response.data.derma_budget
                        this.riba_budget = response.data.riba_budget
                        this.zakat_budget = response.data.zakat_budget
                        this.fee = response.data.fee
                        this.set_amount = response.data.set_amount
                        this.project_process = response.data.project_process
                        this.publish_status = response.data.publish_status
                        this.$Progress.finish()
                    })
                    .catch(() => {
                        this.$Progress.fail()
                    })
            },
            gotoDetail(targetId){
                // window.location.hash='target-donate'
                // document.getElementById('target-donate').scrollIntoView();
                var getMeTo = document.getElementById(targetId);
                getMeTo.scrollIntoView({behavior: 'smooth'}, true);
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0')
            },
            showAmount(money){
                return filter.numberAmount(money, '0,0')
            },
            copyToClipboard(){
                let accountNumber = document.querySelector('#accountNumber')
                accountNumber.setAttribute('type', 'text')
                accountNumber.select()
                try {
                    document.execCommand('copy');
                    this.is_copied = false
                } catch (err) {
                    alert('Oops, unable to copy');
                }
                /* unselect the range */
                accountNumber.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges() 
            },
            retreiveProfile(){
                http.get(`member/profile`)
                    .then(response => {
                        this.name = response.data.data.mem_name
                        this.lastname = response.data.data.mem_lastname
                        this.telephone = response.data.data.mem_tel
                        this.email = response.data.data.mem_email
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                            // this.$store.dispatch('authMember/unauthenticateResetting')
                            this.email = ''
                        }
                    })
            },
        }
    }
</script>

<style lang="css" scoped>
  .show-modal{
    display: block;
  }
  .hide-modal{
    display: none;
  }
</style>