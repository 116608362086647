<template>
    <div class="w-full overflow-hidden rounded-lg shadow-xs">
        <div class="w-full overflow-x-auto">

            <!-- table -->
            <table class="w-full mb-3 whitespace-no-wrap">
                <thead>
                    <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th class="w-2/3 px-4 py-3">ชื่อหมวดหมู่</th>
                        <th class="w-1/3 px-4 py-3 text-right">Action</th>
                    </tr>    
                </thead>
                <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <tr v-for="(item, index) in data" :key="index" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                        <td class="px-4 py-3 text-sm" width="70%">
                            <div class="flex items-center text-sm">
                                {{ item.name }}
                            </div>
                        </td>
                        <td class="px-4 py-3 text-right">
                            <router-link :to="{name: 'ProjectDonationDetail', params: {project_id: item.id}}">
                                <font-awesome-icon :icon="['fas', 'circle-info']" />
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- end table -->

            <!-- pagination -->
            <VueTailwindPagination
                :current="current_page"
                :total="total"
                :per-page="per_page"
                @page-changed="onPageClick($event)"
            />
            <!-- end pagination -->

        </div>
    </div>    
</template>

<script>
    import '@ocrv/vue-tailwind-pagination/dist/style.css'
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    import { mapState } from 'vuex'
    export default {
        name: "project-donate-table",
        components: {
            VueTailwindPagination,
        },
        computed: {
            ...mapState('projectDonation', [
                "current_page",
                "total",
                "per_page",
                "data",
                "keyword"
            ]),
        },
        methods:{
            onPageClick(event){
                if(this.keyword == ""){
                    this.$Progress.start();
                    this.$store.dispatch(`projectDonation/getProjectDonation`, {
                        page_number: event
                    })
                        .then(() => {
                            this.$Progress.finish();
                        })
                        .catch((err) => {
                            if(err.response.status == 401) {
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                            this.$Progerss.fail();
                        })
                }else{
                    this.$Progress.start()
                    this.$store.dispatch("projectDonation/submitSearchForm", {keyword: this.keyword, page_number: event})
                        .then(() => {
                            this.$Progress.finish()
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                            this.$Progress.fail()
                        })    
                }
            },
            showPosition(position) {
                console.log("Latitude: " + position.coords.latitude);
                console.log("Longitude: " + position.coords.longitude);
            }
        },
        mounted(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition);
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>