<template>
    <a class="b_title">
        <div class="flex items-center">
            <router-link to="/dashboard/role">
                <span>จัดการบทบาท</span>
            </router-link>
            &nbsp;
            <span>/ {{role.name_thai}}</span>
        </div>
    </a>

    <!-- ตารางแสดงข้อมูล -->
    <div class="flex justify-between w-full overflow-hidden rounded-lg shadow-xs">
        <div class="w-1/2 pr-3 overflow-x-auto">
            <table class="w-full mt-3 mb-3 whitespace-no-wrap">
                <thead>
                    <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th class="items-center px-4 py-3">
                            <input type="checkbox" v-model="selectAllToAdd">
                            เลือก
                        </th>
                        <th class="px-4 py-3">permission</th>
                        <th class="px-4 py-3">ชื่อสิทธ์</th>
                        <!-- <th class="px-4 py-3">
                            เพิ่ม   
                        </th> -->
                    </tr>
                </thead>
                <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <tr v-for="(item, index) in permissions" :key="index" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                        <td class="items-center px-4 py-3">
                            <input type="checkbox" v-model="selectedToAdd" :value="item.id">
                        </td>
                        <td class="px-4 py-3 text-sm">
                            {{item.name}}    
                        </td>
                        <td>
                            {{item.name_thai}}
                        </td>
                        <!-- <td class="">
                            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>    
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <button v-if="selectedToAdd.length" @click="addPermission()" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="justify-end px-3 py-1 mb-3 font-bold text-white bg-green-500 rounded hover:bg-blue-700 ">
                บันเทึก
            </button>
            
            <!-- pagination -->
            <VueTailwindPagination class="mb-3" v-if="permissions.length"
                :current="pagination.permission.current_page"
                :total="pagination.permission.total"
                :per-page="pagination.permission.per_page"
                @page-changed="onPageClickPermission($event)"
            />
            <!-- \.pagination -->

        </div>
        <div class="w-1/2 pl-3 overflow-x-autof">
            <table class="w-full mt-3 mb-3 whitespace-no-wrap">
                <thead>
                    <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th class="items-center px-4 py-3">
                            <input type="checkbox" v-model="selectAllToDelete">
                            เลือก
                        </th>
                        <th class="px-4 py-3">permission</th>
                        <th class="px-4 py-3">ชื่อสิทธ์</th>
                        <!-- <th class="px-4 py-3">ลบ</th> -->
                    </tr>
                </thead>
                <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <tr v-for="(item, index) in hasPermissions" :key="index" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                        <td class="px-4 py-3 text-sm">
                            <input type="checkbox" v-model="selectedToDelete" :value="item.permission_id">
                        </td>
                        <td>
                           {{item.name}}
                        </td>
                        <td>
                            {{item.name_thai}}
                        </td>
                        <!-- <td>
                            <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <button v-if="selectedToDelete.length" @click="deletePermission()" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="justify-end px-3 py-1 mb-3 font-bold text-white bg-green-500 rounded hover:bg-blue-700 ">
                บันเทึก
            </button>
            
            <!-- pagination -->
            <VueTailwindPagination class="mb-3" v-if="hasPermissions.length"
                :current="pagination.hasPermission.current_page"
                :total="pagination.hasPermission.total"
                :per-page="pagination.hasPermission.per_page"
                @page-changed="onPageClickHasPermission($event)"
            />
            <!-- \.pagination -->
            
        </div>
    </div>
    <!-- \.ตารางแสดงข้อมูล -->
            
</template>

<script>
    import http from '@/services/BackendService'
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    export default {
        components:{
           VueTailwindPagination 
        },
        mounted(){
            this.getRole()
            this.retreivePermission()
            this.retreiveHasPermission()
        },
        data(){
            return{
                role: '',
                permissions: [],
                hasPermissions: [],
                pagination: {
                    permission:{
                        current_page: 1
                    },
                    hasPermission:{
                        current_page: 1
                    }
                },
                selectedToAdd: [],
                selectAllToAdd: false,
                selectedToDelete: [],
                selectAllToDelete: false,
                disabled: false,
            }
        },
        watch:{
            selectAllToDelete: function(){
                if(this.selectAllToDelete == true){
                    this.hasPermissions.forEach(item => {
                        this.selectedToDelete.push(item.permission_id)
                    })
                }else{
                    this.selectedToDelete = []
                }
            },
            selectAllToAdd: function(){
                if(this.selectAllToAdd == true){
                    this.permissions.forEach(item => {
                        this.selectedToAdd.push(item.id)
                    })
                }else{
                    this.selectedToAdd = []
                }
            },
        },
        methods:{
            deletePermission(){
                let data = new FormData()
                data.append('permissions', this.selectedToDelete)
                data.append('role_id', this.$route.params.id)
                this.disabled = true
                this.$Progress.start()
                http.post(`roleHasPermission/revokePermission`, data)
                    .then(() => {
                        this.retreiveHasPermission() 
                        this.retreivePermission()
                        this.disabled = false
                        this.selectAllToDelete = ""
                        this.$Progress.finish()       
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }    
                        this.$Progress.fail()
                        this.disabled = false
                    })
            },
            addPermission(){
                let data = new FormData()
                data.append('permissions', this.selectedToAdd)
                data.append('role_id', this.$route.params.id)
                this.disabled = true
                this.$Progress.start()
                http.post(`roleHasPermission/addPermission`, data)
                    .then(() => {
                        this.retreivePermission()
                        this.retreiveHasPermission()
                        this.selectAllToAdd = ""
                        this.disabled = false  
                        this.$Progress.finish()  
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                        this.$Progress.fail()
                        this.disabled = false   
                    })
            },
            retreiveHasPermission(){
                http.get(`roleHasPermission/hasPermission?page=${this.pagination.hasPermission.current_page}&id=${this.$route.params.id}`)
                    .then(response => {
                        this.hasPermissions = response.data.permissions.data
                        this.pagination.hasPermission = response.data.permissions
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }    
                    })
            },
            retreivePermission(){
                http.get(`roleHasPermission/permission?page=${this.pagination.permission.current_page}&id=${this.$route.params.id}`)
                    .then(response => {
                        this.permissions = response.data.permissions.data
                        this.pagination.permission = response.data.permissions
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }    
                    })
            },
            getRole(){
                http.get(`role/role/${this.$route.params.id}`)
                    .then(response => {
                        this.role = response.data.role
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            onPageClickPermission(event){
                this.$Progress.start()
                http.get(`roleHasPermission/permission?page=${event}&id=${this.$route.params.id}`)
                    .then(response => {
                        this.permissions = response.data.permissions.data
                        this.pagination.permission = response.data.permissions
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()    
                    })
            },
            onPageClickHasPermission(event){
                this.$Progress.start()
                http.get(`roleHasPermission/hasPermission?page=${event}&id=${this.$route.params.id}`)
                    .then(response => {
                        this.hasPermissions = response.data.permissions.data
                        this.pagination.hasPermission = response.data.permissions
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()    
                    })
            },
        }   
    }
</script>

<style lang="scss" scoped>

</style>