<template>
    <!-- navbar -->
    <a class="mt-5 b_title">
        <div class="flex items-center">
            <router-link :to="{name: 'DonateManagement'}" class="cursor-pointer">
                <span>ข้อมูลการบริจาค</span>
            </router-link>
            &nbsp;
            <div class="flex items-center text-gray-600">
                <span> / ข้อมูลการบริจาค</span>
            </div>
        </div>
    </a>
    <!-- end navbar -->

    <!-- search form -->
    <project-donation-search-form> </project-donation-search-form>
    <!-- end search form -->

    <!-- content -->
    <project-donate-table> </project-donate-table>
    <!-- end content -->

</template>

<script>
    import ProjectDonationSearchForm from './ProjectDonationSearchForm.vue';
    import ProjectDonationTable from './ProjectDonationTable.vue';
    export default {
        components: {
            'project-donate-table': ProjectDonationTable,
            'project-donation-search-form': ProjectDonationSearchForm,
        },
        methods: {
            getProjectDonation() {
                this.$Progress.start();
                this.$store.dispatch(`projectDonation/getProjectDonation`, {
                    page_number: 1
                })
                    .then(() => {
                        this.$Progress.finish();
                    })
                    .catch((err) => {
                        if(err.response.status == 401) {
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progerss.fail();
                    })
            },
        },
        mounted() {
            this.getProjectDonation();
        },
    }
</script>

<style lang="scss" scoped>

</style>