<template>
  <section class="">
      <div class="container mx-auto lg:pt-24 ">
          <div class="flex flex-wrap">
              
              <div class="w-full p-4 lg:w-12/12 lg:ml-auto">
                  <h6 class="pt-24 font-medium text-indigo-900 lg:pt-10">ความรู้ / วีดีโอ / การจัดการดอกเบี้ยในอิสลาม</h6>
                  <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize ">การจัดการการดอกเบี้ย ตอนที่ 1 </h2>
                  <img src="@/assets/img/m7.jpg" class="object-cover w-full h-40 pt-14 2xl:w-full lg:w-full lg:h-20 sm:pt-10" alt="" width="" height="" />

                  <p class="mb-4"><iframe class="w-full lg:w-full " width="" height="600" src="https://www.youtube.com/embed/j6OW2gR-29s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br> 
	
                  <a href="#" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">เรียนรู้เพิ่มเติม</a>
              </p>
              </div>
          </div>
      </div>
  </section>
</template>
