<template>
    <section class="bgcc">
        <div class="container p-1 pt-40 pb-2 mx-auto text-center">
            <p class="mb-2 text-2xl text-gray-800">
                เปิดรับสมัครโครงการสาธารณะประโยชน์ประจำปี 2567
            </p>
        </div>
    </section>
    <section class="bgcc">
        <div class="container mx-auto text-center">
            <div class="flex flex-wrap items-center">
               <div class="w-full p-4">
                   <img src="@/assets/img/openpbb2024-4.png" class="object-cover w-full h-full"/>
               </div>
            </div>
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 sm:w-6/12">
                    <img src="@/assets/img/openbpp2024-1.png" class="object-cover w-full h-full" />
                </div>
                <div class="w-full p-4 lg:w-6/12 sm:w-6/12">
                    <img src="@/assets/img/openpbb2024-2.png" class="object-cover w-full h-full" />
                </div>
            </div>
        </div>
        <div class="container mx-auto text-center">
            <div class="flex flex-wrap items-center">
                <div class="w-full lg:w-6/12 sm:w-6/12">
                    <div class="w-full pb-5 pl-4 pr-4">
                        <button class="w-full btncc-riba" @click="downloadApplyForm()"> 
                            ดาวน์โหลดแบบเสนอโครงการสาธารณประโยชน์
                        </button>
                    </div>
                </div>
                <div class="w-full lg:w-6/12 sm:w-6/12">
                    <div class="w-full pb-5 pl-4 pr-4">
                        <button class="w-full btncc-riba" @click="openPbbApplyForm()"> 
                            สมัครโครงการ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
  export default{
    mounted(){
      localStorage.setItem('lastVisit', 'Projects')
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    methods:{
        downloadApplyForm(){
            //download file (.doc file) from server
            window.open(`${process.env.VUE_APP_URL_COOKIE}files/openpbbapplyform.docx`, '_blank');
        },
        openPbbApplyForm(){
            //open new tab to apply form
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSdmPgmkdu3nn6n1vJr8hgwFZIupko-84sOCu444ZMj276PTWQ/viewform', '_blank');
        }
    }
  }
</script>