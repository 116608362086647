<template>
    <header class="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
        
        <div class="container flex items-center justify-between h-full px-6 mx-auto text-yellow-600 dark:text-purple-300">
            <!-- Mobile hamburger -->
            <!-- <p class="text-right "> Admin : Imorn Bin Hj.Husin Bin Awang</p> -->
            <button @click="onClickMobileMenu" class="p-1 mr-5 -ml-1 rounded-md xl:hidden focus:outline-none focus:shadow-outline-purple">
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"></path>
                </svg>
            </button>
            <!-- Search input -->
            <div class="flex justify-center flex-1 lg:mr-32">
                
            </div>
            <ul class="flex items-center flex-shrink-0 space-x-6">
                <!-- Notifications menu -->
                <li class="relative">
                    <button class="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple"
                        aria-label="Notifications"
                        aria-haspopup="true"
                        @click="onclickShowNotify()">
                        <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                        </svg>
                        <!-- Notification badge -->
                        <span aria-hidden="true" class="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"></span>
                    </button>
                    <template :class="{'block': showNotificationMenu}">
                        <ul class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:text-gray-300 dark:border-gray-700 dark:bg-gray-700">
                        <li class="flex">
                            <a class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200" href="#">
                                <span>Messages</span>
                                <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-600">13</span>
                            </a>
                        </li>
                        <li class="flex">
                            <a class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                            href="#">
                                <span>Sales</span>
                                <span class="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full dark:text-red-100 dark:bg-red-600">2</span>
                            </a>
                        </li>
                        <li class="flex">
                            <a class="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200">
                                <span>Alerts</span>
                            </a>
                        </li>
                        </ul>
                    </template>
                </li>

                <!-- Profile menu -->
                <li class="relative">
                <button
                    class="align-middle rounded-full focus:shadow-outline-purple focus:outline-none"
                    aria-label="Account"
                    aria-haspopup="true">
                    <img
                    class="object-cover w-8 h-8 rounded-full"
                    src="@/assets/img/user.png"
                    alt=""
                    aria-hidden="true"
                    @click="onclickShowProfile()"
                    />
                </button>
                    <template :class="{'block': showProfileMenu}">
                        <ul class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
                        aria-label="submenu">
                        <li class="flex">
                            <a @click="test"
                            class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                            href="#">
                            <svg
                                class="w-4 h-4 mr-3"
                                aria-hidden="true"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                ></path>
                            </svg>
                            <span>Profile</span>
                            </a>
                        </li>
                        <li class="flex">
                            <a @click="test"
                            class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                            href="#"
                            >
                            <svg
                                class="w-4 h-4 mr-3"
                                aria-hidden="true"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                ></path>
                                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            </svg>
                            <span>Settings</span>
                            </a>
                        </li>
                        <li class="lock">
                            <a @click="signOut()" class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                            href="#">
                                <svg
                                    class="w-4 h-4 mr-3"
                                    aria-hidden="true"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path
                                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                                    ></path>
                                </svg>
                                <span>Log out</span>
                            </a>
                        </li>
                        </ul>
                    </template>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
// import http from '@/services/BackendService'

export default {
    data(){
        return{
            showProfileMenu: false,
            showNotificationMenu: false,
        }
    },
    methods:{
        onClickMobileMenu(){
            this.$store.commit("sidebar/toggleSideMenu")
        },
        onclickShowProfile(){
            this.showProfileMenu = !this.showProfileMenu
            this.showNotificationMenu = false
        },
        onclickShowNotify(){
            this.showProfileMenu = false
            this.showNotificationMenu = !this.showNotificationMenu
        },
        // onclickLogout(){

        //     http.post('logout')
        //         .then(() => {
        //             localStorage.removeItem('cuciMember')
        //             this.$router.push({name: 'Login'})
        //         })
        //         .catch(error => {
        //             // if(error.response){
        //             //     console.log(error.response.data)
        //             //     console.log(error.response.status)
        //             // } 
        //             if(error.response.status == 401){
        //                 localStorage.removeItem("cuciUser") 
        //                 this.$router.push({name: 'LoginAdmin'})  
        //             }  
        //         }) 
        // }
        signOut(){
                this.$Progress.start()
                this.$store.dispatch('authMember/signOut')
                    .then(() => {
                        this.$router.push({name: 'Login'})
                        localStorage.removeItem('cuciMember')
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            localStorage.removeItem("cuciMember") 
                            this.$router.push({name: 'Login'})  
                        }
                        this.$Progress.fail()
                    })
            }
    }
}
</script>