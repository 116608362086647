<template>
    <div class="container pt-20 pb-20 pl-4 mx-auto lg:pt-40">
        <div class="container mx-auto ">
            <div class="py-4 mr-4 bgcc01 "> 
                <a class="flexcc-top">
                  <div class="container flex items-center">
                      <span>บัตรกำนัล Gift Voucher</span>
                  </div>
                </a>
                <ul class="flex flex-col flex-wrap pl-0 mb-4 list-none border-b-0 nav nav-tabs md:flex-row" id="tabs-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                      <a @click="retreiveUnkeepedVoucher()" href="#" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL"
                      aria-selected="true">
                      
                      <div class="">
                          <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                              <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>{{ unkeeped_amount }}</span></span>
                              <span class="ml-1.5"><i class="fas fa-gifts"></i>  Gift Voucher ที่ยังไม่เก็บ </span>
                          </strong>
                      </div>
                
                    </a>
                  </li>
                  <li class=" nav-item" role="presentation">
                    <a @click="retreiveKeepedVoucher()" href="#" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL" aria-selected="true">
                      <div class="">
                        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                          <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>{{ keeped_amount }}</span></span>
                          <span class="ml-1.5"><i class="fas fa-gifts"></i>  Gift Voucher ที่เก็บทั้งหมด </span>
                        </strong>
                      </div>
                    </a>
                  </li>
                  <!-- <li class=" nav-item" role="presentation">
                    <a href="#tabs-GV03" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-messages-tab" data-bs-toggle="pill" data-bs-target="#tabs-messages" role="tab"
                      aria-controls="tabs-messages" aria-selected="false">
                      <div class="">
                        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                            <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>2</span></span>
                            <span class="ml-1.5"><i class="fas fa-gifts"></i>  ใช้ไปแล้ว</span>
                          </strong>
                        </div>
                    </a>
                  </li>
                  <li class=" nav-item" role="presentation">
                    <a href="#tabs-GV04" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-contact-tab" data-bs-toggle="pill" data-bs-target="#tabs-contact" role="tab" aria-controls="tabs-contact" aria-selected="false">
                      <div class="">
                        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                            <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>3</span></span>
                            <span class="ml-1.5"><i class="fas fa-gifts"></i> ยังไม่ได้ใช้</span>
                          </strong>
                        </div>
                      </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a href="#tabs-GV05" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent" id="tabs-contact-tab" data-bs-toggle="pill" data-bs-target="#tabs-contact" role="tab"
                      aria-controls="tabs-contact" aria-selected="false">
                      <div class="">
                        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                            <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>1</span></span>
                            <span class="ml-1.5"><i class="fas fa-gifts"></i> หมดอายุ</span>
                          </strong>
                        </div>
                      </a>
                  </li> -->
                </ul>
                <div class="tabs-GV01" id="tabs-tabContent">
                  <h1 class="inline mx-2 my-20 font-black text-orange-500 ">Gift Voucher ที่ยังไม่เก็บ</h1> 
                </div>
                <div v-for="(item, index) in vouchers" :key="index"  class="relative flex flex-wrap mx-4 my-4 border-2 rounded-lg shadow-lg border-slate-100 lg:grid-cols-1 lg:mb-2 hover:bg-emerald-200 hover:text-emerald-700">
                  <a href="#tabs-GV01" class="">
                    <div class="flex flex-wrap items-center mx-2 my-2">
                      <div>
                        <img class="object-contain w-64 h-32 mx-2" :src="`${imagePath}images/giftvoucher/${item.picture}`"/>
                      </div>
                      <div class="ml-2 text">
                        <p class="text-lg">
                          Gift Voucher ID<span class="text-sm">| {{ item.gvcode }} | {{ item.unit_name }} </span>
                        </p>
                        <p class="text-xs">
                            โดย {{ item.organization }}
                        </p>
                        <!-- <p class="text-xs">
                          วันที่เก็บ  25 ก.ค. 65 เวลา 16:23:41 น.
                        </p> -->
                        <p class="text-xl ">
                          วันที่หมดอายุ {{ formatDate(item.use_befor) }}
                        </p>
                        <div class="text-left sm:px-6" v-if="isVr == false">
                          <button disabled class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm opacity-50 cursor-not-allowed bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            รับบัตรกำนัล
                          </button>
                          <br>
                           กรุณายืนยันอีเมลก่อนที่เมนูบัญชี <router-link to="/account">[ไปหน้าบัญชี]</router-link> 
                        </div>
                        <div class="text-left sm:px-6" v-if="item.vouchor_status == 'active' && item.used_status == 0 && isVr == true">
                          <button @click="usingVoucher(item.id)" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            ใช้บัตรกำนัล
                          </button>
                        </div>
                        <div class="text-left sm:px-6" v-else-if="item.vouchor_status == 'active' && item.used_status == null && isVr == true">
                          <button @click="voucherKeeping(item.id)" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            รับบัตรกำนัล
                          </button>
                        </div>
                        <div class="text-left sm:px-6" v-else-if="item.vouchor_status == 'active' && item.used_status == 1 && isVr == true">
                          <button class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            ขอใช้แล้ว 
                          </button>
                        </div>
                        <div class="text-left sm:px-6" v-else-if="item.vouchor_status == 'active' && item.used_status == 2 && isVr == true">
                          <button class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            ใช้บัตรกำนัลเเล้ว
                          </button>
                        </div>
                        <div class="text-left sm:px-6" v-else-if="item.vouchor_status == 'expired' && isVr == true">
                          <button class="inline-flex justify-center px-2 py-2 m-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                            หมดอายุแล้ว
                          </button>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="h-96"></div>
            </div>
        </div>
    </div>
 </template>

<script>
  import http from '@/services/AuthService'
  import filter from '@/helpers/filter'
  import moment from 'moment'
  export default{
    mounted(){
      this.retreiveUnkeepedVoucher()
      this.keepedAmount()
      this.unkeepedAmount()
      this.isVerifiedEmail()
      this.imagePath = process.env.VUE_APP_URL_COOKIE
    },
    data(){
      return{
        vouchers: [],
        keeped_amount: 0,
        unkeeped_amount: 0,
        imagePath: '',
        disabled: false,
        isVr: false,
      }
    },
    methods:{
      isVerifiedEmail(){
        http.get(`member/isVerifiedEmail`)
          .then((r) => {
            this.isVr = r.data.isVerified
          })
      },
      usingVoucher(id){
        this.$Progress.start()
        this.disabled = true
        let data = new FormData()
        data.append('id', id)
        http.post(`giftVoucher/usingVoucher`, data)
          .then(() => {
            this.retreiveKeepedVoucher()
            this.$Progress.finish()
            this.disabled = false
          })
          .catch(error => {
            if(error.response.statu == 401 || error.response.statu == 419){
              this.$store.dispatch('authMember/unauthenticateResetting')
            }
            this.disabled = false
            this.$Progress.fail()
          })

      },
      keepedAmount(){
        http.get(`giftVoucher/keepedAmount`)
          .then(response => {
            this.keeped_amount = response.data
          })
      },
      unkeepedAmount(){
        http.get(`giftVoucher/unkeepedAmount`)
          .then(response => {
            this.unkeeped_amount = response.data
          })
      },
      retreiveKeepedVoucher(){
        this.disabled = true
        this.$Progress.start()
        http.get(`giftVoucher/keeped`) 
          .then(response => {
            this.vouchers = response.data.data
            this.keeped_amount = response.data.amount
            this.$Progress.finish()
            this.disabled = false
          })
          .catch(error => {
            if(error.response.statu == 401 || error.response.statu == 419){
              this.$store.dispatch('authMember/unauthenticateResetting')
            }
            this.disabled = false
            this.$Progress.fail()
          })
      },
      voucherKeeping(id){
        this.disabled = true
        this.$Progress.start()
        let data = new FormData()
        data.append('gift_voucher_id', id)
        http.post(`giftVoucher/keeping`, data)
          .then(() => {
            this.$swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เก็บ gift voucher แล้ว',
                confirmButtonText: 'OK',
            })
            this.unkeepedAmount()
            this.keepedAmount()
            this.retreiveUnkeepedVoucher()
            this.disabled = false
            this.$Progress.finish()
          })
          .catch(error => {
            if(error.response.statu == 401 || error.response.statu == 419){
              this.$store.dispatch('authMember/unauthenticateResetting')
            }
            this.disabled = false
            this.$Progress.fail()
          })
      },
      retreiveUnkeepedVoucher(){
        this.$Progress.start()
        http.get(`giftVoucher/unkeeped`)
          .then(response => {
            this.vouchers = response.data.data
            this.unkeeped_amount = response.data.amount
            this.$Progress.finish()
          })
          .catch(error => {
            if(error.response.statu == 401 || error.response.statu == 419){
              this.$store.dispatch('authMember/unauthenticateResetting')
            }
            this.$Progress.fail()
          })
      },
      showMoney(money){
        return filter.numberFormat(money, '0,0.00')
      },
      formatDate(value){
        if(value){
            return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
        }
      },
    }
  }
</script>