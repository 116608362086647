import http from '@/services/BackendService'

const state = {
    categories: [],
    category: {},
    currentPage: 0,
    perPage: 0,
    total: 0,
    keyword: '',
    showFormModal: false,
    editMode: false,
    showDeleteModal: false,
    deleteId: '' //project category id form delete
}

const getters = {

}

const mutations = {
    getProjectCategory(state, categories){
        state.categories = categories.data
        state.currentPage = categories.current_page
        state.perPage = categories.per_page
        state.total = categories.total
    },
    getProjectCategorySearch(state, categories){
        state.categories = categories.data
        state.currentPage = categories.current_page
        state.perPage = categories.per_page
        state.total = categories.total
    },
    changeKeyword(state, keyword){
        state.keyword = keyword
    },
    submitSeachForm(state, payload){
        state.categories = payload.data.data
        state.currentPage = payload.data.current_page
        state.perPage = payload.data.per_page
        state.total = payload.data.total
        state.keyword = payload.keyword
    },
    openFormModal(state){
        state.showFormModal = true
        state.editMode = false
        // state.category = ''
    },
    closeFormModal(state){
        state.showFormModal = false
        state.editMode = false
    },
    showProjectCategory(state, category){
        state.category = category
        state.showFormModal = true
        state.editMode = true
    },
    showDeleteForm(state, id){
        state.showDeleteModal = true
        state.deleteId = id
        
    },
    closeDeleteForm(state){
        state.showDeleteModal = false
    }
}

const actions = {
    closeDeleteForm(context){
        context.commit("closeDeleteForm")
    },
    showDeleteForm(context, id){
        context.commit("showDeleteForm", id)
    },
    showProjectCategory(context, id){
        return new Promise((resolve, reject) => {
            http.get(`project/category/${id}`)
                .then(response => {
                    resolve(response)
                    context.commit("showProjectCategory", response.data.category)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    closeFormModal(context){
        context.commit('closeFormModal')
    },
    openFormModal(context){
        context.commit('openFormModal')
    },
    submitSeachForm(context, payload){
        return new Promise((resolve, reject) => {
            http.get(`project/categorySearch/${payload.keyword}`)
                .then(response => {
                    context.commit("submitSeachForm", {data:response.data.categories, keyword: payload.keyword})
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })  
    },
    changeKeyword(context, keyword){
        context.commit("changeKeyword", keyword)
    },
    getProjectCategorySearch(context, payload){
        return new Promise((resolve, reject) => {
            http.get(`project/categorySearch/${context.state.keyword}?page=${payload.pageNumber}`)
                .then(response => {
                    resolve(response)
                    context.commit("getProjectCategorySearch", response.data.categories)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getProjectCategory(context, payload){
        return new Promise((resolve, reject) => {
            http.get(`project/category?page=${payload.pageNumber}`)
                .then(response => {
                    resolve(response)
                    context.commit('getProjectCategory', response.data.categories)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}