<template>
    <!-- navbar -->
    <a class="w-full mt-5 b_title">
        <div class="flex items-center">
            <router-link :to="{name: 'DonateManagement'}" class="cursor-pointer">
                <span>ข้อมูลการบริจาค</span>
            </router-link>
            &nbsp;
            <router-link :to="{name: 'ProjectDonation'}">
                <div class="flex items-center">
                    <span> / ข้อมูลการบริจาค</span>
                </div>
            </router-link>
            &nbsp;
            <div class="flex items-center text-gray-600">
                <span> / รายละเอียดการบริจาค</span>
            </div>
        </div>
    </a>
    <!-- end navbar -->

    <!-- chart -->
    <div class="container pb-2 mx-auto text-center">
        <div class="flex flex-wrap justify-around">
            <!-- <div class="w-full p-1 md:w-1/2 lg:w-1/2"> -->
            <div class="flex items-center md:w-1/2 sm:flex-col">
                <!-- <GChart
                    type="PieChart"
                    :data="pieChartData"
                    :options="chartOptions"
                    :resizeDebounce="500"
                /> -->
                <apexchart 
                    v-if="donutSeries.length" 
                    type="donut" 
                    width="430" 
                    :options="donutChartOptions" 
                    :series="donutSeries">
                </apexchart>
            </div>
            <div class="flex items-center md:w-1/2 sm:flex-col" >
                <!-- <GChart
                  type="ColumnChart"
                  :data="barChartData"
                  :options="chartOptions"
                /> -->
                <apexchart 
                    v-if="barChartSeries[0].data.length"
                    type="bar" 
                    height="350" 
                    width="400" 
                    :options="barChartOptions" 
                    :series="barChartSeries"
                    @dataPointSelection="dataPointSelection">
                </apexchart>

            </div>
        </div>
    </div>
    <!-- end chart -->

    <!-- <div class="container mx-auto text-center">
        <div class="flex flex-wrap justify-around " data-v-2d4292e8="">
            <div class="flex items-center lg:flex-row sm:flex-col" data-v-2d4292e8=""><i class="ml-4 sm:text-1xl lg:text-4xl text-emerald-700 fas fa-border-all" data-v-2d4292e8=""></i><p class="ml-2 text-sm text-left " data-v-2d4292e8="">หมวดหมู่ทั้งหมด</p></div>
            <div class="flex items-center lg:flex-row sm:flex-col" data-v-2d4292e8=""><i class="mb-4 ml-4 sm:text-1xl lg:text-4xl text-emerald-700 fa-school fas" data-v-2d4292e8=""></i><p class="ml-2 text-sm text-left " data-v-2d4292e8="">การศึกษา</p></div>
            <div class="flex items-center lg:flex-row sm:flex-col" data-v-2d4292e8=""><i class="mb-4 ml-4 sm:text-1xl lg:text-4xl text-emerald-700 fa-retweet fas" data-v-2d4292e8=""></i><p class="ml-2 text-sm text-left " data-v-2d4292e8="">ศาสนาและสังคม</p></div>
            <div class="flex items-center lg:flex-row sm:flex-col" data-v-2d4292e8=""><i class="mb-4 ml-4 sm:text-1xl lg:text-4xl text-emerald-700 fa-water fas" data-v-2d4292e8=""></i><p class="ml-2 text-sm text-left " data-v-2d4292e8="">ภัยพิบัติ</p></div>
            <div class="flex items-center lg:flex-row sm:flex-col" data-v-2d4292e8=""><i class="mb-4 ml-4 sm:text-1xl lg:text-4xl text-emerald-700 fa-heartbeat fas" data-v-2d4292e8=""></i><p class="ml-2 text-sm text-left " data-v-2d4292e8="">สุขภาพและการแพทย์</p></div>
            <div class="flex items-center lg:flex-row sm:flex-col" data-v-2d4292e8=""><i class="mb-4 sm:text-1xl lg:text-4xl text-emerald-700 fa-balance-scale fas" data-v-2d4292e8=""></i><p class="ml-2 text-sm text-left " data-v-2d4292e8="">กระบวนการยุติธรรม</p></div>
        </div>
    </div> -->

    <!-- table -->
    <table class="w-full mb-3 whitespace-no-wrap border">
        <thead>
            <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                <th class="w-1/4 px-4 py-3">ผู้สนับสนุน/วันที่</th>
                <th class="hidden w-2/4 px-4 py-3 md:block">ผู้สนับสนุน</th>
                <th class="w-1/4 px-4 py-3 ">ประเภทเงิน</th>
                <th class="w-1/4 px-4 py-3 text-right">จำนวนเงิน</th>
            </tr>    
        </thead>
        <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            <tr v-for="(item, index) in transactions" :key="index" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                <td class="px-4 py-3 text-sm">
                    <p>
                        {{ item.mem_name }} {{ item.mem_lastname }}
                    </p>
                    <p class="text-gray-400">
                        {{ item.confirm_date }}
                    </p>
                </td>
                <td class="hidden px-4 py-3 text-left md:block">
                    {{ item.mem_name }} {{ item.mem_lastname }}
                </td>
                <td class="px-4 py-3 text-left">
                   {{ item.budget_type }}
                </td>
                <td class="px-4 py-3 text-right">
                   {{ item.amount }}
                </td>
            </tr>
        </tbody>
    </table>
    <!-- end table -->

</template>

<script>
    //import vue-google-chart
    // import { GChart } from 'vue-google-charts'
    import http from '@/services/BackendService'
    export default {
        // components:{
        //     GChart,
        // },
        data() {
            return {
                // pieChartData: [
                //     ['Task', 'ยอดบริจาค'],
                // ],
                // barChartData: [
                // ['วันที่', 'จำนวนเงิน', { role: 'style' } ],
                // ],
                // chartOptions: {
                //     title: 'สัดส่วนการสนับสนุนโครงการ',
                //     // responsive: true,
                //     // is3D: true,
                //     // legend:"none",
                //     height: 300,
                //     // chartArea:{
                //     //     width:"100%",
                //     //     height:"100%"
                //     // },
                //     // 'backgroundColor': {
                //     //     'fill': '#F4F4F4',
                //     //     'opacity': 100
                //     // },
                // },
                isShow: false,

                //donut chart
                donutSeries: [],
                donutSeriesCache: [],
                donutChartOptions: {
                    chart: {
                        type: 'donut',
                    },
                    labels: [],
                    labelsCache: [],
                    legend: {
                        position: 'bottom',
                    },
                    plotOptions: {
                        pie: {
                            startAngle: 0,
                            endAngle: 360,
                            expandOnClick: true,
                            offsetX: 0,
                            offsetY: 0,
                            customScale: 1,
                            dataLabels: {
                                offset: 0,
                                minAngleToShowLabel: 10
                            }, 
                            donut: {
                                size: '65%',
                                background: 'transparent',
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '22px',
                                        fontFamily: 'Helvetica, Arial, sans-serif',
                                        fontWeight: 600,
                                        color: undefined,
                                        offsetY: -10,
                                        formatter: function (val) {
                                            return val
                                        }
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica, Arial, sans-serif',
                                        fontWeight: 400,
                                        color: undefined,
                                        offsetY: 16,
                                        formatter: function (val) {
                                            return val
                                        }
                                    },
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        label: 'Total',
                                        fontSize: '22px',
                                        fontFamily: 'Helvetica, Arial, sans-serif',
                                        fontWeight: 600,
                                        color: '#373d3f',
                                        formatter: function (w) {
                                            //format number to 2 decimal places and add comma
                                            return w.globals.seriesTotals.reduce((a, b) => {
                                                return a + b
                                            }, 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                        }
                                    }
                                }
                            },      
                        }
                    },
                    tooltip:{
                        // enabled: true,
                        fillSeriesColor: false,
                        theme: 'dark',
                        style: {
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                        },
                        onDatasetHover: {
                            highlightDataSeries: true,
                        },
                        y: {
                            formatter: (value) => {
                                //format number to 2 decimal places and add comma
                                return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' บาท';
                            },
                            title: {
                                formatter: (seriesName) => {
                                    return seriesName + ': ' ;
                                },
                            },
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: '100%'
                            },
                            legend: {
                                position: 'bottom',
                                //set align legend to center
                                horizontalAlign: 'center',
                            },

                        },
                    }],
                },

                //bar chart
                barChartSeriesCache: [
                    {
                        data: []
                    }
                ],
                barChartSeries: [
                    {
                        data: []
                    }
                ],
                barChartOptions: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        width: 500,
                        events: {
                            click: function(event) {
                                // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                                console.log(event)
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '100%',
                            horizontal: false,
                            endingShape: 'rounded',
                            labels: {
                                position: 'top', // top, center, bottom
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true
                    },
                    labels: [],
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'บาท'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        theme: 'dark',
                        style: {
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                        },
                        onDatasetHover: {
                            highlightDataSeries: true,
                        },
                        y: {
                            formatter: (value) => {
                                    return value + ' ครั้ง';
                            },
                            title: {
                                //display label in tooltip with labels array
                                formatter: () => {
                                    return 'จำนวน' + ': ' ;
                                },
                            },
                        },
                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: '100%'
                            },
                            legend: {
                                position: 'bottom',
                                //set align legend to center
                                horizontalAlign: 'center',
                            },

                        },
                    }],
                },

                transactions: [],  
                transactionsCache: [],
            }        
        },
        methods: {
            //get chart report
            retreiveReport(){
                http.get(`project/donation/report/${this.$route.params.project_id}`)
                    .then(response => {
                        let budget_type = response.data.budget_type;
                        //push data to chartData
                        budget_type.forEach(element => {
                            this.donutSeries.push(element.total);
                            this.donutSeriesCache.push(element.total);
                            this.donutChartOptions.labelsCache.push(
                                `${element.budget_type}`
                            );
                            // let total = element.total;
                            //format number to 2 decimal places
                            this.donutChartOptions.labels.push(
                                `${element.budget_type}`
                            );
                        });
                        this.isShow = true;
                        // this.chartOptions.labels = budget_type.map(item => item.budget_type);
                        let date_range = response.data.date_range;
                        //push data to chartData
                        date_range.forEach(element => {
                            // this.barChartData.push([element.date, element.total, 'color: #76A7FA']);
                            // this.barChartSeriesCache[0].data.push(element.total);
                            this.barChartSeries[0].data.push(element.total);
                            this.barChartOptions.xaxis.categories.push(element.date);
                            // this.barChartOptions.labels.push(element.date);
                        });
                        this.transactions = response.data.transactions;
                        this.transactionsCache = response.data.transactions;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            click(event, chartContext, config) {    
                console.log('click', event, chartContext, config);
            },
            legendClick(chartContext, seriesIndex, config) {
                console.log('legendClick', chartContext, seriesIndex, config);
            },
            markerClick(event, chartContext, { seriesIndex, dataPointIndex, config }) {
                console.log('markerClick', event, chartContext, seriesIndex, dataPointIndex, config);
            },
            selection(chartContext, { xaxis, yaxis }) {
                console.log('selection', chartContext, xaxis, yaxis);
            },
            dataPointSelection(event, chartContext, config) {
                // console.log('dataPointSelection', event, chartContext, config);
                // console.log('dataPointSelection event', event);
                // console.log('dataPointSelection chartContext', chartContext);
                // console.log('dataPointSelection config', config.dataPointIndex);
                //find date from config.dataPointIndex
                let date = this.barChartOptions.xaxis.categories[config.dataPointIndex];
                console.log('date', date);
                //get data from transactions where comfirm_date = date and transform data to do
                let transactions = this.transactionsCache.filter(item => item.confirm_date == date);
                //get item from transactions where budget_type = 1
                let budget_type_1 = transactions.filter(item => item.budget_type == 1);
                //get item from transactions where budget_type = 2
                let budget_type_2 = transactions.filter(item => item.budget_type == 2);
                //get item from transactions where budget_type = 3
                let budget_type_3 = transactions.filter(item => item.budget_type == 3);
                //push budget_type_1 to donutSeries
                this.donutSeries = []
                this.donutChartOptions.labels = []
                if(budget_type_1.length > 0){
                    //sum amount from budget_type_1
                    let sum = budget_type_1.reduce((a, b) => {
                        return a + b.amount
                    }, 0);
                    this.donutSeries.push(sum);
                    //this.donutSeries = budget_type_1.amount;
                    this.donutChartOptions.labels[0] = 'บริจาค';
                }
                // //push budget_type_2 to donutSeries
                if(budget_type_2.length > 0){
                    //sum amount from budget_type_2
                    let sum = budget_type_2.reduce((a, b) => {
                        return a + b.amount
                    }, 0);
                    this.donutSeries.push(sum);
                    // this.donutSeries = budget_type_2.amount;
                    this.donutChartOptions.labels[1] = 'ดอกเบี้ย';
                }
                // //push budget_type_3 to donutSeries
                if(budget_type_3.length > 0){
                    //sum amount from budget_type_3
                    let sum = budget_type_3.reduce((a, b) => {
                        return a + b.amount
                    }, 0);
                    this.donutSeries.push(sum);
                    // this.donutSeries = budget_type_3.amount;
                    this.donutChartOptions.labels[2] = 'ซะกาต';
                }
                this.transactions = transactions;
            },
        },
        mounted() {
            this.retreiveReport();
        },
    }
</script>

<style lang="scss" scoped>

</style>