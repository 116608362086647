<template>
    <a class="mt-5 b_title">
    <div class="flex items-center">
      <router-link to="/dashboard/member">
        <span>จัดการผู้ใช้งาน</span>
      </router-link>
      &nbsp;
      <span> / บทบาทสมาชิก</span>
    </div>
  </a>
  
  <!-- content -->
  <div class="flex justify-between w-full rounded-lg oerflow-hidden space-between">
    <div class="w-full p-3 bg-gray-200 rounded-lg">
      ชื่อ สกุล : {{info.mem_name}} {{info.mem_lastname}}
    </div>
  </div>
  
  <div class="flex justify-between w-full rounded-lg oerflow-hidden space-between">
    <div class="w-1/2 p-3 mt-3 mr-1.5 bg-gray-200 rounded-lg">
        <table class="w-full mt-3 mb-3 whitespace-no-wrap">
            <thead>
                <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                    <th class="items-center px-4 py-3">
                        <input type="checkbox" v-model="selectAllToAdd">
                        เลือก
                    </th>
                    <th class="px-4 py-3">role</th>
                    <th class="px-4 py-3">บทบาท</th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                <tr v-for="(item, index) in roles" :key="index" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                    <td class="items-center px-4 py-3">
                        <input type="checkbox" v-model="selectedToadd" :value="item.name">
                    </td>
                    <td class="px-4 py-3 text-sm">
                        {{item.name}}    
                    </td>
                    <td>
                        {{item.name_thai}}
                    </td>
                </tr>
            </tbody>
        </table>
        
        <button @click="addRole()" v-if="selectedToadd.length" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="justify-end px-3 py-1 mb-3 font-bold text-white bg-green-500 rounded hover:bg-blue-700 ">
            บันเทึก
        </button>

        <!-- pagination -->
        <VueTailwindPagination class="mb-3" v-if="roles.length"
            :current="pagination.roles.current_page"
            :total="pagination.roles.total"
            :per-page="pagination.roles.per_page"
            @page-changed="onPageClickRole($event)"
        />
        <!-- \.pagination -->
           
    </div>
    <div class="w-1/2 p-3 mt-3 ml-1.5 bg-gray-200 rounded-lg">
      <table class="w-full mt-3 mb-3 whitespace-no-wrap">
            <thead>
                <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                    <th class="items-center px-4 py-3">
                        <input type="checkbox" v-model="selectAllToDelete">
                        เลือก
                    </th>
                    <th class="px-4 py-3">role</th>
                    <th class="px-4 py-3">บทบาท</th>
                </tr>
            </thead>
            <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                <tr v-for="(item, index) in modelHasRoles" :key="index" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                    <td class="items-center px-4 py-3">
                        <input type="checkbox" v-model="selectedToDelete" :value="item.name">
                    </td>
                    <td class="px-4 py-3 text-sm">
                        {{item.name}}    
                    </td>
                    <td>
                        {{item.name_thai}}
                    </td>
                    <!-- <td class="">
                        <svg class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>    
                    </td> -->
                </tr>
            </tbody>
        </table>
        
        <button @click="deleteRole()" v-if="selectedToDelete.length" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="justify-end px-3 py-1 mb-3 font-bold text-white bg-green-500 rounded hover:bg-blue-700 ">
            บันเทึก
        </button>
        
    </div>
  </div>
  <!-- \.content -->
  
</template>

<script>
  import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
  import http from '@/services/BackendService'
  export default {
    components:{
        VueTailwindPagination 
    },
    mounted(){
      this.retreiveRole()
      this.retreiveMemberInfo()
      this.retreiveModelHasRole()
    },
    data(){
      return{
        disabled: false,
        info: {
          mem_name: '',
          mem_lastname: ''
        },
        roles: [],
        modelHasRoles: [],
        pagination:{
          roles:{
            current_page: 1
          },
          memberHasRoles:{
            current_page: 1
          }
        },
        selectedToadd: [],
        selectAllToAdd: false,
        selectedToDelete: [],
        selectAllToDelete: false,
      }
    },
    watch:{
      selectAllToDelete: function(){
        if(this.selectAllToDelete == true){
          this.modelHasRoles.forEach(item => {
            this.selectedToDelete.push(item.name)
          })
        }else{
          this.selectedToDelete = []
        }
      },
      selectAllToAdd: function(){
        if(this.selectAllToAdd == true){
          this.roles.forEach(item => {
            this.selectedToadd.push(item.name)
          })
        }else{
          this.selectedToadd = []
        }
      }
    },
    methods:{
      deleteRole(){
        this.$Progress.start()
        this.disabled = true
        let data = new FormData()
        data.append('user_id', this.$route.params.id)
        data.append('roles', this.selectedToDelete)
        http.post(`modelHasRole/deleteRole`, data)
          .then(() => {
            this.selectedToDelete = []
            this.selectAllToDelete = false
            this.disabled = false  
            this.retreiveRole()
            this.retreiveModelHasRole()
            this.$Progress.finish()
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.dispatch('authMember/unauthenticateResetting')
            } 
          })
      },
      retreiveModelHasRole(){
        http.get(`modelHasRole/modelHasRole/${this.$route.params.id}`)
          .then(response => {
            this.modelHasRoles = response.data.roles
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.dispatch('authMember/unauthenticateResetting')
            } 
          })
      },
      addRole(){
        this.$Progress.start()
        this.disabled = true
        let data = new FormData()
        data.append('user_id', this.$route.params.id)
        data.append('roles', this.selectedToadd)
        http.post(`modelHasRole/addRole`, data)
          .then(() => {
            this.selectAllToAdd = false
            this.selectedToadd = []
            this.retreiveModelHasRole()
            this.retreiveRole()
            this.$Progress.finish()
            this.disabled = false  
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.dispatch('authMember/unauthenticateResetting')
            } 
            this.disabled = false
            this.$Progress.fail()
          })
      },
      onPageClickRole(event){
        this.$Progress.start()
        http.get(`modelHasRole/roles?page=${event}&&id=${this.$route.params.id}`)
          .then(response => {
            this.roles = response.data.roles.data
            this.pagination.roles = response.data.roles
            this.$Progress.finish()
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.dispatch('authMember/unauthenticateResetting')
            }
            this.$Progress.fail()
          })
      },
      retreiveRole(){
        http.get(`modelHasRole/roles?page=${this.pagination.roles.current_page}&&id=${this.$route.params.id}`)
          .then(response => {
            this.roles = response.data.roles.data
            this.pagination.roles = response.data.roles
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.dispatch('authMember/unauthenticateResetting')
            }  
            this.$Progress.fail()
          })
      },
      retreiveMemberInfo(){
        http.get(`member/info/${this.$route.params.id}`)
          .then(response => {
            this.info = response.data.info
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>