<template>
    <div class="container pt-20 pb-20 pl-4 mx-auto lg:pt-40">
        <div class="max-w-md overflow-hidden bg-white shadow-md lg:mx-10 rounded-xl md:max-w-2xl">
            <div class="md:flex">
            </div>
        </div>

        <h2 class="my-6 text-2xl font-semibold text-gray-700 lg:mx-20 dark:text-gray-200">
            ประวัติการสนับสนุน
        </h2>
        <div class="overflow-hidden rounded-lg shadow-xs lg:mx-20">
            <div class="w-full overflow-x-auto">
                
                <table class="w-full whitespace-no-wrap" >
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th class="px-4 py-3">วัน เดือน ปี</th>
                            <th class="px-4 py-3 text-right">จำนวนเงิน (บาท)</th>
                            <th class="px-4 py-3 text-center">สถานะ</th>
                            <th class="px-4 py-3 text-center">รายละเอียด</th>
                            <!-- <th class="px-4 py-3 text-center">ยกเลิก</th> -->
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in histories" :key="index">
                            <td class="px-4 py-3">
                                <span>{{ item.date  }}</span> <span class="text-sm text-gray-400">{{ item.time }} น.</span>
                            </td>
                            <td class="px-4 py-3 text-sm text-right">
                                {{ item.amount }}
                            </td>
                            <td class="px-4 py-3 text-xs text-center" :class="{'text-orange-300': item.status == 'รอชำระ', 'text-green-600': item.status == 'ชำระแล้ว', 'text-red-600': item.status == 'ยกเลิก', 'text-yellow': item.status == 'ชำระไม่ตรงยอด', 'text-black-600': item.status == 'ไม่ระบุ'}">
                                {{ item.status }}
                            </td>
                            <td class="px-4 py-3 text-sm text-center cursor-pointer">
                                <router-link :to="{name: 'TransactionDetail', params: {'transaction_id': item.id}}">
                                    <i class="fa-solid fa-circle-info"></i>
                                </router-link>
                            </td>
                            <!-- <td class="px-4 py-3 text-sm text-center cursor-pointer">
                                <i v-if="item.status == 'รอชำระ'" @click="cancelTransaction(item.id)" class="fa-solid fa-trash" style="color: red"></i>
                            </td> -->
                        </tr>
                        <tr>
                            <td colspan="5" class="text-center">
                                <p class="mt-2 cursor-pointer" @click="loadmore()" v-if="show_loadmore_text">
                                    โหลดข้อมูลเพิ่มเติม
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
           </div>
       </div>
    </div>
</template>

<script>
    import http from '@/services/FrontendService'
    export default{
        mounted(){
            this.retreiveHistory('firstload')
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        data(){
            return{
                histories: [],
                pagination: {
                    total: 0,
                    per_page: 0,
                    from: 0,
                    to: 0,
                    current_page: 1
                },
                show_loadmore_text: true,
            }
        },
        methods:{
            cancelTransaction(transaction_id){
                //sweet alert confirm
                this.$swal({
                    title: 'ยืนยันการยกเลิก',
                    text: "คุณต้องการยกเลิกการสนับสนุนนี้ใช่หรือไม่",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก'
                }).then((result) => {
                    if (result.isConfirmed) {                       
                        this.$Progress.start()
                        http.delete(`account/deleteTransaction/${transaction_id}`)
                        .then(() => {
                            this.$Progress.finish()
                            this.$swal({
                                title: 'ยกเลิกสำเร็จ',
                                text: "ยกเลิกรายการชำระเรียบร้อย",
                                icon: 'success',
                                confirmButtonText: 'ตกลง'
                            })
                            // .then((result) => {
                            //     if (result.isConfirmed) {
                            //         this.retreiveHistory('firstload')
                            //     }
                            // })
                            //change status to cancel
                            this.histories.forEach((item, index) => {
                                if(item.id == transaction_id){
                                    this.histories[index].status = 'ยกเลิก'
                                }
                            })
                        })
                        .catch(error => {
                            if(error.response.status == 401 || error.response.status == 419){
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                            this.$Progress.fail()
                        })
                        console.log('cancel transaction id: ' + transaction_id)
                    }
                })
            },
            loadmore(){
                this.pagination.current_page++
                this.retreiveHistory('loadmore')
            },
            retreiveHistory(typeLoading){
                this.$Progress.start()
                http.get(`account/paymentHistory?page=${this.pagination.current_page}`)
                .then(response => {
                    if(typeLoading == 'firstload'){
                        this.histories = response.data.data.data
                    }else{
                        this.histories = this.histories.concat(response.data.data.data)
                    }
                    if(this.pagination.current_page == response.data.data.last_page){
                        this.show_loadmore_text = false
                    }
                    this.pagination = response.data.data
                    this.$Progress.finish()
                })
                .catch(error => {
                    if(error.response.status == 401 || error.response.status == 419){
                        this.$store.dispatch('authMember/unauthenticateResetting')
                    }
                    this.$Progress.fail()
                })
            }
        }
    }
</script>