import axios from 'axios'

const backendService = axios.create({
    baseURL: process.env.VUE_APP_URL_API,
    withCredentials: true,
    headers: {
        "Content-type": "multipath/form-data",
        "Accept": "application/json",
    }
})

export default backendService