<template>
    
    <a class="flex items-center justify-between p-4 mt-8 text-sm font-semibold text-purple-100 bg-purple-600 rounded-lg shadow-md focus:outline-none focus:shadow-outline-purple">
        <div class="flex items-center">
            <span> จัดการหมวดหมู่โครงการ</span>
        </div>
    </a>

    <category-search-form></category-search-form>

    <!-- ตารางแสดงหมวดหมู่โครงการ -->
    <category-list></category-list>
    <!-- \.ตารางแสดงหมวดหมู่โครงการ -->

    <category-form></category-form>

    <category-delete-form></category-delete-form>
    
</template>

<script>
    
    // import http from '@/services/BackendService'

    import {mapState} from 'vuex'
    import CategoryDeleteFormVue from './CategoryDeleteForm.vue'
    import CategoryForm from './CategoryForm'
    import CategoryList from './CategoryList'
    import CategorySearchForm from './CategorySearchForm'
    
    export default {

        components:{
            'category-list': CategoryList,
            'category-search-form': CategorySearchForm,
            'category-form': CategoryForm,
            'category-delete-form': CategoryDeleteFormVue,
        },
        
        data(){
            return{
               
            }
        },    
        
        computed:{
            ...mapState("projectCategory", [
                "total"
            ])  
        },

        methods:{
            getProjectCategory(){
                this.$Progress.start();
                this.$store.dispatch('projectCategory/getProjectCategory', {pageNumber: 1})
                    .then(() => {
                        // console.log(response.data)
                        this.$Progress.finish()
                    })
                    .catch( error => {
                        // console.log(error.status)
                        if(error.response.status == 401){
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            }
        },

        mounted(){
            this.getProjectCategory()  
        },
        
    }
</script>

<style lang="scss" scoped>
</style>
