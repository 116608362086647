<template>
    <section>
         <div class="w-full px-4 pt-40 mx-auto lg:mx-auto ">
             <h2 class="mb-2 text-4xl font-semibold leading-tight text-center text-gray-800 capitalize">นโยบายการคืนเงิน</h2>
             
             <div class="mx-4 lg:mx-40">
                <br>                <br>
                

        
            <h3 class="text-xl font-semibold">#การขอเงินคืน</h3>
            ผู้ใช้งานระบบที่สะสางดอกเบี้ย หรือ บริจาค หรือ จ่ายซะกาต สามารถขอเงินคืนได้ ภายใน 7 วัน หลังจากที่ชำระเงินเข้ามาแล้ว
            <br><br>

            <h3 class="text-xl font-semibold">#วิธีขอเงินคืน</h3>
            
1.ผู้ทำรายการบริจาคเขียนอีเมลมาหา CuCi ที่อีเมล์ cuci.pbf@gmail.com พร้อมระบุหัวข้อเรื่อง "ขอยกเลิกการสะสางดอกเบี้ย หรือ บริจาค หรือ จ่ายซะกาต และคืนเงิน"
<br>2.แจ้งหมายเลขการการชำระ หรืออีเมลที่ทำรายการไว้ พร้อมระบุเหตุผลการขอยกเลิกและคืนเงิน
<br>3.มีการตรวจสอบข้อมูลข้อเท็จจริง            
<br><br>

<h3 class="text-xl font-semibold">#ระยะเวลาการคืนเงิน</h3>    
การชำระเงินด้วยการโอนเงินผ่าน QR Code Promptpay หรือบัญชีธนาคาร  CuCi จะขอข้อมูลบัญชี เพื่อทำเรื่องคืนเงินภายใน 15 วันทำการ
<br><br>



       
        <p style="color: #999; font-style: italic;" class="mb-3">ปรับปรุงล่าสุด 1 ธันวาคม 2565</p>
</div>   
         </div>
         <div class="w-full bg-green-100 lg:px-40 lg: lg:mx-auto bgcontent-center">
            
         </div>
     </section>
 </template> 

 <script>
    export default{
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
    }
</script>