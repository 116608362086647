<template>
    <section class="bg-orange-200">
      <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
          <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
              <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">
                  องค์กรของคุณ
              </h1>
          </div>
          <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
              <div  class="flex-1 max-w-4xl m-5">
                  <div class="flex flex-wrap">
                      <div class="sm:p-1 md:p-1 sm:w-full md:w-1/2 lg:w-1/3">
                          <router-link to="/createOrganization">
                            <button type="button" class="inline-flex justify-center px-4 py-2 mx-2 text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"><i class="fa-solid fa-handshake"> </i> เปิดบัญชีองค์ใหม่</button>
                          </router-link>
                      </div>
                      <div class="sm:p-1 md:p-1 sm:w-full md:w-1/2 lg:w-2/3">
                        การเปิดบัญชีองค์กรของท่านสามารถเปิดได้มากกว่าหนึ่งองค์กร 
                          <br> โดยท่านสามารถสลับการใช้ระบบในนามองค์กรเพื่อทำรายการต่างๆ ได้ เช่น การบริจาค การจ่ายซะกาตและการสะสางดอกเบี้ย ขององค์กรท่าน
                      </div>
                  </div>
              </div>
          </div>
          <!-- <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              <div class="px-4 py-3 text-center sm:px-6">
                <router-link to="/createOrganization">
                    <button type="button" class="inline-flex justify-center px-4 py-2 mx-2 text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"><i class="fa-solid fa-handshake"> </i> เปิดบัญชีองค์ใหม่</button>
                </router-link>
              </div>
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                การเปิดบัญชีองค์กรของท่านสามารถเปิดได้มากกว่าหนึ่งองค์กร 
                <br> โดยท่านสามารถสลับการใช้ระบบในนามองค์กรเพื่อทำรายการต่างๆ ได้ เช่น การบริจาค การจ่ายซะกาตและการสะสางดอกเบี้ย ขององค์กรท่าน
            </dd>
          </div> -->
          <div v-for="(item, index) in organizations" :key="index" class="mt-2 ml-10 mr-10 rounded-lg rflex bg-gray-50 dark:bg-gray-900">
            <router-link :to="{name: 'OrganizationProfile', params:{id: item.code}}" class="flex">
              <div  class="flex-1 max-w-4xl m-5">
                  <div class="flex flex-wrap">
                      <div class="items-center justify-center sm:p-1 md:p-1 sm:w-full md:w-1/2 lg:w-1/3">
                        <img :src="`${image_path}images/organization/thumbnail/${item.logo}`" class="object-contain w-40 h-40 mx-2" />
                      </div>
                      <div class="sm:p-1 md:p-1 sm:w-full md:w-1/2 lg:w-2/3">
                        <p class="text-lg">
                          {{item.name}}
                          <!-- <span class="text-sm">| Super Administrator </span> -->
                        </p>
                        <p class="text-sm">
                          สถานะ : Active | Email : {{item.email}} | Tel. {{item.telephone}}
                        </p>
                        <p class="text-xs">
                          วันที่สร้าง {{formatDate(item.created_at)}} เวลา {{formatTime(item.created_at)}} น. | แก้ไขล่าสุด {{formatDate(item.updated_at)}} เวลา {{formatTime(item.updated_at)}}
                          เวลา 16:04:11 น.
                        </p>
                      </div>
                  </div>
              </div>
              <!-- <a href="#" > -->
                <!-- <div class="flex-1 max-w-4xl m-5">
                  <img :src="`${image_path}images/organization/thumbnail/${item.logo}`" class="object-contain w-16 h-16 mx-2" />
                  <div class="text">
                    <p class="text-lg">
                      {{item.name}}
                      <span class="text-sm">| Super Administrator </span>
                    </p>
                    <p class="text-sm">
                      สถานะ : Active | Email : {{item.email}} | Tel. {{item.telephone}}
                    </p>
                    <p class="text-xs">
                      วันที่สร้าง {{formatDate(item.created_at)}} เวลา {{formatTime(item.created_at)}} น. | แก้ไขล่าสุด {{formatDate(item.updated_at)}} เวลา {{formatTime(item.updated_at)}}
                      เวลา 16:04:11 น.
                    </p>
                  </div>
                </div> -->
              <!-- </a> -->
            </router-link>
          </div>
      </div> 
    </section>
</template>

<script>
  import http from '@/services/AuthService';
  import moment from 'moment'
  export default{
    mounted(){
      this.retreiveOrganization()
    },
    data(){
      return{
        organizations: [],
        image_path: '',
      }
    },
    methods:{
      retreiveOrganization(){
        http.get(`organization/myOrganization`)
          .then(response => {
            this.organizations = response.data.data
            this.image_path = process.env.VUE_APP_URL_COOKIE
          })
          .catch(error => {
            if(error.response.status == 401){
              this.$store.dispatch('authMember/unauthenticateResetting')
            } 
          })
      },
      formatDate(value){
          if(value){
              return moment(String(value)).format('DD/MM/YYYY')
          }
      },
      formatTime(value){
        if(value){
          return moment(String(value)).format('HH:mm')
        }
      }
  }
}    
</script>
