<template>
    <div class="flex items-center min-h-screen p-6 py-40 bg-gray-50 dark:bg-gray-900">
      <div  class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="flex items-center justify-center p-3 sm:p-6 md:w-full">
              <h1 class="mb-4 text-xl font-semibold text-gray-700 te dark:text-gray-200">
                กำลังเข้าสู่ระบบ...
              </h1>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import http from '@/services/AuthService'
    export default {
        mounted(){
            this.queryString()
            if(!localStorage.getItem('lastVisit')){
                this.lastVisit = 'Home'
            }else{
                this.lastVisit = localStorage.getItem('lastVisit')
            }
        },
        data(){
            return{
                code: '',
                state: '',
                token: '',
                profile: '',
                // access_token: '',
                // id_token: '',
                // token_type: '',
                // refresh_token: '',
                lastVisit: '',
            }
        },
        methods:{
            queryString(){
              const urlSearchParams = new URLSearchParams(window.location.search);
              const params = Object.fromEntries(urlSearchParams.entries());
              // console.log(params)
              if(params == {}){
                  this.code = null
                  this.state = null
              }else{
                  this.code = params.code
                  this.state = params.state
                  this.getLineToken()
                  // this.register_status = false
                  // this.user = params.state
              }
            },
            getLineToken(){
                this.$Progress.start()
                http.get(`lineToken/${this.code}/${this.state}`)
                    .then(response => {
                        let responseData = response.data
                        this.responseData = responseData
                        let isError = Object.keys(responseData).includes('error')
                        if(isError){
                            this.$router.push({ name: 'Login' });
                        }else{
                            let id_token = response.data.id_token
                            let access_token = response.data.access_token
                            let refresh_token = response.data.refresh_token
                            // this.lineProfile(id_token, access_token, refresh_token)
                            // this.getProfile(access_token)
                            this.loginOrRegister(access_token, id_token, refresh_token)
                        }
                        this.$Progress.finish()
                    })
                    .catch(() => {
                        this.$router.push({name: 'Login'})
                        this.$Progress.fail()
                    })
            },
            loginOrRegister(access_token, id_token, refresh_token){
                http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
                    .then(() => {
                        http.post('lineLoginOrRegister', {
                            access_token: access_token,
                            id_token: id_token,
                            refresh_token: refresh_token
                        })
                            .then(response => {
                                this.$store.dispatch('authMember/socialSignIn', response.data)
                                this.$store.dispatch("authMember/retreiveCart")
                                this.$Progress.finish()
                                // this.$router.push({name: this.lastVisit})
                                let lastVisit = localStorage.getItem('lastVisit')
                                if(lastVisit == 'projectDetail'){
                                    this.$router.push({ name: 'ProjectDetail', params: { id: localStorage.getItem('project_id') } });
                                }else if(lastVisit == null){
                                    this.$router.push({ name: 'Home' });
                                }else{
                                    this.$router.push({ name: lastVisit });
                                }
                            })
                            .catch(() => {
                                this.$router.push({name: 'Login'})
                                this.$Progress.fail()
                            })
                    })
            },
            lineProfile(id_token, access_token, refresh_token){
                http.get(`profileFormIdToken/${id_token}/${access_token}/${refresh_token}`)
                    .then(response => {
                        this.profile = response.data
                    })
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>