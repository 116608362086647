<template>
    <section class="bg-orange-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">
                    บัญชีของคุณ
                </h1>
            </div>
            <!-- <div class="flex ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <b>แก้ไขข้อมูลของคุณ </b>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fa-regular fa-user"></i>&nbsp;&nbsp;
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <router-link to="/profile">
                                <b>โปรไฟล์ </b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fa-regular fa-pen-to-square"></i>&nbsp;&nbsp;
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <router-link to="/accountSetting">
                                <b>ตั้งค่าบัญชี</b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fa-solid fa-list-ul"></i>
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <router-link to="/cleanupHistory">
                                <b>ประวัติการสะสางดอกเบี้ย </b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fa-solid fa-list-ul"></i>&nbsp;&nbsp;
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <router-link to="/paymentHistories">
                                <b> ประวัติการชำระ</b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fas fa-sitemap"></i>&nbsp;&nbsp;
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <router-link to="oganizationManagement">
                                <b>จัดการข้อมูลองค์กร</b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fas fa-gift"></i>&nbsp;&nbsp;
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <router-link to="/giftVoucher">
                                <b>Gift Voucher</b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <i class="fa-regular fa-file-lines"></i>&nbsp;&nbsp;
                        <div class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <b>ยื่นโครงการ</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900" v-if="!is_verified && !is_send_email">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div @click="!disabled ? verifyEmail() : stopAction()" class="sm:p-1 md:p-1 sm:w-full md:w-4/5" :class="!disabled ? 'cursor-pointer': 'cursor-wait'">
                            <b>{{progressText}}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900" v-if="is_send_email">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div @click="!disabled ? verifyEmail() : stopAction()" class="sm:p-1 md:p-1 sm:w-full md:w-4/5">
                            <b>เราได้ส่งลิงค์เพื่อยืนยันบัญชีผู้ของท่านแล้ว กรุณาตรวจสอบอีเมล</b>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex mt-2 ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900" v-if="is_send_email">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div @click="!disabled ? verifyEmail() : stopAction()" class="sm:p-1 md:p-1 sm:w-full md:w-4/5" :class="!disabled ? 'cursor-pointer': 'cursor-wait'">
                            <b>ส่งลิงค์ยืนยันอีกครั้ง</b>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <p>{{is_verified}}</p> -->
        </div> 
    </section>
</template>

<script>
    import http from '@/services/FrontendService';
    export default {
        mounted(){
            this.isVerifiedEmail()
        },
        data(){
            return{
                progressText: 'ยืนยันอีเมล',
                disabled: false,
                is_verified: Boolean,
                is_send_email: false,
            }
        },
        methods:{
            retreivePermission(){
                http.get('memberPermission/directPermissions')
                    .then(response => {
                        console.log(response.data)
                    })
            },
            isVerifiedEmail(){
                http.get('member/isVerifiedEmail')
                    .then(response => {
                        // console.log(response.data)
                        if(response.data.isVerified == true){
                            this.is_verified = true
                        }else{
                            this.is_verified = false
                        }
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            stopAction(){
                
            },
            verifyEmail(){
                this.$Progress.start()
                this.progressText = 'กำลังดำเนินการ...'
                this.disabled = true
                this.is_send_email = false
                http.post('email/verification-notification')
                    .then(() => {
                        // console.log(response.data)
                        this.progressText = 'ยืนยันอีเมล'
                        // this.$router.push({name: 'EmailVerification'})
                        this.$Progress.finish()
                        this.disabled = false
                        this.is_send_email = true
                    })  
                    .catch(() => {
                        // console.log(error.response.data.errors) 
                        this.progressText = 'ยืนยันอีเมล'  
                        this.$Progress.fail()
                        this.disabled = false
                    })  
            }
        }   
    }
</script>
