<template>
    <div class="flex items-center min-h-screen p-6 py-40 bg-gray-50 dark:bg-gray-900">
      <div  class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="flex items-center justify-center p-3 sm:p-6 md:w-full">
              <h1 class="mb-4 text-xl font-semibold text-gray-700 te dark:text-gray-200">
                กำลังออกจากสู่ระบบ...
              </h1>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        mounted(){
            this.signOut()
        },
        data(){
            return{
                
            }
        },
        methods:{
            signOut(){
                this.$Progress.start()
                this.$store.dispatch('authMember/signOut')
                    .then(() => {
                        this.$router.push({name: 'Login'})
                        // localStorage.removeItem('cuciMember')
                        // document.cookie = 'role=; Max-Age=0;';  
                        // document.cookie = 'permission=; Max-Age=0;';  
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            }
        }     
    }
</script>