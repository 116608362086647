<template>
    <div class="w-full overflow-hidden rounded-lg shadow-xs">
        <div class="w-full overflow-x-auto">
            <table class="w-full mb-3 whitespace-no-wrap">
                <thead>
                    <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th class="px-4 py-3">ชื่อหมวดหมู่ (ทั้งหมด : {{total}})</th>
                        <th class="px-4 py-3">โดย</th>
                        <th class="px-4 py-3">Action</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <tr v-for="category in categories" :key="category.id" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                        <td class="px-4 py-3 text-sm" width="70%">
                            <div class="flex items-center text-sm">
                                <div class="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                                    <img class="object-cover w-full h-full rounded-full" :src="category.icon" alt="" loading="lazy"/>
                                    <div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                </div>
                                <div>
                                    <p class="font-semibold">{{ category.name }}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="flex items-center text-sm">
                                <div class="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                                    <img class="object-cover w-full h-full rounded-full" :src="category.user.avatar" alt="" loading="lazy"/>
                                    <div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                </div>
                                <div>
                                    <p class="font-semibold">{{ category.user.name }} {{ category.user.lastname }}</p>
                                    <p class="text-xs text-gray-600 dark:text-gray-400">Updated {{ formatDate(category.updated_at) }}</p>
                                </div>
                            </div>
                        </td>
                        <td class="px-4 py-3">
                            <div class="flex items-center space-x-4 text-sm">
                                <button @click="openFormModal(category.id)" class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray" aria-label="Edit">
                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                    </svg>
                                </button>
                                <button @click="openDeleteForm(category.id)" class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray" aria-label="Delete">
                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>   
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- pagination -->
            <VueTailwindPagination
                :current="currentPage"
                :total="total"
                :per-page="perPage"
                @page-changed="onPageClick($event)"
            />
            <!-- \.pagination -->

        </div>
    </div>
</template>

<script>

    import '@ocrv/vue-tailwind-pagination/dist/style.css'
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    import { mapState } from 'vuex'
    import moment from 'moment'

    export default {

        name: 'category-list',

        components:{
            VueTailwindPagination
        },
        
        computed:{
            ...mapState("projectCategory", [
                "categories",
                "currentPage",
                "perPage",
                "total",
                "keyword"
            ])  
        },
        
        methods:{

            openDeleteForm(id){
                this.$store.dispatch("projectCategory/showDeleteForm", id)
            },

            openFormModal(id){
                this.$Progress.start()
                this.$store.dispatch("projectCategory/showProjectCategory", id) 
                    .then(() => {
                        this.$Progress.finish()
                    })
                    .catch(() => {
                        this.$Progress.fail()
                    })
            },

            //สร้างฟังก์ชันสำหรับจัดรูปแบบวันที่
            formatDate(value){
                if(value){
                    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
                }
            },

            //เปลี่ยนหน้าแสดงผลข้อมูลสินค้า
            onPageClick(event){
                if(this.keyword == ""){
                    this.$Progress.start()
                    this.$store.dispatch("projectCategory/getProjectCategory", {pageNumber: event})
                    .then(() => {
                        this.$Progress.finish()
                    })
                    .catch(() => {
                        this.$Progress.fail() 
                    })
                }else{
                    this.$Progress.start()
                    this.$store.dispatch("projectCategory/getProjectCategorySearch", {pageNumber: event})
                        .then(() => {
                            this.$Progress.finish()  
                        })
                        .catch(() => {
                            this.$Progress.fail()
                        })
                }
                
            }
        }
        
    }   
</script>

<style lang="scss" scoped>

</style>