<template>
    <section class="bg-indigo-200">
        <div class="container pt-40 pb-20 mx-auto text-center">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">สะสางดอกเบี้ย</h2>
                <p>เลือกประเภทการสะสาง</p>
                
            </div>
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <router-link to="/cleanupFor" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/m1.jpg" @click="cleanupSelected(1)" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900" @click="cleanupSelected(1)">เข้ากองกลาง</h5>
                            <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                </div>
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <router-link to="/cleanupFor" @click="cleanupSelected(2)" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/m2.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900" @click="cleanupSelected(2)">สาธารณประโยชน์</h5>
                            <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                </div>
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <router-link to="/cleanupFor" @click="cleanupSelected(3)" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/m3.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900" @click="cleanupSelected(3)">การศึกษา</h5>
                            <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                </div>
                <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
                    <router-link to="/cleanupFor" @click="cleanupSelected(4)" class="block text-left hover:opacity-75"> 
                        <img src="@/assets/img/m4.jpg" class="object-cover w-full h-64"/> 
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900" @click="cleanupSelected(4)">ฟากิรและมิสกิน</h5>
                            <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                </div>
            </div>
            <!-- <a href="#" class="inline-block px-6 py-2 text-white bg-green-600 border-2 border-green-600 rounded-md hover:text-green-600 hover:bg-green-200">ดูทั้งหมด</a> -->
        </div> 
    </section>
</template>

<script>
    export default {
        methods:{
            cleanupSelected(cleanupType){
                /* รหัสประเภทกองทุนสะสาง
                1: เข้ากองกลาง
                2: สาธารณประโยชน์
                3: การศึกษา
                4: ฟากิรและมิสกิน
                */
                localStorage.setItem('cuciCleanupTypeSelected', cleanupType)   
            }
        }   
    }
</script>