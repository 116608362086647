<template>
    <div class="flex items-center min-h-screen p-6 py-40 bg-gray-50 dark:bg-gray-900">
      <div
        class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
      >
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:hidden"
              src="@/assets/img/m7.jpg"
              alt="Office"
            />
            <img
              aria-hidden="true"
              class="hidden object-cover w-full h-full dark:block"
              src="@/assets/img/m7.jpg"
              alt="Office"
            />
          </div>
          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div class="w-full">
              <h1 class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                เข้าสู่ระบบ
              </h1>
              <form @submit.prevent="login()">

                <label class="block mb-2 text-sm text-gray-700" for="fullname">อีเมล</label>
                <input type="text"
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" 
                  :class="{'border-red-500': serverError.username}"
                  v-model="username">
                <div v-if="serverError.username" class="mt-2 text-sm text-red-500">
                    {{serverError.username[0]}}
                </div>

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="username">รหัสผ่าน</label>
                <input type="password"
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" 
                  :class="{'border-red-500': serverError.password}"
                  v-model="password">
                <div v-if="serverError.password" class="mt-2 text-sm text-red-500">
                    {{serverError.password[0]}}
                </div>
                
                <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700" >
                  เข้าสู่ระบบ
                </button>
              </form>

              <button @click="useAuthProvider('google', null)" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-orange-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-orange-500" >
                <i class="fab fa-google"></i> เข้าสู่ระบบด้วยบัญชี google
              </button>
              
              <button @click="lineLogin()" type="submit" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-green-600 hover:bg-green-500" >
                <i class="fab fa-line"></i> เข้าสู่ระบบด้วยบัญชี LINE
              </button>
              <!-- <button @click="useAuthProvider('google', null)">auth Google</button> -->
              
              <p class="mt-4">
                <router-link to="/forgotPassword"
                  class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                >
                  ลืมรหัสผ่าน
                </router-link>
              </p>
              <p class="mt-1">
                <router-link to="/register" class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline">
                  สมัครสมาชิก
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

// Button M1
import { Providers} from 'universal-social-auth'
// Button M2
import { Google} from 'universal-social-auth'
import http from '@/services/AuthService'

export default {
  mounted(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    if(!localStorage.getItem('lastVisit')){
      this.lastVisit = 'Home'
    }else{
      this.lastVisit = localStorage.getItem('lastVisit')
    }
  },
  data() {
    return {
      username: "",
      password: "",
      serverError: "",
      disabled: false,

      //google login
      responseData: {},
      hash: '',
      data: {},
      fauth: false,
      // Github,
      // Twitch,
      // Facebook,
      Google,
      // Twitter,

      lastVisit: '',
      
    };
  },

  methods: {

    lineLogin(){
      this.disabled = true
      this.$Progress.start()
      http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
        .then(() => {
          http.get(`lineLogin`)
            .then(response => {
              window.location.href = response.data.link
              this.$Progress.finish()
              this.disabled = false
            })
        })
    },

    useAuthProvider (provider, proData) {
      this.disabled = true
      http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
        .then(() => {
          const pro = proData
          const ProData = pro || Providers[provider]
          this.disabled = true
          this.$Oauth.authenticate(provider, ProData)
            .then((response) => {
              // console.log(response)
              const rsp = response
              if (rsp.code) {
                this.responseData.code = rsp.code
                this.responseData.provider = provider
                this.useSocialLogin()
                // this.$Progress.finish()
              }
            }).catch(() => {
              // console.log(err)
              // this.$Progress.fail()
          })
        })
    },
    useSocialLogin(){
      // otp from input Otp form
      // hash user data in your backend with Cache or save to database
      // let lastVisit = localStorage.getItem('lastVisit')
      const pdata = { code: this.responseData.code, otp: this.data.tok, hash: this.hash }
      this.$Progress.start()
      http.post(`socialAuth/google/signup/${this.responseData.provider}`, pdata)
        .then(response => {
          this.$store.dispatch('authMember/socialSignIn', response.data)
          this.myCart()
          // console.log(response.data)
          this.serverError = "";
          this.disabled = false
          this.$Progress.finish()
          
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "กำลังเข้าสู่ระบบ...",
          }).then(() => {
            this.disabled = false;
            this.$Progress.finish();
          });
          let lastVisit = localStorage.getItem('lastVisit')
          if(lastVisit == 'projectDetail'){
            this.$router.push({ name: 'ProjectDetail', params: { id: localStorage.getItem('project_id') } });
          }else if(lastVisit == null){
            this.$router.push({ name: 'Home' });
          }else{
            this.$router.push({ name: lastVisit });
          }
          // console.log(localStorage.getItem('lastVisit'))
      }).catch(() => {
        this.disabled = false
        this.$Progress.fail()
      })
    },
    submitForm() {
      this.$store
        .dispatch("authMember/signIn", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.serverError = "";
          this.myCart()
          
          const Toast = this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "กำลังเข้าสู่ระบบ...",
          }).then(() => {
            this.disabled = false;
            this.$Progress.finish();
            this.$router.push({ name: this.lastVisit });
          });
          
        })
        .catch((error) => {
          this.disabled = false;
          this.$Progress.fail();
          this.serverError = error.response.data.errors;
        });
    },
    login(){
      this.disabled = true
      this.$Progress.start()
      http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
        .then(() => {
          this.submitForm()
        })
    },
    myCart(){
      this.$store.dispatch("authMember/retreiveCart")
    }
  },
};
</script>

