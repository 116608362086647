<template>
    <section class="bgcc">
      <div class="container pt-20 mx-auto">
        <div class="flex flex-wrap items-center mt-3">
          <div class="order-first w-full p-4 lg:w-7/12 lg:order-first">
            <h1 class="mt-6 mb-4 text-4xl font-semibold leading-tight text-gray-800 capitalize">โครงการ {{project.name}}</h1>
            <h3>งบประมาณที่ต้องการ {{showMoney(project.budget)}} บาท</h3>
                <div class="items-center justify-center p-3 sm:p-6 md:w-full">
                    <form @submit.prevent="submitForm()">
                        <div class="content-center ">
                            <label class="mt-3 mb-3 font-semibold dark:text-gray-200 " for="ribaAmount">ระบุจำนวนเงินที่ต้องการบริจาค</label>
                            <input v-model="money"
                                :class="{'border-red-500': serverError.money}"
                                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                type="number" step="any"/>
                            <div v-if="serverError.money" class="mt-1 text-sm text-red-500">
                                {{serverError.money[0]}}
                            </div>
                        </div>  
                        <button class="mt-3 btncc-riba" 
                            :class="{'opacity-50 cursor-not-allowed': disabled}"
                            :disabled="disabled"> 
                            บริจาค
                        </button>
                    </form>
                </div>
            </div>
            <div class="order-last w-full p-4 lg:w-5/12 lg:order-last">
                <div class="flex flex-col md:flex-row">
                    <div class="flex items-center justify-center p-3 sm:p-6 md:w-full">
                        <img src="@/assets/img/bg_home.png" class="w-full" alt="Smart Inventory System" width="50%" height="50%"/>     
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
</template>

<script>
    import http from '@/services/FrontendService'
    import filter from '@/helpers/filter.js'
    export default {
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.retreiveProjectInfo()
        },
        data(){
            return{
                project: '',
                money: '',
                serverError: '',
                disabled: false,
                project_id: this.$route.params.id,
            }
        },
        methods:{
            submitForm(){
                this.disabled = true
                this.$Progress.start()
                http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
                    .then(() => {
                        let data = new FormData()
                        data.append('project_id', this.project_id)
                        data.append('money', this.money)
                        http.post('donate/donate', data)
                            .then(() => {
                                this.disabled = false
                                this.$Progress.finish()
                                this.serverError = ''
                                localStorage.setItem('donateAmount', this.money)
                                this.$router.push({name: 'DonateCheckout', params:{id: this.project_id}})
                            })
                            .catch(error => {
                                this.serverError = error.response.data.errors
                                this.disabled = false
                                this.$Progress.fail()
                                if(error.response.status == 401){
                                    this.$store.dispatch('authMember/unauthenticateResetting')
                                }
                            })
                    })
            },
            retreiveProjectInfo(){
                this.$Progress.start()
                http.get(`frontendProject/projectInfo/${this.project_id}`)
                    .then(response => {
                        this.project = response.data.project
                        this.$Progress.finish()
                    })
                    .catch(() => {
                        this.$Progress.fail()
                    })
            },
            showMoney(money){
                return filter.numberFormat(money, '0.00')
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>