<template>
    <section class="bg-orange-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">ประวัติการบริจาค</h1>
            </div>
            <div class="flex ml-10 mr-10 rounded-lg bg-gray-50 dark:bg-gray-900">
                <div class="flex-1 max-w-4xl m-5">
                    <div v-for="(item, index) in histories" :key="index" class="flex flex-row">
                        <div class="w-3/6 mt-2 text-left">{{item.name}}</div>
                        <div class="w-2/6 mt-2 text-right">{{item.amount}} บ.</div>
                        <div class="w-2/6 mt-2 text-right">
                            <span v-if="item.status == 1">กำลังตรวจสอบ</span>
                            <span v-else-if="item.status == 2">ชำระเเล้ว</span>
                            <span v-else-if="item.status == 3">ยกเลิก</span>
                            <span v-else>ชำระไม่ตรงยอด</span>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <div v-if="loadmore_show" class="w-full mt-2 text-center cursor-pointer" @click="loadMore()">โหลดข้อมูลเพิ่ม</div>
                    </div>
                </div>
            </div>
        </div> 
    </section>
</template>

<script>
    import http from '@/services/FrontendService'
    export default {
        mounted(){
            this.retreiveHistories()       
        },
        data(){
            return{
                histories: [],
                pagination:{
                    current_page: 1,
                },
                next_page: 1,
                loadmore_show: false
            }
        },
        methods:{
            loadMore(){
                this.$Progress.start()
                http.get('donate/histories?page='+this.next_page)
                    .then(response => {
                        let histories = response.data.histories.data
                        histories.forEach(element => {
                            this.histories.push(element)
                        });
                        if(response.data.histories.next_page_url == null){
                            this.loadmore_show = false
                        }else{
                            this.loadmore_show = true
                        }
                        this.next_page = this.next_page+1 
                        this.$Progress.finish()   
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveHistories(){
                this.$Progress.start()
                http.get('donate/histories?page='+this.pagination.current_page)
                    .then(response => {
                        this.histories = response.data.histories.data
                        this.next_page = this.next_page+1
                        if(response.data.histories.next_page_url == null){
                            this.loadmore_show = false
                        }else{
                            this.loadmore_show = true
                        }
                        this.$Progress.finish()   
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>