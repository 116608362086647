<template>
    <div class="flex items-center justify-between my-8">
        
        <!-- ฟอร์มสำหรับค้าหา -->
        <div class="flex justify-center flex-1 lg:mr-32">
            <div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
                <div class="absolute inset-y-0 flex items-center pl-2">
                    <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                </div>
                <form @submit.prevent="submitSearchForm">
                    <input
                        v-model="search_keyword"
                        class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-200 border-0 rounded-md"
                        type="text"
                        placeholder="ป้อนหมวดหมู่โครงการ"
                        aria-label="Search"
                    />
                    <button type="submit" class="hidden">Submit</button>
                </form>
            </div>

            <div>
                <button :disabled="searchDisabled" :class="{'opacity-50 cursor-not-allowed': searchDisabled}" @click="submitSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                    <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    <span>ค้นหา</span>
                </button>
            </div>

            <div>
                <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" @click="resetSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-700 focus:outline-none focus:shadow-outline-purple">
                    <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    <span>ล้าง</span>
                </button>
            </div>
        </div>
        <!-- \.ฟอร์มสำหรับค้าหา -->

        <button @click="openFormModal" class="flex items-center justify-between px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
            <svg class="w-4 h-4 mr-2 -ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            <span>เพิ่ม</span>
        </button>
        
    </div>   
</template>

<script>
import { mapState } from 'vuex'
    export default {
        
        name: 'category-search-form',

        data(){
            return{
                search_keyword: '',
                searchDisabled: true,
                disabled: false,
            }
        },

        watch: {
            search_keyword: function(){
                this.$store.dispatch("projectCategory/changeKeyword", this.search_keyword)
                if(this.search_keyword == ""){
                    this.searchDisabled = true
                }else{
                    this.searchDisabled = false
                }
            },
        },

        computed:{
            ...mapState("projectCategory", [
                "showFormModal"
            ])
        },

        methods:{
            openFormModal(){
                this.$store.dispatch("projectCategory/openFormModal")
            },
            resetSearchForm(){
                this.$Progress.start()
                this.search_keyword = ''
                this.disabled = true
                this.$store.dispatch("projectCategory/changeKeyword", '')
                this.$store.dispatch("projectCategory/getProjectCategory", {pageNumber: 1})
                    .then(() => {
                        this.$Progress.finish()
                        this.disabled = false
                    })
                    .catch(() => {
                        this.$Progress.fail()
                        this.disabled = false 
                    })
            },
            submitSearchForm(){
                // console.log(this.keyword)  
                if(this.search_keyword == ""){
                    this.$swal.fire("ป้อนชื่อหมวดหมู่โครงการที่ต้องการค้นหาก่อน")
                }else{
                    this.$Progress.start()
                    this.$store.dispatch('projectCategory/submitSeachForm', {keyword: this.search_keyword}) 
                        .then(() => {
                            this.$Progress.finish()
                        })
                        .catch(() => {
                            this.$Progress.fail()   
                    }) 
                }
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>