<template>

  <!-- menu -->
  <div> 
    <h1 class="inline mx-2 ">ข้อมูลการบริจาค</h1> 
    <h1 class="inline mx-2 cursor-pointer" @click="changeTransactionStatus(1)" :class="{'textccactive hover:bg-orange-500 hover:border-x-2 hover:text-white': transactionStatus == 1}">รายการใหม่</h1>  
    <h1 class="inline mx-2">|</h1>   
    <h1 class="inline mx-2 cursor-pointer" @click="changeTransactionStatus(2)" :class="{'textccactive hover:bg-orange-500 hover:border-x-2 hover:text-white': transactionStatus == 2}">รายการตรวจสอบแล้ว</h1>  
    <h1 class="inline mx-2">|</h1>   
    <h1 class="inline mx-2 cursor-pointer" @click="changeTransactionStatus(3)" :class="{'textccactive hover:bg-orange-500 hover:border-x-2 hover:text-white': transactionStatus == 3}">ข้อมูลไม่ถูกต้อง</h1>
  </div>
  <!-- \.menu -->

  <!-- talble -->
  <div class="w-full overflow-hidden rounded-lg shadow-xs">
    <div class="w-full overflow-x-auto">
      <table class="w-full whitespace-no-wrap">
        <thead>
          <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
            <th class="px-4 py-3">วันเดือนปี</th>
            <th class="px-4 py-3">เวลา</th>
            <th class="px-4 py-3">จำนวนเงิน</th>
            <th class="px-4 py-3">User id</th>
            <th class="px-4 py-3">ช่องทางการชำระ</th>
            <th class="px-4 py-3">ยืนยันข้อมูล</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
          <tr v-for="(item, index) in transactions" :key="index" class="text-gray-700 dark:text-gray-400">
            <td class="px-4 py-3">
              <div class="flex items-center text-sm">
                <div>
                  <p class="text-xs text-gray-600 dark:text-gray-400">
                    {{ formatDate(item.transfer_date) }}
                  </p>
                </div>
              </div>
            </td>
            <td class="px-4 py-3 text-sm">
              {{ item.transfer_time }}
            </td>
            <td class="px-4 py-3 text-xs">
              <span class="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
                {{ showMoney(item.amount) }} บาท
              </span>
            </td>
            <td class="px-4 py-3 text-sm">{{ item.mem_name }} {{ item.mem_lastname }}</td>
            <td class="px-4 py-3 text-sm">บัญชีธนาคาร</td>
            <td class="px-4 py-3 text-sm">
              <div class="flex items-center space-x-4 text-sm">
                <button @click="openConfirmModal()" class="flexbtncc-og">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                    />
                  </svg>
            
        </button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- \.talble -->

  <!-- modal from -->
  <div v-if="show == true" class="fixed left-0 flex items-center justify-center w-full h-full pt-2 mx-auto modal">
    <div class="absolute w-full h-full bg-gray-500 opacity-70 modal-overlay"></div>
    <div class="z-50 p-2 pt-10 mx-auto overflow-y-auto bg-white rounded shadow-lg h-5/5 modal-container md:max-w-xl">
      <!-- Header -->
      <div class="flex items-center justify-center w-full h-auto">
        <div class="flex items-start w-full h-auto py-1 text-xl font-bold text-emerald-800">
          <span class="text-center align-middle">รายละเอียดการบริจาค</span>
        </div>
        <div @click="closeConfirmModal()" class="flex justify-center w-2/12 h-auto text-white cursor-pointer">
          <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linejoin="round" class="feather feather-x">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <!--Header End-->
      </div>
      <!-- Modal Content-->
      <div class="w-auto h-auto mb-2">
        <form ref="ribaApprove" enctype="multipart/form-data">
          <div class="w-full mx-auto sm:w-4/5 xl:w-5/5">
            <!-- ***** -->
            <div class="sm:flex">
              <div class="sm:shrink-0">
                <img class="object-cover w-full h-full md:h-full md:w-80" src="@/assets/img/slip.png" alt="Man looking at item at a store"/>
              </div>
              <div class="p-4">
                <div class="text-sm font-semibold tracking-wide text-orange-500 uppercase">
                  ข้อมูลที่ส่งมา
                </div>
                <h1 class="textcc-sm">วันเดือนปี: 10/01/2565</h1>
                <p class="textcc-sm">เวลา: 15:16:00</p>
                <p class="textcc-sm">จำนวนเงิน: 331.69 บาท</p>
                <p class="textcc-sm">ช่องทางธนาคาร แนบสลิป</p>
                <div class="grid gap-4">
                  <div class="justify-center col-span-1">
                    <button type="submit" class="mt-5 btncc-riba focus:shadow-outline-purple">
                      <span>ยืนยันข้อมูล</span>
                    </button>
                    <button v-if="!editMode" type="button" class="mt-5 btncc-nothing focus:outline-none focus:shadow-outline-purple">
                      ไม่มีข้อมูล
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- \.modal from -->

</template>

<script>
  import http from '@/services/AuthService'
  import filter from '@/helpers/filter'
  import moment from 'moment'
  export default{
    name: 'item-list',
    mounted(){
      this.retreiveTransaction()
    },
    data() {
      return {
        show: false,
        transactions: [],
        transactionStatus: 1,
      };
    },
    methods: {
      retreiveTransaction(){
        this.$Progress.start()
        http.get(`donateTransaction/bankingTransaction/${this.transactionStatus}`)
          .then(response => {
            this.transactions = response.data.data.data
            this.$Progress.finish()
          })
          .catch(error => {
            if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
              this.$store.dispatch('authMember/unauthenticateResetting')
            }  
            this.$Progress.fail()
          })
      },
      changeTransactionStatus(transactionStatus){
        this.transactionStatus = transactionStatus
        this.retreiveTransaction()
      },
      openConfirmModal(){
        this.show = true  
      },
      closeConfirmModal(){
        this.show = false
      },
      showMoney(money){
        return filter.numberFormat(money, '0,0.00')
      },
      formatDate(value){
        if(value){
          return moment(String(value)).format('DD/MM/YYYY')
        }
      },
      formatTime(value){
        if(value){
          return moment(String(value)).format('DD/MM/YYYY h:mm:ss')
        }
      },
    }
  }
</script>

<style>
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }


/* The Modal (background) */
  .modal {
    transition: opacity 0.25s ease;
  }
  body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
  }
</style>
