<template>
    <section class="bg-indigo-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">สะสางดอกเบี้ยด้วย QR code</h1>
                <img :src="`data:image/png;base64, ${qrcode}`" alt="" srcset="">
                <p class="flex items-center mt-3">{{money}} บาท</p>
            </div>
        </div> 
    </section>
</template>

<script>
    import http from '@/services/FrontendService'
    export default {
        data(){
            return{
                cref: localStorage.getItem('cref'),
                qrcode: '',
                money: '',
                timer: '',
            }
        },
        beforeCreate(){
            if(!localStorage.getItem('cref')){
                this.$router.push({name: 'CleanupDetail'})
            }
        },
        computed:{
            isLogedIn(){
                return this.$store.getters['authMember/logedIn']
            }
        },
        created(){
            if(this.isLogedIn){
                this.signinQrPayment()
            }else{
                this.unsigninQrPayment()    
            }
        },
        methods:{
            unsigninCheckPayment(){
                console.log('update status')       
                http.get(`unsigninQrPayment/unsigninCheckPayment/${this.cref}`)
                    .then(response => {
                        if(response.data.pay_status == true){
                            this.cancelAutoUpdate()
                            localStorage.removeItem('cref')
                            localStorage.removeItem('cuciCleanupTypeSelected')
                            localStorage.removeItem('cuciCheckoutMoney')
                            this.$swal.fire({
                                icon: 'success',
                                title: 'สำเร็จ',
                                text: 'ขอบคุณที่สะสางดอกเบี้ยกับ จูจี ขอความบารอกัตบังเกิดแก่ท่าน',
                                confirmButtonText: 'OK'
                            }).then(() => {
                                this.$router.push({name: 'Cleanup'})
                            })
                        }
                    })
            },
            checkPayment(){
                console.log('update status')       
                http.get(`signinQrPayment/checkPayment/${this.cref}`)
                    .then(response => {
                        if(response.data.pay_status == true){
                            this.cancelAutoUpdate()
                            localStorage.removeItem('cref')
                            localStorage.removeItem('cuciCleanupTypeSelected')
                            localStorage.removeItem('cuciCheckoutMoney')
                            this.$swal.fire({
                                icon: 'success',
                                title: 'สำเร็จ',
                                text: 'ขอบคุณที่สะสางดอกเบี้ยกับ จูจี ขอความบารอกัตบังเกิดแก่ท่าน',
                                confirmButtonText: 'OK'
                            }).then(() => {
                                this.$router.push({name: 'Cleanup'})
                            })
                        }
                    })
            },
            unsigninQrPayment(){
                http.get(`unsigninQrPayment/unsigninRetreiveQrcode/${this.cref}`)
                    .then(response => {
                        this.qrcode = response.data.data.qrcode
                        this.money = response.data.data.money
                        this.timer = setInterval(this.unsigninCheckPayment, 5000)
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })    
            },
            signinQrPayment(){
                http.get(`signinQrPayment/retreiveQrcode/${this.cref}`)
                    .then(response => {
                        this.qrcode = response.data.data.qrcode
                        this.money = response.data.data.money
                        this.timer = setInterval(this.checkPayment, 5000)
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            cancelAutoUpdate(){
                clearInterval(this.timer)
            },
        },
        beforeUnmount () {
            this.cancelAutoUpdate()
        }
    }
</script>

<style lang="scss" scoped>

</style>