<template>
    <!-- สะสางดอกเบี้ย -->
    <section class="bgcc">
      <div class="container pt-20 mx-auto">
        <div class="flex flex-wrap mt-3">
          <div class="w-full p-4 lg:w-1/2">
            <h1 class="mt-6 mb-4 text-4xl font-semibold leading-tight text-gray-800 capitalize">คำนวณซะกาต</h1>
            <embed class="w-full" type="text/html" src="https://cu-ci.org/zakat/" width="100%" height="1050">
          </div>

          <div class="w-full p-4 lg:w-1/2">
            <h1 class="mt-6 mb-4 text-4xl font-semibold leading-tight text-gray-800 capitalize">จ่ายซะกาต</h1>
            <h3>ทางเลือกเพื่อความสะอาดใจ.... สู่การร่วมกันสร้างสรรค์สังคมที่ดีกว่ากับ...<br />กองทุนซากาต มูลนิธิจูจีสร้างสรรค์สังคม</h3>
            <div class="w-full p-3 sm:p-6">
                <div class="flex flex-wrap justify-around w-full">
                  <!-- <div class="max-w-sm p-2 mt-1 rounded-lg">  -->
                    <button @click="selectMoney(100)" class="w-40 h-24 px-4 py-1 mt-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                      100 บาท
                    </button>
                  <!-- </div> -->
                  <!-- <div class="max-w-sm p-2 mt-1 rounded-lg"> -->
                    <button @click="selectMoney(500)" class="w-40 h-24 px-4 py-1 mt-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                      500 บาท
                    </button>
                  <!-- </div> -->
                  <!-- <div class="max-w-sm p-2 mt-1 rounded-lg"> -->
                      <button @click="selectMoney(1000)" class="w-40 h-24 px-4 py-1 mt-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                        1,000 บาท
                      </button>
                  <!-- </div> -->
                  <!-- <div class="max-w-sm p-2 mt-1 rounded-lg">  -->
                    <button class="w-40 h-24 px-4 py-1 mt-1 font-semibold border border-purple-200 rounded-sm text-l text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ระบุจำนวน (บาท)
                    <!-- <br> -->
                    <input type="number" v-model="input_money" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder=""/>
                    </button>     
                  <!-- </div> -->
                </div>
                <div class="flex flex-wrap w-full mt-2">
                  <div v-if="serverError.money" class="text-sm text-center text-red-500">
                    {{serverError.money[0]}}
                  </div>
                  <div v-if="!serverError.money" class="text-xl text-center text-bold">
                    <span v-if="money">ท่านจ่ายซะกาตเป็นเงิน {{showMoney(money)}} บาท</span>
                  </div>
                </div>
                <button @click="createOrder()" class="mt-3 btncc-riba" 
                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                    :disabled="disabled"
                > 
                จ่ายซากาต
                </button>
            </div>
            </div>
        </div>
      </div>
    </section>
  
    <!-- สะสางดอกเบี้ย -->
    <section class="bgcc">
      <div class="container py-1 mx-auto">
        <div class="flex flex-wrap items-center ">
          <div class="order-last w-full p-4 lg:w-full lg:order-first">
            <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          ข้อมูลการจ่ายซากาต
      </h2>
      
      <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3">
           <!-- Card จำนวนวัน-->
           <div class="flex items-center p-4 rounded-lg shadow-xs bg-emerald-500 dark:bg-orange-800">
              <div class="p-3 mr-4 rounded-full text-emerald-500 bg-emerald-100 dark:text-orange-100">
                <i class="fa-solid fa-calendar-days"></i>      
              </div>
              <div>
                  <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">จำนวนครั้งที่รับ </p>
                  <p class="text-lg font-semibold text-white dark:text-gray-200">75 ครั้ง</p>
              </div>
          </div>
           <!-- Card ครั้งที่โอน-->
           <div class="flex items-center p-4 rounded-lg shadow-xs bg-emerald-500 dark:bg-orange-800">
              <div class="p-3 mr-4 rounded-full text-emerald-500 bg-emerald-100 dark:text-orange-100">
                <i class="fa-solid fa-money-bill-transfer"></i>   
              </div>
              <div>
                  <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">จำนวนเงิน</p>
                  <p class="text-lg font-semibold text-white dark:text-gray-200">86,875.56 บาท </p>
              </div>
          </div>      
                   
      </div>
      <!-- <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          ข้อมูลโครงการสาธารณประโยชน์
      </h2> -->
    
          </div>
        </div>
      </div>
    </section>
  
   
  </template>
  
  
<script>
    import filter from '@/helpers/filter'
    import http from '@/services/AuthService'
    export default{
      mounted(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        localStorage.setItem('lastVisit', 'Zakat');
      },
      computed:{
        logedIn(){
          return this.$store.getters['authMember/logedIn']
        },
      },
      data(){
          return{
              money: '',
              input_money: '',
              disabled: false,
              serverError: {},    
          }
      },
      watch:{
        input_money: function(){
          this.money = this.input_money
        }
      },
      methods:{
        selectMoney(money){
          this.money = money
          this.input_money = ''
          this.serverError = {}
        },
        createOrder(){
            this.disabled = true
            this.$Progress.start()
            // http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
                // .then(() => {
                    // this.disabled = true
                    localStorage.removeItem('riba_amount')
                    let data = new FormData()
                    data.append('money', this.money)
                    http.post('order/createZakatOrder', data)
                        .then(response => {
                            localStorage.setItem('zakat_amount', this.money)
                            this.$Progress.finish()
                            this.serverError = ''
                            this.$store.dispatch("authMember/retreiveCart")
                            this.$router.push({name: 'ZakatConfirmation', params: {'order_id': response.data.order_id}})
                        })  
                        .catch(error => {
                            this.disabled = false
                            this.$Progress.fail()
                            this.serverError = error.response.data.errors
                            if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }  
                        })  
            // })
        },
        showMoney(money){
              return filter.numberFormat(money, '0,0.00')
          },
      }
    }
</script>