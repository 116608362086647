<template>
  <section class="bgcc-riba">
    <div class="container py-20 mx-auto">
      <div class="flex flex-wrap items-center mt-3">
        <div class="order-last w-full p-4 lg:w-5/12 lg:order-first">
          <h1 class="mb-4 text-4xl font-semibold leading-tight text-gray-800 capitalize">
            ทางเลือกเพื่อความสะอาดใจ....<br />
            สู่การร่วมกันสร้างสรรค์สังคมที่ดีกว่า...กับกองทุนสะสางดอกเบี้ย
          </h1>
          <p class="mb-4">CUCI จูจี สร้างสรรค์สังคม</p>
          <!-- <router-link to="/cleanupType" class="btncc-riba">สะสางดอกเบี้ย</router-link> -->
        </div>
        <div class="order-first w-full p-4 lg:w-7/12 lg:order-last">
          <img src="@/assets/img/bg_home.png" class="w-full" alt="Smart Inventory System" width="" height=""/>
        </div>
      </div>
    </div>
  </section>
  <section class="bgcc-riba">
    <div class="container pb-20 mx-auto text-center">
      <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
        <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">
          เลือกหมวดหมู่การสะสางดอกเบี้ย
        </h2>
      </div>
      <div class="flex flex-wrap items-center mb-4">
        
        <!-- 1-เข้ากองกลาง --> 
        <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
          <router-link :to="{name: 'CleanupFor', params: {type: 1}}" class="block text-left hover:opacity-75">
            <img src="@/assets/img/CenterPoint.jpg" class="object-cover w-full h-64"/>
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                เข้ากองกลาง
              </h5>
              <i class="fas fa-book-reader"></i>
            </div>
          </router-link>
          <div class="flexcc-center">
            <h3 class="text-xl text-left">ยอดเงิน 200,000 บาท</h3>
            <h4 class="text-left">1,123 ครั้ง</h4>
          </div>
          <router-link :to="{name: 'CleanupFor', params: {type: 1}}" class="flexbtncc-riba">สะสางดอกเบี้ย</router-link>
        </div>
        <!-- \.1-เข้ากองกลาง --> 

        <!-- 2-สาธารณประโยชน์ -->        
        <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
          <router-link :to="{name: 'CleanupFor', params: {type: 2}}" class="block text-left hover:opacity-75">
            <img src="@/assets/img/Public.jpg" class="object-cover w-full h-64"/>
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">สาธารณประโยชน์</h5>
              <i class="fas fa-book-reader"></i>
            </div>
          </router-link>
          <div class="flexcc-center">
            <h3 class="text-xl text-left">ยอดเงิน 200,000 บาท</h3>
            <h4 class="text-left">1,123 ครั้ง</h4>
          </div>
          <router-link :to="{name: 'CleanupFor', params: {type: 2}}" class="flexbtncc-riba">สะสางดอกเบี้ย</router-link>
        </div>
        <!-- \.2-สาธารณประโยชน์ -->     

        <!-- 3-การศึกษา -->     
        <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
          <router-link :to="{name: 'CleanupFor', params: {type: 3}}" class="block text-left hover:opacity-75">
            <img src="@/assets/img/studying.jpg" class="object-cover w-full h-64"/>
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                การศึกษา
              </h5>
              <i class="fas fa-book-reader"></i>
            </div>
          </router-link>
          <div class="flexcc-center">
            <h3 class="text-xl text-left">ยอดเงิน 200,000 บาท</h3>
            <h4 class="text-left">1,123 ครั้ง</h4>
          </div>
          <router-link :to="{name: 'CleanupFor', params: {type: 3}}" class="flexbtncc-riba">สะสางดอกเบี้ย</router-link>
        </div>
        <!-- \.3-การศึกษา -->     

        <!-- 4-ฟากิรและมิสกิน -->     
        <div class="w-full p-4 lg:w-3/12 sm:w-6/12">
          <router-link :to="{name: 'CleanupFor', params: {type: 4}}" class="block text-left hover:opacity-75">
            <img src="@/assets/img/poor.jpg" class="object-cover w-full h-64" />
            <div class="flexcc-riba">
              <h5 class="text-base font-semibold text-gray-900">
                ฟากิรและมิสกิน
              </h5>
              <i class="fas fa-book-reader"></i>
            </div>
          </router-link>
          <div class="flexcc-center">
            <h3 class="text-xl text-left">ยอดเงิน 200,000 บาท</h3>
            <h4 class="text-left">1,123 ครั้ง</h4>
          </div>
          <router-link :to="{name: 'CleanupFor', params: {type: 4}}" class="flexbtncc-riba">สะสางดอกเบี้ย</router-link>
        </div>
      </div>
      <!-- \.4-ฟากิรและมิสกิน -->     
      
      <!-- <a href="#" class="inline-block px-6 py-2 text-white bg-green-600 border-2 border-green-600 rounded-md hover:text-green-600 hover:bg-green-200">ดูทั้งหมด</a> -->
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    
  },
};
</script>
