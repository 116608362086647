<template>
    <section class="bgcc">
        <div class="container pt-20 pb-20 mx-auto lg:pt-40 sm:w-full md:w-3/4 lg:w-3/4" v-if="isConclusionForm">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">
                                ชื่อองค์กร : {{info.name}} 
                                <router-link to="/oganizationManagement">
                                    <button type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        <i class="fa-solid fa-circle-left"></i> &nbsp; กลับ
                                    </button>
                                </router-link>
                            </h3>
                            <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                                <div class="px-4 py-5 sm:px-6">
                                    <h3 class="text-lg font-medium leading-6 text-gray-900">Organization Information</h3>
                                    <p class="max-w-2xl mt-1 text-sm text-gray-500">
                                        ข้อมูลองค์กร
                                        <button type="button" @click="openEditForm()" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-right text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                            <i class="fa-solid fa-pen-to-square"></i> แก้ไข
                                        </button>
                                    </p>
                                </div>
                                <div class="border-t border-gray-200">
                                    <dl>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">โลโก้</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <span v-if="logo_show != null">
                                                    <img :src="`${image_path}/images/organization/thumbnail/${logo_show}`" width="200" height="200" alt="" srcset="">
                                                </span>
                                                <span class="text-red-500" v-else>ยังไม่มีโลโก้</span>

                                            </dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">ชื่อองค์กร/หน่วยงาน/ผู้ประกอบการ</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{info.name}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">ประเภทการให้บริการ</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{info.category}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">Email address</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{info.email}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">หมายเลขโทรศัพท์</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{info.telephone}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">การบริการโดยรวม</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{info.introduction}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">สมัครเป็น partner</dt>
                                            <dd v-if="info.is_partner == 0" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <button  type="button" @click="applyPartner()" class="inline-flex justify-center px-4 py-2 mx-2 text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                    <i  class="fa-solid fa-handshake"> </i>  &nbsp; สมัครเป็นพาร์ทเนอร์ 
                                                </button>
                                            </dd>
                                            <dd v-else-if="info.is_partner == 1 && info.authorized_by != null" class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <router-link :to="{name: 'VoucherManage', params: {organization_id: this.$route.params.id}}">
                                                    <button type="button" class="inline-flex justify-center px-4 py-2 mx-2 text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        <i  class="fa-solid fa-circle-check"> </i> &nbsp; จัดการข้อมูลพาร์ทเนอร์
                                                    </button>
                                                </router-link>
                                            </dd>
                                            <dd v-else class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <!-- <router-link :to="{name: 'VoucherManage', params: {organization_id: this.$route.params.id}}"> -->
                                                    <button type="button" class="inline-flex justify-center px-4 py-2 mx-2 text-lg font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        <i  class="fa-solid fa-circle-check"> </i> &nbsp; รอตรวจสอบสถานะ
                                                    </button>
                                                <!-- </router-link> -->
                                            </dd>
                                        </div>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">
                                                สมาชิก
                                                <button type="button" @click="addNewMember()" class="inline-flex justify-center px-1 py-1 ml-1 mr-1 text-sm font-medium text-right text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                    <i class="fa-solid fa-circle-plus"></i> &nbsp; เพิ่ม
                                                </button>
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <p class="mt-1" v-for="(item, index) in members" :key="index">
                                                    <button type="button" @click="editMember(item)" class="inline-flex justify-center px-1 py-1 text-sm font-medium text-right text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        <i class="fa-solid fa-pen-to-square"></i> &nbsp; แก้ไข
                                                    </button> 
                                                    ชื่อ {{item.mem_name}} นามสกุล {{item.mem_lastname}} อีเมล {{item.mem_email}} 
                                                </p>   
                                            </dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">
                                                ที่อยู่
                                                <button type="button" @click="newAddress()" class="inline-flex justify-center px-1 py-1 ml-1 mr-1 text-sm font-medium text-right text-white bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                    <i class="fa-solid fa-circle-plus"></i> &nbsp; เพิ่ม
                                                </button>
                                            </dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                <p class="mt-1" v-for="(item, index) in addresses" :key="index">
                                                    <button type="button" @click="editAddress(item)" class="inline-flex justify-center px-1 py-1 text-sm font-medium text-right text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        <i class="fa-solid fa-pen-to-square"></i> &nbsp; แก้ไข
                                                    </button> 
                                                    {{item.address_name}} 
                                                    เลขที่ {{item.home_no}} 
                                                    <span v-if="(item.home_no != null)">หมู่ที่ {{item.moo}} </span>
                                                    <span v-if="(item.village != null)"> หมู่บ้าน {{item.village}} </span>
                                                    <span v-if="(item.soi != null)"> &nbsp; ซอย {{item.soi}} </span>
                                                    <span v-if="(item.road != null)"> &nbsp; ถนน {{item.road}} </span>
                                                    ต.{{item.subdistrict_name}} 
                                                    อ.{{item.district_name}} 
                                                    จ.{{item.province_name}} 
                                                    {{item.postcode}} 
                                                    สถานะ : <span v-if="item.status == 1">ใช้งาน</span>
                                                    <span v-if="item.status == 0">ยกเลิก</span>
                                                </p>
                                            </dd>
                                        </div>
                                    </dl>
                                    <!-- <div class="py-1 pb-2 ml-4 mr-4 text-left">
                                        <router-link to="/oganizationManagement">
                                            <button type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                <i class="fa-solid fa-left-long"></i> &nbsp; กลับ
                                            </button>
                                        </router-link>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="container pt-20 pb-5 mx-auto lg:pt-40 sm:w-full md:w-3/4 lg:w-3/4" v-if="isEditForm">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-2 mr-2 shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">แก้ไขข้อมูลองค์กร</h3>
                            
                            <div>
                                <div class="col-span-6 sm:col-span-3">
                                    <form @submit.prevent="updateOrganization()">
                                        <div class="container grid content-center grid-flow-col grid-cols-2 ">
                                            <div class="content-center row-span-6">
                                                <div class="col-span-4 sm:col-span-2">
                                                    <img :src="`${image_path}images/organization/thumbnail/${logo_show}`" alt="" width="100" height="100">
                                                    <label class="block mt-2 text-sm font-medium text-gray-700">รูป LOGO</label>
                                                    <div class="flex items-center mt-1">
                                                        <span class="inline-block w-12 h-12 overflow-hidden bg-gray-100 rounded-full">
                                                            <!-- <svg class="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                            </svg> -->
                                                            <img :src="logo_url">
                                                        </span>
                                                        <input type="file" ref="fileUpload" @change="changePhoto" class="ml-2 cc_input_text" :class="{'border-red-500': serverError.logo}">
                                                        <div v-if="serverError.logo" class="mt-1 text-sm text-red-500">
                                                            {{serverError.logo[0]}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label  class="block mt-2 text-sm font-medium text-gray-700" >ชื่อผู้ประกอบการ/หน่วยงาน/ร้านค้า</label>
                                                <input type="text" v-model="name" :class="{'border-red-500': serverError.name}" class="mt-1 mb-1 cc_input_text" id="" placeholder="ชื่อผู้ประกอบการ/หน่วยงาน/ร้านค้า" />
                                                <div v-if="serverError.name" class="mt-1 text-sm text-red-500">
                                                    {{serverError.name[0]}}
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label  class="block mt-2 text-sm font-medium text-gray-700" >การบริการโดยสรุป</label>
                                                <input type="textarea" v-model="introduction" :class="{'border-red-500': serverError.introduction}" class="mt-1 mb-1 form-textarea cc_input_text" id="" placeholder="การบริการโดยสรุป" />
                                                <div v-if="serverError.introduction" class="text-sm text-red-500 ">
                                                    {{serverError.introduction[0]}}
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <label  class="block mt-2 text-sm font-medium text-gray-700" >หมายเลขนิติบุคคล/เลขเสียภาษี</label>
                                                <input type="textarea" v-model="corporation_no" :class="{'border-red-500': serverError.corporation_no}" class="mt-1 mb-1 form-textarea cc_input_text" id="" placeholder="หมายเลขนิติบุคคล/เลขเสียภาษี" />
                                                <div v-if="serverError.corporation_no" class="text-sm text-red-500 ">
                                                    {{serverError.corporation_no[0]}}
                                                </div>
                                            </div>
                                            <div class="col-span-6">              
                                                <div class="relative mb-2">
                                                    <label  class="block mt-2 text-sm font-medium text-gray-700" >ประเภท</label>
                                                    <select v-model="organization_category_id" :class="{'border-red-500': serverError.organization_category_id}" class="block w-full h-full px-4 py-2 pr-8 mt-1 mb-1 cc_input_text">
                                                        <option value="">--เลือก--</option>
                                                        <option value="1">การศึกษา</option>
                                                        <option value="2">สุขภาพ</option>
                                                        <option value="3">มัสยิด</option>
                                                        <option value="4">มูลนิธิ</option>
                                                    </select>
                                                    <div v-if="serverError.organization_category_id" class="mt-1 text-sm text-red-500">
                                                        {{serverError.organization_category_id[0]}}
                                                    </div>
                                                    <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                        <i class="w-4 h-4 fill-current far fa-arrow-alt-square-down"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <label  class="block mt-2 text-sm font-medium text-gray-700" >Facebook ID</label>
                                        <input type="text" v-model="facebook_id" :class="{'border-red-500': serverError.facebook_id}" class="mb-1 cc_input_text" id="" placeholder="Facebook ID"/>
                                        <div v-if="serverError.facebook_id" class="text-sm text-red-500 ">
                                            {{serverError.facebook_id[0]}}
                                        </div>
                                        <label  class="block mt-2 text-sm font-medium text-gray-700" >Line ID</label>
                                        <input type="text" v-model="line_id" :class="{'border-red-500': serverError.line_id}" class="mb-1 cc_input_text" id="" placeholder="Line ID"/>
                                        <div v-if="serverError.line_id" class="text-sm text-red-500 ">
                                            {{serverError.line_id[0]}}
                                        </div>
                                        <label  class="block mt-2 text-sm font-medium text-gray-700" >อีเมล</label>
                                        <input type="text" v-model="email" :class="{'border-red-500': serverError.email}" class="mb-1 cc_input_text" id="" placeholder="Email address"/>
                                        <div v-if="serverError.email" class="text-sm text-red-500 ">
                                            {{serverError.email[0]}}
                                        </div>
                                        <label  class="block mt-2 text-sm font-medium text-gray-700" >เว็ปไซต์</label>
                                        <input type="text" v-model="website" :class="{'border-red-500': serverError.website}" class="mb-1 cc_input_text" id="" placeholder="Website"/>
                                        <div v-if="serverError.website" class="text-sm text-red-500 ">
                                            {{serverError.website[0]}}
                                        </div>
                                        <label  class="block mt-2 text-sm font-medium text-gray-700" >หมายเลขโทรศัพท์</label>
                                        <input type="text" v-model="telephone" :class="{'border-red-500': serverError.telephone}" class="mb-1 cc_input_text" id="" placeholder="หมายเลขโทรศัพท์"/>
                                        <div v-if="serverError.telephone" class="text-sm text-red-500 ">
                                            {{serverError.telephone[0]}}
                                        </div>
                                        <label  class="block mt-2 text-sm font-medium text-gray-700" >หมายเลขโทรศัพท์มือถือ</label>
                                        <input type="text" v-model="mobile_phone" :class="{'border-red-500': serverError.mobile_phone}" class="mb-1 cc_input_text" id="" placeholder="หมายเลขโทรศัพท์มือถือ"/>
                                        <div v-if="serverError.mobile_phone" class="text-sm text-red-500 ">
                                            {{serverError.mobile_phone[0]}}
                                        </div>
                                        <div class="py-1 pb-2 text-right">
                                            <button type="button" @click="closeEditForm()"
                                                class="inline-flex justify-center px-3 py-2 mt-3 ml-3 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                ยกเลิก
                                            </button>
                                            <button type="submit" 
                                                :disabled="disabled"
                                                :class="{'opacity-50 cursor-not-allowed': disabled}"
                                                class="inline-flex justify-center px-3 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                บันทึก
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-3/4" v-if="isAddressForm">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-10 mr-10 shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 v-if="!isCreateAddress" class="flexcc-top">แก้ไขที่อยู่ : {{name}} --> {{address_name}}</h3>
                            <h3 v-else class="flexcc-top">เพิ่มที่อยู่ : {{name}}</h3>
                            <div>
                                <div class="col-span-6 sm:col-span-3">
                                    <form @submit.prevent="isCreateAddress ? createAddress() : updateAddress()">
                                        <div class="overflow-hidden sm:rounded-md">
                                            <div class="px-4 py-5 sm:p-6">
                                                <div class="grid grid-cols-6 gap-6">
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-6">
                                                        <label for="street-address" class="block text-sm font-medium text-gray-700">ชื่อที่อยู่</label>
                                                        <input type="text"
                                                            v-model="address_name"
                                                            :class="{'border-red-500': serverError.address_name}"
                                                            class="cc_input_text" placeholder="ชื่อของที่อยู่ เช่น สำนักงานใหญ่ สาขาย่อย ตามที่จดทะเบียนไว้"/>
                                                        <div v-if="serverError.address_name" class="mt-1 text-sm text-red-500">
                                                            {{serverError.address_name[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label  class="block text-sm font-medium text-gray-700" >เลขที่</label>
                                                        <input type="text"
                                                            v-model="home_no"
                                                            :class="{'border-red-500': serverError.home_no}"
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.home_no" class="mt-1 text-sm text-red-500">
                                                            {{serverError.home_no[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">หมู่ที่</label>
                                                        <input type="text"
                                                            v-model="moo"
                                                            :class="{'border-red-500': serverError.moo}"
                                                            class="cc_input_text"/>
                                                            <div v-if="serverError.moo" class="mt-1 text-sm text-red-500">
                                                                {{serverError.moo[0]}}
                                                            </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">หมู่บ้าน</label>
                                                        <input type="text" 
                                                            v-model="village"
                                                            :class="{'border-red-500': serverError.village}"
                                                            class="cc_input_text"/>
                                                            <div v-if="serverError.village" class="mt-1 text-sm text-red-500">
                                                                {{serverError.village[0]}}
                                                            </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">ซอย</label>
                                                        <input type="text" 
                                                            v-model="soi"
                                                            :class="{'border-red-500': serverError.soi}"
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.soi" class="mt-1 text-sm text-red-500">
                                                            {{serverError.soi[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">ถนน</label>
                                                        <input type="text"
                                                            v-model="road"
                                                            :class="{'border-red-500': serverError.road}"
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.road" class="mt-1 text-sm text-red-500">
                                                            {{serverError.road[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">จังหวัด</label>
                                                        <select v-model="province_id" @change="findDistrict($event.target.value)" :class="{'border-red-500': serverError.province}" class="cc_input_text">
                                                            <option value="">--เลือกจังหวัด--</option>
                                                            <option v-for="(item, index) in provinces" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                                        </select>
                                                        <div v-if="serverError.province_id" class="mt-1 text-sm text-red-500">
                                                            {{serverError.province_id[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">อำเภอ/เขต</label>
                                                        <select v-model="district_id" @change="findSubdistrict($event.target.value)" :class="{'border-red-500': serverError.district}" class="cc_input_text">
                                                            <option value="">--เลือกอำเภอ/เขต-</option>
                                                            <option v-for="(item, index) in districts" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                                        </select>
                                                        <div v-if="serverError.district_id" class="mt-1 text-sm text-red-500">
                                                            {{serverError.district_id[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">ตำบล/แขวง</label>
                                                        <select v-model="subdistrict_id" :class="{'border-red-500': serverError.subdistrict}" class="cc_input_text">
                                                            <option value="">--เลือกตำบล--</option>
                                                            <option v-for="(item, index) in subdistricts" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                                        </select>
                                                        <div v-if="serverError.subdistrict_id" class="mt-1 text-sm text-red-500">
                                                            {{serverError.subdistrict_id[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">รหัสไปรษณีย์</label>
                                                        <input type="text"
                                                            v-model="postcode"
                                                            :class="{'border-red-500': serverError.postcode}" 
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.postcode" class="mt-1 text-sm text-red-500">
                                                            {{serverError.postcode[0]}}
                                                        </div>
                                                    </div>
                                                    <div v-if="!isCreateAddress" class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">สถานะ</label>
                                                        <select v-model="status" class="cc_input_text">
                                                            <option value="1">ใช้ที่อยู่นี้</option>
                                                            <option value="0">ยกเลิกที่อยู่นี้</option>
                                                        </select>
                                                        <div v-if="serverError.postcode" class="mt-1 text-sm text-red-500">
                                                            {{serverError.postcode[0]}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-1 pb-2 text-right">
                                                    <button type="submit" @click="closeEditForm()" class="inline-flex justify-center px-4 py-2 mt-3 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        ยกเลิก
                                                    </button>
                                                    <button type="submit"
                                                        :disabled="disabled"
                                                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                                                        class="inline-flex justify-center px-4 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        บันทึก
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-3/4" v-if="isMemberForm">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-10 mr-10 shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 v-if="!isAddMember" class="flexcc-top">
                                แก้ไขสมาชิก : {{mem_name}} {{mem_lastname}}
                                <button type="button" @click="closeEditForm()" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                    <i class="fa-solid fa-circle-left"></i> &nbsp; กลับ
                                </button>
                            </h3>
                            <h3 v-else class="flexcc-top">
                                เพิ่มสมาชิก : {{name}}
                                <button type="button" @click="closeEditForm()" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                    <i class="fa-solid fa-circle-left"></i> &nbsp; กลับ
                                </button>
                            </h3>
                            <div>
                                <div class="col-span-6 sm:col-span-3">
                                    <div class="overflow-hidden sm:rounded-md">
                                        <div class="px-4 py-5 sm:p-6">
                                            <div class="grid grid-cols-6 gap-6" v-if="isAddMember">
                                                <div class="col-span-6 sm:col-span-4 lg:col-span-4">
                                                    <input type="text" v-model="search_keyword" :class="{'border-red-500': serverError.search_keyword}" class="cc_input_text" placeholder="ค้นหาสมาชิกด้วยอีเมล"/>
                                                    <div v-if="serverError.search_keyword" class="text-sm text-red-500">
                                                        {{serverError.search_keyword[0]}}
                                                    </div>
                                                </div>
                                                <div class="col-span-6 sm:col-span-1 lg:col-span-1">
                                                    <button @click="searchMember()" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        <i class="fa-solid fa-magnifying-glass"></i> &nbsp; ค้นหา
                                                    </button>
                                                    <!-- <button @click="closeEditForm()" class="inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        <i class="fa-solid fa-xmark"></i> &nbsp; ยกเลิก
                                                    </button> -->
                                                </div>
                                                <div class="col-span-3 sm:col-span-6 lg:col-span-6" v-if="is_founded != '' && is_founded != 'notFound'">
                                                    <form @submit.prevent="createMember()">
                                                        <p class="mt-3">
                                                            ชื่อ - นามสกุล : {{mem_name}}  {{mem_lastname}} | อีเมล : {{mem_email}}
                                                            <select class="ml-3 " v-model="access_level" :class="{'border-red-500': serverError.access_level}">
                                                                <option value="">--เลือกระดับ--</option>
                                                                <option value="2">viewer</option>
                                                                <option value="1">admin</option>
                                                                <option value="0">super admin</option>
                                                            </select>
                                                            
                                                            <button type="submit"
                                                                :disabled="disabled"
                                                                :class="{'opacity-50 cursor-not-allowed': disabled}"
                                                                class="inline-flex justify-center px-4 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                                <i class="fa-solid fa-circle-plus"></i> &nbsp; เพิ่ม
                                                            </button>
                                                        </p>
                                                        <p>
                                                            <span v-if="serverError.access_level" class="text-sm text-red-500">
                                                                {{serverError.access_level[0]}}
                                                            </span>
                                                        </p>
                                                    </form>
                                                </div>
                                                <div class="col-span-3 sm:col-span-6 lg:col-span-6" v-if="is_founded == 'notFound'">
                                                    <span>ไม่พบข้อมูล</span>
                                                </div>
                                            </div>
                                            <div class="grid grid-cols-6 gap-6" v-if="!isAddMember">
                                                <div class="col-span-6 sm:col-span-2 lg:col-span-2">
                                                    <label for="street-address" class="block text-sm font-medium text-gray-700">ระดับสมาชิก</label>
                                                    <select class="cc_input_text" v-model="access_level">
                                                        <option value="2">viewer</option>
                                                        <option value="1">admin</option>
                                                        <option value="0">super admin</option>
                                                    </select>
                                                </div>
                                                <div class="col-span-6 sm:col-span-2 lg:col-span-2">
                                                    <label for="street-address" class="block text-sm font-medium text-gray-700">สถานะ</label>
                                                    <select class="cc_input_text" v-model="member_status">
                                                        <option value="1">active</option>
                                                        <option value="0">unactive</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="py-1 pb-2 text-right" v-if="!isAddMember">
                                                <button type="submit"
                                                    :disabled="disabled"
                                                    @click="updateMember()"
                                                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                                                    class="inline-flex justify-center px-4 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                    บันทึก
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/AuthService'
    export default {
        mounted(){
            this.retreiveInfo()
            this.retreiveProvince()
            this.retreiveMember()
            window.scrollTo({top: 0, behavior: 'smooth'})
        },
        data(){
            return{
                info: '',
                addresses: [],
                isConclusionForm: true,
                isEditForm: false,
                isAddressForm: false,
                isCreateAddress: false,
                isMemberForm: false,
                isAddMember: false,

                //edit form
                // id: '',
                logo: '',
                logo_show: '',
                logo_url: '',
                name: '',
                introduction: '',
                corporation_no: '',
                organization_category_id: '',
                organization_category: '',
                facebook_id: '',
                line_id: '',
                email: '',
                website: '',
                telephone: '',
                mobile_phone: '',
                image_path: '',

                //thai address
                provinces: [],
                districts: [],
                subdistricts: [],
                //form data
                address_id: '',
                address_name: '',
                home_no: '',
                moo: '',
                village: '',
                soi: '',
                road: '',
                province_id: '',
                district_id: '',
                subdistrict_id: '',
                postcode: '',
                status: '',
                // province: '',
                // district: '',
                // subdistrict: '',

                //server error
                disabled: false,
                serverError: {},

                //member management
                members: [],
                search_keyword: '',
                is_founded: '',
                member_id: '',
                mem_name: '',
                mem_lastname: '',
                mem_email: '',
                access_level: '', //ระบบของสมาชิก
                member_status: '',
            }
        },
        methods:{
            applyPartner(){
                this.$swal.fire({
                    title: 'สมัครเป็นพาร์ทเนอร์',
                    text: "ต้องการสมัครเป็นพาร์ทเนอร์หรือไม่",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'ไม่',
                    confirmButtonText: 'ใช่'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        let data = new FormData()
                        data.append('status', 'applyPartner')
                        data.append('_method', 'PATCH')
                        this.$Progress.start()
                        http.post(`organization/applyPartner/${this.$route.params.id}`, data)
                            .then(() => {
                                this.$swal.fire(
                                    'สำเร็จ',
                                    'สมัครเป็นพาร์ทเนอร์เรียบร้อบ',
                                    'success'
                                )
                                this.$Progress.finish()
                                this.retreiveInfo()
                            })
                            .catch(error => {
                                if(error.response.status == 401 || error.response.status == 419){
                                    this.$store.dispatch('authMember/unauthenticateResetting')
                                }     
                                this.$Progress.fail()    
                            })
                    }
                })
            },
            updateMember(){
                this.disabled = true
                this.$Progress.start()
                let data = new FormData()
                data.append('access_level', this.access_level)
                data.append('status', this.member_status)
                data.append('_method', 'PATCH')
                http.post(`organization/updateMember/${this.member_id}`, data)
                    .then(() => {
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขข้อมูลสมาชิกสำเร็จ'
                        })
                        this.retreiveMember()
                        this.disabled = false
                        this.$Progress.finish()    
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }     
                        this.disabled = false
                        this.$Progress.fail()    
                    })
            },
            editMember(item){
                this.isAddMember = false
                this.isMemberForm = true
                this.isCreateAddress = false
                this.isAddressForm = false
                this.isEditForm = false
                this.isConclusionForm = false
                this.member_id  = item.id
                this.mem_name  = item.mem_name
                this.mem_lastname  = item.mem_lastname
                this.access_level = item.access_level
                this.mem_email  = item.mem_email
                this.member_status = item.status
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            createMember(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('access_level', this.access_level)
                data.append('member_id', this.member_id)
                data.append('organization_id', this.$route.params.id)
                http.post( `organization/addMember`, data)
                    .then(response => {
                        let status = response.data.status
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        if(status == 'success'){
                            Toast.fire({
                                icon: 'success',
                                title: 'เพิ่มสมาชิกสำเร็จ'
                            })
                        }else if(status == 'exist'){
                            Toast.fire({
                                icon: 'warning',
                                title: 'เป็นสมาชิกองค์กรแล้ว'
                            })
                        }
                        this.retreiveMember()
                        this.is_founded = ''              
                        this.member_id = ''
                        this.mem_name = ''
                        this.mem_lastname = ''
                        this.mem_email = ''
                        this.access_level = ''
                        this.serverError = {}
                        this.disabled = false
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }     
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
                
            },
            searchMember(){
                this.$Progress.start()
                this.disabled = true
                http.get(`organization/searchMember?search_keyword=${this.search_keyword}`)
                    .then(response => {
                        let status = response.data.status
                        if(status == 'notFound'){
                            this.is_founded = status
                        }else{
                            this.is_founded = status
                            this.member_id = response.data.data.id
                            this.mem_name = response.data.data.mem_name
                            this.mem_lastname = response.data.data.mem_lastname
                            this.mem_email = response.data.data.mem_email
                            this.access_level = ''
                        }
                        this.serverError = {}
                        this.$Progress.finish()
                        this.disabled = false
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            addNewMember(){
                this.isMemberForm = true
                this.isAddMember = true
                this.isCreateAddress = false
                this.isAddressForm = false
                this.isEditForm = false
                this.isConclusionForm = false
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            retreiveMember(){
                http.get(`organization/members/${this.$route.params.id}`)
                    .then(response => {
                        this.members = response.data.data
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            createAddress(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData() 
                data.append('address_name', this.address_name)
                data.append('home_no', this.home_no)
                data.append('moo', this.moo)
                data.append('village', this.village)
                data.append('soi', this.soi)
                data.append('road', this.road)
                data.append('province_id', this.province_id)
                data.append('district_id', this.district_id)
                data.append('subdistrict_id', this.subdistrict_id)
                data.append('postcode', this.postcode)
                data.append('organization_id', this.$route.params.id)
                http.post(`organization/createOrganizationAddress`, data)
                    .then(() => {
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'เพิ่มที่อยู่เรียบร้อย'
                        }).then(() => {
                            this.closeEditForm()
                            this.retreiveInfo()
                            this.isCreateAddress = false
                        })
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }     
                    })
            },
            newAddress(){
                this.isCreateAddress = true
                this.isAddressForm = true
                this.isEditForm = false
                this.isConclusionForm = false
                
                this.address_id = ''
                this.address_name = ''
                this.home_no = ''
                this.moo = ''
                this.village = ''
                this.soi = ''
                this.road = ''
                this.province_id = ''
                this.district_id = ''
                this.subdistrict_id = ''
                this.postcode = ''
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            updateAddress(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('address_name', this.address_name)
                data.append('home_no', this.home_no)
                data.append('moo', this.moo)
                data.append('village', this.village)
                data.append('soi', this.soi)
                data.append('road', this.road)
                data.append('province_id', this.province_id)
                data.append('district_id', this.district_id)
                data.append('subdistrict_id', this.subdistrict_id)
                data.append('postcode', this.postcode)
                data.append('status', this.status)
                // data.append('organization_id', this.id)
                data.append('_method', 'PATCH')
                http.post(`organization/updateAddress/${this.address_id}`, data)
                    .then(() => {
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขที่อยู่เรียบร้อย'
                        }).then(() => {
                            this.closeEditForm()
                            this.retreiveInfo()
                        })
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }     
                    })
            },
            editAddress(item){
                this.isEditForm = false
                this.isAddressForm = true
                this.isConclusionForm = false
                this.isCreateAddress = false
                this.address_id = item.id
                this.address_name = item.address_name
                this.home_no = item.home_no
                this.moo = item.moo
                this.village = item.village
                this.soi = item.soi
                this.road = item.road
                this.province_id = item.province
                this.district_id = item.district
                this.subdistrict_id = item.subdistrict
                this.postcode = item.postcode
                this.status = item.status
                window.scrollTo({top: 0, behavior: 'smooth'});
                this.findDistrict(item.province)
                this.findSubdistrict(item.district)
                // this.province = item.
                // this.district = item.
                // this.subdistrict = item.
            },
            updateOrganization(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('logo', this.logo)
                data.append('name', this.name)
                data.append('introduction', this.introduction)
                data.append('corporation_no', this.corporation_no)
                data.append('organization_category_id', this.organization_category_id)
                data.append('facebook_id', this.facebook_id)
                data.append('line_id', this.line_id)
                data.append('email', this.email)
                data.append('website', this.website)
                data.append('telephone', this.telephone)
                data.append('mobile_phone', this.mobile_phone)
                data.append('_method', 'PATCH')
                http.post(`organization/update/${this.$route.params.id}`, data)
                    .then(response => {
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขข้อมูลองค์กรเรียบร้อย'
                        }).then(() => {
                            this.logo_show = response.data.data.logo
                            this.logo = ''
                            this.$refs.fileUpload.value = null
                            this.logo_url = ''
                            this.closeEditForm()
                            this.retreiveInfo()
                        })
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            openEditForm(){
                this.isEditForm = true
                this.isAddressForm = false
                this.isConclusionForm = false
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            closeEditForm(){
                this.isEditForm = false
                this.isAddressForm = false
                this.isConclusionForm = true
                this.isAddMember = false
                this.isMemberForm = false
                this.serverError = {}
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            changePhoto(e){
                const image = e.target.files[0]
                this.logo = e.target.files[0]
                this.logo_url = URL.createObjectURL(image)
            },
            retreiveInfo(){
                http.get(`organization/info/${this.$route.params.id}`)
                    .then(response => {
                        this.info = response.data.data
                        this.addresses = response.data.addresses
                        // this.id = response.data.data.id
                        this.logo_show = response.data.data.logo
                        this.name = response.data.data.name
                        this.introduction = response.data.data.introduction
                        this.corporation_no = response.data.data.corporation_no
                        this.organization_category_id = response.data.data.organization_category_id
                        // this.organization_category = response.data.data.
                        this.facebook_id = response.data.data.facebook_id
                        this.line_id = response.data.data.line_id
                        this.email = response.data.data.email
                        this.website = response.data.data.website
                        this.telephone = response.data.data.telephone
                        this.mobile_phone = response.data.data.mobile_phone
                        this.image_path = process.env.VUE_APP_URL_COOKIE
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            findSubdistrict(district_id){
                http.get(`address/subdistrict/${district_id}`)
                    .then(response => {
                        this.subdistricts = response.data.data
                        this.subdistrict_id = response.data.data[0].id
                        // this.subdistrict_id = response.data.data[0].id
                        this.postcode = response.data.data[0].zip_code
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            findDistrict(province_id){
                http.get(`address/district/${province_id}`)
                    .then(response => {
                        this.districts = response.data.data
                        this.district_id = response.data.data[0].id
                        // this.district_id = response.data.data[0].id
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            retreiveProvince(){
                http.get(`address/province`)
                    .then(response => {
                        this.provinces = response.data.data
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>