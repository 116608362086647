const state = {
    showSideMenu: true,
}

const getters = {
    mobileMenu(state){
        return state.showSideMenu
    },
}

const mutations = {
    toggleSideMenu(state){
        state.showSideMenu = !state.showSideMenu
    }
}

const actions = {

}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}