<template>
    <section class="bg-indigo-200">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">
                    ตั้งรหัสผ่านใหม่
                </h1>
            </div>
            <form @submit.prevent="resetPassword()">
                <div class="flex justify-center mb-2">
                    <div class="w-1/2 mt-1">
                        <input type="text" placeholder="อีเมล"
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                            v-model="email">
                        <div v-if="serverError.email" class="mt-2 text-sm text-red-500">
                            {{serverError.email[0]}}
                        </div>   
                    </div>
                </div>
                <div class="flex justify-center mb-2">
                    <div class="w-1/2 mt-1">
                        <input type="password" placeholder="ตั้งรหัสผ่านใหม่"
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                            v-model="password">
                        <div v-if="serverError.password" class="mt-2 text-sm text-red-500">
                            {{serverError.password[0]}}
                        </div>   
                    </div>
                </div>
                <div class="flex justify-center mb-2">
                    <div class="w-1/2 mt-1">
                        <input type="password" placeholder="ยืนยันรหัสผ่านใหม่"
                            class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                            v-model="password_confirmation">
                        <div v-if="serverError.password_confirmation" class="mt-2 text-sm text-red-500">
                            {{serverError.password_confirmation[0]}}
                        </div>   
                    </div>
                </div>
                <div class="flex justify-center">
                    <button type="submit"
                        class="block w-1/2 px-4 py-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                        บันทึก
                    </button>
                </div>
            </form>
            <br>
            <br>
            <br>
            <br>
            <!-- <p>{{token}}</p> -->
        </div> 
    </section>
</template>

<script>
    // import axios from 'axios'
    import http from '@/services/AuthService'
    export default {
        mounted(){
            this.queryString()
        },
        data(){
            return{
                email: localStorage.getItem("email_reseting"),
                password: '',
                password_confirmation: '',
                token: '',
                disabled: false,
                serverError: '',
            }
        },
        methods:{
            resetPassword(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append("email", this.email)
                data.append("password", this.password)
                data.append("password_confirmation", this.password_confirmation)
                data.append("token", this.token)
                http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
                    .then(() => {
                        http.post(`reset-password`, data)
                            .then(response => {
                                console.log(response.data)
                                if(response.data.status == 'token expired'){
                                    this.$swal.fire({
                                        icon: 'warning',
                                        title: 'ลิงค์หมดอายุ',
                                        text: 'กรุณาคลิกปุ่ม OK เพื่อรับลิงค์อีกครั้ง'
                                    }).then(() => {
                                        this.$router.push({name: 'ForgotPassword'})
                                    })
                                }else{
                                    this.$swal.fire({
                                        icon: 'success',
                                        title: 'รีเซ็ตรหัสผ่านสำเร็จ',
                                        text: 'กรุณาคลิกปุ่ม OK เพื่อเข้าสู่ระบบ'
                                    }).then(() => {
                                        this.$router.push({name: 'Login'})
                                    })  
                                    localStorage.removeItem('ccan')
                                    localStorage.removeItem('ccups')
                                    localStorage.removeItem('ccur')
                                    localStorage.removeItem('email_reseting')
                                }
                                this.serverError = ''
                                this.disabled = false
                                this.$Progress.finish()
                            })
                            .catch(error => {
                                this.serverError = error.response.data.errors
                                this.disabled = false
                                this.$Progress.fail()
                            })
                    })
                    
            },
            queryString(){
                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries())
                if(Object.keys(params).length === 0 && params.constructor === Object){
                  this.$router.push({name: 'forgotPassword'})
              }else{
                  this.token = params.token
              }
            }   
        } 
    }
</script>