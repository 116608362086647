<template>
    <section class="bgcc">
      <div class="container pt-40 pb-2 mx-auto text-center">
        <!-- <a href="#" class="inline-block px-6 py-2 text-white border-2 rounded-md bg-emerald-600 border-emerald-600 hover:text-emerald-600 hover:bg-emerald-100">โครงการทั้งหมด</a> -->
  
        <!-- TAB -->
        <!-- <div
          class="justify-center py-2 align-middle border-b border-gray-200 dark:border-gray-700"
        >
          <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
          >
            <li class="mr-2">
              <a href="#" class="inline-block px-6 py-2 text-white border-2 rounded-md bg-emerald-600 border-emerald-600 hover:text-emerald-600 hover:bg-emerald-100 dark:hover:text-gray-300 group">
                <i class="fas fa-window-frame">โครงการทั้งหมด</i>
              </a>
            </li>
            <li class="mr-2">
              <a
                href="#"
                class="inline-block px-6 py-2 text-white border-2 rounded-md bg-emerald-600 border-emerald-600 hover:text-emerald-600 hover:bg-emerald-100 active dark:text-blue-500 dark:border-blue-500 group"
                aria-current="page"
              >
                <i class="fa-solid fa-house-heart">โครงการจากเงินดอกเบี้ย</i>
              </a>
            </li>
            <li class="mr-2">
              <a href="#" class="inline-block px-6 py-2 text-white border-2 rounded-md bg-emerald-600 border-emerald-600 hover:text-emerald-600 hover:bg-emerald-100 group">
                <i class="fa-solid fa-house-heart">โครงการเงินบริจาค</i>
              </a>
            </li>
          </ul>
        </div> -->
        <div class="w-full px-4 py-3 lg:mx-auto">
          <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">
            โครงการที่สนับสนุนงบประมาณจากเงินสะสางดอกเบี้ย
          </h2>
          <p></p>
          <!-- <p>
            <a href="">โครงการที่ดำเนินการเสร็จแล้ว</a> |
            <a href="">โครงการที่กำลังดำเนินการ</a>
          </p> -->
        </div>
      </div>
    </section>
  
    <!-- // โครงการที่ผ่านมา -->
    <section class="bgcc">
      <div class="container pb-2 mx-auto text-center">
        <div>
          <h2
            class="mb-2 text-xl font-semibold leading-tight text-gray-800 capitalize "
          >
            โครงการที่ดำเนินการเสร็จแล้ว
          </h2>
        </div>
        <div class="flex flex-wrap items-center mb-4">
          <!-- p001 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p001.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P001
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการห้องน้ำสาธารณะสร้างใหม่
                ศูนย์การศึกษาอิสลามประจำมัสยิด(ตาดีกา) บ้านโต๊ะทูวอ (จำนวน 4 ห้อง)
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 72,000 บาท</router-link
            >
          </div>
          <!-- p002 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p002.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P002
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการห้องน้ำสาธารณะสร้างใหม่ ศูนย์การศึกษาอัลกุรอาน
                ตามันอัลกุรอาน บ้านบินยาลีมอ (จำนวน 4 ห้อง)
              </p>
  
              <p class="text-xs text-left">ท</p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 72,000 บาท</router-link
            >
          </div>
          <!-- p003 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p003.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P003
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการห้องน้ำสาธารณะสร้างใหม่ ศูนย์การศึกษาอัลกุรอาน ดารุลกุรอาน
                บ้านมะรือโบออก (จำนวน 2 ห้อง)
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 72,000 บาท</router-link
            >
          </div>
          <!-- p004 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p004.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P004
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการห้องน้ำสาธารณะซ่อมแซม
                ศูนย์การศึกษาอิสลามประจำมัสยิด(ตาดีกา) บ้านมะหุด (จำนวน 5 ห้อง)
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 36,000 บาท</router-link
            >
          </div>
          <!-- p005 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p005.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P005
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการห้องน้ำสาธารณะต่อเติม
                ศูนย์การศึกษาอิสลามประจำมัสยิด(ตาดีกา) บ้านบาซาเวาะเซ็ง (จำนวน 2
                ห้อง)
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 36,000 บาท</router-link
            >
          </div>
          <!-- p006 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p006.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P006
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการห้องน้ำสาธารณะสร้างใหม่
                ศูนย์การศึกษาอิสลามประจำมัสยิด(ตาดีกา) บ้านโต๊ะชูด (จำนวน 4 ห้อง)
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 72,000 บาท</router-link
            >
          </div>
          <!-- p007 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p007.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P007
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการแก้ไขปัญหา COVID19 กลุ่มนักสาธารณสุขเพื่อสังคม PHOS
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 60,000 บาท</router-link
            >
          </div>
          <!-- p008 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p008.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P008
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการสนับสนุนซื้อรถกู้ภัยกู้ชีพ กลุ่มบารอกัต BabaZu
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <!-- p009 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p004.jpg" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P009
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการพัฒนาโครงสร้างพื้นฐาน(มาตรฐานระบบไฟฟ้า) สถาบันปอเนาะ
                ดีรอสาตอิสลามียะห์
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <!-- p010 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p010.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P010
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการส่งเสริมศูนย์การเรียนรู้และนวัตกรรมทางการศึกษา
                จังหวัดชายแดนภาคใต้ สถาบันปาตานีเพื่อการวิจัยและพัฒนามนุษย์
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <!-- p011 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p011.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P011
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการปรับปรุงท่าเทียบเรือสาธารณะเพื่อการท่องเที่ยวของชุมชนบางปู
                วิสาหกิจการท่องเที่ยวชุมชนบางปู
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <!-- p012 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p012.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P012
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการ โดมลานกิจกรรมการเรียนรู้ พัฒนาทักษะ ของนักเรียน
                เยาวชนและชาวบ้าน ศูนย์การศึกษาอิสลามประจำมัสยิด(ตาดีกา)
                บ้านบูเกะบือราแง
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <!-- p013 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p013.jpg" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P013
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการจัดซื้ออุปกรณ์บนรถฉุกเฉิน หน่วยกู้ชีพอิควะห์รามัน
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <!-- p014 -->
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p014.jpg" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P014
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการช่วยเหลือและแก้ไขปัญหา COVID19 จังหวัดชายแดนภาคใต้
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 200,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p15.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P015
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการพัฒนาองค์กรสิทธิฯ รากหญ้า (JASAD) เพื่อผู้ได้รับผลกระทบจากกฎหมายพิเศษ
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  70,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/16.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P016
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการจัดซื้ออุปกรณ์บนรถฉุกเฉินหน่วยกู้ชีพกู้ภัยตำบลพร่อน
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  80,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/17.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P017
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการจัดซื้อเรือท้องแบนและเครื่องยนต์ช่วยเหลือบรรเทาทุกข์
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  100,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/18.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P018
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการพัฒนาห้องน้ำสาธารณะ สู่รักษ์สะอาดที่ยั่งยืน
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/19.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P019
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการสร้างรั้วที่สาธารณะกูโบร์ บ้านตันหยงมูหลง
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  70,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p020.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P020
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการพัฒนาห้องน้ำสะอาดและปลอดภัย มัสยิดบ้านเราปะลุกาสาเมาะ
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  80,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p022.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P022
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการพัฒนาเพื่อจัดซื้ออุปกรณ์และครุภัณฑ์สำนักงาน ชมรมตาดีกาอำเภอเมืองยะลา (PERTAMA)
              </p>
  
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  100,000 บาท</router-link
            >
          </div>
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img src="@/assets/img/p023.png" class="object-cover w-full h-full" />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">
                  รหัสโครงการ P023
                </h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
              <p class="text-sm text-left">
                โครงการพัฒนาถนนสาธารณะและบุกเบิกถนนดินลุกรั้ง ซอย 3 มัสยิดนูรูลอิสลาม บ้านปอฮนต้อมูซู
              </p>
              <p class="text-xs text-left"></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน  100,000 บาท</router-link
            >
          </div>
        </div>
        <!-- <a href="#" class="inline-block px-6 py-2 text-white bg-green-600 border-2 border-green-600 rounded-md hover:text-green-600 hover:bg-green-200">ดูทั้งหมด</a> -->
        <!-- <router-link
          to="/projects"
          class="text-orange-800 hover:text-orange-500"
          >โครงการที่ดำเนินการเสร็จแล้ว...ต่อ..</router-link
        > -->
      </div>
    </section>
    
    <!-- // โครงการที่กำลังดำเนินการ -->
    <!-- <section class="bgcc-riba">
      <div class="container pb-20 mx-auto text-center">
        <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
          <h2
            class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize "
          >
          โครงการที่กำลังดำเนินการ
          </h2>
        </div>
        <div class="flex flex-wrap items-center mb-4">
          <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img
                src="@/assets/img/openPPP016.png"
                class="object-cover w-full h-full"
              />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">รหัสโครงการ P015</h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
             
              <p class="text-sm text-left ">โครงการจัดซื้ออุปกรณ์บนรถฉุกเฉินหน่วยกู้ชีพกู้ภัยตำบลพร่อน</p>
              
              <p class="text-xs text-left "></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 80,000 บาท</router-link
            >
          </div>        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img
                src="@/assets/img/openPPP017.png"
                class="object-cover w-full h-full"
              />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">รหัสโครงการ P017</h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
             
              <p class="text-sm text-left "> โครงการจัดซื้อเรือท้องแบนและเครื่องยนต์ช่วยเหลือบรรเทาทุกข์</p>
              
              <p class="text-xs text-left "></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 100,000 บาท</router-link
            >
          </div>        <div class="w-full p-4 lg:w-4/12 sm:w-6/12">
            <router-link
              to="/projects"
              class="block text-left"
            >
              <img
                src="@/assets/img/openPPP019.png"
                class="object-cover w-full h-full"
              />
              <div class="flexcc-riba">
                <h5 class="text-base font-semibold text-gray-900">รหัสโครงการ P019</h5>
                <i class="fas fa-book-reader"></i>
              </div>
            </router-link>
            <div class="flex-auto mt-2">
             
              <p class="text-sm text-left ">โครงการสร้างรั้วที่สาธารณะกูโบร์ บ้านตันหยงมูหลง</p>
              
              <p class="text-xs text-left "></p>
            </div>
            <router-link to="/projects" class="flexbtncc-riba"
              >งบประมาณที่สนับสนุน 70,000 บาท</router-link
            >
          </div>
        </div>
        <a href="#" class="inline-block px-6 py-2 text-white bg-green-600 border-2 border-green-600 rounded-md hover:text-green-600 hover:bg-green-200">ดูทั้งหมด</a>
      </div>
    </section> -->
  </template>
<script>
  export default{
    mounted(){
      localStorage.setItem('lastVisit', 'Projects')
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
</script>