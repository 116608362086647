<template>
    <section class="bgcc">
        <div class="w-full px-4 pt-20 mx-auto my-auto text-center lg:pt-40 bgcc lg:mx-auto ">
            <a href="/accountPersonal">
                 บัญชี Personal 
            </a> 
            <a href="/accoutOrganization">
                บัญชี Organization 
            </a>
            <h1 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize"> 
                องค์กร : Organization 
            </h1>
        </div>
        <div class="container pb-5 mx-auto sm:w-full md:w-3/4 lg:w-3/4" v-if="show_create_form">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-2 mr-2 shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">
                                องค์กร : Organization
                                <router-link to="/oganizationManagement">
                                    <button type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        <i class="fa-solid fa-circle-left"></i> &nbsp; กลับ
                                    </button>
                                </router-link>
                            </h3>
                            <ul class="stepper" data-mdb-stepper="stepper" data-mdb-stepper-type="  vertical">
                                <li class="stepper-step" :class="{'stepper-active': show_create_form}" @click="openForm('create')">
                                    <div class="stepper-head">
                                        <span class="stepper-head-icon"> 1 </span>
                                        <span class="stepper-head-text"> ข้อมูลทั่วไป </span>
                                    </div>
                                    <div class="stepper-content"> 011111
                                    </div>
                                </li>
                                <li class="stepper-step">
                                    <div class="stepper-head">
                                    <span class="stepper-head-icon"> 2 </span>
                                    <span class="stepper-head-text"> ข้อมูลที่อยู่ </span>
                                    </div>
                                    <div class="stepper-content">
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </div>
                                </li>
                                <li class="stepper-step">
                                    <div class="stepper-head">
                                    <span class="stepper-head-icon"> 3 </span>
                                    <span class="stepper-head-text"> สรุปผลการสมัคร </span>
                                    </div>
                                    <div class="stepper-content">
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </div>
                                </li>
                            </ul>
                            <div>
                                <div class="col-span-6 sm:col-span-3">
                                    <form @submit.prevent="createOrganization()">
                                        <div class="container grid content-center grid-flow-col grid-cols-2 ">
                                            <div class="content-center row-span-6">
                                                <div class="col-span-4 sm:col-span-2">
                                                    <label class="block text-sm font-medium text-gray-700">รูป LOGO</label>
                                                    <div class="flex items-center mt-1">
                                                        <span class="inline-block w-12 h-12 overflow-hidden bg-gray-100 rounded-full">
                                                            <svg class="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                                                            </svg>
                                                        </span>
                                                        <input type="file" @change="changePhoto" class="ml-2 cc_input_text" :class="{'border-red-500': serverError.logo}">
                                                        <div v-if="serverError.logo" class="mt-1 text-sm text-red-500">
                                                            {{serverError.logo[0]}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-span-2">
                                                <input type="text" v-model="name" :class="{'border-red-500': serverError.name}" class="mt-1 mb-2 cc_input_text" id="" placeholder="ชื่อผู้ประกอบการ/หน่วยงาน/ร้านค้า" />
                                                <div v-if="serverError.name" class="mt-1 text-sm text-red-500">
                                                    {{serverError.name[0]}}
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <input type="textarea" v-model="introduction" :class="{'border-red-500': serverError.introduction}" class="mt-1 mb-2 form-textarea cc_input_text" id="" placeholder="การบริการโดยสรุป" />
                                                <div v-if="serverError.introduction" class="text-sm text-red-500 ">
                                                    {{serverError.introduction[0]}}
                                                </div>
                                            </div>
                                            <div class="col-span-6">
                                                <input type="textarea" v-model="corporation_no" :class="{'border-red-500': serverError.corporation_no}" class="mt-1 mb-2 form-textarea cc_input_text" id="" placeholder="หมายเลขนิติบุคคล/เลขเสียภาษี" />
                                                <div v-if="serverError.corporation_no" class="text-sm text-red-500 ">
                                                    {{serverError.corporation_no[0]}}
                                                </div>
                                            </div>
                                            <div class="col-span-6">              
                                                <div class="relative mb-2">
                                                    <select v-model="organization_category_id" :class="{'border-red-500': serverError.organization_category_id}" class="block w-full h-full px-4 py-2 pr-8 mt-2 mb-1 cc_input_text ">
                                                        <option value="">--เลือก--</option>
                                                        <option value="1">การศึกษา</option>
                                                        <option value="2">สุขภาพ</option>
                                                        <option value="3">มัสยิด</option>
                                                        <option value="4">มูลนิธิ</option>
                                                    </select>
                                                    <div v-if="serverError.organization_category_id" class="mt-1 text-sm text-red-500">
                                                        {{serverError.organization_category_id[0]}}
                                                    </div>
                                                    <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                        <i class="w-4 h-4 fill-current far fa-arrow-alt-square-down"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" v-model="facebook_id" :class="{'border-red-500': serverError.facebook_id}" class="mt-2 mb-1 cc_input_text" id="" placeholder="Facebook ID"/>
                                        <div v-if="serverError.facebook_id" class="text-sm text-red-500 ">
                                            {{serverError.facebook_id[0]}}
                                        </div>
                                        <input type="text" v-model="line_id" :class="{'border-red-500': serverError.line_id}" class="mt-2 mb-1 cc_input_text" id="" placeholder="Line ID"/>
                                        <div v-if="serverError.line_id" class="text-sm text-red-500 ">
                                            {{serverError.line_id[0]}}
                                        </div>
                                        <input type="text" v-model="email" :class="{'border-red-500': serverError.email}" class="mt-2 mb-1 cc_input_text" id="" placeholder="Email address"/>
                                        <div v-if="serverError.email" class="text-sm text-red-500 ">
                                            {{serverError.email[0]}}
                                        </div>
                                        <input type="text" v-model="website" :class="{'border-red-500': serverError.website}" class="mt-2 mb-1 cc_input_text" id="" placeholder="Website"/>
                                        <div v-if="serverError.website" class="text-sm text-red-500 ">
                                            {{serverError.website[0]}}
                                        </div>
                                        <input type="text" v-model="telephone" :class="{'border-red-500': serverError.telephone}" class="mt-2 mb-1 cc_input_text" id="" placeholder="หมายเลขโทรศัพท์"/>
                                        <div v-if="serverError.telephone" class="text-sm text-red-500 ">
                                            {{serverError.telephone[0]}}
                                        </div>
                                        <input type="text" v-model="mobile_phone" :class="{'border-red-500': serverError.mobile_phone}" class="mt-2 mb-1 cc_input_text" id="" placeholder="หมายเลขโทรศัพท์มือถือ"/>
                                        <div v-if="serverError.mobile_phone" class="text-sm text-red-500 ">
                                            {{serverError.mobile_phone[0]}}
                                        </div>
                                        <div class="py-1 pb-2 text-right">
                                            <button type="submit" v-if="id == ''"
                                                :disabled="disabled"
                                                :class="{'opacity-50 cursor-not-allowed': disabled}"
                                                class="inline-flex justify-center px-3 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                บันทึก
                                            </button>
                                            <button type="button" v-if="id != ''" @click="openForm('address')"
                                                class="inline-flex justify-center px-3 py-2 mt-3 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                ถัดไป
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="container pb-20 mx-auto sm:w-full md:w-3/4 lg:w-3/4" v-if="show_address_form">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-10 mr-10 shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">ข้อมูลที่อยู่องค์กร : Organization Address</h3>
                            <ul class="stepper" data-mdb-stepper="stepper" data-mdb-stepper-type="  vertical">
                                <li class="stepper-step">
                                    <div class="stepper-head">
                                        <span class="stepper-head-icon"> 1 </span>
                                        <span class="stepper-head-text"> ข้อมูลทั่วไป </span>
                                    </div>
                                </li>
                                <li class="stepper-step" :class="{'stepper-active': show_address_form}">
                                    <div class="stepper-head">
                                        <span class="stepper-head-icon"> 2 </span>
                                        <span class="stepper-head-text"> ข้อมูลที่อยู่ </span>
                                    </div>
                                </li>
                                <li class="stepper-step">
                                    <div class="stepper-head">
                                        <span class="stepper-head-icon"> 3 </span>
                                        <span class="stepper-head-text"> สรุปผลการสมัคร </span>
                                    </div>
                                </li>
                            </ul>
                            <div>
                                <div class="col-span-6 sm:col-span-3">
                                    <form @submit.prevent="createOrganizationAddress()">
                                        <div class="overflow-hidden sm:rounded-md">
                                            <div class="px-4 py-5 sm:p-6">
                                                <div class="grid grid-cols-6 gap-6">
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-6">
                                                        <label for="street-address" class="block text-sm font-medium text-gray-700">ชื่อที่อยู่</label>
                                                        <input type="text"
                                                            v-model="address_name"
                                                            :class="{'border-red-500': serverError.address_name}"
                                                            class="cc_input_text" placeholder="ชื่อของที่อยู่ เช่น สำนักงานใหญ่ สาขาย่อย ตามที่จดทะเบียนไว้"/>
                                                        <div v-if="serverError.address_name" class="mt-1 text-sm text-red-500">
                                                            {{serverError.address_name[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label  class="block text-sm font-medium text-gray-700" >เลขที่</label>
                                                        <input type="text"
                                                            v-model="home_no"
                                                            :class="{'border-red-500': serverError.home_no}"
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.home_no" class="mt-1 text-sm text-red-500">
                                                            {{serverError.home_no[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">หมู่ที่</label>
                                                        <input type="text"
                                                            v-model="moo"
                                                            :class="{'border-red-500': serverError.moo}"
                                                            class="cc_input_text"/>
                                                            <div v-if="serverError.moo" class="mt-1 text-sm text-red-500">
                                                                {{serverError.moo[0]}}
                                                            </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">หมู่บ้าน</label>
                                                        <input type="text" 
                                                            v-model="village"
                                                            :class="{'border-red-500': serverError.village}"
                                                            class="cc_input_text"/>
                                                            <div v-if="serverError.village" class="mt-1 text-sm text-red-500">
                                                                {{serverError.village[0]}}
                                                            </div>
                                                    </div>
                                                    <div class="col-span-3 sm:col-span-3 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">ซอย</label>
                                                        <input type="text" 
                                                            v-model="soi"
                                                            :class="{'border-red-500': serverError.soi}"
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.soi" class="mt-1 text-sm text-red-500">
                                                            {{serverError.soi[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">ถนน</label>
                                                        <input type="text"
                                                            v-model="road"
                                                            :class="{'border-red-500': serverError.road}"
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.road" class="mt-1 text-sm text-red-500">
                                                            {{serverError.road[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">จังหวัด</label>
                                                        <select v-model="province_id" @change="findDistrict($event.target.value)" :class="{'border-red-500': serverError.province}" class="cc_input_text">
                                                            <option value="">--เลือกจังหวัด--</option>
                                                            <option v-for="(item, index) in provinces" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                                        </select>
                                                        <div v-if="serverError.province_id" class="mt-1 text-sm text-red-500">
                                                            {{serverError.province_id[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">อำเภอ/เขต</label>
                                                        <select v-model="district_id" @change="findSubdistrict($event.target.value)" :class="{'border-red-500': serverError.district}" class="cc_input_text">
                                                            <option value="">--เลือกอำเภอ/เขต-</option>
                                                            <option v-for="(item, index) in districts" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                                        </select>
                                                        <div v-if="serverError.district_id" class="mt-1 text-sm text-red-500">
                                                            {{serverError.district_id[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">ตำบล/แขวง</label>
                                                        <select v-model="subdistrict_id" :class="{'border-red-500': serverError.subdistrict}" class="cc_input_text">
                                                            <option value="">--เลือกตำบล--</option>
                                                            <option v-for="(item, index) in subdistricts" :value="item.id" :key="index">{{item.name_in_thai}}</option>
                                                        </select>
                                                        <div v-if="serverError.subdistrict_id" class="mt-1 text-sm text-red-500">
                                                            {{serverError.subdistrict_id[0]}}
                                                        </div>
                                                    </div>
                                                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                                                        <label class="block text-sm font-medium text-gray-700">รหัสไปรษณีย์</label>
                                                        <input type="text"
                                                            v-model="postcode"
                                                            :class="{'border-red-500': serverError.postcode}" 
                                                            class="cc_input_text"/>
                                                        <div v-if="serverError.postcode" class="mt-1 text-sm text-red-500">
                                                            {{serverError.postcode[0]}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-1 pb-2 text-right">
                                                    <button type="submit" v-if="address_id == ''"
                                                        :disabled="disabled"
                                                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                                                        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        บันทึก
                                                    </button>
                                                    <button type="button" @click="openForm('conclusion')" v-else
                                                        class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                                        ถัดไป
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
        <div class="container pb-20 mx-auto sm:w-full md:w-3/4 lg:w-3/4" v-if="show_conclusion_form">
            <div class="container">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-10 mr-10 shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">สรุปผลการสมัครองค์กร : Organization</h3>
                            <ul class="stepper" data-mdb-stepper="stepper" data-mdb-stepper-type="  vertical">
                                <li class="stepper-step">
                                    <div class="stepper-head">
                                        <span class="stepper-head-icon"> 1 </span>
                                        <span class="stepper-head-text"> ข้อมูลทั่วไป </span>
                                    </div>
                                    <div class="stepper-content"> 011111
                                    </div>
                                </li>
                                <li class="stepper-step">
                                    <div class="stepper-head">
                                    <span class="stepper-head-icon"> 2 </span>
                                    <span class="stepper-head-text"> ข้อมูลที่อยู่ </span>
                                    </div>
                                    <div class="stepper-content">
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </div>
                                </li>
                                <li class="stepper-step" :class="{'stepper-active': show_conclusion_form}">
                                    <div class="stepper-head">
                                    <span class="stepper-head-icon"> 3 </span>
                                    <span class="stepper-head-text"> สรุปผลการสมัคร </span>
                                    </div>
                                    <div class="stepper-content">
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    </div>
                                </li>
                            </ul>
                            <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                                <div class="px-4 py-5 sm:px-6">
                                    <h3 class="text-lg font-medium leading-6 text-gray-900">Organization Information</h3>
                                    <p class="max-w-2xl mt-1 text-sm text-gray-500">ข้อมูลองค์กร</p>
                                </div>
                                <div class="border-t border-gray-200">
                                    <dl>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">ชื่อองค์กร/หน่วยงาน/ผู้ประกอบการ</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{name}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">ประเภทการให้บริการ</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{organization_category}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">Email address</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{email}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">หมายเลขโทรศัพท์</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{telephone}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">การบริการโดยรวม</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{introduction}}</dd>
                                        </div>
                                        <div class="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">ที่อยู่</dt>
                                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                {{address_name}} 
                                                เลขที่ {{home_no}} 
                                                <span v-if="(home_no != null)">หมู่ที่ {{moo}} </span>
                                                <span v-if="(village != null)">หมู่บ้าน {{village}} </span>
                                                <span v-if="(soi != null)"> &nbsp; ซอย {{soi}} </span>
                                                <span v-if="(road != null)"> &nbsp; ถนน {{road}} </span>
                                                {{province}} 
                                                {{district}} 
                                                {{subdistrict}} 
                                                {{postcode}} 
                                            </dd>
                                        </div>
                                    </dl>
                                    <div class="py-1 pb-2 mr-4 text-right">
                                        <button type="button" @click="confirm()"
                                            class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                            ยืนยัน
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/AuthService'
    export default {
        mounted(){
            // this.retreiveOrganization()
            if(localStorage.getItem('organization_id') == null){
                // console.log('no setting')
            }else{
                // console.log(localStorage.getItem('organization_id'))
                this.retreiveCurrentOrganization()
                this.retreiveCurrentAddress()
            }
            this.retreiveProvince()
        },
        data(){
            return{
                //address form
                //thai address
                provinces: [],
                districts: [],
                subdistricts: [],
                //form data
                address_id: '',
                address_name: '',
                home_no: '',
                moo: '',
                village: '',
                soi: '',
                road: '',
                province_id: '',
                district_id: '',
                subdistrict_id: '',
                postcode: '',
                province: '',
                district: '',
                subdistrict: '',

                //create form
                id: '',
                logo: '',
                logo_url: '',
                name: '',
                introduction: '',
                corporation_no: '',
                organization_category_id: '',
                organization_category: '',
                facebook_id: '',
                line_id: '',
                email: '',
                website: '',
                telephone: '',
                mobile_phone: '',

                //show edit form
                show_create_form: true,
                show_address_form: false,
                show_conclusion_form: false,

                //server error
                serverError: {},
                disabled: false,

                //organization list
                organizations: [],

            }
        },
        methods:{
            confirm(){
                localStorage.removeItem('organization_id')
                localStorage.removeItem('address_id')
                this.id = ''
                this.address_id = ''
                this.openForm('create')

                this.logo = ''
                this.name = ''
                this.introduction = ''
                this.corporation_no = ''
                this.organization_category_id = ''
                this.facebook_id = ''
                this.line_id = ''
                this.email = ''
                this.website = ''
                this.telephone = ''
                this.mobile_phone = ''

                this.address_name = ''
                this.home_no = ''
                this.moo = ''
                this.village = ''
                this.soi = ''
                this.road = ''
                this.province_id = ''
                this.district_id = ''
                this.subdistrict_id = ''
                this.postcode = ''

                this.$router.push({name: 'OrganizationManagement'})
            },
            createOrganizationAddress(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('address_name', this.address_name)
                data.append('home_no', this.home_no)
                data.append('moo', this.moo)
                data.append('village', this.village)
                data.append('soi', this.soi)
                data.append('road', this.road)
                data.append('province_id', this.province_id)
                data.append('district_id', this.district_id)
                data.append('subdistrict_id', this.subdistrict_id)
                data.append('postcode', this.postcode)
                data.append('organization_id', this.id)
                http.post(`organization/createOrganizationAddress`, data)
                    .then(response => {
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'เพิ่มที่อยู่องค์กรเรียบร้อย'
                        })

                        this.province = response.data.province
                        this.district = response.data.district
                        this.subdistrict = response.data.subdistrict

                        //show conclusion form
                        this.show_create_form = false
                        this.show_address_form = false
                        this.show_conclusion_form = true

                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            createOrganization(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('logo', this.logo)
                data.append('name', this.name)
                data.append('introduction', this.introduction)
                data.append('corporation_no', this.corporation_no)
                data.append('organization_category_id', this.organization_category_id)
                data.append('facebook_id', this.facebook_id)
                data.append('line_id', this.line_id)
                data.append('email', this.email)
                data.append('website', this.website)
                data.append('telephone', this.telephone)
                data.append('mobile_phone', this.mobile_phone)
                http.post(`organization/create`, data)
                    .then(response => {
                        localStorage.setItem('organization_id', response.data.data)
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'เพิ่มข้อมูลองค์กรเรียบร้อย'
                        })

                        this.id = response.data.data
                        this.organization_category = response.data.organization_category
                        // this.logo = ''
                        // this.name = ''
                        // this.introduction = ''
                        // this.corporation_no = ''
                        // this.organization_category_id = ''
                        // this.facebook_id = ''
                        // this.line_id = ''
                        // this.email = ''
                        // this.website = ''
                        // this.telephone = ''
                        // this.mobile_phone = ''

                        //show edit form
                        this.show_create_form = false
                        this.show_address_form = true
                        this.show_conclusion_form = false

                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = {}
                    })
                    .catch(error => {
                        this.$Progress.fail()
                        this.disabled = false
                        this.serverError = error.response.data.errors
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })

            },
            changePhoto(e){
                const image = e.target.files[0]
                this.logo = e.target.files[0]
                this.logo_url = URL.createObjectURL(image)
            },
            openForm(formType){
                if(formType == 'create'){
                    this.show_create_form = true
                    this.show_address_form = false
                    this.show_conclusion_form = false
                }else if(formType == 'address'){
                    this.show_create_form = false
                    this.show_address_form = true
                    this.show_conclusion_form = false
                }else{
                    this.show_create_form = false
                    this.show_address_form = false
                    this.show_conclusion_form = true
                }
            },
            retreiveCurrentAddress(){
                http.get(`organization/myCurrentAddress/${localStorage.getItem('organization_id')}`)
                    .then(response => {
                        this.address_id = response.data.data.id
                        this.address_name = response.data.data.address_name
                        this.home_no = response.data.data.home_no
                        this.moo = response.data.data.moo
                        this.village = response.data.data.village
                        this.soi = response.data.data.soi
                        this.road = response.data.data.road
                        this.province_id = response.data.data.province
                        this.district_id = response.data.data.district
                        this.subdistrict_id = response.data.data.subdistrict
                        this.postcode = response.data.data.postcode
                        this.province = response.data.province
                        this.district = response.data.district
                        this.subdistrict = response.data.subdistrict
                        this.findDistrict(response.data.data.province)
                        this.findSubdistrict(response.data.data.district)
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }     
                    })
            },
            retreiveCurrentOrganization(){
                http.get(`organization/myCurrentOrganization/${localStorage.getItem('organization_id')}`)   
                    .then(response => {
                        this.id = response.data.id
                        // this.logo = response.data.
                        this.name = response.data.name
                        this.introduction = response.data.introduction
                        this.corporation_no = response.data.corporation_no
                        this.organization_category_id = response.data.organization_category_id
                        this.organization_category = response.data.organization_category
                        this.facebook_id = response.data.facebook_id
                        this.line_id = response.data.line_id
                        this.email = response.data.email
                        this.website = response.data.website
                        this.telephone = response.data.telephone
                        this.mobile_phone = response.data.mobile_phone
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            findSubdistrict(district_id){
                http.get(`address/subdistrict/${district_id}`)
                    .then(response => {
                        this.subdistricts = response.data.data
                        // this.subdistrict_id = response.data.data[0].id
                        this.postcode = response.data.data[0].zip_code
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            findDistrict(province_id){
                http.get(`address/district/${province_id}`)
                    .then(response => {
                        this.districts = response.data.data
                        // this.district_id = response.data.data[0].id
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            },
            retreiveProvince(){
                http.get(`address/province`)
                    .then(response => {
                        this.provinces = response.data.data
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        } 
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>