<template>
    <div class="container pt-40 pb-20 pl-4 mx-auto">
        
       <!-- Cards -->
       <h2 class="my-6 text-2xl font-semibold text-center text-gray-700 lg:mx-80 dark:text-gray-200">
           ยืนยันการสะสางดอกเบี้ย 
       </h2>
       <h3 class="my-6 text-2xl font-semibold text-center text-gray-700 lg:mx-80 dark:text-gray-200">
           จำนวนเงิน {{showMoney(money)}} บาท 
       </h3>
 
       <div class="container pt-5 pb-5 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <br><br><br>
            <div class="flex mt-2 rounded-lg">
                <div  class="flex-1 max-w-4xl m-5">
                    <div class="flex flex-col md:flex-row">
                        <div class="sm:p-2 md:p-2 sm:w-full md:w-4/5">
                            <u><b>เลือกช่องทางการชำระเงิน :- </b></u>
                        </div>
                    </div>
                    <div class="flex rounded-lg">
                        <div  class="justify-center flex-1 clemax-w-4xl">
                            <div class="flex flex-col md:flex-row">
                                <div class="mt-2 md:mr-2 lg:w-1/2 sm:w-full md:w-1/2">
                                    <div>
                                        <input class="hidden" @click="selectPaymentMethod(2)" id="radio_1" type="radio" name="radio">
                                        <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_1">
                                            <span class="text-xs font-semibold uppercase">
                                                <img src="@/assets/img/qr_scb.png" alt="">
                                            </span>
                                            <span class="mt-3 text-xl font-bold text-center">
                                                โอนเงินด้วย qr code
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="mt-2 md:ml-2 lg:w-1/2 sm:w-full md:w-1/2">
                                    <div>
                                        <input class="hidden" @click="selectPaymentMethod(1)" id="radio_2" type="radio" name="radio">
                                        <label class="flex flex-col items-center p-4 border-2 border-gray-400 cursor-pointer" for="radio_2">
                                            <span class="text-xs font-semibold uppercase">
                                                <img src="@/assets/img/kasikorn-bank.png" alt="">
                                            </span>
                                            <span class="mt-3 text-xl font-bold text-center">
                                                บัญชีธนาคาร
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="flex rounded-lg" v-if="paymentMethod == 2 && money >= 20">
                                <div  class="flex-1">
                                    <div class="flex md:flex-row">
                                        <div class="w-full mt-2">
                                            <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม 
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="mt-4 row">
                                <p v-if="paymentMethod == 1" class="text-base">
                                    โอนเงินมายังบัญชีธนาคารกสิกรไทย สาขายะลา ชื่อบัญชี:มูลนิธิจูจีสร้างสรรค์สังคม (กองทุนสะสางดอกเบี้ย)
                                    <span class="text-sm font-bold text-red-500" style="margin-top: -15px">
                                        &nbsp;&nbsp;
                                        0998339405
                                        <button @click.stop.prevent="copyToClipboard()"  class="px-1 py-1 mb-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700">
                                            <div class="flex">
                                                <span>คัดลอก</span> 
                                                <svg class="w-5 h-5" :class="{'hidden': is_copied}" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                        </button>
                                        <input type="hidden" id="accountNumber" value="0998339405">
                                    </span>
                                </p>
                                <p v-if="paymentMethod == 1" class="font-baseextrabold">
                                    อย่าลืมกรอกฟอร์มยืนยันโอนเงินที่เว็บไซต์ หลังจากโอนเงินผ่านธนาคารแล้วในหน้าถัดไป
                                </p>
                                <!-- <p v-if="paymentMethod == 2" class="font-baseextrabold text-">พร้อมให้บริการเร็วๆนี้</p> -->
                                <p v-if="paymentMethod == 2 && money >= 20" class="text-base">
                                    ชำระผ่านการ สแกน/อัพโหลด QR code ด้วย mobile banking application ของ ธนาคารไทยพานิชย์ ธนาคารทหารไทย ธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรี ธนาคารธนชาต ธนาคารกสิกร ธนาคารออมสิน
                                </p>
                                <p v-if="paymentMethod == 2 && money >= 20" class="font-baseextrabold">คุณจะได้ QR code หลังจากดกปุ่มยืนยัน</p>
                                <p v-if="paymentMethod == 2 && money < 20" class="font-baseextrabold">จำนวนเงินน้อยกว่า 20 บาท ไม่สามารถชำระด้วย qrcode ได้</p>
                                <div class="flex-1" v-if="paymentMethod != ''">
                                    <!-- if not logedIn -->
                                    <div class="flex md:flex-row">
                                            <div class="w-1/2 mt-2">
                                                ชื่อ *
                                            </div>
                                            <div class="w-1/2 mt-2 ml-2">
                                                นามสกุล *
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row">
                                            <div class="w-1/2 mt-2">
                                                <input type="text" v-model="name" class="cc_input_text" :class="{'border-red-500': serverError.name}">
                                                <div v-if="serverError.name" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.name[0] }}
                                                </div>
                                            </div>
                                            <div class="w-1/2 mt-2 ml-2">
                                                <input type="text" v-model="lastname" class="cc_input_text" :class="{'border-red-500': serverError.lastname}">
                                                <div v-if="serverError.lastname" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.lastname[0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row">
                                            <div class="w-1/2 mt-2">
                                                เบอร์โทรศัพท์ *
                                            </div>
                                        </div>
                                        <div class="flex md:flex-row">
                                            <div class="w-1/2 mt-2">
                                                <input type="text" v-model="telephone" class="cc_input_text" :class="{'border-red-500': serverError.telephone}">
                                                <div v-if="serverError.telephone" class="mt-2 text-sm text-red-500">
                                                    {{ serverError.telephone[0] }}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="flex md:flex-row">
                                            <div class="w-full mt-2">
                                                <input type="checkbox" v-model="anonymous" class="cc_input_text"> ไม่ประสงค์ออกนาม 
                                            </div>
                                        </div> -->
                                        <!-- \. if not logedIn -->
                                </div>
                                <button v-if="paymentMethod == 1"
                                    class="w-full mt-2 btncc-riba"
                                    @click="logedIn ? createTransaction() : createTransactionWithoutLogin()" 
                                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                                    :disabled="disabled">
                                    ยืนยันการสะสางดอกเบี้ย
                                </button>
                                <button v-if="paymentMethod == 2 && money >= 20"
                                    class="w-full mt-2 btncc-riba"
                                    @click="logedIn ? createPromptpayTransaction() : createPromptpayTransactionWithoutLogin()" 
                                    :class="{'opacity-50 cursor-not-allowed': disabled}"
                                    :disabled="disabled">
                                    ยืนยันการสะสางดอกเบี้ย
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <form @submit.prevent="createTransaction()" v-if="paymentMethod == 1">
               
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5">
                                ชื่อ นามสกุล {{name}} {{lastname}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5 ">
                                <span class="text-gray-500">(Email: {{email}})</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex rounded-lg">
                    <div  class="flex-1">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 ml-5 mr-5">
                                <input type="text" v-model="telephone" class="cc_input_text" :class="{'border-red-500': serverError.telephone}" placeholder="เบอร์โทรศัพท์">
                                <div v-if="serverError.telephone" class="text-sm text-red-500">
                                    {{serverError.telephone[0]}}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-2 rounded-lg ">
                    <button
                        class="w-full mt-1 ml-5 mr-5 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                        ยืนยันการสะสางดอกเบี้ย
                    </button>
                </div>
            </form> -->

        </div>
   
    </div>  
</template>

<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter.js'
    import Cookies from 'js-cookie'
    export default{
        mounted(){
            let logedIn = this.$store.getters['authMember/logedIn']
            if(logedIn){
                this.retreiveProfile()
                this.retreiveOrder()
            }else{
                this.retreiveOrderWithoutLogin()
            }
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        computed:{
            logedIn(){
                return this.$store.getters['authMember/logedIn']
            },
        },
        data(){
            return{
                paymentMethod: '',
                cartItem : [],

                name: '',
                lastname: '',
                email: '',
                telephone: '',
                anonymous: true,
                money: '',
                order_id: '',

                serverError: '',
                disabled: false,

                is_copied: true,
            }
        },
        methods:{
            createTransactionWithoutLogin(){
                if(this.name == ''){
                    this.serverError = {
                        name: ['กรุณากรอกชื่อ']
                    }
                }else if(this.lastname == ''){
                    this.serverError = {
                        lastname: ['กรุณากรอกนามสกุล']
                    }
                }else if(this.telephone == ''){
                    this.serverError = {
                        telephone: ['กรุณากรอกเบอร์โทรศัพท์']
                    }
                }else{
                    this.$Progress.start()
                    this.disabled = true
                    let data = new FormData();
                    data.append('cart_items', JSON.stringify(this.cartItem))
                    data.append('name', this.name)
                    data.append('lastname', this.lastname)
                    // data.append('email', this.email)
                    data.append('telephone', this.telephone)
                    data.append('anonymous', this.anonymous)
                    http.post(`transferMoneyWithoutLogin/createTransaction`, data)
                        .then(response => {
                            this.$router.push({ name: 'RibaRecheckout', params: {order_id: response.data.order_id_code }})
                            this.$Progress.finish()
                            this.disabled = false
                            this.serverError = ''
                        })
                        .catch(error => {
                            this.$Progress.fail()
                            this.disabled = false
                            this.serverError = error.response.data.errors
                        })
                }    
            },
            createPromptpayTransactionWithoutLogin(){
                if(this.name == ''){
                    this.serverError = {
                        name: ['กรุณากรอกชื่อ']
                    }
                }else if(this.lastname == ''){
                    this.serverError = {
                        lastname: ['กรุณากรอกนามสกุล']
                    }
                }else if(this.telephone == ''){
                    this.serverError = {
                        telephone: ['กรุณากรอกเบอร์โทรศัพท์']
                    }
                }else{
                    this.$Progress.start()
                    this.disabled = true
                    let data = new FormData();
                    data.append('cart_items', JSON.stringify(this.cartItem))
                    data.append('name', this.name)
                    data.append('lastname', this.lastname)
                    data.append('email', this.email)
                    data.append('telephone', this.telephone)
                    data.append('anonymous', Number(this.anonymous))
                    http.post(`omiseWithoutLogin/createPromptpayTransactionWithoutLogin`, data)
                        .then(response => {
                            this.$Progress.finish()
                            this.disabled = false
                            Cookies.remove('cart_items')
                            localStorage.removeItem('myCart')
                            Cookies.remove('riba_amount')
                            this.$router.push({name: 'CartQrPromptpayWithoutLogin', params:{'order_id_code': response.data.order_id_code, 'unmember_id_code': response.data.unmember_id_code}})
                            this.serverError = ''
                        })
                        .catch(error => {
                            if(error.response.data.errors['totalMoney']){
                                var getMeTo = document.getElementById("target-cart-list");
                                getMeTo.scrollIntoView({behavior: 'smooth'}, true);       
                            }
                            this.$Progress.fail()
                            this.disabled = false
                            this.serverError = error.response.data.errors
                        })
                }
            },
            retreiveOrderWithoutLogin(){
                let riba_amount = Cookies.get('riba_amount')
                if(riba_amount == undefined){
                    this.$router.push({name: 'Riba'})
                }else{
                    this.money = riba_amount
                    //remove cart_items cookie
                    // Cookies.remove('cart_items')
                    //generte new cart_items cookie
                    // let currentCart = Cookies.get('cart_items');
                    let item = {
                        'id': 1,
                        'money': riba_amount,
                        'budget_type': 2,
                    }
                    // if(currentCart == undefined){
                        Cookies.set('cart_items', JSON.stringify([item]), {expires: 1})
                    // }else{
                    //     let currentCart = JSON.parse(Cookies.get('cart_items'))
                    //     currentCart.push(item)
                    //     Cookies.set('cart_items', JSON.stringify(currentCart), { expires: 1})
                    // }
                    this.$store.dispatch("authMember/retreiveCartWithoutLogin", JSON.parse(Cookies.get('cart_items')))
                    this.$Progress.start()
                    let data = new FormData();
                    data.append('cart_items', Cookies.get('cart_items'))
                    http.post(`cartWithoutLogin/retreiveCart`, data)
                        .then(response => {
                            this.cartItem = response.data.data 
                            this.$Progress.finish()
                        }) 
                        .catch(() => {
                            this.$Progress.fail()
                            this.disabled = false
                        })
                }
            },
            createPromptpayTransaction(){
                // console.log('creating promptpay transaction')
                this.$Progress.start()
                this.disabled = true
                let data = new FormData();
                data.append('order_id', this.order_id)
                data.append('anonymous', Number(this.anonymous))
                data.append('name', this.name)
                data.append('lastname', this.lastname)
                // data.append('email', this.email)
                data.append('telephone', this.telephone)
                // http.post(`riba/createPromptpayTransaction`, data)
                http.post(`omise/createPromptpayTransaction`, data)
                    .then(() => {
                        this.$Progress.finish()
                        this.disabled = false
                        this.$router.push({name: 'RibaQrPromptpay', params:{'order_id': this.order_id}})
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
            },
            createTransaction(){
                this.$Progress.start()
                this.disabled = true
                let data = new FormData()
                data.append('order_id', this.order_id)
                data.append('name', this.name)
                data.append('lastname', this.lastname)
                data.append('telephone', this.telephone)
                data.append('amount', this.money)
                // data.append('money', this.money)
                data.append('pay_type', this.paymentMethod)
                data.append('anonymous', Number(this.anonymous))
                http.post(`riba/createTransaction`, data)    
                    .then(() => {
                        this.serverError = ''
                        this.disabled = false
                        this.$Progress.finish()
                        this.$router.push({ name: 'RibaRecheckout', params: {order_id: this.order_id }})
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
            },
            selectPaymentMethod(id){
                this.paymentMethod = id
                // this.anonymous = ''
            },
            retreiveOrder(){
                http.get(`riba/order/${this.$route.params.order_id}`)
                    .then(response => {
                        if(response.data.amount == 0){
                            this.$router.push({name: 'Riba'})
                        }else{
                            this.money = response.data.amount
                            this.order_id = this.$route.params.order_id
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        }
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.status == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })

            },
            retreiveProfile(){
                http.get(`member/profile`)
                    .then(response => {
                        this.name = response.data.data.mem_name
                        this.lastname = response.data.data.mem_lastname
                        this.telephone = response.data.data.mem_tel
                        this.email  = response.data.data.mem_email
                    })
                    .catch(error => {
                        if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            copyToClipboard(){
                let accountNumber = document.querySelector('#accountNumber')
                accountNumber.setAttribute('type', 'text')
                accountNumber.select()
                try {
                    document.execCommand('copy');
                    this.is_copied = false
                } catch (err) {
                    alert('Oops, unable to copy');
                }
                /* unselect the range */
                accountNumber.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges() 
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0.00')
            },
        }
    }
</script>