<template>
    <div class="flex items-center justify-between my-1">
        <!-- ฟอร์มสำหรับค้าหา -->
        <div class="flex justify-center flex-1 lg:mr-32">
            <div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
                <div class="absolute inset-y-0 flex items-center pl-2">
                    <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                </div>
                <form @submit.prevent="submitSearchForm">
                    <input v-model="search_keyword" class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-200 border-0 rounded-md" type="text" placeholder="ป้อนหมวดหมู่โครงการ" aria-label="Search"/>
                    <button type="submit" class="hidden">Submit</button>
                </form>
            </div>

            <div>
                <button :disabled="searchDisabled" :class="{'opacity-50 cursor-not-allowed': searchDisabled}" @click="submitSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                    <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                    <span>ค้นหา</span>
                </button>
            </div>
        </div>
        <!-- \.ฟอร์มสำหรับค้าหา -->
    </div>       
</template>

<script>
    export default {
        name: 'project-donation-search-form',
        watch:{
            search_keyword: function(){
                this.$store.dispatch("projectDonation/changeKeyword", this.search_keyword)
                if(this.search_keyword.length == ""){
                    this.searchDisabled = true;
                    this.$Progress.start();
                    this.$store.dispatch(`projectDonation/getProjectDonation`, {
                        page_number: 1
                    })
                        .then(() => {
                            this.$Progress.finish();
                        })
                        .catch((err) => {
                            if(err.response.status == 401) {
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                            this.$Progerss.fail();
                        })
                }else{
                    this.searchDisabled = false;
                }
            }
        },
        data(){
            return{
                search_keyword: '',
                searchDisabled: true,
                disabled: false,
            }
        },
        methods:{
            submitSearchForm(){
                if(this.search_keyword == ""){
                    this.$swal.fire("ป้อนชื่อโครงการที่ต้องการค้นหา")
                }else{
                    this.$Progress.start()
                    this.$store.dispatch("projectDonation/submitSearchForm", {keyword: this.search_keyword, page_number: 1})
                        .then(() => {
                            this.$Progress.finish()
                        })
                        .catch(error => {
                            if(error.response.status == 401){
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                            this.$Progress.fail()
                        })
                }
            }
        }
}
</script>

<style lang="scss" scoped>

</style>