<template>
    <section class="bgcc">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">ยืนยันการบริจาค</h1>
            </div>
            <div class="flex ml-4 mr-4 rounded-lg bg-gray-50 dark:bg-gray-900">
                
                <!-- desktop show -->
                <div  class="flex-1 hidden max-w-4xl m-5 md:block lg:block">
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            โครงการ
                        </div>
                        <div class="md:text-right sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            {{project.name}}
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            จำนวนเงิน
                        </div>
                        <div class="md:text-right sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            {{showMoney(money)}} บาท
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            ธนาคารกสิกรไทย สาขายะลา 099-8-33940-5
                        </div>
                        <div class="md:text-right sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            <span class="text-sm font-bold text-red-500" style="margin-top: -15px">
                                <button @click.stop.prevent="copyToClipboard()"  class="px-1 py-1 mb-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700">
                                    <div class="flex">
                                        <span>คัดลอก</span> 
                                        <svg class="w-5 h-5" :class="{'hidden': is_copied}" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                </button>
                                <input type="hidden" id="accountNumber" value="0998339405">
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-1/4">
                            ชื่อบัญชี
                        </div>
                        <div class="md:text-right sm:p-1 md:p-1 sm:w-full md:w-3/4">
                            มูลนิธิจูจีสร้างสรรค์สังคม (กองทุนสะสางดอกเบี้ย)
                        </div>
                    </div>
                </div>
                <!-- \.desktop show -->

                <!-- mobile show -->
                <div  class="flex-1 max-w-4xl m-5 md:hidden lg:hidden">
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            โครงการ : <i>{{project.name}}</i>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            <i>จำนวนเงิน : {{showMoney(money)}} บาท </i>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-2/4">
                            ธนาคารกสิกรไทย สาขายะลา <i>099-8-33940-5 </i>
                            <span class="text-sm font-bold text-red-500" style="margin-top: -15px">
                                <button @click.stop.prevent="copyToClipboard()"  class="px-1 py-1 mb-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700">
                                    <div class="flex">
                                        <span>คัดลอก</span> 
                                        <svg class="w-5 h-5" :class="{'hidden': is_copied}" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                </button>
                                <input type="hidden" id="accountNumber" value="0998339405">
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div class="font-bold sm:p-1 md:p-1 sm:w-full md:w-1/4">
                            ชื่อบัญชี : <i> มูลนิธิจูจีสร้างสรรค์สังคม (กองทุนสะสางดอกเบี้ย)</i>
                        </div>
                    </div>
                </div>
                <!-- \.mobile show -->
            </div>
            
            <form @submit.prevent="checkout()">
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                วันที่โอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-4/12 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.year}"
                                    v-model="year">
                                    <option style="display: none">--ปี--</option>
                                    <option v-for="(year, index) in yearList" :value="year" :key="index">{{year}}</option>
                                </select>
                                <div v-if="serverError.year" class="mt-2 text-sm text-red-500">
                                    {{serverError.year[0]}}
                                </div>
                            </div>
                            <div class="w-4/12 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.month}"
                                    v-model="month"
                                    @change="changeMonth($event.target.value)">
                                    <option value="" style="display: none">--เดือน--</option>
                                    <option v-for="(month, index) in 12" :value="formatMonth(month)" :key="index">{{formatMonth(month)}}</option>
                                </select>
                                <div v-if="serverError.month" class="mt-2 text-sm text-red-500">
                                    {{serverError.month[0]}}
                                </div>
                            </div>
                            <div class="w-4/12 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.day}"
                                    v-model="day">
                                    <option value="" style="display: none">--วันที่--</option>
                                    <option v-for="(day, index) in dayList" :value="day" :key="index">
                                        {{formatDay(day)}}
                                    </option>
                                </select>
                                <div v-if="serverError.day" class="mt-2 text-sm text-red-500">
                                    {{serverError.day[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                เวลาโอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.hour}"
                                    v-model="hour">
                                    <option value="" style="display: none">--ชั่วโมง--</option>
                                    <option v-for="(h, index) in 24" :value="hourFormat(index)" :key="index">{{hourFormat(index)}}</option>
                                </select>
                                <div v-if="serverError.hour" class="mt-2 text-sm text-red-500">
                                    {{serverError.hour[0]}}
                                </div>
                            </div>
                            <div class="w-2/4 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.minute}"
                                    v-model="minute">
                                    <option value="" style="display: none">--นาที--</option>
                                    <option v-for="(m, index) in 60" :value="minuteFormat(index)" :key="index">{{minuteFormat(index)}}</option>
                                </select>
                                <div v-if="serverError.minute" class="mt-2 text-sm text-red-500">
                                    {{serverError.minute[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                หลักฐานการโอนเงิน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-1 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                <input type="file"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 bg-white bg-auto border rounded"
                                    :class="{'border-red-500': serverError.slip}"
                                    @change="onFileChange">
                                <div v-if="serverError.slip" class="mt-2 text-sm text-red-500">
                                    {{serverError.slip[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                หมายเหตุ
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 mr-1 md:mr-1 lg:mr-1">
                                <textarea rows="5"
                                class="w-full px-3 py-2 leading-tight text-gray-700 bg-white bg-auto border rounded"
                                :class="{'border-res-500': serverError.description}"
                                v-model="description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-2 ml-10 mr-10 rounded-lg ">
                    <button
                        class="w-full mt-1 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                    ยืนยันการสะสางดอกเบี้ย
                    </button>
                </div>
            </form>
            
        </div> 
    </section>
</template>

<script>
    import http from '@/services/FrontendService'
    import filter from '@/helpers/filter'
    import moment from 'moment'

    export default {
        mounted(){
            this.retreiveProjectInfo()
            if(!localStorage.getItem('donateAmount')){
                this.$router.push({name: 'Donate'})
            }
        },
        created(){
            window.location.hash = 'top'
            let beforThisyear = moment().subtract(1, 'year').format("YYYY")
            let yearList = [parseInt(beforThisyear)]
            this.yearList.push(parseInt(beforThisyear))
            let i = 1
            while(i < 2){
                yearList.push(parseInt(yearList[i-1])+1)
                this.yearList.push(parseInt(yearList[i-1])+1)
                i++
            }
            this.year = moment().format("YYYY")
            this.month = moment().format("MM")
            this.dayList = moment().daysInMonth()
            this.day = moment().format("D")
        },
        data(){
            return{
                project: {},
                project_id: this.$route.params.id,
                disabled: false,
                serverError: '',
                is_copied: true,
                //form
                yearList: [],
                dayList: [],
                money: localStorage.getItem('donateAmount'),
                year: '',
                month: '',
                day: '',
                hour: '',
                minute: '',
                slip: '',
                description: '',
            }
        },
        methods:{
            checkout(){
                this.disabled = true
                this.$Progress.start()
                let data = new FormData()
                data.append("money", this.money)
                data.append("year", this.year)
                data.append("month", this.month)
                data.append("day", this.day)
                data.append("hour", this.hour)
                data.append('minute', this.minute)
                data.append('slip', this.slip)
                data.append('project_id', this.project_id)
                data.append('_method', 'patch')
                http.post('donate/checkout', data)
                    .then(() => {
                        this.$store.dispatch("authMember/retreiveCart")
                        this.serverError = ''   
                        this.$swal.fire({
                            icon: 'success',
                            title: 'สำเร็จ',
                            text: 'ขอบคุณที่บริจาค',
                            confirmButtonText: 'OK',
                        }).then(() => {
                            this.$router.push({name: 'Donate'})
                            localStorage.removeItem('donateAmount')
                        })
                        this.disabled = false
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
            },
            onFileChange(e){
                const slip = e.target.files[0]
                this.slip = e.target.files[0]
                this.imgUrl = URL.createObjectURL(slip)
            },
            retreiveProjectInfo(){
                this.$Progress.start()
                http.get(`frontendProject/projectInfo/${this.project_id}`)
                    .then(response => {
                        this.project = response.data.project
                        this.$Progress.finish()
                    })
                    .catch(() => {
                        this.$Progress.fail()
                    })
            },
            minuteFormat(m){
                return moment(m, "mm").format("mm")
            },
            hourFormat(h){
                return moment(h, "HH").format("HH")
            },
            changeMonth(month){
               this.dayList = moment(moment().month(month-1).year(this.year).format('YYYY-MM')).daysInMonth()
            },
            formatMonth(month){
                return moment(month, "m").format("mm")
            },
            formatDay(d){
                return filter.dayFormat(d)
            },
            copyToClipboard(){
                let accountNumber = document.querySelector('#accountNumber')
                accountNumber.setAttribute('type', 'text')
                accountNumber.select()
                try {
                    document.execCommand('copy');
                    this.is_copied = false
                } catch (err) {
                    alert('Oops, unable to copy');
                }
                /* unselect the range */
                accountNumber.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges() 
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0.00')
            },
        }    
    }
</script>

<style lang="scss" scoped>

</style>