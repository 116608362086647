<template>
      <!-- Desktop sidebar -->
      <aside class="z-20 flex-shrink-0 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 xl:block">
        <div class="py-4 text-gray-500 dark:text-gray-400">
          
          <a class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
            href="#">
            <img src="@/assets/img/logo-main.png" alt="logo" class="box-content border-0 w-50 h-30">
          </a>

          <ul class="mt-6">
            <!-- Dashboard -->
            <li class="relative px-6 py-3">
              <span class="absolute inset-y-0 left-0 w-1 bg-orange-600 rounded-tr-lg rounded-br-lg" aria-hidden="true"></span>
              <router-link to="/dashboard" @click="closeToggleMenu" class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
                <i class="w-5 h-5 fa-solid fa-house"> </i>
                <span class="ml-4">Dashboard</span>
              </router-link>
            </li>
            <!-- \.Dashboard -->
            
            <!-- ระบบยื่นโครงการ -->
            <li class="relative px-6 py-3">
              <button @click="showToggleMenu('project')" class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <i class="w-5 h-5 fa-solid fa-bars-progress"></i>
                  <span class="ml-4"> ระบบยื่นโครงการ</span>
                </span>
                <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
              </button>
              <template x-if="isPagesMenuOpen" :class="{'block': toggleMenu == 'project' && hideOrShow}">
                <ul class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900" x-transition:enter="transition-all ease-in-out duration-300" x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl" x-transition:leave="transition-all ease-in-out duration-300" x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0" aria-label="submenu">
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/project-Category" class="w-full">จัดการหมวดหมู่โครงการ</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#">
                      จัดการเกณฑ์การประเมิน
                    </a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#">
                      จัดการข้อมูลโครงการ
                    </a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#"> รับเรื่องและตรวจสอบโครงการ</a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#"> ระบบพิจารณา</a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#">  อนุมัติโครงการ</a>
                  </li>
                </ul>
              </template>
            </li>
            <!--\.ระบบยื่นโครงการ -->

            <!-- จัดการการบริจาค -->
            <li class="relative px-6 py-3">
              <button @click="showToggleMenu('dermaMenu')" class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <i class="w-5 h-5 fa-solid fa-hand-holding-dollar"></i>
                  <span class="ml-4"> จัดการบริจาค</span>
                </span>
                <i class="w-4 h-4 fa-solid fa-angle-down"></i>
              </button>
              <template x-if="isPagesMenuOpen" :class="{'block': toggleMenu == 'dermaMenu' && hideOrShow}">
                <ul x-transition:enter="transition-all ease-in-out duration-300" x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl" x-transition:leave="transition-all ease-in-out duration-300" x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0" class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900" aria-label="submenu">
                  <!-- <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/backend/adminDerma" class="w-full">ข้อมูลการบริจาค</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                      <router-link to="/backend/adminDermaCat" class="w-full">หมวดหมู่และโครงการการ</router-link>
                  </li> -->
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/donateManagement" class="w-full">ข้อมูลการบริจาค</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/dermaIncome" class="w-full">ตรวจสอบการบริจาค</router-link>
                  </li>
                </ul>
              </template>
            </li>
            <!--\.จัดการการบริจาค -->
            
            <!-- จัดการสมาชิก -->
            <li class="relative px-6 py-3">
              <button @click="showToggleMenu('permission')" class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <i class="fa-solid fa-users"></i>
                  <span class="ml-4"> จัดการผู้ใช้งาน</span>
                </span>
                <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
              </button>
              <template x-if="isPagesMenuOpen" :class="{'block': toggleMenu == 'permission' && hideOrShow}">
                <ul x-transition:enter="transition-all ease-in-out duration-300" x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl" x-transition:leave="transition-all ease-in-out duration-300" x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0" class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900" aria-label="submenu">
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/role" class="w-full">บทบาท</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/permission" class="w-full">สิทธิ์</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/member" class="w-full">สมาชิก</router-link>
                  </li>
                </ul>
              </template>
            </li>
            <!-- \.จัดการสมาชิก -->

          </ul>
        </div>
      </aside>

      <!-- Mobile sidebar -->
      <!-- Backdrop -->
      <aside :class="{'hidden': mobileMenu}" class="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-gray-100 dark:bg-gray-800">
        <div class="py-4 text-gray-500 dark:text-gray-400">
          <a class="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200" href="#">Inventory</a>
          <ul class="mt-6">
            <li class="relative px-6 py-3">
              <span class="absolute inset-y-0 left-0 w-1 bg-orange-600 rounded-tr-lg rounded-br-lg" aria-hidden="true"></span>
              <router-link to="/backend" @click="closeToggleMenu" class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
                <svg class="w-5 h-5" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                </svg>
                <span class="ml-4">Dashboard</span>
              </router-link>
            </li>
            <!-- ระบบยื่นโครงการ -->
            <li class="relative px-6 py-3">
              <button @click="showToggleMenu('project')" class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <i class="w-5 h-5 fa-solid fa-bars-progress"></i>
                  <span class="ml-4"> ระบบยื่นโครงการ</span>
                </span>
                <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
              </button>
              <template x-if="isPagesMenuOpen" :class="{'block': toggleMenu == 'project' && hideOrShow}">
                <ul class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900" x-transition:enter="transition-all ease-in-out duration-300" x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl" x-transition:leave="transition-all ease-in-out duration-300" x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0" aria-label="submenu">
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/project-Category" class="w-full">จัดการหมวดหมู่โครงการ</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#">
                      จัดการเกณฑ์การประเมิน
                    </a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#">
                      จัดการข้อมูลโครงการ
                    </a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#"> รับเรื่องและตรวจสอบโครงการ</a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#"> ระบบพิจารณา</a>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <a class="w-full" href="#">  อนุมัติโครงการ</a>
                  </li>
                </ul>
              </template>
            </li>
            <!-- จัดการการบริจาค -->
            <li class="relative px-6 py-3">
              <button @click="showToggleMenu('dermaMenu')" class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <i class="w-5 h-5 fa-solid fa-hand-holding-dollar"></i>
                  <span class="ml-4"> จัดการบริจาค</span>
                </span>
                <i class="w-4 h-4 fa-solid fa-angle-down"></i>
              </button>
              <template x-if="isPagesMenuOpen" :class="{'block': toggleMenu == 'dermaMenu' && hideOrShow}">
                <ul x-transition:enter="transition-all ease-in-out duration-300" x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl" x-transition:leave="transition-all ease-in-out duration-300" x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0" class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900" aria-label="submenu">
                  <!-- <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/backend/adminDerma" class="w-full">ข้อมูลการบริจาค</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                      <router-link to="/backend/adminDermaCat" class="w-full">หมวดหมู่และโครงการการ</router-link>
                  </li> -->
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/donateManagement" class="w-full">ข้อมูลการบริจาค</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/dermaIncome" class="w-full">ตรวจสอบการบริจาค</router-link>
                  </li>
                </ul>
              </template>
            </li>
            <!--\.จัดการการบริจาค -->
            <!-- จัดการสมาชิก -->
            <li class="relative px-6 py-3">
              <button @click="showToggleMenu('permission')" class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <i class="fa-solid fa-users"></i>
                  <span class="ml-4"> จัดการผู้ใช้งาน</span>
                </span>
                <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                </svg>
              </button>
              <template x-if="isPagesMenuOpen" :class="{'block': toggleMenu == 'permission' && hideOrShow}">
                <ul x-transition:enter="transition-all ease-in-out duration-300" x-transition:enter-start="opacity-25 max-h-0" x-transition:enter-end="opacity-100 max-h-xl" x-transition:leave="transition-all ease-in-out duration-300" x-transition:leave-start="opacity-100 max-h-xl" x-transition:leave-end="opacity-0 max-h-0" class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900" aria-label="submenu">
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/role" class="w-full">บทบาท</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/permission" class="w-full">สิทธิ์</router-link>
                  </li>
                  <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
                    <router-link to="/dashboard/member" class="w-full">สมาชิก</router-link>
                  </li>
                </ul>
              </template>
            </li>
            <!-- \.จัดการสมาชิก -->
          </ul>
          <ul>
            <li class="relative px-6 py-3">
              <button @click="onclickLogout" class="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">Logout</button>
            </li>
          </ul>
        </div>
      </aside>
      
</template>

<script>
export default {

  data(){
    return{
      toggleMenu: '',
      hideOrShow: false,
      // sidebar: true,
    }
  },

  computed:{
    mobileMenu(){
      return this.$store.getters['sidebar/mobileMenu']
    }
  },

  methods: {
    closeToggleMenu(){
      this.toggleMenu4 = false
    },
    showToggleMenu(selectedMenu){
      if(this.toggleMenu == ''){
        this.toggleMenu = selectedMenu
        this.hideOrShow = !this.hideOrShow
      }else if(this.toggleMenu == selectedMenu && this.toggleMenu != ''){
        this.toggleMenu = selectedMenu
        this.hideOrShow = !this.hideOrShow
      }else{
        this.toggleMenu = selectedMenu
        this.hideOrShow = true
      }
    },
    onclickLogout(){
      localStorage.removeItem('user')
      // กลับไปหน้า login
      this.$router.push({ name:'Login'})
      // window.location.href = "/login"
    }
  },
}
</script>