<template>
    <section class="bgcc">
        <div class="container pt-40 pb-20 mx-auto text-center">
            <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
                <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">ความรอบรู้ด้านการเงินในอิสลาม</h2>
                <p>องค์ความรู้เกี่ยวกับการเงินในอิสลาม</p>
            </div>
            <!-- Clip 1-2 -->
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/VJ6AjXLA0O8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">การจัดการเงินที่ไม่สอดคล้องกับซารีอะฮ์ Pengurusan Wang Tidak Patuh Syariah</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/rTbcY_q8Zu4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">ดอกเบี้ย : ปัญหาและทางออกในสังคมยุคใหม่</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>

            </div>
            <!-- Clip 3-4 -->
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/wZc215e0Ks8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">ดร.มุสลิม คาเรง :: ประสบการณ์การจัดการเงินดอกเบี้ยเพื่อสาธารณประโยชน์ในประเทศไทย</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/lDDnG2x12B0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">อ.อัลอามีน มะแต :: กระบวนการสะสางดอกเบี้ยทางการเงินเพื่อสาธารณประโยชน์: กรณีศึกษาการลงทุนในตราสารทุน</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>

            </div>
            <!-- Clip 5-6 -->
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/b4LJ8IQs6u4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">อ.อัดนัน อัลฟารีตีย์ :: เหตุผลในเชิงเศรษฐกิจของการห้ามดอกเบี้ย</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/SX9Bq6oo_b4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">ดร.ฆอซาลี เบ็ญหมัด :: ประเด็นถกเถียงเกี่ยวกับการจัดการเงินดอกเบี้ยเพื่อสาธารณประโยชน์</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>

            </div>
            <!-- Clip 7-8 -->
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/LOx2UdOTe9M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">ผศ.ดร.อับดุลรอยะ บินเซ็ง :: ดอกเบี้ยในศาสนาต่างๆ</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/j6OW2gR-29s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">ดร.นิอาบาดี มิง :: ดอกเบี้ยในกฎหมายอิสลาม</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>

            </div>
            <!-- Clip 9-10 -->
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/2n0pF4VysEU?start=1016" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">รายการ วิถีรอมฎอน ปี8 EP29 Startup ชายแดนใต้กับ CuCi มูลนิธิสร้างสรรค์สังคม</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/6Hs1eeR8QnQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">รายการ "นวัตกรรมเพื่อสังคมน่าอยู่ " การบริหารจัดการดอกเบี้ย เพื่อสาธารณประโยชน์</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>

            </div>
            <!-- Clip 11-12 -->
            <div class="flex flex-wrap items-center mb-4">
                <div class="w-full p-4 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                        <!-- <img src="@/assets/img/m1.jpg" class="object-cover w-full h-64"/>  -->
                        
                        <iframe class="object-cover w-full h-80 " src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmuslimtimethailand%2Fvideos%2F392599323057366%2F&show_text=false&width=560&t=765" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">วิถีธรรม วิถีไทย | EP.79 มูลนิธิ CuCi สร้างสรรค์สังคม</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div>
                <!-- <div class="w-full p-4 sm:w-1 md:w-1 lg:w-6/12 xl:w-6/12 2xl:w-6/12 ">
                    <router-link to="/article" class="block text-left"> 
                       
                        <iframe class="object-cover w-full h-80 " src="https://www.youtube.com/embed/rTbcY_q8Zu4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div class="flex items-center justify-between px-4 py-3 bg-green-200">
                            <h5 class="text-base font-semibold text-gray-900">ดอกเบี้ย : ปัญหาและทางออกในสังคมยุคใหม่</h5>
                           <i class="fas fa-book-reader"></i>
                        </div> 
                    </router-link>
                  
                </div> -->

            </div>
            
            <!-- <a href="#" class="inline-block px-6 py-2 text-white bg-green-600 border-2 border-green-600 rounded-md hover:text-green-600 hover:bg-green-200">ดูทั้งหมด</a> -->
        </div> 
    </section>
</template>

<script>
    export default{
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }
</script>