<template>
    <a class="text-xl flexcc-top">
      <div class="flex items-center">
        <span> การบริจาค</span>
      </div>
    </a>
    <br/>

    <report></report>

    <item-list></item-list>

  </template>
  
<script>
    import ItemListVue from './ItemList.vue';
    import ReportVue from './Report.vue';
    // import http from '@/services/AuthServices'
    export default{
      components:{
        'report': ReportVue,
        'item-list': ItemListVue
      },
      data() {
        return {
          show: false,
        };
      },
      methods: {
        // openConfirmModal(){
        //   this.show = true  
        // },
        // closeConfirmModal(){
        //   this.show = false
        // }
      }
    }
  </script>
  
  <style>
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }
  
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    input:checked + .slider {
      background-color: #2196f3;
    }
  
    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
  
    .slider.round:before {
      border-radius: 50%;
    }
  
  
  /* The Modal (background) */
    .modal {
      transition: opacity 0.25s ease;
    }
    body.modal-active {
      overflow-x: hidden;
      overflow-y: visible !important;
    }
  </style>
  