<template>
    <a class="mt-5 b_title">
      <div class="flex items-center text-gray-600">
        <span> ข้อมูลการบริจาค</span>
      </div>
    </a>
    <div class="grid gap-6 mt-5 mb-8 md:grid-cols-2 xl:grid-cols-4">
      <div class="flex items-center p-4 bg-green-100 rounded-lg shadow-xs shadow-lg dark:bg-gray-800 shadow-emerald-100 hover:bg-emerald-400 hover:text-white">
        <div class="p-3 mr-4 text-green-500 rounded-full bg-green-50 dark:text-green-100 dark:bg-green-500">
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div>
          <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            จำนวนหมวดหมู่
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">6</p>
        </div>
      </div>
      <div class="flex items-center p-4 bg-green-100 rounded-lg shadow-xs shadow-lg dark:bg-gray-800 shadow-emerald-100 hover:bg-emerald-400 hover:text-white">
        <div
          class="p-3 mr-4 text-green-500 rounded-full bg-green-50 dark:text-green-100 dark:bg-green-500"
        >
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path>
          </svg>
        </div>
        <div>
          <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            จำนวนโครงการ
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">3</p>
        </div>
      </div>
  
      <!-- Card -->
      <div
        class="flex items-center p-4 bg-green-100 rounded-lg shadow-xs shadow-lg dark:bg-gray-800 shadow-emerald-100 hover:bg-emerald-400 hover:text-white"
      >
        <div
          class="p-3 mr-4 text-green-500 rounded-full bg-green-50 dark:text-green-100 dark:bg-green-500"
        >
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div>
          <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            จำนวนการบริจาค (ครั้ง)
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
            5
          </p>
        </div>
      </div>
      <!-- Card -->
      <div
        class="flex items-center p-4 bg-green-100 rounded-lg shadow-xs shadow-lg dark:bg-gray-800 shadow-emerald-100 hover:bg-emerald-400 hover:text-white"
      >
        <div
          class="p-3 mr-4 text-green-500 rounded-full bg-green-50 dark:text-green-100 dark:bg-green-500"
        >
          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div>
          <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            จำนวนเงิน (บาท)
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
            6,839.30
          </p>
        </div>
      </div>
    </div>
    <section>
      <div class="container pb-20 mx-auto">
        <div class="flex flex-wrap items-center mb-4">
          <div class="w-full p-4 lg:flex-1">
            <h6 class="font-medium text-emerald-600">ข้อมูลการบริจาค</h6>
            <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">
              หมวดหมู่และโครงการ
            </h2>
          </div>
          <div class="w-full p-4 lg:w-auto">
            <router-link :to="{name: 'ProjectDonation'}">
              <a href="#" class="inline-block px-6 py-2 text-white border-2 rounded-md bg-emerald-600 border-emerald-600 hover:text-emerald-600 hover:bg-emerald-100">จัดการโครงการ</a>
            </router-link>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full p-4 xl:w-4/12 sm:w-6/12 md:w-4/12">
            <a href="#" class="block px-6 py-8 bg-gray-200 rounded-lg shadow-lg hover:bg-emerald-400 hover:text-white">
              <i class="mb-4 text-4xl text-emerald-700 fas fa-border-all"></i>
              <h5 class="mb-1 text-lg font-semibold">หมวดหมู่ทั้งหมด</h5>
              <p class="text-sm">
                รายละเอียดแต่ละโครงการในหมวดหมู่นี้ <br />
                จำนวน 14 โครงการ
              </p>
            </a>
          </div>
          <div class="w-full p-4 xl:w-4/12 sm:w-6/12 md:w-4/12">
            <a href="#" class="block px-6 py-8 bg-gray-200 rounded-lg shadow-lg hover:bg-emerald-400 hover:text-white">
              <i class="mb-4 text-4xl text-emerald-700 fa-school fas"></i>
              <h5 class="mb-1 text-lg font-semibold">การศึกษา</h5>
              <p class="text-sm">
                การศึกษาและการพัฒนาทรัพยกรมนุษย์ <br />
                จำนวน - โครงการ
              </p>
            </a>
          </div>
          <div class="w-full p-4 xl:w-4/12 sm:w-6/12 md:w-4/12">
            <a href="#" class="block px-6 py-8 bg-gray-200 rounded-lg shadow-lg hover:bg-emerald-400 hover:text-white">
              <i class="mb-4 text-4xl text-emerald-700 fa-retweet fas"></i>
              <h5 class="mb-1 text-lg font-semibold">ศาสนาและสังคม</h5>
              <p class="text-sm">
                ศาสนาและสังคม เมืองและชุมชนที่ยั่งยืน <br />
                จำนวน - โครงการ
              </p>
            </a>
          </div>
          <div class="w-full p-4 xl:w-4/12 sm:w-6/12 md:w-4/12">
            <a href="#" class="block px-6 py-8 bg-gray-200 rounded-lg shadow-lg hover:bg-emerald-400 hover:text-white">
              <i class="mb-4 text-4xl text-emerald-700 fa-water fas"></i>
              <h5 class="mb-1 text-lg font-semibold">ภัยพิบัติ</h5>
              <p class="text-sm text-emerald-600">
                วาตภัย อุทกภัย ทุกขภิกขภัย พายุฝนฟ้าคะนอง คลื่นพายุซัดฝั่ง
                แผ่นดินไหว แผ่นดินถล่ม ไฟป่า <br />
                จำนวน - โครงการ
              </p>
            </a>
          </div>
          <div class="w-full p-4 xl:w-4/12 sm:w-6/12 md:w-4/12">
            <a href="#" class="block px-6 py-8 bg-gray-200 rounded-lg shadow-lg hover:bg-emerald-400 hover:text-white">
              <i class="mb-4 text-4xl text-emerald-700 fa-heartbeat fas"></i>
              <h5 class="mb-1 text-lg font-semibold">สุขภาพและการแพทย์ </h5>
              <p class="text-sm text-emerald-600">
                สุขภาพและความเป็นอยู่ที่ดี สถานการณ์โรคระบาด  อุปกรณ์ทางการแพทย์ <br />
                จำนวน - โครงการ
              </p>
            </a>
          </div>
          <div class="w-full p-4 xl:w-4/12 sm:w-6/12 md:w-4/12">
            <a href="#" class="block px-6 py-8 bg-gray-200 rounded-lg shadow-lg hover:bg-emerald-400 hover:text-white">
              <i class="mb-4 text-4xl text-emerald-700 fa-balance-scale fas"></i>
              <h5 class="mb-1 text-lg font-semibold">กระบวนการยุติธรรม</h5>
              <p class="text-sm text-emerald-600">
                การเข้าถึงกระบวนการยุติธรรมภายใต้ กฎหมายที่ บังคับใช้หลายตัว <br />
                จำนวน - โครงการ
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
      };
    },
    methods: {
      
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
  