<template>
    <div class="container pt-40 pb-20 pl-4 mx-auto">
        <div class="container mx-auto ">
            <div class="px-4 py-4 bgcc01 "> 
                <a class="flexcc-top">
                  <div class="container flex items-center">
                      <span>บัตรกำนัล Gift Voucher</span>
                  </div>
                </a>
                <ul class="flex flex-col flex-wrap pl-0 mb-4 list-none border-b-0 nav nav-tabs md:flex-row" id="tabs-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                      <a @click="retreiveRequestVoucher()" href="#" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL"
                      aria-selected="true">
                      
                      <div class="">
                          <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                              <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span>{{ requestAmount }}</span></span>
                              <span class="ml-1.5"><i class="fas fa-gifts"></i>  ขอใช้บัตรกำนัล </span>
                          </strong>
                      </div>
                
                    </a>
                  </li>
                  <li class=" nav-item" role="presentation">
                    <a @click="retreiveComfirmedUsing()" href="#" class="block px-2 py-2 my-2 text-xs font-medium leading-tight uppercase border-t-0 border-b-2 border-transparent border-x-0 hover:border-transparenthover:border-x-2focus:border-transparent active" id="tabs-GVALL-tab" data-bs-toggle="pill" data-bs-target="#tabs-GVALL" role="tab" aria-controls="tabs-GVALL" aria-selected="true">
                      <div class="">
                        <strong class="relative inline-flex items-center rounded border border-emerald-800 bg-orange-200  hover:text-white  hover:bg-orange-500  hover:border-white px-10 py-1.5 text-xs font-medium">
                          <span class="absolute flex items-center justify-center w-10 h-10 text-white bg-orange-500 border border-white rounded-full hover:border-orange-500 roun -top-2 -right-2 items hover:bg-white hover:text-emerald-800"><span> {{ confirmedAmount }}</span></span>
                          <span class="ml-1.5"><i class="fas fa-gifts"></i>  ยืนยันการใช้บัตรกำนัลเเล้ว </span>
                        </strong>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="w-full overflow-x-auto">
                    <table class="w-full whitespace-no-wrap">
                        <thead>
                            <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                                <th  class="px-4 py-3 font-semibold">#</th>
                                <th  class="px-4 py-3 font-semibold">ชื่อ สกุล</th>
                                <th  class="px-4 py-3 font-semibold">email</th>
                                <th  class="px-4 py-3 font-semibold">gift voucher</th>
                                <th  class="px-4 py-3 font-semibold">ยืนยัน</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                            <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in members" :key="index">
                                <td class="px-4 py-3 text-sm">
                                    {{ index+1 }}
                                </td>
                                <td class="px-4 py-3">
                                    {{ item.mem_name }} {{ item.mem_lastname }}
                                </td>
                                <td  class="px-4 py-3 text-sm">
                                    {{ item.mem_email }}
                                </td>
                                <td  class="px-4 py-3 text-sm">
                                    {{ item.name }} {{ item.unit_name }}
                                </td>
                                <td class="px-4 py-3 text-sm">
                                    <span @click="confirmUsing(item.id)" v-if="item.used_status == 1"> 
                                        <i class="far fa-check-circle"></i> confirm
                                    </span>
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from '@/services/AuthService'
    export default {
        mounted(){
            this.retreiveRequestVoucher() 
            this.getConfirmedAmount()
            this.getRequestAmount()  
        },
        data(){
            return{
                members: [],
                requestAmount: 0,
                confirmedAmount: 0,
            }
        },
        methods:{
            confirmUsing(id){
                let data = new FormData()
                data.append('id', id)
                http.post(`giftVoucher/confirmUsing`, data)
                    .then(() => {
                        this.getRequestAmount()
                        this.getConfirmedAmount()
                        this.retreiveRequestVoucher()
                        this.$swal.fire({
                            icon: 'success',
                            title: 'สำเร็จ',
                            text: 'ยืนยันการใช้สำเร็จ',
                            confirmButtonText: 'ok',
                        })
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.statu == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveRequestVoucher(){
                this.$Progress.start()
                http.get(`giftVoucher/requestVoucher/${this.$route.params.organization_id}`)
                    .then(response => {
                        this.members = response.data.data
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.statu == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })
            },
            retreiveComfirmedUsing(){
                this.$Progress.start()
                http.get(`giftVoucher/requestVoucherConfirmed/${this.$route.params.organization_id}`)
                    .then(response => {
                        this.members = response.data.data
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.statu == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    })   
            },
            getRequestAmount(){
                http.get(`giftVoucher/requestVoucherAmount/${this.$route.params.organization_id}`)
                    .then(response => {
                        this.requestAmount = response.data.amount
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.statu == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })        
            },
            getConfirmedAmount(){
                http.get(`giftVoucher/requestVoucherConfirmedAmount/${this.$route.params.organization_id}`)
                    .then(response => {
                        this.confirmedAmount = response.data.amount
                    })
                    .catch(error => {
                        if(error.response.statu == 401 || error.response.statu == 419){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    }) 
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>